<template>
  <Frame :width="3840" :height="2160" bgColor="#03050c">
    <div v-if="isLoading" class="loading">loading...</div>
    <div v-else class="container">
      <div class="header">
        <display-header />
      </div>
      <div class="content">
        <div class="left">
          <div class="daily">
            <daily-sales />
          </div>
          <div class="alert">
            <alert />
          </div>
        </div>
        <div class="center">
          <div class="right-upper">
            <div class="flow">
              <flow-direction />
              <overall class="overall"/>
            </div>
          </div>

        </div>
        <div class="right">
          <div class="servers">
            <servers/>
          </div>
          <div class="star-product">
            <realtime />
          </div>
        </div>
      </div>
    </div>
  </Frame>
</template>

<script>
import DisplayHeader from '@/components/DisplayHeader'
import Overall from '@/components/Overall'
import DailySales from '@/components/DailySales'
import Alert from '@/components/Alert'
import FlowDirection from '@/components/FlowDirection'
import Servers from '@/components/Servers'
import Realtime from '@/components/Realtime'
import Frame from '@/components/Common/Frame'
export default {
  name: 'Home',
  components: {
    DisplayHeader,
    Overall,
    DailySales,
    Alert,
    FlowDirection,
    Servers,
    Realtime,
    Frame
  },
  computed: {
    isLoading () {
      return this.$store.state.loading
    }
  },
  created () {
    this.$store.dispatch('updateAll')
    // setInterval(() => { // 更新数据的间隔时间
    //   this.$store.dispatch('updateAll')
    // }, 10 * 1000)
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/variable.scss";
.loading{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 50px;
}
.container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 40px;
  .header{
    height: 200px;
    margin: 0 40px;
    border-bottom: 4px solid rgba(255,255,255,.3)
  }
  .content{
    margin-top: $margin;
    flex: 1;
    display: flex;
    margin-left: $margin-around;
    .left{
      width: 920px;
      display: flex;
      flex-direction: column;
      .total{
        height: 400px;
      }
      .daily{
        /* margin-top: $margin; */
        height: 600px;
      }
      .alert{
        margin-top: $margin;
        height: 1280px;
      }
    }
    .center{
      margin-left: $margin;
      /* margin-right: $margin-around; */
      flex: 1;
      display: flex;
      flex-direction: column;
      .right-upper{
        flex: 1;
        display: flex;
        .flow{
          position: relative;
          width: 2000px;
          height: 1900px;
          .overall {
            position: absolute;
            bottom: 0;
            height: 300px;
            background: none;
          }
        }

      }
    }
    .right{
      margin-right: $margin-around;
      width: 800px;
      display: flex;
      flex-direction: column;
      .servers{
        height: 600px;
      }
      .star-product{
        margin-top: $margin;
        height: 1280px;
      }
    }
  }
}
</style>
