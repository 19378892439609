var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll"},[_c('div',{staticClass:"scroll-header",style:({
          height : _vm.headerConfig.headerHeight + 'px',
          background: _vm.headerConfig.headerBackground,
        })},_vm._l((_vm.headerConfig.headerItems),function(item,index){return _c('div',{key:'scroll-header-item' + index,staticClass:"scroll-header-item",style:({
           width: _vm.averageWidth + 'px',
           ... _vm.headerConfig.headerStyle[index]
         })},[_vm._v(" "+_vm._s(item))])}),0),_c('div',{ref:"scrollContent",staticClass:"scroll-content"},_vm._l((_vm.rowsData),function(item,index){return _c('div',{key:item.index,staticClass:"scroll-content-li",style:({
           height: _vm.rowHeights[index] + 'px',
           background: item.index % 2 === 0 ? _vm.rowBackground[0] : _vm.rowBackground[1]
          })},_vm._l((Object.keys(item.data)),function(k,i){return _c('div',{key:'scroll-content-li' + i,staticClass:"scroll-content-li-item",style:({
             width: _vm.headerConfig.headerStyle[i].width ? _vm.headerConfig.headerStyle[i].width : _vm.averageWidth + 'px',
            })},[_vm._v(_vm._s(item.data[k]))])}),0)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }