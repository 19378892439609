<template>
  <box>
    <div class="servers">
      <div class="title">Servers</div>
      <div class="list">
        <scroll :header = "header"
                :rows = "list"
                :duration = "duration"
                :capacity = "5"></scroll>
      </div>
    </div>
  </box>
</template>
<script>
import Scroll from '@/components/Common/Scroll'
export default {
  components: {
    Scroll
  },
  data () {
    return {
      header: {
        headerItems: ['IP', 'Nature'],
        headerHeight: 0,
        headerStyle: [
          {
            display: 'none'
          },
          {
            display: 'none'
          }
        ]
      },
      rowBg: ['red', 'green'],
      duration: 3000
    }
  },
  computed: {
    list () {
      return [
        {
          ip: '20.128.178.66',
          nature: 'Overload'
        },
        {
          ip: '137.22.105.166',
          nature: '67%'
        },
        {
          ip: '233.159.178.83',
          nature: 'Overload'
        },
        {
          ip: '35.201.94.168',
          nature: 'Offline'
        },
        {
          ip: '204.151.57.91',
          nature: '12.44%'
        }
      ]
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/variable.scss";
.servers{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .title{
    margin-bottom: 20px;
    padding-left: 20px;
    height: 80px;
    line-height: 80px;
    font-size: 40px;
    font-weight: bold;
    background: linear-gradient(to right,rgba(0,160,255,1),rgba(255,0,0,0));
  }
  .list{
    flex: 1;
    width: 100%;
    font-size: 36px;
    .scroll{
      .scroll-header{
        display: none !important;
      }
    }
  }
}
</style>
