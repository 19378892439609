import Vue from 'vue'
import Vuex from 'vuex'
import {
  flowData,
  dailyData
} from '@/assets/data/appData'

import { getDashboardData } from '../network/dashboard'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: true,
    updateTime: '',
    overall: null,
    dailySales: null,
    alert: null,
    flowDirection: null,
    realtime: null
  },
  mutations: {
    updateLoading (state) {
      state.loading = false
    },
    updateOverall (state, payload) {
      state.overall = payload
    },
    updateDailySales (state, payload) {
      state.dailySales = payload
    },
    updateTime (state, payload) {
      state.updateTime = payload
    },
    updateAlert (state, payload) {
      state.alert = payload
    },
    updateFlowDirection (state, payload) {
      state.flowDirection = payload
    },
    updateRealtime (state, payload) {
      state.realtime = payload
    }
  },
  actions: {
    updateAll (context) {
      getDashboardData()
        .then(res => {
          return res.data.data
        }).then(data => {
          const overallData = {
            lorry: data.lorry,
            goods: data.goods,
            warehouse: data.warehouse
          }
          // 更新state
          const time = new Date()
          context.commit('updateLoading', false)
          context.commit('updateTime', time)
          context.commit('updateOverall', overallData)
          context.commit('updateDailySales', JSON.parse(JSON.stringify(dailyData)))
          context.commit('updateAlert', data.latest_alerts)
          context.commit('updateFlowDirection', JSON.parse(JSON.stringify(flowData)))
          context.commit('updateRealtime', data.latest_data)
        })
    }
  },
  modules: {
  }
})
