/* eslint-disable */
export const flowData = [
  {name: 'Boke', plate: 7659, store: 300, coords: [
    [-14.279586, 10.961569],
    [-15.580586, 11.561569]
  ]},
  {name: 'Conakry', plate: 1547, store: 301, coords: [
    [-13.579567, 9.648452],
    [-14.879567, 9.648452]
  ]},
  {name: 'Faranah', plate: 3168, store: 302, coords: [
    [-10.761524, 10.054803],
    [-11.361524, 8.354803]
  ]},
  {name: 'Kankan', plate: 992, store: 303, coords: [
    [-9.310755, 10.377681],
    [-7.310755, 11.377681]
  ]},
  {name: 'Kindia', plate: 4045, store: 304, coords: [
    [-12.859014, 10.085028],
    [-13.959014, 8.785028]
  ]},
  {name: 'Labe', plate: 689, store: 305, coords: [
    [-12.286940, 11.354046],
    [-12.286940, 12.654046]
  ]},
  {name: 'Mamou', plate: 1309, store: 306, coords: [
    [-12.071620, 10.403528],
    [-12.671620, 8.603528]
  ]},
  {name: 'Nzerekore', plate: 2010, store: 307, coords: [
    [-8.844376, 7.879012],
    [-7.144376, 9.079012]
  ]}
]
export const overallData = {
  totalPlate: 2233,
  totalStore: 501,
  totalGoods: 97
}
export const annualData = [
  {
    year: '2012',
    spring: 90,
    fall: 70
  },
  {
    year: '2013',
    spring: 120,
    fall: 90
  },
  {
    year: '2014',
    spring: 110,
    fall: 130
  },
  {
    year: '2015',
    spring: 150,
    fall: 140
  },
  {
    year: '2016',
    spring: 180,
    fall: 160
  },
  {
    year: '2017',
    spring: 200,
    fall: 180
  },
  {
    year: '2018',
    spring: 170,
    fall: 150
  },
  {
    year: '2019',
    spring: 180,
    fall: 210
  },
  {
    year: '2020',
    spring: 160,
    fall: 200
  },
]
export const categoryData = [
  { value: 335, name: '家装服务' },
  { value: 310, name: '卫浴陶瓷' },
  { value: 274, name: '橱柜厨电' },
  { value: 235, name: '地板门窗' },
  { value: 135, name: '综合装饰' },
  { value: 400, name: '家具软装' }
]
export const completionData = {
  sales: 0.4,
  order: 0.35
}
export const dailyData = [
  {
    name: 'Lorries',
    value: 230
  },
  {
    name: 'Whse',
    value: 1230
  },
  {
    name: 'Goods',
    value: 2130
  }
]
export const starProduct = [
  {
    name: 'A品牌木门',
    sales: 92090,
    orders: 51
  },
  {
    name: 'B品牌沙发',
    sales: 602090,
    orders: 107
  },
  {
    name: 'C品牌衣柜',
    sales: 802090,
    orders: 94
  },
  {
    name: 'D品牌木门',
    sales: 92090,
    orders: 51
  },
  {
    name: 'E品牌沙发',
    sales: 602090,
    orders: 107
  },
  {
    name: 'F品牌木门',
    sales: 92090,
    orders: 51
  },
  {
    name: 'G品牌木门',
    sales: 92090,
    orders: 51
  },
  {
    name: 'H品牌沙发',
    sales: 602090,
    orders: 107
  }
]

export const alertData = [
  {
    type: "C23CF1",
    msg: "Temperature high"
  },
  {
    type: "C23CF2",
    msg: "Humidity high"
  },
  {
    type: "C23CF3",
    msg: "Temperature high"
  },
  {
    type: "C23CF4",
    msg: "Humidity high"
  },
  {
    type: "C23CF5",
    msg: "Temperature high"
  },
  {
    type: "C23CF6",
    msg: "Humidity high"
  },
  {
    type: "C23CF7",
    msg: "Temperature high"
  },
  {
    type: "C23CF8",
    msg: "Humidity high"
  }
]
export const realtimeData = [
  {
    palte: "C23CF1",
    goods: "103521",
    temp: "45.1",
    humidity: "65",
  },
  {
    palte: "C23CF2",
    goods: "103522",
    temp: "45.2",
    humidity: "62",
  },
  {
    palte: "C23CF3",
    goods: "103523",
    temp: "45.3",
    humidity: "63",
  },
  {
    palte: "C23CF4",
    goods: "103524",
    temp: "45.4",
    humidity: "64",
  },
  {
    palte: "C23CF5",
    goods: "103525",
    temp: "45.5",
    humidity: "65",
  },
  {
    palte: "C23CF6",
    goods: "103526",
    temp: "45.6",
    humidity: "66",
  },
  {
    palte: "C23CF7",
    goods: "103527",
    temp: "45.7",
    humidity: "67",
  },
  {
    palte: "C23CF8",
    goods: "103528",
    temp: "45.8",
    humidity: "68",
  },
  {
    palte: "C23CF9",
    goods: "103529",
    temp: "45.9",
    humidity: "69",
  },
  {
    palte: "C23CF10",
    goods: "1035210",
    temp: "45.10",
    humidity: "70",
  }
]