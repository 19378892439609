<template>
  <box>
    <div class="wrapper">
      <div class="item">
        <p>Total States</p>
        <p>
          <count-to :startVal="startVal.totalStates"
                    :endVal="7"
                    :duration="3000"
                    separator></count-to>
        </p>
      </div>
      <div class="item">
        <p>Total Trucks</p>
        <p>
          <count-to :startVal="startVal.totalTrucks"
                    :endVal="192"
                    :duration="3000"
                    separator=""></count-to>
        </p>
      </div>
      <div class="item">
        <p>Total Packs</p>
        <p>
          <count-to :startVal="startVal.totalPacks"
                    :endVal="1728"
                    :duration="3000"
                    separator=""></count-to>
        </p>
      </div>
      <div class="item">
        <p>Total Loads</p>
        <p>
          <count-to :startVal="startVal.totalLoads"
                    :endVal="1827"
                    :duration="3000"
                    separator=""></count-to>
        </p>
      </div>
    </div>
  </box>
</template>
<script>
import CountTo from 'vue-count-to'
export default {
  data () {
    return {
      startVal: {
        totalStates: 0,
        totalTrucks: 0,
        totalPacks: 0,
        totalLoads: 0
      }
    }
  },
  computed: {
    overall () {
      return this.$store.state.overall
    }
  },
  watch: {
    overall: {
      handler (oldVal, newVal) {
        this.startVal = oldVal
      },
      deep: true
    }
  },
  components: {
    CountTo
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/variable.scss";
.wrapper{
  display: flex;
  justify-content: space-evenly;

  .item{
    width: 240px;
    height: 240px;
    border-radius: 30px;
    background-color: #0b4f7f;

    p:first-child{
      margin-top: 34px;
      text-align: center;
      color: #959595;
      font-size: 40px;
    }
    p:last-child{
      text-align: center;
      color: #959595;
      font-size: 90px;
      margin-top: 10px;
    }
  }
}
</style>
