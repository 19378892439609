export default {
  type: 'FeatureCollection',
  features: [{
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-13.771470068999975, 9.844448090000071],
            [-13.769942283999967, 9.848415375000059],
            [-13.783061980999946, 9.844584466000072],
            [-13.788594246999935, 9.840861322000023],
            [-13.79042434799993, 9.837858201000074],
            [-13.787855148999938, 9.835578918000067],
            [-13.771470068999975, 9.844448090000071]
          ]
        ],
        [
          [
            [-13.724986076999926, 9.858151436000071],
            [-13.719228745999942, 9.862794877000056],
            [-13.72294807399993, 9.864145278000024],
            [-13.73263454499994, 9.858091354000067],
            [-13.732025146999945, 9.855197907000047],
            [-13.724986076999926, 9.858151436000071]
          ]
        ],
        [
          [
            [-14.038882255999965, 10.108675958000049],
            [-14.041002272999947, 10.112106323000035],
            [-14.048246382999935, 10.117560386000036],
            [-14.055471419999947, 10.120944977000022],
            [-14.05970001199995, 10.126425743000027],
            [-14.062944411999979, 10.12593746300007],
            [-14.07279682099994, 10.112509728000077],
            [-14.072303771999941, 10.109524727000064],
            [-14.063852310999948, 10.099023819000024],
            [-14.05827999099995, 10.09861373900003],
            [-14.038882255999965, 10.108675958000049]
          ]
        ],
        [
          [
            [-14.575121880999973, 10.768721581000023],
            [-14.576331138999933, 10.772848129000067],
            [-14.58085823099998, 10.761758804000067],
            [-14.578053474999933, 10.760408402000053],
            [-14.575121880999973, 10.768721581000023]
          ]
        ],
        [
          [
            [-14.873248100999945, 10.856413841000062],
            [-14.872101783999938, 10.85780716000005],
            [-14.875234604999946, 10.866511345000049],
            [-14.87070941899998, 10.876915932000031],
            [-14.872857093999926, 10.881260872000041],
            [-14.871954918999961, 10.883572578000042],
            [-14.87339019899997, 10.886775971000077],
            [-14.87086200899995, 10.88933563300003],
            [-14.880745887999979, 10.898189546000026],
            [-14.885635376999971, 10.898361206000061],
            [-14.888626098999964, 10.895565034000072],
            [-14.892550467999968, 10.894022943000039],
            [-14.896530151999968, 10.883238792000043],
            [-14.887304306999965, 10.877948762000074],
            [-14.884885787999963, 10.873603822000064],
            [-14.88053703299994, 10.862537384000063],
            [-14.876270294999927, 10.856146813000066],
            [-14.873248100999945, 10.856413841000062]
          ]
        ],
        [
          [
            [-14.897646903999942, 10.816326142000037],
            [-14.89093494399998, 10.819167137000022],
            [-14.887634276999961, 10.834388733000026],
            [-14.881670952999968, 10.841361046000031],
            [-14.881245613999965, 10.844586373000027],
            [-14.886671066999952, 10.850502015000075],
            [-14.887735367999937, 10.861300468000024],
            [-14.885284424999952, 10.870071411000026],
            [-14.887613295999927, 10.874587060000067],
            [-14.89476871599993, 10.876537323000036],
            [-14.89923286499993, 10.881534576000035],
            [-14.902012824999929, 10.886661530000026],
            [-14.901339530999962, 10.888740541000061],
            [-14.89689636299994, 10.894246102000068],
            [-14.886901855999952, 10.906626703000029],
            [-14.889368057999945, 10.917868615000032],
            [-14.896377563999977, 10.920084953000071],
            [-14.901906013999962, 10.915417672000046],
            [-14.90646553099998, 10.908001900000045],
            [-14.90911960699998, 10.896697998000036],
            [-14.914361953999958, 10.887893677000022],
            [-14.925369262999936, 10.874878884000054],
            [-14.93458080299996, 10.867176056000062],
            [-14.937087058999964, 10.86300563900005],
            [-14.931616782999981, 10.843308448000073],
            [-14.930018425999947, 10.837557792000041],
            [-14.932980537999981, 10.832461358000046],
            [-14.923862457999974, 10.82889175400004],
            [-14.917646408999929, 10.815625192000027],
            [-14.911796570999968, 10.812934876000043],
            [-14.903405188999955, 10.811655998000049],
            [-14.897646903999942, 10.816326142000037]
          ]
        ],
        [
          [
            [-14.853347778999932, 10.903346062000026],
            [-14.854658127999926, 10.915291786000068],
            [-14.861644745999968, 10.934532166000054],
            [-14.86495399499995, 10.93863392000003],
            [-14.872451781999928, 10.94268512800005],
            [-14.877345084999945, 10.943086624000046],
            [-14.885918617999948, 10.939993859000026],
            [-14.887729643999933, 10.935831071000052],
            [-14.885489462999942, 10.924126625000042],
            [-14.876532554999926, 10.91503143400007],
            [-14.878757475999976, 10.90672397700007],
            [-14.876861572999928, 10.903985977000048],
            [-14.8700475699999, 10.898777008000025],
            [-14.86260223399995, 10.898865699000055],
            [-14.859831809999946, 10.900738717000024],
            [-14.858805656999948, 10.900270463000027],
            [-14.85491752799993, 10.898496628000032],
            [-14.853347778999932, 10.903346062000026]
          ]
        ],
        [
          [
            [-14.802824973999975, 10.940055847000053],
            [-14.802869796999971, 10.94373607600005],
            [-14.812616347999949, 10.94132137300005],
            [-14.81536960699998, 10.938068389000023],
            [-14.814411163999978, 10.935779572000058],
            [-14.810443877999944, 10.934906006000062],
            [-14.802824973999975, 10.940055847000053]
          ]
        ],
        [
          [
            [-14.897632599999952, 10.946063997000067],
            [-14.897942543999932, 10.952271461000066],
            [-14.90059661999993, 10.959830285000066],
            [-14.906624793999981, 10.957917214000076],
            [-14.912612915999944, 10.95278453800006],
            [-14.912345886999958, 10.95002746700004],
            [-14.90905571099995, 10.947536469000056],
            [-14.899709701999939, 10.944658280000056],
            [-14.897632599999952, 10.946063997000067]
          ]
        ],
        [
          [
            [-14.92306900099993, 10.895609857000068],
            [-14.904608726999982, 10.926888467000026],
            [-14.905353545999958, 10.930788994000068],
            [-14.911500930999978, 10.938305856000056],
            [-14.91689968199995, 10.941921234000063],
            [-14.924709320999966, 10.95217800100005],
            [-14.933639526999968, 10.95897102500004],
            [-14.95432758499993, 10.975051880000024],
            [-14.96130943399993, 10.974966049000045],
            [-14.96941176699994, 10.967147695000051],
            [-14.985275938999962, 10.961581318000071],
            [-14.994182141999943, 10.954901666000069],
            [-15, 10.953173639000056],
            [-15.00143146499994, 10.951766968000072],
            [-15.006149851999965, 10.948222014000066],
            [-15.009211359999938, 10.939315811000029],
            [-15.013664459999973, 10.932357840000066],
            [-15.017560923999952, 10.928739696000036],
            [-15.023962257999926, 10.925956507000024],
            [-15.030477523999934, 10.923273087000041],
            [-15.035629271999937, 10.922088624000025],
            [-15.047418594999954, 10.919380189000037],
            [-15.058916091999947, 10.908654214000023],
            [-15.062848090999978, 10.906764031000023],
            [-15.066979408999941, 10.902341843000045],
            [-15.067868232999956, 10.899108888000058],
            [-15.077990531999944, 10.890240670000026],
            [-15.082058906999976, 10.880987167000058],
            [-15.084904671999936, 10.869412422000039],
            [-15.08625602799998, 10.863911629000029],
            [-15.084515572999976, 10.855422973000032],
            [-15.078563690999943, 10.845146181000075],
            [-15.072896003999972, 10.83877754200006],
            [-15.05062103299997, 10.81248569500002],
            [-15.032019615999957, 10.79052353000003],
            [-15.021666525999933, 10.78145313300007],
            [-15.015139578999936, 10.777903557000059],
            [-15.014638900999955, 10.777630806000047],
            [-15, 10.775509834000047],
            [-14.996446608999975, 10.774980546000052],
            [-14.982245445999979, 10.772971153000071],
            [-14.969934463999948, 10.774272920000044],
            [-14.96292972499998, 10.772288324000044],
            [-14.952934264999953, 10.772871017000057],
            [-14.951847076999968, 10.778864861000045],
            [-14.941772460999971, 10.79163932800003],
            [-14.941368102999945, 10.796474457000045],
            [-14.948698043999968, 10.805816651000043],
            [-14.953829764999966, 10.806903839000029],
            [-14.956889152999963, 10.809627533000025],
            [-14.961291313999936, 10.808193207000045],
            [-14.962939262999953, 10.809782982000058],
            [-14.96044158899997, 10.814643860000047],
            [-14.974436759999946, 10.817462921000072],
            [-14.978878021999947, 10.82448387100004],
            [-14.972022055999958, 10.835474968000028],
            [-14.964181899999971, 10.834380150000072],
            [-14.961899757999959, 10.837859155000046],
            [-14.96358966799994, 10.84266853400004],
            [-14.967788695999957, 10.843538284000033],
            [-14.965729712999973, 10.846323013000074],
            [-14.967693329999975, 10.854350091000072],
            [-14.971368789999929, 10.859572411000045],
            [-14.971823692999976, 10.860219002000065],
            [-14.970881461999966, 10.867192268000053],
            [-14.971567153999956, 10.875153543000067],
            [-14.977767944999982, 10.883828163000032],
            [-14.976437567999938, 10.886863709000068],
            [-14.97372245899993, 10.887225151000052],
            [-14.970434188999945, 10.886981012000035],
            [-14.96665954699995, 10.882886886000051],
            [-14.962082863999967, 10.866879463000032],
            [-14.958638190999977, 10.863204003000021],
            [-14.953166961999955, 10.861570358000051],
            [-14.949439048999977, 10.863963127000034],
            [-14.94531726899993, 10.86980533700006],
            [-14.94328403399993, 10.874661445000072],
            [-14.933378219999952, 10.882602693000024],
            [-14.92306900099993, 10.895609857000068]
          ]
        ],
        [
          [
            [-14.707219123999948, 11.004416467000055],
            [-14.705611228999942, 11.006273269000076],
            [-14.70915699099993, 11.010834694000039],
            [-14.709663391999982, 11.01427936600004],
            [-14.714305877999948, 11.013076783000031],
            [-14.71773529099994, 11.007746696000027],
            [-14.716769218999957, 11.004767418000029],
            [-14.71466064499998, 11.003641129000073],
            [-14.707219123999948, 11.004416467000055]
          ]
        ],
        [
          [
            [-14.901341438999964, 11.000997544000029],
            [-14.91444301599995, 11.024360657000045],
            [-14.918906211999968, 11.027352333000067],
            [-14.930520335999972, 11.044838972000036],
            [-14.951562058999968, 11.008099455000036],
            [-14.953612488999966, 11.004519339000069],
            [-14.953823622999948, 11.004150693000042],
            [-14.953851633999932, 11.003925331000062],
            [-14.953927041999975, 11.003359446000047],
            [-14.954009582999959, 11.002794557000072],
            [-14.954099244999952, 11.002230755000028],
            [-14.95419601499998, 11.001668128000063],
            [-14.954299873999958, 11.00110676600002],
            [-14.954410805999942, 11.000546760000077],
            [-14.954528796999966, 10.99998819800004],
            [-14.954653822999944, 10.999431170000037],
            [-14.95478586799993, 10.998875762000068],
            [-14.954924909999932, 10.998322065000025],
            [-14.955070926999952, 10.997770166000066],
            [-14.95522389499996, 10.997220155000036],
            [-14.955383789999928, 10.996672115000024],
            [-14.955550585999958, 10.996126137000033],
            [-14.955724258999965, 10.995582308000053],
            [-14.955904777999933, 10.995040711000058],
            [-14.956092114999933, 10.994501436000064],
            [-14.956286241999976, 10.993964568000024],
            [-14.956487126999946, 10.993430191000073],
            [-14.956694737999953, 10.99289839200003],
            [-14.95690904099996, 10.99236925300005],
            [-14.957130002999975, 10.991842861000066],
            [-14.957357588999969, 10.991319299000054],
            [-14.957591762999982, 10.990798649000055],
            [-14.957832485999973, 10.99028099700007],
            [-14.95807972199998, 10.98976642100007],
            [-14.958333428999936, 10.989255005000075],
            [-14.958593568999959, 10.98874683200006],
            [-14.958860098999935, 10.988241981000044],
            [-14.959132977999957, 10.987740534000068],
            [-14.959412161999978, 10.987242569000045],
            [-14.959697604999974, 10.986748164000062],
            [-14.959989264999933, 10.986257401000046],
            [-14.96028709199993, 10.985770357000035],
            [-14.9605910399999, 10.985287110000058],
            [-14.960901061999948, 10.98480773500006],
            [-14.961217105999935, 10.98433231100006],
            [-14.961539123999955, 10.983860911000022],
            [-14.961867062999943, 10.983393612000043],
            [-14.962200871999926, 10.982930487000033],
            [-14.962540497999953, 10.98247161100005],
            [-14.962885884999935, 10.982017056000075],
            [-14.963236979999976, 10.981566894000025],
            [-14.963593727999978, 10.98112119800004],
            [-14.965976756999964, 10.97814450900006],
            [-14.962768553999979, 10.979779243000053],
            [-14.957011222999938, 10.979819299000042],
            [-14.94663524699996, 10.974225998000065],
            [-14.929482460999964, 10.961551666000048],
            [-14.922433853999962, 10.956347466000068],
            [-14.917758941999978, 10.954792978000057],
            [-14.902306556999974, 10.966250419000062],
            [-14.900729179999928, 10.97041034800003],
            [-14.901341438999964, 11.000997544000029]
          ]
        ],
        [
          [
            [-12.563143, 12.367398],
            [-12.564062675999935, 12.36852260300003],
            [-12.56458, 12.36856],
            [-12.565702926999961, 12.36975676700007],
            [-12.577050000999975, 12.38185],
            [-12.58437, 12.395380001000035],
            [-12.58977, 12.39927],
            [-12.60595000099994, 12.40518],
            [-12.61627, 12.41896],
            [-12.621740000999978, 12.426270001000034],
            [-12.63394, 12.431280001000061],
            [-12.647760000999938, 12.43305],
            [-12.67281000099996, 12.433400001000052],
            [-12.7147, 12.432040001000075],
            [-12.718680000999939, 12.43042],
            [-12.73763, 12.42941],
            [-12.73957, 12.43002],
            [-12.748524970999938, 12.432867772000066],
            [-12.75545, 12.435070001000042],
            [-12.75877000099996, 12.442180001000054],
            [-12.7612, 12.45827],
            [-12.769670000999952, 12.46628],
            [-12.77183, 12.4709],
            [-12.7766, 12.47189],
            [-12.78156, 12.47626],
            [-12.786820000999967, 12.47563000100007],
            [-12.80015, 12.4728],
            [-12.81047, 12.475960001000033],
            [-12.83418, 12.486880001000031],
            [-12.83759, 12.49112],
            [-12.841730000999974, 12.496270001000028],
            [-12.84181, 12.510540001000038],
            [-12.84559, 12.51558],
            [-12.868810000999929, 12.52304],
            [-12.873990000999981, 12.52692],
            [-12.87919, 12.53495],
            [-12.88765, 12.54065],
            [-12.893270000999962, 12.5397],
            [-12.90007, 12.54197],
            [-12.90523, 12.541940001000057],
            [-12.922530000999927, 12.53701],
            [-12.930910000999972, 12.53052],
            [-12.932620000999975, 12.52747],
            [-12.936470000999975, 12.5206],
            [-12.940490000999944, 12.487460001000045],
            [-12.93955000099993, 12.487460001000045],
            [-12.94815000099993, 12.47706],
            [-12.96702000099998, 12.46269],
            [-12.96865, 12.461670001000073],
            [-12.97405, 12.463580001000025],
            [-12.988899291999928, 12.468901651000067],
            [-13.002400000999955, 12.47374],
            [-13.00399, 12.47434],
            [-13.01645, 12.477320001000066],
            [-13.028540000999953, 12.47798],
            [-13.03975, 12.47493],
            [-13.05099, 12.47395],
            [-13.05877000099997, 12.47707],
            [-13.0606, 12.47781],
            [-13.06578, 12.48238],
            [-13.07121000099994, 12.49132],
            [-13.07315, 12.50212],
            [-13.072290000999942, 12.51524],
            [-13.07659, 12.529710001000069],
            [-13.07497000099994, 12.53386000100005],
            [-13.065650000999938, 12.54196],
            [-13.06014, 12.560620001000075],
            [-13.0525, 12.57538],
            [-13.050900000999945, 12.582060001000059],
            [-13.051880000999972, 12.59056],
            [-13.05621, 12.61009000100006],
            [-13.05673, 12.619290001000024],
            [-13.065430000999982, 12.625000001000046],
            [-13.073470000999976, 12.638990001000025],
            [-13.07769000099995, 12.63988],
            [-13.08003, 12.639180001000057],
            [-13.08794, 12.62994],
            [-13.09728, 12.625750001000029],
            [-13.100800000999982, 12.62687],
            [-13.10277, 12.62834],
            [-13.110920000999954, 12.634410001000049],
            [-13.119120000999942, 12.63505],
            [-13.130499491999956, 12.640561823000041],
            [-13.13415, 12.64233],
            [-13.13906, 12.64161000100006],
            [-13.145110000999978, 12.63491],
            [-13.15264, 12.63969],
            [-13.153490000999966, 12.63965],
            [-13.171840000999964, 12.63866],
            [-13.17956, 12.63655],
            [-13.184650000999966, 12.62778],
            [-13.18770000099994, 12.62776],
            [-13.197120000999973, 12.636900001000072],
            [-13.209100000999968, 12.6419],
            [-13.21077, 12.64649],
            [-13.21335000099998, 12.64877],
            [-13.221550000999969, 12.64826],
            [-13.232970000999956, 12.63968],
            [-13.23719000099993, 12.639890001000026],
            [-13.24706, 12.64466],
            [-13.263010000999941, 12.64916],
            [-13.27496, 12.649090001000047],
            [-13.287860000999956, 12.65246],
            [-13.29067, 12.651750001000039],
            [-13.294885068999974, 12.647136087000035],
            [-13.295320000999936, 12.64666],
            [-13.297420000999978, 12.646420001000024],
            [-13.304660000999945, 12.64246],
            [-13.32409, 12.64172],
            [-13.33325, 12.64461],
            [-13.336020000999952, 12.652120001000071],
            [-13.33854, 12.65480000100007],
            [-13.34231000099993, 12.653890001000036],
            [-13.34631000099995, 12.64971],
            [-13.35017000099998, 12.65344],
            [-13.350900000999957, 12.659570001000077],
            [-13.39306, 12.662120001000062],
            [-13.45208, 12.66598],
            [-13.45308, 12.66605],
            [-13.456289111999979, 12.665683138000077],
            [-13.461740000999953, 12.66506],
            [-13.46738, 12.666860001000032],
            [-13.57874, 12.67498],
            [-13.580230000999961, 12.67499],
            [-13.708273132999977, 12.676212773000032],
            [-13.708373473999927, 12.674623538000048],
            [-13.708445271999949, 12.673950736000052],
            [-13.711665742999969, 12.643772367000054],
            [-13.711111617999961, 12.59944236900003],
            [-13.700583242999926, 12.571736119000036],
            [-13.677309993999927, 12.520202493000056],
            [-13.67571165399994, 12.516168200000038],
            [-13.675565438999968, 12.516000212000051],
            [-13.675413832999936, 12.515837074000046],
            [-13.675256997999952, 12.515678957000034],
            [-13.675095097999929, 12.515526028000068],
            [-13.67492830699996, 12.515378450000071],
            [-13.674756800999944, 12.515236379000044],
            [-13.674580761999948, 12.515099967000026],
            [-13.674400374999948, 12.514969356000051],
            [-13.674215831999959, 12.51484468700005],
            [-13.674027328999955, 12.514726089000021],
            [-13.673835065999981, 12.514613691000022],
            [-13.673639245999937, 12.514507611000056],
            [-13.673440077999942, 12.514407959000039],
            [-13.673237769999957, 12.514314845000058],
            [-13.673032539999951, 12.514228364000076],
            [-13.672824602999981, 12.514148609000074],
            [-13.672614180999972, 12.514075665000064],
            [-13.672401494999974, 12.514009608000038],
            [-13.672186771999975, 12.51395051000003],
            [-13.67197023999995, 12.51389843100003],
            [-13.671752126999934, 12.51385342900005],
            [-13.671532663999926, 12.51381555100005],
            [-13.671312085999944, 12.513784835000024],
            [-13.671090624999977, 12.513761316000057],
            [-13.670868513999949, 12.51374501600003],
            [-13.670645990999958, 12.513735956000062],
            [-13.670423290999963, 12.513734143000022],
            [-13.67020065, 12.513739580000049],
            [-13.669978303999926, 12.513752262000025],
            [-13.669756488999951, 12.513772173000064],
            [-13.669535439999947, 12.51379929500007],
            [-13.669315389999952, 12.51383359700003],
            [-13.669096572999933, 12.513875043000041],
            [-13.668879222999976, 12.513923589000058],
            [-13.668663565999964, 12.513979186000029],
            [-13.668449832999954, 12.514041772000041],
            [-13.668238250999934, 12.514111281000055],
            [-13.66802904399998, 12.514187640000046],
            [-13.66782243299997, 12.514270768000074],
            [-13.667618636999975, 12.514360578000037],
            [-13.667417872999977, 12.51445697500003],
            [-13.667220351999958, 12.514559854000026],
            [-13.667026284999963, 12.514669108000021],
            [-13.666835877999972, 12.514784620000057],
            [-13.666649328999938, 12.51490627100003],
            [-13.66646684099993, 12.515033927000047],
            [-13.666288604999977, 12.515167456000029],
            [-13.666114807999975, 12.515306718000033],
            [-13.665945635999947, 12.515451560000031],
            [-13.665781269999968, 12.515601833000062],
            [-13.665621880999936, 12.515757377000057],
            [-13.665467640999964, 12.515918026000065],
            [-13.665318708999962, 12.516083611000056],
            [-13.665175247999969, 12.516253956000071],
            [-13.665119242999936, 12.516323619000048],
            [-13.665069073999973, 12.516317654000034],
            [-13.66480188199995, 12.516281565000043],
            [-13.664535753999928, 12.516238315000066],
            [-13.66427088599994, 12.516187936000051],
            [-13.664007463999951, 12.51613046400007],
            [-13.66374567899993, 12.516065941000022],
            [-13.663557749999939, 12.516014230000053],
            [-13.663485721999962, 12.515994412000055],
            [-13.663227779999943, 12.515915928000027],
            [-13.662972036999975, 12.515830549000043],
            [-13.662718677999976, 12.515738335000037],
            [-13.662467885999945, 12.515639350000072],
            [-13.662219843999935, 12.515533669000035],
            [-13.661974725999926, 12.515421366000055],
            [-13.661732712999935, 12.515302524000049],
            [-13.661493978999943, 12.515177226000048],
            [-13.66125869299998, 12.515045562000068],
            [-13.661027027999978, 12.514907631000028],
            [-13.660799147999967, 12.514763530000039],
            [-13.660575219999942, 12.514613361000045],
            [-13.660355404999962, 12.514457235000066],
            [-13.660139861999937, 12.514295265000044],
            [-13.659928742999966, 12.514127564000034],
            [-13.659722202999944, 12.513954257000023],
            [-13.659520390999944, 12.513775468000063],
            [-13.659323450999977, 12.513591323000071],
            [-13.659131527999932, 12.513401958000031],
            [-13.658944754999936, 12.51320750800005],
            [-13.65876327199993, 12.513008115000048],
            [-13.658587206999982, 12.512803921000057],
            [-13.658416687999932, 12.512595073000057],
            [-13.658251837999956, 12.512381723000033],
            [-13.658092774999943, 12.512164022000036],
            [-13.657939614999975, 12.511942131000069],
            [-13.657792465999933, 12.511716207000063],
            [-13.65765143799996, 12.511486415000036],
            [-13.657516628999929, 12.511252918000025],
            [-13.657388136999941, 12.511015886000052],
            [-13.65726605499998, 12.510775490000071],
            [-13.657150471999955, 12.51053190400006],
            [-13.648073529999976, 12.491392174000055],
            [-13.647097464999945, 12.489334034000024],
            [-13.647018770999978, 12.488932646000023],
            [-13.646933024999953, 12.488532706000058],
            [-13.646840253999926, 12.488134337000076],
            [-13.646740485999942, 12.48773766000005],
            [-13.646633751999957, 12.487342803000047],
            [-13.646520084999963, 12.486949885000058],
            [-13.646399522999957, 12.486559026000066],
            [-13.64627209899993, 12.486170352000045],
            [-13.64613785499995, 12.485783980000065],
            [-13.64599683199998, 12.485400029000061],
            [-13.645849072999965, 12.485018622000041],
            [-13.645694624999976, 12.48463987200006],
            [-13.64553353499997, 12.48426390000003],
            [-13.645365853999976, 12.483890822000035],
            [-13.645191631999978, 12.483520751000071],
            [-13.645010924999951, 12.48315380400004],
            [-13.644823788999929, 12.482790095000041],
            [-13.644630278999955, 12.482429736000029],
            [-13.644430457999931, 12.482072837000032],
            [-13.644224386999952, 12.481719510000062],
            [-13.644012129999965, 12.481369866000023],
            [-13.643793751999965, 12.481024009000066],
            [-13.643569320999973, 12.480682052000077],
            [-13.64333890599994, 12.48034409500002],
            [-13.643102579999947, 12.480010247000052],
            [-13.642860413999927, 12.47968061000006],
            [-13.642612483999926, 12.479355284000064],
            [-13.642358867999974, 12.479034374000037],
            [-13.642099643999927, 12.478717976000041],
            [-13.641834890999974, 12.478406188000065],
            [-13.641564692999964, 12.478099109000027],
            [-13.64128913199994, 12.47779683400006],
            [-13.64100829299997, 12.477499454000053],
            [-13.640722264999965, 12.477207062000048],
            [-13.63531272399996, 12.467673845000036],
            [-13.631871744999955, 12.46035699500004],
            [-13.631991982999978, 12.45994666300004],
            [-13.632279283999935, 12.458984458000032],
            [-13.632573469999954, 12.45802433600005],
            [-13.632874528999935, 12.457066345000044],
            [-13.633182442999953, 12.456110538000075],
            [-13.633497194999961, 12.455156959000021],
            [-13.633818771999927, 12.45420566000007],
            [-13.634147156999973, 12.453256690000046],
            [-13.634482330999958, 12.452310097000066],
            [-13.634824278999929, 12.451365929000076],
            [-13.635172981999972, 12.45042423600006],
            [-13.635528422999926, 12.449485064000044],
            [-13.635890581999945, 12.448548465000044],
            [-13.636259443999961, 12.447614482000063],
            [-13.63663498699998, 12.44668316700006],
            [-13.637017192999963, 12.445754567000051],
            [-13.637406039999973, 12.444828727000072],
            [-13.637801511999953, 12.443905698000037],
            [-13.638203585999975, 12.442985527000076],
            [-13.638612242999955, 12.442068258000063],
            [-13.638705632999972, 12.441862611000033],
            [-13.639027459999966, 12.441153941000039],
            [-13.639449217999982, 12.440242623000074],
            [-13.639877493999961, 12.439334349000035],
            [-13.640312265999967, 12.438429168000027],
            [-13.640753511999947, 12.437527125000031],
            [-13.641201208999973, 12.436628265000024],
            [-13.64165533399995, 12.435732637000058],
            [-13.642115863999948, 12.434840283000028],
            [-13.642582774999937, 12.433951254000021],
            [-13.643056043999934, 12.433065591000059],
            [-13.643535644999929, 12.432183343000077],
            [-13.644021555999927, 12.431304553000075],
            [-13.644513748999941, 12.430429267000022],
            [-13.645012200999929, 12.429557530000068],
            [-13.645516886999928, 12.42868938600003],
            [-13.646027777999961, 12.42782488000006],
            [-13.646544849999941, 12.426964056000031],
            [-13.647068075999982, 12.426106958000048],
            [-13.647597427999926, 12.425253632000022],
            [-13.648132880999981, 12.42440411900003],
            [-13.648674405999941, 12.423558465000042],
            [-13.649221975999978, 12.422716712000067],
            [-13.649775562999935, 12.421878905000028],
            [-13.650335137999946, 12.42104508400007],
            [-13.650900671999977, 12.42021529300007],
            [-13.651472136999928, 12.41938957700006],
            [-13.652049501999954, 12.418567974000041],
            [-13.652632737999966, 12.417750531000024],
            [-13.653221816999974, 12.416937285000074],
            [-13.653816706999976, 12.416128282000045],
            [-13.654417377999948, 12.415323561000037],
            [-13.655023796999956, 12.414523165000048],
            [-13.655144993999954, 12.414364619000025],
            [-13.655325214999948, 12.414277347000052],
            [-13.655730195999979, 12.414075455000045],
            [-13.656131905999928, 12.41386712800005],
            [-13.656530242999963, 12.413652422000041],
            [-13.65692510499997, 12.413431393000053],
            [-13.657316392999974, 12.413204093000047],
            [-13.657658991999938, 12.412997702000041],
            [-13.65770400599996, 12.41297058500004],
            [-13.658087846999933, 12.412730923000026],
            [-13.658467815999927, 12.41248517200006],
            [-13.65884381899997, 12.412233392000076],
            [-13.65921575799996, 12.411975650000045],
            [-13.65958354099996, 12.411712007000062],
            [-13.659947071999966, 12.411442536000038],
            [-13.66030626, 12.411167300000045],
            [-13.66066101399997, 12.41088637100006],
            [-13.661011241999972, 12.410599822000052],
            [-13.66135685599994, 12.410307722000027],
            [-13.661697767999954, 12.410010150000062],
            [-13.662033891999954, 12.409707179000065],
            [-13.662365141999942, 12.409398887000066],
            [-13.662691433999953, 12.409085351000044],
            [-13.663012684999956, 12.408766653000043],
            [-13.663328811999975, 12.408442871000034],
            [-13.663639735999936, 12.408114091000073],
            [-13.663945376999948, 12.407780392000063],
            [-13.66424565799997, 12.407441864000077],
            [-13.664540501999966, 12.407098590000032],
            [-13.664829834999978, 12.406750658000021],
            [-13.665113583999926, 12.406398155000034],
            [-13.665391675999956, 12.406041174000052],
            [-13.665664037999932, 12.405679802000066],
            [-13.665930602999936, 12.405314134000037],
            [-13.666191304999927, 12.404944260000036],
            [-13.666446073999964, 12.404570278000051],
            [-13.666694845999928, 12.404192280000075],
            [-13.666937559999951, 12.403810362000058],
            [-13.667174152999962, 12.403424623000035],
            [-13.66740456499997, 12.403035160000059],
            [-13.667628735999926, 12.402642073000038],
            [-13.667846610999959, 12.402245461000064],
            [-13.668058133999978, 12.401845424000044],
            [-13.668263249999939, 12.401442067000062],
            [-13.668461908999973, 12.401035488000048],
            [-13.668654057999959, 12.400625795000053],
            [-13.668839649999939, 12.400213088000044],
            [-13.669018635999976, 12.39979747500007],
            [-13.669190973999946, 12.399379061000047],
            [-13.669356615999959, 12.398957950000067],
            [-13.669515520999937, 12.398534254000026],
            [-13.66966765099994, 12.398108075000039],
            [-13.669812964999949, 12.397679527000037],
            [-13.669951427999933, 12.397248714000057],
            [-13.670083001999956, 12.396815748000051],
            [-13.670207653999967, 12.39638074100003],
            [-13.670325354999932, 12.395943799000065],
            [-13.670436073999952, 12.39550503700002],
            [-13.670539780999945, 12.395064565000041],
            [-13.67063645099995, 12.394622495000021],
            [-13.670726057999957, 12.394178940000074],
            [-13.67080858099996, 12.393734014000074],
            [-13.670883998999955, 12.393287827000051],
            [-13.67095229299997, 12.39284049400004],
            [-13.671013443999982, 12.39239212900003],
            [-13.671067437999966, 12.391942846000063],
            [-13.671114259999968, 12.391492758000027],
            [-13.671153898999933, 12.391041982000047],
            [-13.671186345999956, 12.390590631000066],
            [-13.671211588999938, 12.390138819000072],
            [-13.671229625999956, 12.389686662000031],
            [-13.67124044999997, 12.389234276000025],
            [-13.671244056999967, 12.388781774000051],
            [-13.67124044999997, 12.388329274000057],
            [-13.671229625999956, 12.387876887000061],
            [-13.671211588999938, 12.387424731000067],
            [-13.671186345999956, 12.386972919000073],
            [-13.671153898999933, 12.386521568000035],
            [-13.671114259999968, 12.386070792000055],
            [-13.671067437999966, 12.385620704000075],
            [-13.671013443999982, 12.385171421000052],
            [-13.67095229299997, 12.384723056000041],
            [-13.670883998999955, 12.38427572300003],
            [-13.67080858099996, 12.383829536000064],
            [-13.670726057999957, 12.383384608000028],
            [-13.670660492999957, 12.383056557000032],
            [-13.670502185999965, 12.382644540000058],
            [-13.670274050999978, 12.382067948000042],
            [-13.670039248999956, 12.381494041000053],
            [-13.669797807999942, 12.380922893000047],
            [-13.669549761999974, 12.380354581000063],
            [-13.669295146999957, 12.37978918400006],
            [-13.66903399399996, 12.379226777000042],
            [-13.668766337999955, 12.378667433000032],
            [-13.668492215999947, 12.378111232000037],
            [-13.668211664999944, 12.377558244000056],
            [-13.667924722999942, 12.37700854600007],
            [-13.667631427999936, 12.376462213000025],
            [-13.66733181799998, 12.375919314000043],
            [-13.667025935999959, 12.37537992600005],
            [-13.666713821999963, 12.374844120000034],
            [-13.666395515999966, 12.374311968000029],
            [-13.666071063999937, 12.373783543000059],
            [-13.665740507999942, 12.37325891300003],
            [-13.665403891999972, 12.372738151000021],
            [-13.665061262999927, 12.372221325000055],
            [-13.664712663999978, 12.37170850700005],
            [-13.664358144999937, 12.37119976300005],
            [-13.663997751999943, 12.37069516400004],
            [-13.663631533999933, 12.370194777000052],
            [-13.663259538999966, 12.369698669000059],
            [-13.66288181899995, 12.369206906000045],
            [-13.662498422999931, 12.368719555000041],
            [-13.662109403999978, 12.368236680000052],
            [-13.661714812999946, 12.367758349000042],
            [-13.66131470299996, 12.367284623000046],
            [-13.660909129999936, 12.36681556800005],
            [-13.660498143999973, 12.366351245000033],
            [-13.660081804999948, 12.365891718000057],
            [-13.65966016699997, 12.365437049000036],
            [-13.65923328599996, 12.364987296000038],
            [-13.658801220999976, 12.364542524000058],
            [-13.658364026999948, 12.36410279100005],
            [-13.657918691999953, 12.363665168000068],
            [-13.657750438999926, 12.363488085000029],
            [-13.657588056999941, 12.363306477000037],
            [-13.657431153999937, 12.363120115000072],
            [-13.657279868999979, 12.362929163000047],
            [-13.657134336999945, 12.362733792000029],
            [-13.656994685999962, 12.362534173000029],
            [-13.656861041999946, 12.362330486000076],
            [-13.656733518999943, 12.36212291000004],
            [-13.656612233999965, 12.361911631000055],
            [-13.656497294999951, 12.361696833000053],
            [-13.656388799999945, 12.36147870700006],
            [-13.65628684799998, 12.36125744800006],
            [-13.656191529999944, 12.361033252000027],
            [-13.656102928999928, 12.360806318000073],
            [-13.65602112299996, 12.360576846000072],
            [-13.655946185999937, 12.360345040000027],
            [-13.65587818299997, 12.360111105000044],
            [-13.655817175999971, 12.359875250000073],
            [-13.655763218999937, 12.359637683000074],
            [-13.655724491999933, 12.359440110000037],
            [-13.655716358999939, 12.359398614000042],
            [-13.655676637999932, 12.359158257000047],
            [-13.655644089999953, 12.358916823000072],
            [-13.65561874599996, 12.358674528000051],
            [-13.655600625999966, 12.358431585000062],
            [-13.655589748999944, 12.358188210000037],
            [-13.655586120999942, 12.357944620000069],
            [-13.655589748999944, 12.357701030000044],
            [-13.655600625999966, 12.357457655000076],
            [-13.65561874599996, 12.35721471200003],
            [-13.655644089999953, 12.356972417000065],
            [-13.655676637999932, 12.356730983000034],
            [-13.655716358999939, 12.356490626000038],
            [-13.655763218999937, 12.356251557000064],
            [-13.655817175999971, 12.356013990000065],
            [-13.65587818299997, 12.355778134000047],
            [-13.655946185999937, 12.355544200000054],
            [-13.65602112299996, 12.355312394000066],
            [-13.656102928999928, 12.355082922000065],
            [-13.656191529999944, 12.354855988000054],
            [-13.65628684799998, 12.354631792000077],
            [-13.656388799999945, 12.354410533000078],
            [-13.656497294999951, 12.354192407000028],
            [-13.656612233999965, 12.353977609000026],
            [-13.656733518999943, 12.353766328000063],
            [-13.656861041999946, 12.353558753000073],
            [-13.656994685999962, 12.353355067000052],
            [-13.657134336999945, 12.353155448000052],
            [-13.657279868999979, 12.352960077000034],
            [-13.657431153999937, 12.352769125000066],
            [-13.657588056999941, 12.352582763000044],
            [-13.657750438999926, 12.352401155000052],
            [-13.657918156999926, 12.352224461000048],
            [-13.658091059999947, 12.352052840000056],
            [-13.658268997999926, 12.351886444000058],
            [-13.658451808999928, 12.351725418000058],
            [-13.658639333999929, 12.351569906000066],
            [-13.65883140699998, 12.351420047000033],
            [-13.659027856999955, 12.351275973000043],
            [-13.659228508999945, 12.351137812000047],
            [-13.659433183999965, 12.351005688000043],
            [-13.659641702999977, 12.350879715000076],
            [-13.659811408999929, 12.350783969000076],
            [-13.659853881999936, 12.35076000500004],
            [-13.66006952899994, 12.350646667000035],
            [-13.660288455999932, 12.350539801000025],
            [-13.660510466999938, 12.35043949900006],
            [-13.660735366999972, 12.350345851000043],
            [-13.660962953999956, 12.35025894100005],
            [-13.661193028999946, 12.350178846000063],
            [-13.661425387999941, 12.35010563700007],
            [-13.661659820999944, 12.350039379000066],
            [-13.661896124999942, 12.349980130000063],
            [-13.66213408699997, 12.349927942000022],
            [-13.66237349599993, 12.349882863000062],
            [-13.662614142999928, 12.349844932000053],
            [-13.662855811999975, 12.349814184000024],
            [-13.66290274399995, 12.349809057000073],
            [-13.663003771999968, 12.349750269000026],
            [-13.663285544999951, 12.349580659000026],
            [-13.663563538999938, 12.349404922000076],
            [-13.663837616999956, 12.34922314000005],
            [-13.664107651999927, 12.349035403000073],
            [-13.66437351299993, 12.34884179900007],
            [-13.664635070999964, 12.348642423000058],
            [-13.66489220099993, 12.348437368000077],
            [-13.66514478199997, 12.348226733000047],
            [-13.665392690999965, 12.348010618000046],
            [-13.66563580999997, 12.34778913100007],
            [-13.665874021999969, 12.347562373000073],
            [-13.666107213999965, 12.34733045400003],
            [-13.66633527099998, 12.347093487000052],
            [-13.666558086999942, 12.346851584000035],
            [-13.666775553999969, 12.34660486100006],
            [-13.666987567999968, 12.346353437000062],
            [-13.667194027999926, 12.346097433000068],
            [-13.667394833999936, 12.345836969000061],
            [-13.667589888999942, 12.34557217300005],
            [-13.66777910199994, 12.345303169000033],
            [-13.667962380999938, 12.345030089000034],
            [-13.668139636999967, 12.344753063000041],
            [-13.668310786999939, 12.344472221000046],
            [-13.668475746999945, 12.344187700000077],
            [-13.668634439999948, 12.34389963700005],
            [-13.668786788999967, 12.34360816900005],
            [-13.668932720999976, 12.343313436000074],
            [-13.669072165999978, 12.343015577000074],
            [-13.669205056999942, 12.34271473800004],
            [-13.669331331999956, 12.342411063000043],
            [-13.669450926999957, 12.342104696000035],
            [-13.669563786999959, 12.341795785000045],
            [-13.669669858999953, 12.341484476000062],
            [-13.669769087999953, 12.341170920000025],
            [-13.669861431999948, 12.340855267000052],
            [-13.669946842999934, 12.340537668000024],
            [-13.670025279999948, 12.34021827600003],
            [-13.67009670899995, 12.339897243000053],
            [-13.670161090999954, 12.339574723000055],
            [-13.670218396999928, 12.339250872000036],
            [-13.670268601999965, 12.338925843000027],
            [-13.670311676999972, 12.338599793000071],
            [-13.67034760699994, 12.338272878000055],
            [-13.670376369999929, 12.337945256000069],
            [-13.670397954999942, 12.337617083000055],
            [-13.670412349999935, 12.337288514000022],
            [-13.670419549999963, 12.33695971000003],
            [-13.670419549999963, 12.336630827000022],
            [-13.670412349999935, 12.33630202300003],
            [-13.670397954999942, 12.335973456000033],
            [-13.670376369999929, 12.33564528100004],
            [-13.67034760699994, 12.335317659000054],
            [-13.670311676999972, 12.334990745000027],
            [-13.670268601999965, 12.334664694000026],
            [-13.670218396999928, 12.334339666000062],
            [-13.670161090999954, 12.334015814000054],
            [-13.67009670899995, 12.333693296000035],
            [-13.670025279999948, 12.333372263000058],
            [-13.669946842999934, 12.333052869000028],
            [-13.669861431999948, 12.332735270000057],
            [-13.669769087999953, 12.332419619000063],
            [-13.669669858999953, 12.332106062000037],
            [-13.669563786999959, 12.331794754000043],
            [-13.669450926999957, 12.331485843000053],
            [-13.669331331999956, 12.331179474000066],
            [-13.669205056999942, 12.330875799000069],
            [-13.669072165999978, 12.330574960000035],
            [-13.66899811899998, 12.330414682000026],
            [-13.66883427199997, 12.330221084000073],
            [-13.668637126999954, 12.329978013000073],
            [-13.668445646999942, 12.329730453000025],
            [-13.668259937999949, 12.329478536000067],
            [-13.668080096999972, 12.329222397000024],
            [-13.667906221999942, 12.328962173000036],
            [-13.66773840199994, 12.328698],
            [-13.667576728999961, 12.328430024000056],
            [-13.667421287999957, 12.328158383000073],
            [-13.66727216299995, 12.32788322500005],
            [-13.667129434999936, 12.327604696000037],
            [-13.666993177999927, 12.327322945000049],
            [-13.666863464999949, 12.327038121000044],
            [-13.666740364999953, 12.326750378000042],
            [-13.666623942999934, 12.326459868000029],
            [-13.666514262999954, 12.326166745000023],
            [-13.666411384999947, 12.325871168000049],
            [-13.666315357999963, 12.325573294000037],
            [-13.666226239999958, 12.325273280000033],
            [-13.66614407399993, 12.324971290000065],
            [-13.666068905999964, 12.324667481000063],
            [-13.66600077499993, 12.324362017000055],
            [-13.665939716999958, 12.324055060000035],
            [-13.665885765999974, 12.323746776000064],
            [-13.665838948999976, 12.323437327000022],
            [-13.665799294999943, 12.323126881000064],
            [-13.66576681999993, 12.322815601000059],
            [-13.665741542999967, 12.32250365300007],
            [-13.6657234799999, 12.32219120600007],
            [-13.665712636999956, 12.321878424000033],
            [-13.66570902199993, 12.321565475000057],
            [-13.665712636999956, 12.321252526000023],
            [-13.6657234799999, 12.320939744000043],
            [-13.665741542999967, 12.320627296000055],
            [-13.66576681999993, 12.320315349000055],
            [-13.665799294999943, 12.32000406900005],
            [-13.665838948999976, 12.319693620000066],
            [-13.665885765999974, 12.31938417200007],
            [-13.665939716999958, 12.319075888000043],
            [-13.66600077499993, 12.318768933000058],
            [-13.666068905999964, 12.318463468000061],
            [-13.66614407399993, 12.31815965900006],
            [-13.666226239999958, 12.317857669000034],
            [-13.666315357999963, 12.31755765500003],
            [-13.666411384999947, 12.317259780000029],
            [-13.666514262999954, 12.316964203000055],
            [-13.666623942999934, 12.316671081000038],
            [-13.666740364999953, 12.316380571000025],
            [-13.666863464999949, 12.316092827000034],
            [-13.666993177999927, 12.315808004000075],
            [-13.667129434999936, 12.31552625300003],
            [-13.66727216299995, 12.315247723000027],
            [-13.667421287999957, 12.314972565000062],
            [-13.667576728999961, 12.314700925000068],
            [-13.66773840199994, 12.314432947000057],
            [-13.667906221999942, 12.314168776000031],
            [-13.668080096999972, 12.313908551000054],
            [-13.668259937999949, 12.313652413000057],
            [-13.668445646999942, 12.313400496000042],
            [-13.668637126999954, 12.313152936000051],
            [-13.66883427199997, 12.312909863000073],
            [-13.669036977999951, 12.312671411000053],
            [-13.66924513899994, 12.312437703000057],
            [-13.669458642999928, 12.312208867000038],
            [-13.669677374999935, 12.311985022000044],
            [-13.669901219999929, 12.311766291000026],
            [-13.670130055999948, 12.31155278700004],
            [-13.670363763999944, 12.31134462600005],
            [-13.670602215999963, 12.311141918000033],
            [-13.670845287999953, 12.310944773000074],
            [-13.671092847999944, 12.31075329600003],
            [-13.671268074999944, 12.310624121000046],
            [-13.671344764999958, 12.310567586000047],
            [-13.671600904999934, 12.310387744000025],
            [-13.671861129999968, 12.310213868000062],
            [-13.672125300999937, 12.31004604800006],
            [-13.672393278999948, 12.309884375000024],
            [-13.672664917999953, 12.309728935000066],
            [-13.672940076999964, 12.309579812000038],
            [-13.673218604999931, 12.309437082000045],
            [-13.673500355999977, 12.309300824000047],
            [-13.673785180999971, 12.309171111000069],
            [-13.674072924999962, 12.309048012000062],
            [-13.674363434999975, 12.308931591000032],
            [-13.674656556999935, 12.308821910000063],
            [-13.674952133999966, 12.308719031000066],
            [-13.675250006999931, 12.308623006000062],
            [-13.675550019999946, 12.308533887000067],
            [-13.67585201299994, 12.30845172100004],
            [-13.676155819999963, 12.308376552000027],
            [-13.67646128399997, 12.30830842100005],
            [-13.676755868999976, 12.308249682000053],
            [-13.67678584999993, 12.30824227100004],
            [-13.677217290999977, 12.30813164400007],
            [-13.677646884999945, 12.30801404600004],
            [-13.67807451799996, 12.307889508000073],
            [-13.678500078999946, 12.307758059000037],
            [-13.678923453999971, 12.307619739000074],
            [-13.679344534999927, 12.30747457900003],
            [-13.67976320799994, 12.307322621000026],
            [-13.680179366999937, 12.30716390300006],
            [-13.680592902999933, 12.30699846500005],
            [-13.681003703999977, 12.306826354000066],
            [-13.681411663999938, 12.306647614000042],
            [-13.681816676999972, 12.30646229100006],
            [-13.68221863399998, 12.306270433000066],
            [-13.682617433999951, 12.306072092000022],
            [-13.683012967999957, 12.305867318000026],
            [-13.68340513499993, 12.305656167000052],
            [-13.683793832999982, 12.305438693000042],
            [-13.684178955999926, 12.305214953000075],
            [-13.684560405999946, 12.304985007000028],
            [-13.684938082999963, 12.304748915000062],
            [-13.685311886999955, 12.304506736000064],
            [-13.68568172099998, 12.304258536000077],
            [-13.686047487999929, 12.304004381000027],
            [-13.686409089999927, 12.303744337000069],
            [-13.686766433999935, 12.303478469000027],
            [-13.68711942699997, 12.303206851000027],
            [-13.687467973999958, 12.30292955300007],
            [-13.687811985999929, 12.302646648000064],
            [-13.688151371999936, 12.302358207000054],
            [-13.688486040999976, 12.302064309000059],
            [-13.688815907999981, 12.301765032000048],
            [-13.689140885999961, 12.301460450000036],
            [-13.689460889999964, 12.301150647000043],
            [-13.689775833999931, 12.300835703000075],
            [-13.690085636999981, 12.300515700000062],
            [-13.690390216999958, 12.300190723000071],
            [-13.690689494999958, 12.299860855000077],
            [-13.690901159999953, 12.299619826000026],
            [-13.690983393999943, 12.299526186000037],
            [-13.691271831999927, 12.29918680000003],
            [-13.691554739999958, 12.298842789000048],
            [-13.691832036999926, 12.298494240000025],
            [-13.692103655999972, 12.298141248000036],
            [-13.692369521999979, 12.297783904000028],
            [-13.692629567999973, 12.29742230100004],
            [-13.692883722999966, 12.297056535000024],
            [-13.693131921999964, 12.296686701000056],
            [-13.69337410099996, 12.296312898000053],
            [-13.693610193999973, 12.295935220000047],
            [-13.693840138999974, 12.295553771000073],
            [-13.694063878999941, 12.295168647000025],
            [-13.694281352999951, 12.29477994900003],
            [-13.69602029799995, 12.294935808000048],
            [-13.697292991999973, 12.294262028000048],
            [-13.698565686999927, 12.292914468000049],
            [-13.69938919599997, 12.29089312800005],
            [-13.699529734999942, 12.290330975000074],
            [-13.699988111999971, 12.28849746700007],
            [-13.701630759999944, 12.281715349000024],
            [-13.703666572999964, 12.27375495900003],
            [-13.704899429999955, 12.26988026600003],
            [-13.707071605999943, 12.264009520000059],
            [-13.707658679999952, 12.26260054100004],
            [-13.708062438999946, 12.261969669000052],
            [-13.708598, 12.261132854000039],
            [-13.71006568599995, 12.259019385000045],
            [-13.711892015999979, 12.256584277000059],
            [-13.713841620999972, 12.253984805000073],
            [-13.713848544999962, 12.253975573000048],
            [-13.71394, 12.25361],
            [-13.71949, 12.24689],
            [-13.719838164999942, 12.248311499000067],
            [-13.72145493599993, 12.247806258000026],
            [-13.724272893999967, 12.247454013000038],
            [-13.72822264599995, 12.247358839000071],
            [-13.729145612999957, 12.24733659800006],
            [-13.730672006999953, 12.248041088000036],
            [-13.731728740999927, 12.248804285000062],
            [-13.73284418299994, 12.250037142000053],
            [-13.73345377399994, 12.251001777000056],
            [-13.734748546999981, 12.253050665000046],
            [-13.735420117999979, 12.255033465000054],
            [-13.737570362999975, 12.25914423000006],
            [-13.73870872799995, 12.261104747000047],
            [-13.74313570499993, 12.268504122000024],
            [-13.74408434299994, 12.269895457000075],
            [-13.746487557999956, 12.271666249000077],
            [-13.748321590999979, 12.272867856000062],
            [-13.750598320999927, 12.274195948000056],
            [-13.75350747799996, 12.27558728400004],
            [-13.755404752999937, 12.27640943700004],
            [-13.756732846999967, 12.276852134000023],
            [-13.758566878999943, 12.277168347000043],
            [-13.760780367999928, 12.277231590000042],
            [-13.762171702999979, 12.277105104000043],
            [-13.763942492999945, 12.275903496000069],
            [-13.764954373999956, 12.274828374000037],
            [-13.766771181999957, 12.271930679000036],
            [-13.767652098999974, 12.270525673000066],
            [-13.769950531999939, 12.266859817000068],
            [-13.771974292999971, 12.263128508000023],
            [-13.772543474999964, 12.261547445000076],
            [-13.773681840999927, 12.255918860000065],
            [-13.774124537999967, 12.254021586000022],
            [-13.775073175999978, 12.252819978000048],
            [-13.776274783999952, 12.251808097000037],
            [-13.777286664999963, 12.251555128000064],
            [-13.797144814999967, 12.251049187000035],
            [-13.79891560599998, 12.251365399000065],
            [-13.800243698999964, 12.251934582000047],
            [-13.801571791999947, 12.253009705000068],
            [-13.80340582499997, 12.254780496000024],
            [-13.805050129999927, 12.256677772000046],
            [-13.806197836999957, 12.258153393000043],
            [-13.806378223999957, 12.258385320000059],
            [-13.80688416299995, 12.259460443000023],
            [-13.80694740599995, 12.260978263000027],
            [-13.80688416299995, 12.262369598000078],
            [-13.806820921999929, 12.263507964000041],
            [-13.806378223999957, 12.264393359000053],
            [-13.805872282999928, 12.265215511000065],
            [-13.803785280999932, 12.266606846000059],
            [-13.800813652999977, 12.268238328000052],
            [-13.800559910999937, 12.268377637000071],
            [-13.79872587799997, 12.269326275000026],
            [-13.79752427099993, 12.269832216000054],
            [-13.796322662999955, 12.27078085200003],
            [-13.79512105599997, 12.27198246100005],
            [-13.79429890299997, 12.273057584000071],
            [-13.79391944699995, 12.274765132000027],
            [-13.793539992999968, 12.278686167000046],
            [-13.793476748999979, 12.28026723000005],
            [-13.793350264999958, 12.284314752000057],
            [-13.793350264999958, 12.286401755000043],
            [-13.79391944699995, 12.29019630700003],
            [-13.79524754099998, 12.29399085700004],
            [-13.795879965999973, 12.295255707000024],
            [-13.796638875999975, 12.296330830000045],
            [-13.79765075499995, 12.297026499000026],
            [-13.79891560599998, 12.297658924000075],
            [-13.799737757999935, 12.297722165000039],
            [-13.800370184999963, 12.297469196000065],
            [-13.801318820999938, 12.296520558000054],
            [-13.802646914999968, 12.294560041000068],
            [-13.802933140999926, 12.294004933000053],
            [-13.804733916999965, 12.29051251900006],
            [-13.806504708999967, 12.285642844000051],
            [-13.807832800999961, 12.281911536000052],
            [-13.815358661999937, 12.269516003000035],
            [-13.82199912599998, 12.25927071500007],
            [-13.822631549999926, 12.25832207600007],
            [-13.823516946999973, 12.25781613600003],
            [-13.824655310999958, 12.25787937900003],
            [-13.825667191999969, 12.258575046000033],
            [-13.826015645999973, 12.258877041000062],
            [-13.82661583, 12.259397199000034],
            [-13.827501224999935, 12.260535565000055],
            [-13.827943921999974, 12.261420960000066],
            [-13.829398500999957, 12.266543604000049],
            [-13.83009416699997, 12.269199789000027],
            [-13.830600107999942, 12.271350036000058],
            [-13.830663349999952, 12.272931098000072],
            [-13.830347138999969, 12.27653592200005],
            [-13.83003092599995, 12.279887775000077],
            [-13.83028389499998, 12.28134235300007],
            [-13.83097956399996, 12.282860174000064],
            [-13.831548745999953, 12.283619084000065],
            [-13.832560625999974, 12.284061781000048],
            [-13.83433141699993, 12.284630965000076],
            [-13.83572275299997, 12.284694208000076],
            [-13.837177330999964, 12.284567722000077],
            [-13.843311854999968, 12.283872055000074],
            [-13.851723108999977, 12.282543961000044],
            [-13.853810112999952, 12.282101263000072],
            [-13.855960357999948, 12.28127911200005],
            [-13.85999441599995, 12.27905626300003],
            [-13.86215812599994, 12.277864016000024],
            [-13.863233247999972, 12.277105104000043],
            [-13.864118642999927, 12.275903496000069],
            [-13.864434854999956, 12.274891617000037],
            [-13.864308370999936, 12.274069463000046],
            [-13.863296489999982, 12.273247312000024],
            [-13.860007879999955, 12.27078085200003],
            [-13.85766790699995, 12.269136547000073],
            [-13.856086842999957, 12.267934940000032],
            [-13.854505779999954, 12.266543604000049],
            [-13.853430656999933, 12.265152269000055],
            [-13.85286147499994, 12.264013903000034],
            [-13.852418777999958, 12.262306355000021],
            [-13.852292292999948, 12.261357717000067],
            [-13.852292292999948, 12.258891259000052],
            [-13.852608503999932, 12.255918860000065],
            [-13.85311444399997, 12.25357888800005],
            [-13.85684575299996, 12.243017387000066],
            [-13.85792087599998, 12.241436324000063],
            [-13.858932756999934, 12.241120111000043],
            [-13.86241109499997, 12.240867141000024],
            [-13.865372252999975, 12.24057965000003],
            [-13.888467011999978, 12.238337440000066],
            [-13.90590591299997, 12.236635071000023],
            [-13.915028870999947, 12.235744497000042],
            [-13.91692614699997, 12.235428284000022],
            [-13.918696936999936, 12.23479585900003],
            [-13.920657455999958, 12.233783980000055],
            [-13.92147960699998, 12.232519129000025],
            [-13.921922304999953, 12.229862943000057],
            [-13.922238516999926, 12.22670081800004],
            [-13.922491488999981, 12.223601933000054],
            [-13.923566609999966, 12.219870626000045],
            [-13.92527415799998, 12.215380407000055],
            [-13.926791979999962, 12.212724220000041],
            [-13.929195194999977, 12.209119397000052],
            [-13.931029227999943, 12.206905908000067],
            [-13.934760535999942, 12.202984872000059],
            [-13.936404842999934, 12.202099478000036],
            [-13.937669691999929, 12.201972993000027],
            [-13.939313998999978, 12.202352447000067],
            [-13.941780456999936, 12.202415691000056],
            [-13.945005824999953, 12.202225963000046],
            [-13.94845308899994, 12.201769976000037],
            [-13.956958659999941, 12.200644899000054],
            [-13.957043344999931, 12.200419074000024],
            [-13.95714838799995, 12.200138959000071],
            [-13.958223510999971, 12.18084999100006],
            [-13.957844056999932, 12.180027839000047],
            [-13.95733811599996, 12.179268928000056],
            [-13.956705689999978, 12.178889473000027],
            [-13.954112746999954, 12.177371653000023],
            [-13.948737133999941, 12.167505819000041],
            [-13.949306315999934, 12.164723148000064],
            [-13.949496043999943, 12.161750751000056],
            [-13.949559286999943, 12.159726990000024],
            [-13.948863618999951, 12.157892957000058],
            [-13.947282554999958, 12.154730830000062],
            [-13.945764734999955, 12.152770313000076],
            [-13.941527485999927, 12.148596307000048],
            [-13.937637916999961, 12.145059871000058],
            [-13.934986325999944, 12.14288239700005],
            [-13.928907334999963, 12.142629105000026],
            [-13.90028541299995, 12.13705669500007],
            [-13.894206419999932, 12.135790239000073],
            [-13.8883807199999, 12.134017200000073],
            [-13.884581349999962, 12.132750744000077],
            [-13.88154185299993, 12.129711248000035],
            [-13.880022104999966, 12.127431626000032],
            [-13.88052868799997, 12.123632255000075],
            [-13.88052868799997, 12.115273640000055],
            [-13.879515523999942, 12.112487436000038],
            [-13.875969444999953, 12.108434774000045],
            [-13.872676655999953, 12.105141987000025],
            [-13.865584499999954, 12.100582743000075],
            [-13.857225884999934, 12.09703666400003],
            [-13.84557448399994, 12.092224128000055],
            [-13.83949549099998, 12.090704380000034],
            [-13.835189538999941, 12.090451090000045],
            [-13.832150042999956, 12.089691215000073],
            [-13.821511806999979, 12.085891845000049],
            [-13.81897889299995, 12.084118806000049],
            [-13.815939396999966, 12.080826018000039],
            [-13.813406482999937, 12.075253609000072],
            [-13.812140026999941, 12.06942790900007],
            [-13.810054044999958, 12.058273099000075],
            [-13.810049007999964, 12.05824616600006],
            [-13.80767, 12.05062],
            [-13.807264338999971, 12.050116294000077],
            [-13.807045680999977, 12.050071872000046],
            [-13.806668012999978, 12.049987787000077],
            [-13.80629197899998, 12.049896674000024],
            [-13.80591770899997, 12.049798567000039],
            [-13.805545334999977, 12.049693499000057],
            [-13.805174982999972, 12.049581507000028],
            [-13.804806782999947, 12.04946263000005],
            [-13.804440861999979, 12.049336910000022],
            [-13.804077350999933, 12.049204389000067],
            [-13.80371637299993, 12.04906511400003],
            [-13.803358053999943, 12.048919133000027],
            [-13.803002517999971, 12.048766498000077],
            [-13.80264989099993, 12.04860726100003],
            [-13.802300293999963, 12.04844147700004],
            [-13.801953845999947, 12.048269204000064],
            [-13.80161067299997, 12.048090504000072],
            [-13.801270889999955, 12.047905434000029],
            [-13.800934616999939, 12.047714063000058],
            [-13.800601968999956, 12.047516457000029],
            [-13.800273063999953, 12.04731268200004],
            [-13.799948014999927, 12.047102811000059],
            [-13.799626933999946, 12.046886916000062],
            [-13.79930993499994, 12.046665074000032],
            [-13.79899712699995, 12.046437360000027],
            [-13.798688617999971, 12.046203852000076],
            [-13.798384515999942, 12.045964635000075],
            [-13.798084926999934, 12.045719790000021],
            [-13.797789954999928, 12.045469401000048],
            [-13.797499702999971, 12.045213558000057],
            [-13.797214269999927, 12.044952347000049],
            [-13.796933756999977, 12.044685859000026],
            [-13.796658261999937, 12.044414189000065],
            [-13.796387877999962, 12.044137431000024],
            [-13.796122701999934, 12.043855678000057],
            [-13.795862824999972, 12.04356903200005],
            [-13.795608336999976, 12.04327758900007],
            [-13.795359325999982, 12.042981453000039],
            [-13.795115879999969, 12.042680726000071],
            [-13.794878080999979, 12.04237551400007],
            [-13.794646015999945, 12.042065919000038],
            [-13.794419760999972, 12.041752053000039],
            [-13.794199397999932, 12.041434024000068],
            [-13.78426217599997, 12.036088723000034],
            [-13.769567413999937, 12.028981324000029],
            [-13.762107878999927, 12.025956818000054],
            [-13.747086821999972, 12.022131165000076],
            [-13.717528406999975, 12.016909763000058],
            [-13.717154144999938, 12.016841740000075],
            [-13.716781238999943, 12.016766646000065],
            [-13.716409818999978, 12.016684509000072],
            [-13.71604002099997, 12.016595358000075],
            [-13.715671976999943, 12.016499226000064],
            [-13.715305815999955, 12.016396144000055],
            [-13.714941671999952, 12.016286153000067],
            [-13.714579674999982, 12.01616929000005],
            [-13.714219954999976, 12.016045597000073],
            [-13.713862639999945, 12.015915119000056],
            [-13.713507856999968, 12.015777903000071],
            [-13.713155736999965, 12.015633998000055],
            [-13.712806400999966, 12.015483454000048],
            [-13.712459975999934, 12.015326328000071],
            [-13.712116587999958, 12.015162673000077],
            [-13.711776357999952, 12.014992548000066],
            [-13.711439407999933, 12.014816016000054],
            [-13.711105859999975, 12.014633139000068],
            [-13.710775830999978, 12.014443983000035],
            [-13.710449442999959, 12.014248615000042],
            [-13.710126807999927, 12.014047106000021],
            [-13.709808045999978, 12.013839525000037],
            [-13.70949326899995, 12.013625951000051],
            [-13.709182589999955, 12.013406459000066],
            [-13.708876118999967, 12.013181125000074],
            [-13.708573968999929, 12.012950034000028],
            [-13.708276245999969, 12.01271326400007],
            [-13.707983055999932, 12.012470905000043],
            [-13.70769450499995, 12.012223039000048],
            [-13.707410697999933, 12.011969757000031],
            [-13.707131732999926, 12.011711151000043],
            [-13.70685771199993, 12.011447310000051],
            [-13.706588733999979, 12.011178331000053],
            [-13.706324894999966, 12.010904311000047],
            [-13.706066286999942, 12.010625348000076],
            [-13.705813004999982, 12.010341539000024],
            [-13.705565140999965, 12.01005298900003],
            [-13.70532278, 12.00975980000004],
            [-13.705086011999981, 12.009462075000044],
            [-13.704854918999956, 12.009159925000063],
            [-13.704629584999964, 12.008853456000054],
            [-13.704410093999968, 12.00854277700006],
            [-13.704196518999936, 12.008228],
            [-13.703988939999931, 12.007909236000046],
            [-13.703787430999967, 12.00758660200006],
            [-13.703592062999974, 12.007260213000052],
            [-13.703402904999962, 12.006930186000034],
            [-13.703220027999976, 12.00659663700003],
            [-13.703043495999964, 12.006259686000021],
            [-13.702873372999932, 12.005919456000072],
            [-13.702709716999948, 12.005576069000028],
            [-13.702688917999978, 12.005531197000039],
            [-13.702689731999953, 12.005502449000062],
            [-13.702700578999952, 12.00526204700003],
            [-13.702718648999962, 12.00502208100005],
            [-13.702743922999957, 12.004782764000026],
            [-13.702776379999932, 12.004544316000022],
            [-13.70281598899993, 12.004306953000025],
            [-13.70286271499998, 12.004070886000022],
            [-13.70291651599996, 12.003836331000059],
            [-13.70297734299993, 12.003603499000064],
            [-13.703045139999972, 12.003372599000045],
            [-13.703119848999961, 12.003143843000032],
            [-13.703201398999965, 12.002917436000075],
            [-13.703289716999961, 12.002693582000063],
            [-13.703384724999978, 12.002472483000076],
            [-13.703486335999969, 12.002254341000025],
            [-13.703594457999941, 12.002039351000064],
            [-13.703708992999964, 12.001827710000043],
            [-13.703829837999933, 12.001619606000077],
            [-13.703956882999933, 12.001415227000052],
            [-13.704090014999963, 12.001214761000028],
            [-13.70422911199995, 12.001018387000045],
            [-13.704374048999966, 12.000826283000038],
            [-13.704524694999975, 12.000638622000054],
            [-13.704680913999937, 12.000455575000046],
            [-13.704842563999932, 12.000277305000054],
            [-13.70500949899997, 12.000103976000048],
            [-13.70518156999998, 11.999935741000058],
            [-13.705366564999963, 11.999765871000022],
            [-13.70554048699995, 11.999615167000059],
            [-13.705727011999954, 11.999463115000026],
            [-13.705918019999956, 11.999316737000072],
            [-13.70611334299997, 11.999176169000066],
            [-13.706312803999936, 11.999041533000025],
            [-13.706516219999969, 11.998912955000037],
            [-13.706723410999928, 11.998790549000034],
            [-13.706934185999955, 11.998674427000026],
            [-13.707148356999937, 11.998564693000048],
            [-13.707365728999946, 11.998461446000022],
            [-13.70758610799993, 11.998364779000042],
            [-13.707676042999935, 11.99832757200005],
            [-13.707694210999932, 11.998265027000059],
            [-13.708049038999945, 11.997029914000052],
            [-13.70839695099994, 11.995792834000042],
            [-13.708737936999967, 11.994553827000061],
            [-13.709071983999934, 11.993312933000027],
            [-13.709399082999937, 11.99207018800007],
            [-13.709719224999958, 11.990825634000032],
            [-13.710032396999964, 11.989579306000053],
            [-13.710338591999971, 11.988331248000065],
            [-13.710637798999926, 11.987081494000051],
            [-13.71093001, 11.985830086000021],
            [-13.711215213999935, 11.984577064000064],
            [-13.711493402999963, 11.983322465000072],
            [-13.711764567999978, 11.982066329000077],
            [-13.712028700999952, 11.980808696000054],
            [-13.712285792999978, 11.979549605000045],
            [-13.712535836999962, 11.978289095000036],
            [-13.71277882499993, 11.97702720500007],
            [-13.713014747999978, 11.975763976000053],
            [-13.713243599999942, 11.974499447000028],
            [-13.713465373999952, 11.973233656000048],
            [-13.713680060999934, 11.971966645000066],
            [-13.71388765599994, 11.970698453000068],
            [-13.714088153999967, 11.96942911900004],
            [-13.714281545999938, 11.96815868300007],
            [-13.714467824999929, 11.966887186000065],
            [-13.714484071999948, 11.966771792000031],
            [-13.714646989999949, 11.965614666000022],
            [-13.714819030999934, 11.96434116200004],
            [-13.714983944999972, 11.96306671700006],
            [-13.715141723999977, 11.961791369000025],
            [-13.715292366999961, 11.960515157000032],
            [-13.71543586599995, 11.959238124000024],
            [-13.715572217999977, 11.957960308000054],
            [-13.71570141999996, 11.956681747000061],
            [-13.715823463999982, 11.955402485000036],
            [-13.71593834999993, 11.95412255900004],
            [-13.716046072999973, 11.952842012000076],
            [-13.716146629999969, 11.951560880000045],
            [-13.716240016999961, 11.950279207000051],
            [-13.716326232999961, 11.948997031000033],
            [-13.716405272999964, 11.94771439300007],
            [-13.716477134999934, 11.946431333000021],
            [-13.71654181799994, 11.945147892000023],
            [-13.716599316999975, 11.943864107000024],
            [-13.716649633999964, 11.942580022000072],
            [-13.716692765999937, 11.94129567400006],
            [-13.716728710999973, 11.940011107000032],
            [-13.716757469999948, 11.938726357000064],
            [-13.71677903699998, 11.937441467000042],
            [-13.716793416999963, 11.936156476000065],
            [-13.716800607999971, 11.93487142500004],
            [-13.716800607999971, 11.933586355000045],
            [-13.716799933999937, 11.93337903500003],
            [-13.716764429999955, 11.933158098000035],
            [-13.716653411999971, 11.932437616000072],
            [-13.716549503999943, 11.931716074000065],
            [-13.71645271899996, 11.930993544000046],
            [-13.716363064999939, 11.930270094000036],
            [-13.716280550999954, 11.929545793000045],
            [-13.716205186999957, 11.928820716000075],
            [-13.716136975999973, 11.928094929000054],
            [-13.716075928999942, 11.92736850500006],
            [-13.716022049999935, 11.926641514000039],
            [-13.715975343999958, 11.925914028000022],
            [-13.715935814999966, 11.925186116000077],
            [-13.715903468999954, 11.924457848000031],
            [-13.71587830599998, 11.923729299000058],
            [-13.715860332999966, 11.923000535000028],
            [-13.715849546999948, 11.922271631000058],
            [-13.715845950999949, 11.921542654000064],
            [-13.715849546999948, 11.920813679000048],
            [-13.715860332999966, 11.92008477500002],
            [-13.71587830599998, 11.919356012000037],
            [-13.715903468999954, 11.918627462000075],
            [-13.715935814999966, 11.917899195000075],
            [-13.715975343999958, 11.917171282000027],
            [-13.716022049999935, 11.916443795000077],
            [-13.716075928999942, 11.915716805000045],
            [-13.716136975999973, 11.914990382000042],
            [-13.716205186999957, 11.914264595000077],
            [-13.716280550999954, 11.91353951700006],
            [-13.716363064999939, 11.912815217000059],
            [-13.71645271899996, 11.91209176600006],
            [-13.716549503999943, 11.911369235000052],
            [-13.716653411999971, 11.910647693000044],
            [-13.716764429999955, 11.90992721200007],
            [-13.71688255099997, 11.909207861000027],
            [-13.717007759999944, 11.908489710000026],
            [-13.71714004699993, 11.907772830000056],
            [-13.717279398999949, 11.907057288000033],
            [-13.71742580199998, 11.906343155000059],
            [-13.717579239999964, 11.905630503000054],
            [-13.71773970199996, 11.904919396000025],
            [-13.717907168999943, 11.904209908000041],
            [-13.718081626999947, 11.903502108000055],
            [-13.718263057999934, 11.902796060000071],
            [-13.718451443999982, 11.902091838000047],
            [-13.71864676499996, 11.901389509000069],
            [-13.71884900599997, 11.900689140000054],
            [-13.719018378999976, 11.900123584000028],
            [-13.719058144999963, 11.899990799000022],
            [-13.719274161999977, 11.89929455400005],
            [-13.71949703499996, 11.898600475000023],
            [-13.719726745999935, 11.897908628000039],
            [-13.719963266999969, 11.89721908100006],
            [-13.720206577999932, 11.89653190000007],
            [-13.720456656999943, 11.895847153000034],
            [-13.720713475999958, 11.895164904000069],
            [-13.720977012999981, 11.89448522300006],
            [-13.721247240999958, 11.89380817500006],
            [-13.721524134999981, 11.893133823000028],
            [-13.72171501799994, 11.888577892000058],
            [-13.72175128999993, 11.882631633000074],
            [-13.720448561999945, 11.870087624000064],
            [-13.717915647999973, 11.857676350000077],
            [-13.716221487999974, 11.853122318000032],
            [-13.715352042999939, 11.848668461000045],
            [-13.714577978999955, 11.844703201000073],
            [-13.714582435999944, 11.84362705600006],
            [-13.714622860999953, 11.833866963000048],
            [-13.714622860999953, 11.824495183000067],
            [-13.714369569999974, 11.809804287000077],
            [-13.714116277999949, 11.806258208000031],
            [-13.713356560999955, 11.803240592000066],
            [-13.713393134999933, 11.792253826000035],
            [-13.713400514999933, 11.790036894000025],
            [-13.713538683999957, 11.789884147000066],
            [-13.713825395999947, 11.789559261000022],
            [-13.714106670999968, 11.789229654000053],
            [-13.714382426999975, 11.788895417000049],
            [-13.714652591999936, 11.78855664300005],
            [-13.714917085999957, 11.788213426000027],
            [-13.715175839999972, 11.787865859000021],
            [-13.715428780999957, 11.78751403900003],
            [-13.715675838999971, 11.787158064000039],
            [-13.715916946999926, 11.786798032000036],
            [-13.716152035999926, 11.786434042000053],
            [-13.716381043999945, 11.786066196000036],
            [-13.716603904999943, 11.785694592000027],
            [-13.716820557999938, 11.785319336000043],
            [-13.717030944999976, 11.784940531000075],
            [-13.717235006999942, 11.784558281000045],
            [-13.717432685999938, 11.784172692000027],
            [-13.717623929999945, 11.783783872000072],
            [-13.717808683999976, 11.783391925000046],
            [-13.717986898999982, 11.782996963000073],
            [-13.718158523999932, 11.782599091000066],
            [-13.718323510999937, 11.782198423000068],
            [-13.71848181699994, 11.781795069000054],
            [-13.718633394999927, 11.781389138000065],
            [-13.718778206999957, 11.780980744000033],
            [-13.718916209999975, 11.78057],
            [-13.719047364999938, 11.780157017000022],
            [-13.719171639999956, 11.779741912000077],
            [-13.719288996999978, 11.77932480000004],
            [-13.719399404999933, 11.778905794000025],
            [-13.719502832999979, 11.778485010000054],
            [-13.719599253999945, 11.778062567000063],
            [-13.719688638999969, 11.777638578000051],
            [-13.719770964999952, 11.777213163000056],
            [-13.719846206999932, 11.776786437000055],
            [-13.719914347999975, 11.77635852000003],
            [-13.719975364999982, 11.775929530000042],
            [-13.720029244999978, 11.775499585000034],
            [-13.720075969999982, 11.775068803000067],
            [-13.721476265999968, 11.760919158000036],
            [-13.717663141999935, 11.75351752000006],
            [-13.717445924999936, 11.753235666000023],
            [-13.717234446999953, 11.752949483000066],
            [-13.717028791999951, 11.752659085000062],
            [-13.71682904499994, 11.752364592000049],
            [-13.716635288999953, 11.752066125000056],
            [-13.71644760299995, 11.751763803000074],
            [-13.716266061999931, 11.751457751000032],
            [-13.71609074, 11.751148095000076],
            [-13.715921709999975, 11.750834960000077],
            [-13.715759039999966, 11.750518475000035],
            [-13.715602798999953, 11.750198768000075],
            [-13.715453045999936, 11.749875968000026],
            [-13.715309844999979, 11.749550211000042],
            [-13.715173254999968, 11.749221627000054],
            [-13.715066724999929, 11.748949998000057],
            [-13.715043329999958, 11.748890351000057],
            [-13.714920124999935, 11.748556517000054],
            [-13.71480368899995, 11.748220262000075],
            [-13.714694067999972, 11.747881723000035],
            [-13.714591310999936, 11.747541041000034],
            [-13.714495454999962, 11.747198350000076],
            [-13.714406543999928, 11.74685379300007],
            [-13.71432460899996, 11.746507511000061],
            [-13.714249688999928, 11.746159645000034],
            [-13.714181809999957, 11.745810335000044],
            [-13.714121001999956, 11.745459725000046],
            [-13.714067288999956, 11.745107959000052],
            [-13.714020693999942, 11.744755180000027],
            [-13.713981234999949, 11.74440153200004],
            [-13.713948926999933, 11.744047158000058],
            [-13.713923786999942, 11.743692203000023],
            [-13.713905820999969, 11.743336813000042],
            [-13.713895039999954, 11.742981134000047],
            [-13.713442275999967, 11.712888320000047],
            [-13.71343148799997, 11.711693559000025],
            [-13.71342789299996, 11.710498752000035],
            [-13.713428436999948, 11.710034619000055],
            [-13.713472387999957, 11.709909634000041],
            [-13.71352021599995, 11.709783752000021],
            [-13.713553507999961, 11.709696131000044],
            [-13.713641326999948, 11.709485295000036],
            [-13.713735756999938, 11.70927733700006],
            [-13.713836704999949, 11.709072463000041],
            [-13.713944070999958, 11.708870880000063],
            [-13.71405774799996, 11.708672785000033],
            [-13.714177621999966, 11.708478377000063],
            [-13.715551133999952, 11.706333480000069],
            [-13.716502476999949, 11.705323890000045],
            [-13.719193557999972, 11.705219112000066],
            [-13.720932752999943, 11.705171217000043],
            [-13.72144355599994, 11.705232402000036],
            [-13.721955167999965, 11.705286416000035],
            [-13.72246748699996, 11.705333250000024],
            [-13.72298041299996, 11.705372895000039],
            [-13.723493843999961, 11.705405341000073],
            [-13.724007677999964, 11.705430585000045],
            [-13.724521816999982, 11.705448619000038],
            [-13.725036157999966, 11.705459441000073],
            [-13.725550599999963, 11.705463049000059],
            [-13.72606504199996, 11.705459441000073],
            [-13.726579383999933, 11.705448619000038],
            [-13.727093521999961, 11.705430585000045],
            [-13.727607358999933, 11.705405341000073],
            [-13.728120787999956, 11.705372895000039],
            [-13.728633712999965, 11.705333250000024],
            [-13.72914603199996, 11.705286416000035],
            [-13.729657643999928, 11.705232402000036],
            [-13.730168447999972, 11.705171217000043],
            [-13.730678342999965, 11.705102876000069],
            [-13.731187230999979, 11.705027389000065],
            [-13.731695007999974, 11.704944775000058],
            [-13.732201577999945, 11.704855045000045],
            [-13.732706838999945, 11.704758221000077],
            [-13.733210691999943, 11.704654320000031],
            [-13.733713039999941, 11.704543362000038],
            [-13.734213779999948, 11.70442537200006],
            [-13.73471281899998, 11.704300368000077],
            [-13.735210053999936, 11.704168381000045],
            [-13.735705387999928, 11.704029430000048],
            [-13.73619872699993, 11.703883549000068],
            [-13.73668996899994, 11.703730761000031],
            [-13.736892589999968, 11.703664612000068],
            [-13.737179021999964, 11.703571100000033],
            [-13.737665786999969, 11.703404596000041],
            [-13.738150168999937, 11.703231281000058],
            [-13.738632071999973, 11.703051191000043],
            [-13.739111402999981, 11.702864359000046],
            [-13.739588065999953, 11.702670823000062],
            [-13.740061968999953, 11.702470622000021],
            [-13.740533015999972, 11.702263794000032],
            [-13.740597657999956, 11.702234323000027],
            [-13.741001117999929, 11.70205038000006],
            [-13.741466179999975, 11.701830422000057],
            [-13.741928109999947, 11.701603964000071],
            [-13.742386820999968, 11.701371049000045],
            [-13.742842218999954, 11.70113172300006],
            [-13.743294215999981, 11.700886035000053],
            [-13.743742721999979, 11.700634032000039],
            [-13.744187650999947, 11.700375764000057],
            [-13.744628912999929, 11.700111282000023],
            [-13.745066423999958, 11.699840637000023],
            [-13.745500095999944, 11.699563883000053],
            [-13.745929842999942, 11.699281073000066],
            [-13.746355580999932, 11.698992264000026],
            [-13.746777227999928, 11.69869751400006],
            [-13.747194699999966, 11.698396879000029],
            [-13.747607912999968, 11.69809041800005],
            [-13.748016789999951, 11.697778192000044],
            [-13.748421245999964, 11.697460263000039],
            [-13.748821203999967, 11.697136693000061],
            [-13.749216584999942, 11.69680754600006],
            [-13.74960730999993, 11.696472887000027],
            [-13.74999330399993, 11.696132780000028],
            [-13.75245181799994, 11.693912312000066],
            [-13.752607196999975, 11.693765903000042],
            [-13.752757513999939, 11.69361430300006],
            [-13.752822217999949, 11.69354445700003],
            [-13.752902597999935, 11.693457688000024],
            [-13.753042278999942, 11.693296234000059],
            [-13.753176396999947, 11.693130133000068],
            [-13.753304798999977, 11.692959571000074],
            [-13.753427332999934, 11.692784748000065],
            [-13.753543861999958, 11.692605867000054],
            [-13.753654247999975, 11.692423130000066],
            [-13.753758365999943, 11.692236750000063],
            [-13.753856094999946, 11.692046942000047],
            [-13.75394732199993, 11.691853926000022],
            [-13.754031941999926, 11.691657923000037],
            [-13.754109859999971, 11.691459160000022],
            [-13.754180980999934, 11.691257865000068],
            [-13.754245225999966, 11.69105427200003],
            [-13.754302521999932, 11.69084861400006],
            [-13.754352799999936, 11.690641130000074],
            [-13.754396003999943, 11.690432058000056],
            [-13.754432084999962, 11.690221639000072],
            [-13.754460997999956, 11.690010117000043],
            [-13.754482713999948, 11.689797733000034],
            [-13.754497202999971, 11.689584736000029],
            [-13.758211536999966, 11.684889001000045],
            [-13.758293869999932, 11.684795231000066],
            [-13.75838146999996, 11.684706361000053],
            [-13.758474041999932, 11.684622685000022],
            [-13.75857128399997, 11.684544481000046],
            [-13.758672867999962, 11.684472010000036],
            [-13.758778457999938, 11.684405510000033],
            [-13.75888770499995, 11.684345206000046],
            [-13.759000244999982, 11.684291294000047],
            [-13.759115702999964, 11.684243956000046],
            [-13.759233696999956, 11.684203349000029],
            [-13.759353834999956, 11.684169608000047],
            [-13.75947571599994, 11.684142843000075],
            [-13.759598937999954, 11.684123144000068],
            [-13.759723090999955, 11.684110578000059],
            [-13.759847758999967, 11.684105184000032],
            [-13.759972532999939, 11.68410698200006],
            [-13.76009699399998, 11.684115965000046],
            [-13.767527886999972, 11.683670883000048],
            [-13.771845505999977, 11.683373361000065],
            [-13.772274977999928, 11.685249612000064],
            [-13.774148913999966, 11.688204687000052],
            [-13.775595446999944, 11.69048577500007],
            [-13.775666602999934, 11.690584942000044],
            [-13.775743506999959, 11.690679720000048],
            [-13.775825892999933, 11.690769776000025],
            [-13.775913467999942, 11.690854793000028],
            [-13.776005924999936, 11.690934473000027],
            [-13.776102940999976, 11.691008537000073],
            [-13.77620417299994, 11.691076721000059],
            [-13.776309266999931, 11.691138791000071],
            [-13.776417852999941, 11.691194523000036],
            [-13.776529551999943, 11.691243726000039],
            [-13.776643967999973, 11.691286226000045],
            [-13.776760699999954, 11.69132187300005],
            [-13.776879338999947, 11.691350541000077],
            [-13.776999469999964, 11.691372132000026],
            [-13.777120666999963, 11.691386568000041],
            [-13.777242506999926, 11.691393798000036],
            [-13.777364560999956, 11.691393798000036],
            [-13.777486401999965, 11.691386568000041],
            [-13.777607599999953, 11.691372132000026],
            [-13.777727728999935, 11.691350541000077],
            [-13.777846367999928, 11.69132187300005],
            [-13.777963101999944, 11.691286226000045],
            [-13.778077517999975, 11.691243726000039],
            [-13.77818921499994, 11.691194523000036],
            [-13.77829780199994, 11.691138791000071],
            [-13.778370054999982, 11.691097027000069],
            [-13.77985528299996, 11.687235432000023],
            [-13.782466128999943, 11.686713262000069],
            [-13.783568353999954, 11.686492817000044],
            [-13.788618131999954, 11.690205888000037],
            [-13.789310881999938, 11.690904680000074],
            [-13.790022903999954, 11.69162291300006],
            [-13.79328329599997, 11.692319117000068],
            [-13.796023471999945, 11.692941978000022],
            [-13.799014731999932, 11.693621915000051],
            [-13.799026889999936, 11.693760660000066],
            [-13.80013172799994, 11.70636913100003],
            [-13.800160271999971, 11.706488407000052],
            [-13.80018176599998, 11.706609154000034],
            [-13.800196137999933, 11.706730954000022],
            [-13.800203333999946, 11.70685338800007],
            [-13.80020312399995, 11.706982503000063],
            [-13.800186598999971, 11.707024821000061],
            [-13.800123929999927, 11.70715139400005],
            [-13.800054879999948, 11.707274599000073],
            [-13.79997962699997, 11.70739412000006],
            [-13.799898370999927, 11.707509643000037],
            [-13.799811322999972, 11.707620865000024],
            [-13.799718706999954, 11.707727497000064],
            [-13.799620769999933, 11.707829261000029],
            [-13.799517762999926, 11.707925892000048],
            [-13.799409955999977, 11.70801713700007],
            [-13.799297630999945, 11.708102759000042],
            [-13.799181080999972, 11.70818253400006],
            [-13.79906061, 11.70825625200007],
            [-13.798936529999935, 11.708323724000024],
            [-13.79880916899998, 11.708384772000045],
            [-13.798678855999981, 11.70843923800004],
            [-13.798545931999968, 11.708486979000043],
            [-13.798410744999956, 11.708527870000069],
            [-13.798273644999938, 11.708561805000045],
            [-13.798134989999937, 11.70858869400007],
            [-13.797995144999959, 11.708608470000058],
            [-13.793943684999931, 11.709064291000061],
            [-13.793861806999928, 11.709053511000036],
            [-13.793779301999962, 11.709049908000054],
            [-13.79369679599995, 11.709053511000036],
            [-13.793614915999967, 11.709064291000061],
            [-13.793534289999968, 11.709082164000051],
            [-13.793455527999981, 11.709106998000038],
            [-13.793379228999981, 11.709138602000053],
            [-13.793305974999953, 11.709176735000028],
            [-13.793236324999953, 11.709221108000065],
            [-13.793170804999932, 11.709271382000054],
            [-13.793109916999981, 11.709327176000045],
            [-13.79305412499997, 11.709388064000052],
            [-13.793003848999945, 11.709453582000037],
            [-13.792959477999943, 11.709523234000073],
            [-13.792921342999932, 11.709596488000045],
            [-13.792889740999954, 11.709672785000066],
            [-13.792864905999977, 11.709751549000032],
            [-13.792847030999951, 11.709832175000031],
            [-13.792836251999972, 11.709914053000034],
            [-13.792832648999934, 11.709996559000047],
            [-13.792712605999952, 11.713301409000053],
            [-13.793571063999934, 11.714584892000062],
            [-13.795604152999942, 11.716941365000025],
            [-13.795768065999937, 11.71697836800007],
            [-13.795927951999943, 11.717006965000053],
            [-13.79608894599994, 11.717028463000076],
            [-13.796250733999955, 11.717042818000039],
            [-13.796412998999926, 11.717050002000065],
            [-13.796575423999968, 11.717050002000065],
            [-13.79673768899994, 11.717042818000039],
            [-13.796899475999965, 11.717028463000076],
            [-13.797060470999952, 11.717006965000053],
            [-13.797220356999958, 11.71697836800007],
            [-13.797378822999974, 11.71694272600007],
            [-13.797535555999957, 11.71690010900005],
            [-13.79769025, 11.716850602000022],
            [-13.797842603999982, 11.716794299000071],
            [-13.797992318999945, 11.716731314000072],
            [-13.801514814999962, 11.715089244000069],
            [-13.802095152999982, 11.712513926000042],
            [-13.802601164999942, 11.710543141000073],
            [-13.804807122999932, 11.708622723000076],
            [-13.80586717899996, 11.708092696000051],
            [-13.806886443999929, 11.707583063000072],
            [-13.819910043999926, 11.704200276000051],
            [-13.820012210999948, 11.704178558000024],
            [-13.82011564499993, 11.704164022000043],
            [-13.820219840999982, 11.704156736000073],
            [-13.820324289999974, 11.704156736000073],
            [-13.820428485999969, 11.704164022000043],
            [-13.82053191999995, 11.704178558000024],
            [-13.820634087999963, 11.704200276000051],
            [-13.82073449099994, 11.70422906500005],
            [-13.82083264299996, 11.704264790000025],
            [-13.82092806199995, 11.70430727300004],
            [-13.821020286999953, 11.704356310000037],
            [-13.821108865999975, 11.704411660000062],
            [-13.821193366999978, 11.704473053000072],
            [-13.821273380999969, 11.70454019400006],
            [-13.821348514999954, 11.70461275100007],
            [-13.821418406999953, 11.704690371000027],
            [-13.821482712999966, 11.70477267900003],
            [-13.82154111999995, 11.704859272000022],
            [-13.82159334399995, 11.704949730000067],
            [-13.821639132999962, 11.70504360800004],
            [-13.821678259999942, 11.70514045300007],
            [-13.821710537999934, 11.705239790000064],
            [-13.821735805999936, 11.705341138000051],
            [-13.821753942999976, 11.705444002000036],
            [-13.821764861999952, 11.70554787900005],
            [-13.821768506999945, 11.705652266000072],
            [-13.821764861999952, 11.705756652000048],
            [-13.82416355, 11.710586362000072],
            [-13.826768656999946, 11.715831690000073],
            [-13.828029667999942, 11.717682164000053],
            [-13.82951862799996, 11.717660032000026],
            [-13.8321072599999, 11.717810407000059],
            [-13.832187910999949, 11.717830432000028],
            [-13.832266476999962, 11.71785750600003],
            [-13.832342342999937, 11.717891417000033],
            [-13.832414914999958, 11.717931901000043],
            [-13.832483624999952, 11.717978642000048],
            [-13.832547935999969, 11.71803127100003],
            [-13.83260734199996, 11.718089379000048],
            [-13.83266138199997, 11.71815250800006],
            [-13.832709630999943, 11.718220167000027],
            [-13.832751709999968, 11.71829182600004],
            [-13.832787292999967, 11.718366923000076],
            [-13.832816096999977, 11.718444872000077],
            [-13.832837900999948, 11.718525062000026],
            [-13.832852531999947, 11.718606864000037],
            [-13.833583038999961, 11.72463982000005],
            [-13.83358667899995, 11.724746365000044],
            [-13.833583038999961, 11.72485290800006],
            [-13.833572136999976, 11.724958955000034],
            [-13.833554022999976, 11.725064011000029],
            [-13.833528783999952, 11.725167586000055],
            [-13.833496532999959, 11.725269198000035],
            [-13.83345742399996, 11.72536837000007],
            [-13.833411637999973, 11.725464644000056],
            [-13.83335939099993, 11.725557569000046],
            [-13.833300920999932, 11.725646711000024],
            [-13.833236505999935, 11.725731655000061],
            [-13.833166443999971, 11.72581200600007],
            [-13.833091061999937, 11.725887388000046],
            [-13.833010712999965, 11.725957449000077],
            [-13.832925768999928, 11.726021865000064],
            [-13.83283662499997, 11.726080333000027],
            [-13.83274370099997, 11.726132582000048],
            [-13.832647427999973, 11.726178366000056],
            [-13.831474725999954, 11.72671824200006],
            [-13.831243943999937, 11.726816772000063],
            [-13.831010418999938, 11.72690860800003],
            [-13.830774343999963, 11.726993677000053],
            [-13.830535914999928, 11.727071908000028],
            [-13.830295330999945, 11.727143235000028],
            [-13.830052789999968, 11.727207600000042],
            [-13.82980849699993, 11.727264948000027],
            [-13.829562650999947, 11.727315232000024],
            [-13.829315458999929, 11.727358410000022],
            [-13.829067125999927, 11.727394447000052],
            [-13.828817855999944, 11.727423313000031],
            [-13.828567859999964, 11.727444984000044],
            [-13.828317341999934, 11.727459440000075],
            [-13.828066509999928, 11.727466672000048],
            [-13.827815575999978, 11.727466672000048],
            [-13.827703405999955, 11.727464332000068],
            [-13.827624905999926, 11.727458136000052],
            [-13.827444947999936, 11.727449125000021],
            [-13.827264771999978, 11.727447322000046],
            [-13.82708466899993, 11.727452730000039],
            [-13.82690492699993, 11.727465339000048],
            [-13.826725831999966, 11.727485130000048],
            [-13.826547672999936, 11.727512070000046],
            [-13.826370734999955, 11.727546115000052],
            [-13.826195299999938, 11.727587214000039],
            [-13.826021649999973, 11.727635300000031],
            [-13.825850062999962, 11.727690293000023],
            [-13.825680813999952, 11.727752108000061],
            [-13.825514172999931, 11.727820645000065],
            [-13.825350407999963, 11.727895794000062],
            [-13.825189780999949, 11.72797743600006],
            [-13.823875166999926, 11.728680634000057],
            [-13.823793782999928, 11.728729017000035],
            [-13.823716339999976, 11.728783490000069],
            [-13.823643293999964, 11.728843729000062],
            [-13.823575074999951, 11.728909384000076],
            [-13.823512080999933, 11.728980068000055],
            [-13.823454683999955, 11.729055367000058],
            [-13.823403217999953, 11.729134840000029],
            [-13.823357985999962, 11.72921801800004],
            [-13.823319254999944, 11.729304416000048],
            [-13.823287250999954, 11.729393523000056],
            [-13.823262162999981, 11.72948481900005],
            [-13.82324413699996, 11.72957776800007],
            [-13.823233277999975, 11.729671826000072],
            [-13.823229651999952, 11.729766436000034],
            [-13.823233277999975, 11.729861049000021],
            [-13.82366647799995, 11.733767416000035],
            [-13.823735836999958, 11.733895541000038],
            [-13.823808614999962, 11.734014305000073],
            [-13.823887511999942, 11.73412909800004],
            [-13.823972305999973, 11.734239605000027],
            [-13.824062768999966, 11.734345523000059],
            [-13.824158648999969, 11.734446561000027],
            [-13.824259686999937, 11.734542442000077],
            [-13.82734695199997, 11.737361896000039],
            [-13.831497061999926, 11.741050792000067],
            [-13.83155841499996, 11.741103070000065],
            [-13.831624266999938, 11.74114955400006],
            [-13.831694072999937, 11.741189857000052],
            [-13.831767255999978, 11.741223644000058],
            [-13.841588785999932, 11.745163297000033],
            [-13.84173571499997, 11.745193670000049],
            [-13.841883930999927, 11.745216963000075],
            [-13.84203309399993, 11.745233123000048],
            [-13.842182858999934, 11.745242112000028],
            [-13.842286331999958, 11.745248033000053],
            [-13.854681054999958, 11.745957267000051],
            [-13.860991500999944, 11.737742145000027],
            [-13.863563376999934, 11.729052159000048],
            [-13.862928863999969, 11.727914429000066],
            [-13.85968928899996, 11.722105627000076],
            [-13.856197690999977, 11.72044296200005],
            [-13.854698920999965, 11.719729262000044],
            [-13.8519661, 11.723412629000052],
            [-13.851015553999957, 11.727571268000077],
            [-13.851018611999962, 11.727633414000024],
            [-13.851018611999962, 11.72774676000006],
            [-13.851011422999932, 11.72785987900005],
            [-13.850997072999974, 11.727972315000045],
            [-13.850975621999964, 11.728083612000034],
            [-13.850947155999961, 11.728193326000053],
            [-13.850911787999962, 11.728301015000056],
            [-13.850869659999944, 11.728406241000073],
            [-13.850820943999963, 11.728508585000043],
            [-13.850765834999947, 11.72860763400007],
            [-13.850704556999972, 11.728702987000077],
            [-13.850637351999978, 11.728794261000075],
            [-13.850564492999979, 11.728881091000062],
            [-13.85048627499998, 11.728963123000028],
            [-13.850403011999958, 11.729040031000068],
            [-13.850315037999962, 11.729111501000034],
            [-13.850222707999933, 11.729177248000042],
            [-13.85012639599995, 11.72923700900003],
            [-13.850026484999944, 11.729290538000043],
            [-13.84992338099994, 11.729337625000028],
            [-13.849817497999936, 11.729378076000046],
            [-13.849709263999955, 11.729411731000027],
            [-13.849599111999964, 11.729438454000046],
            [-13.849487486999976, 11.729458137000051],
            [-13.84937483899995, 11.729470699000046],
            [-13.849261620999982, 11.729476092000027],
            [-13.84914828799998, 11.729474294000056],
            [-13.849114461999932, 11.729472361000035],
            [-13.848885461999942, 11.729095918000041],
            [-13.848477743999979, 11.728414916000077],
            [-13.84807621699997, 11.727730244000043],
            [-13.847680914999955, 11.727041963000033],
            [-13.847291868999946, 11.726350123000032],
            [-13.846909111999935, 11.725654787000053],
            [-13.846532673999945, 11.72495600700006],
            [-13.84616258699998, 11.724253845000021],
            [-13.84579888199994, 11.723548355000048],
            [-13.845436339999935, 11.722829067000077],
            [-13.847055589999968, 11.715553502000034],
            [-13.855881573999966, 11.703640375000077],
            [-13.855459356999972, 11.69452790400004],
            [-13.85878626999994, 11.687161170000024],
            [-13.858820335999951, 11.687097183000049],
            [-13.858886213999938, 11.686985502000027],
            [-13.858958249999944, 11.686877692000053],
            [-13.859036217999972, 11.686774091000075],
            [-13.85911987399993, 11.686675024000067],
            [-13.859208950999971, 11.686580803000027],
            [-13.859303170999965, 11.686491727000032],
            [-13.859402237999973, 11.686408071000074],
            [-13.85950583899995, 11.686330103000046],
            [-13.859613649999972, 11.68625806700004],
            [-13.867935276999958, 11.673021794000022],
            [-13.870905734999951, 11.667912608000051],
            [-13.86888582399996, 11.663991605000035],
            [-13.886101097999926, 11.664668349000067],
            [-13.894206419999932, 11.664668349000067],
            [-13.89851237299996, 11.664415057000042],
            [-13.902311743999974, 11.665681514000028],
            [-13.90636440399993, 11.667707845000052],
            [-13.913963144999968, 11.672267090000048],
            [-13.917002641999943, 11.674293419000037],
            [-13.920295428999964, 11.67657304100004],
            [-13.922828340999956, 11.677586208000037],
            [-13.92662771199997, 11.677586208000037],
            [-13.929667207999955, 11.676066460000072],
            [-13.934986325999944, 11.671000632000073],
            [-13.938532403999943, 11.66517493200007],
            [-13.941571901999964, 11.660109104000071],
            [-13.944743236999955, 11.656170454000062],
            [-13.945255568999926, 11.655998682000074],
            [-13.945770146999962, 11.655833759000075],
            [-13.946286880999935, 11.65567571500003],
            [-13.94680567599994, 11.655524577000051],
            [-13.947326440999973, 11.65538037500005],
            [-13.947849082999937, 11.655243132000066],
            [-13.948373508999964, 11.655112873000064],
            [-13.948899625999957, 11.654989621000027],
            [-13.949427340999932, 11.654873398000063],
            [-13.949956559999976, 11.654764227000044],
            [-13.987365058999956, 11.645863142000053],
            [-13.997308872999952, 11.643377327000053],
            [-13.997421495999959, 11.64338035700007],
            [-13.997781240999927, 11.64339476200007],
            [-13.998140626999941, 11.64341636100005],
            [-13.998499507999952, 11.643445148000069],
            [-13.998857740999938, 11.643481110000039],
            [-13.999215182999933, 11.643524231000072],
            [-13.999571689999982, 11.64357449800002],
            [-13.999927120999928, 11.643631885000048],
            [-14.000281331999929, 11.643696375000047],
            [-14.000634180999953, 11.643767938000053],
            [-14.000985527999944, 11.643846548000056],
            [-14.001335230999928, 11.643932171000074],
            [-14.00168315299993, 11.644024775000048],
            [-14.002029150999931, 11.644124322000039],
            [-14.002373087999956, 11.644230770000036],
            [-14.002714826999977, 11.644344080000053],
            [-14.00305422799994, 11.644464205000077],
            [-14.003391158999932, 11.644591098000035],
            [-14.003725484999961, 11.644724706000034],
            [-14.004057068999941, 11.644864978000044],
            [-14.00438578099994, 11.645011855000064],
            [-14.004711487999941, 11.645165280000072],
            [-14.005034059999957, 11.64532519100004],
            [-14.005353366999941, 11.645491525000068],
            [-14.005467476999968, 11.645552956000074],
            [-14.005723003999947, 11.64570090500007],
            [-14.006528203999949, 11.646161634000066],
            [-14.007336952999935, 11.646616100000074],
            [-14.008149203999949, 11.64706428200003],
            [-14.00896490599996, 11.647506149000037],
            [-14.009784011999955, 11.647941675000027],
            [-14.01060647099996, 11.648370834000048],
            [-14.011432233999926, 11.648793601000023],
            [-14.012261252999963, 11.649209948000077],
            [-14.013093473999959, 11.649619854000036],
            [-14.013928852999982, 11.650023291000025],
            [-14.014767333999941, 11.650420237000048],
            [-14.015608867999958, 11.65081066600004],
            [-14.016453407999961, 11.651194556000064],
            [-14.017300897999974, 11.651571883000031],
            [-14.018151289999935, 11.65194262500006],
            [-14.01900453299993, 11.652306759000055],
            [-14.027321366999956, 11.65305406400006],
            [-14.036660838999978, 11.654660394000075],
            [-14.04384852499993, 11.64893087200005],
            [-14.05730297699995, 11.647865555000067],
            [-14.05974981099996, 11.646980067000072],
            [-14.061723591999964, 11.645287194000048],
            [-14.06187922099997, 11.644993649000071],
            [-14.062041171999965, 11.644703542000059],
            [-14.062209371999927, 11.644417012000076],
            [-14.062383736999948, 11.64413419400006],
            [-14.062564190999979, 11.643855220000034],
            [-14.062750644999937, 11.643580220000047],
            [-14.062943011999948, 11.643309326000065],
            [-14.063141204999965, 11.643042660000049],
            [-14.063345126999934, 11.642780352000045],
            [-14.063554684999929, 11.642522524000071],
            [-14.063769777999937, 11.642269296000052],
            [-14.063990307999973, 11.642020787000035],
            [-14.064216169999952, 11.641777114000035],
            [-14.064447256999927, 11.641538393000076],
            [-14.064683460999959, 11.641304732000037],
            [-14.06492467299995, 11.641076244000033],
            [-14.065538190999973, 11.640504930000077],
            [-14.069191147999959, 11.637103258000025],
            [-14.06942358799995, 11.636896227000022],
            [-14.081636188999937, 11.626157650000039],
            [-14.081734573999938, 11.626071965000051],
            [-14.081837530999962, 11.625991832000068],
            [-14.08194474499993, 11.625917491000052],
            [-14.082055890999982, 11.625849171000027],
            [-14.082170632999976, 11.625787075000062],
            [-14.082288620999975, 11.62573139500006],
            [-14.082409494999979, 11.62568230000005],
            [-14.08253289199996, 11.625639938000063],
            [-14.082658435999974, 11.62560443700005],
            [-14.082785744999967, 11.625575906000051],
            [-14.08291443, 11.625554434000037],
            [-14.083044103999953, 11.625540081000054],
            [-14.083174372999963, 11.62553289400006],
            [-14.083304837999947, 11.62553289400006],
            [-14.083435106999957, 11.625540081000054],
            [-14.08356477999996, 11.625554434000037],
            [-14.083693466999932, 11.625575906000051],
            [-14.083820774999936, 11.62560443700005],
            [-14.08394631799996, 11.625639938000063],
            [-14.08406971599993, 11.62568230000005],
            [-14.084190590999981, 11.62573139500006],
            [-14.09390267799995, 11.629727776000038],
            [-14.099933772999975, 11.63173877500003],
            [-14.104498606999982, 11.633085480000034],
            [-14.104857599999946, 11.633119596000029],
            [-14.105215839999971, 11.633160865000036],
            [-14.105573185999958, 11.633209271000055],
            [-14.105929496999977, 11.63326479500006],
            [-14.106284628999958, 11.633327413000075],
            [-14.106638439999927, 11.63339710400004],
            [-14.106990792999966, 11.633473838000043],
            [-14.10734154499994, 11.633557582000037],
            [-14.107690554999976, 11.633648308000033],
            [-14.108037687999968, 11.633745976000057],
            [-14.108382802999927, 11.633850547000065],
            [-14.108725763999928, 11.633961983000063],
            [-14.109066433999942, 11.634080235000056],
            [-14.10940467599994, 11.634205259000055],
            [-14.109740358999943, 11.634337005000077],
            [-14.110073347999958, 11.634475420000058],
            [-14.11040350899998, 11.634620448000021],
            [-14.110730711999963, 11.634772033000047],
            [-14.111054826999975, 11.634930115000031],
            [-14.11137572399997, 11.635094630000026],
            [-14.111693275999926, 11.635265512000046],
            [-14.112007355999935, 11.635442693000073],
            [-14.112317839999946, 11.635626104000039],
            [-14.112624602999972, 11.635815670000056],
            [-14.112927525999964, 11.636011319000033],
            [-14.113226484999927, 11.636212969000042],
            [-14.113521361999972, 11.636420542000053],
            [-14.11381204199995, 11.636633956000026],
            [-14.114098407999961, 11.636853124000027],
            [-14.114380343999926, 11.637077961000045],
            [-14.114657739999927, 11.637308378000057],
            [-14.122213487999943, 11.643671961000052],
            [-14.122388077999972, 11.643823676000068],
            [-14.122557864999976, 11.643980748000047],
            [-14.122722684999928, 11.644143024000073],
            [-14.122882378999975, 11.644310348000033],
            [-14.123036790999947, 11.64448255800005],
            [-14.123185772999932, 11.644659486000023],
            [-14.123329180999974, 11.644840961000057],
            [-14.123466875999952, 11.645026810000047],
            [-14.12359872299993, 11.645216850000054],
            [-14.123724595999931, 11.645410899000069],
            [-14.12384437399993, 11.645608768000045],
            [-14.123957941999947, 11.645810267000059],
            [-14.124065185999939, 11.646015202000058],
            [-14.12416600499995, 11.64622337000003],
            [-14.124260302999971, 11.646434575000058],
            [-14.124347984999929, 11.646648611000046],
            [-14.1244289699999, 11.646865268000056],
            [-14.124503175999962, 11.647084340000049],
            [-14.124570533999929, 11.64730561500005],
            [-14.12463097799997, 11.647528876000024],
            [-14.124684446999936, 11.647753910000063],
            [-14.124730893999981, 11.647980498000038],
            [-14.124770271999978, 11.64820841900007],
            [-14.12480254199994, 11.648437457000057],
            [-14.12482767299997, 11.648667386000056],
            [-14.124845639999933, 11.648897986000065],
            [-14.12485642799993, 11.649129034000055],
            [-14.124860024999975, 11.649360305000073],
            [-14.12485642799993, 11.649591575000045],
            [-14.12485505099994, 11.649632257000064],
            [-14.124987807999958, 11.64976338100007],
            [-14.125358816999949, 11.650122938000038],
            [-14.125734798999929, 11.650477292000062],
            [-14.12611568099993, 11.650826374000076],
            [-14.126501387999951, 11.65117011500007],
            [-14.126891847999957, 11.65150845100004],
            [-14.12728698199993, 11.651841313000034],
            [-14.127686715999971, 11.652168638000035],
            [-14.12809097099995, 11.652490362000037],
            [-14.128499666999971, 11.652806424000062],
            [-14.128912728999978, 11.653116762000025],
            [-14.129330073999938, 11.65342131500006],
            [-14.129751618999933, 11.653720025000041],
            [-14.13017728799997, 11.654012833000024],
            [-14.13060699099998, 11.654299682000044],
            [-14.131040650999978, 11.654580516000067],
            [-14.131478180999977, 11.654855282000028],
            [-14.131919496999956, 11.655123927000034],
            [-14.132364511999981, 11.65538639700003],
            [-14.13281313999994, 11.655642643000022],
            [-14.133265292999965, 11.655892613000049],
            [-14.133720886999981, 11.65613626000004],
            [-14.134179829999937, 11.656373536000046],
            [-14.134642032999977, 11.656604395000045],
            [-14.135107408999943, 11.656828794000035],
            [-14.135575864999964, 11.657046687000047],
            [-14.13604731199996, 11.657258034000051],
            [-14.136521655999957, 11.657462791000057],
            [-14.13699880799993, 11.657660921000058],
            [-14.137478672999976, 11.657852383000034],
            [-14.137961158999929, 11.658037142000069],
            [-14.13844617199993, 11.658215163000023],
            [-14.138933618999943, 11.65838640800007],
            [-14.139423402999967, 11.658550846000026],
            [-14.139915431999952, 11.658708445000059],
            [-14.140409605999935, 11.658859175000032],
            [-14.140905833999966, 11.659003005000045],
            [-14.141404016999957, 11.659139909000032],
            [-14.141904058999955, 11.659269858000073],
            [-14.142405862999965, 11.659392829000069],
            [-14.142532026999959, 11.659422583000037],
            [-14.142912334999949, 11.659439156000076],
            [-14.143617050999978, 11.659464325000044],
            [-14.144321988999934, 11.65948230500004],
            [-14.145027072999937, 11.659493094000027],
            [-14.145732230999954, 11.659496690000026],
            [-14.146437387999981, 11.659493094000027],
            [-14.147142471999928, 11.65948230500004],
            [-14.1478474099999, 11.659464325000044],
            [-14.148552127999949, 11.659439156000076],
            [-14.14925655099995, 11.659406801000046],
            [-14.149960608999947, 11.659367262000046],
            [-14.150664225999947, 11.659320543000035],
            [-14.15136733099996, 11.659266652000042],
            [-14.15206984799994, 11.659205591000045],
            [-14.152771706999943, 11.659137367000028],
            [-14.153472832999967, 11.659061988000076],
            [-14.154173153999977, 11.658979461000058],
            [-14.15487259799994, 11.658889797000029],
            [-14.155571089999967, 11.658793002000039],
            [-14.156268556999976, 11.658689088000074],
            [-14.156964928999969, 11.65857806400004],
            [-14.157660133999968, 11.658459945000061],
            [-14.158354095999982, 11.658334740000043],
            [-14.159046744999955, 11.658202463000066],
            [-14.159738008999966, 11.658063130000073],
            [-14.16042781599998, 11.657916751000073],
            [-14.161116093999965, 11.657763346000024],
            [-14.161802770999941, 11.657602927000028],
            [-14.162487777999957, 11.657435512000063],
            [-14.172586044999946, 11.656730109000023],
            [-14.178397358999973, 11.656969472000071],
            [-14.179572088999976, 11.656975002000024],
            [-14.179600511999979, 11.656980581000028],
            [-14.179908917999967, 11.657045151000034],
            [-14.180215763999968, 11.65711677400003],
            [-14.180520886999943, 11.657195414000057],
            [-14.180824123999969, 11.657281030000036],
            [-14.181125320999968, 11.657373577000044],
            [-14.181424314999958, 11.657473005000043],
            [-14.181720952999967, 11.65757926300006],
            [-14.182015074999981, 11.65769229500006],
            [-14.182306525999934, 11.657812041000057],
            [-14.18259515799997, 11.657938439000077],
            [-14.18288081299994, 11.658071422000035],
            [-14.183163345999958, 11.658210918000066],
            [-14.183442605999971, 11.658356857000058],
            [-14.183718444999954, 11.65850916100004],
            [-14.1839907199999, 11.658667750000063],
            [-14.184259286999975, 11.65883253900006],
            [-14.184524003999968, 11.659003444000064],
            [-14.184784734999937, 11.659180375000062],
            [-14.185041338999952, 11.659363236000047],
            [-14.185293680999962, 11.659551932000056],
            [-14.185541631999968, 11.659746366000036],
            [-14.185785059999944, 11.659946433000073],
            [-14.186023834999958, 11.660152030000063],
            [-14.186257833999946, 11.660363047000033],
            [-14.18648693299997, 11.660579376000044],
            [-14.186711010999943, 11.660800900000027],
            [-14.186929951999957, 11.661027502000024],
            [-14.187143637999952, 11.66125906700006],
            [-14.187351958999955, 11.661495470000034],
            [-14.187554804999934, 11.661736588000053],
            [-14.187752067999952, 11.661982292000062],
            [-14.18794364699994, 11.662232456000027],
            [-14.18812943699993, 11.662486946000058],
            [-14.188309344999936, 11.662745631000064],
            [-14.188483272999974, 11.663008371000046],
            [-14.188651130999972, 11.663275031000069],
            [-14.18881283199994, 11.663545469000042],
            [-14.19413190299997, 11.66658751400007],
            [-14.205703351999944, 11.67198042900003],
            [-14.20676743599995, 11.672476349000021],
            [-14.206955768999933, 11.672401017000027],
            [-14.213566273999959, 11.669756815000028],
            [-14.223356598999942, 11.668125094000061],
            [-14.230728847999956, 11.67096846100003],
            [-14.233615663999956, 11.671745158000022],
            [-14.235322550999967, 11.67220439600004],
            [-14.235596246999933, 11.672099811000066],
            [-14.235922767999966, 11.67196797400004],
            [-14.236246523999966, 11.671829483000067],
            [-14.236567375999982, 11.671684395000057],
            [-14.236885192999978, 11.671532770000056],
            [-14.23719984, 11.671374675000038],
            [-14.237511186999939, 11.671210171000041],
            [-14.237819099999967, 11.67103933200002],
            [-14.238123453999947, 11.670862226000054],
            [-14.238424118999944, 11.670678929000076],
            [-14.238720970999964, 11.670489517000021],
            [-14.239013882999927, 11.670294070000068],
            [-14.239302733999978, 11.670092670000031],
            [-14.239587402999973, 11.669885400000055],
            [-14.239867770999979, 11.669672348000063],
            [-14.240091657999926, 11.669494873000076],
            [-14.240143719999935, 11.669453604000068],
            [-14.240415134999978, 11.66922925700004],
            [-14.240681901999949, 11.668999402000054],
            [-14.240943907999963, 11.66876413700004],
            [-14.241201045999958, 11.66852355900005],
            [-14.24145320499997, 11.668277768000053],
            [-14.241700281999954, 11.668026870000062],
            [-14.241942173999973, 11.667770966000035],
            [-14.242178775999946, 11.667510168000035],
            [-14.242409991999978, 11.667244581000034],
            [-14.242635724999957, 11.66697431700004],
            [-14.24285587999998, 11.666699491000031],
            [-14.243070363999948, 11.666420216000063],
            [-14.243279085999973, 11.66613661100007],
            [-14.243481962999965, 11.665848795000045],
            [-14.243678905999957, 11.665556886000047],
            [-14.243869833999952, 11.665261008000073],
            [-14.249358890999929, 11.66458037800004],
            [-14.254095943999971, 11.665449742000021],
            [-14.254889699999978, 11.665569814000037],
            [-14.25568450399993, 11.665682769000057],
            [-14.256480284999952, 11.665788593000059],
            [-14.257276984999976, 11.665887281000039],
            [-14.25807453799996, 11.665978825000025],
            [-14.258872877999977, 11.666063217000044],
            [-14.259671941999954, 11.666140450000057],
            [-14.260471665999944, 11.666210516000035],
            [-14.261271987999976, 11.666273412000066],
            [-14.262072838999927, 11.666329133000033],
            [-14.262874159999967, 11.666377674000046],
            [-14.263675880999926, 11.666419029000053],
            [-14.264477941999928, 11.666453197000067],
            [-14.265280277999977, 11.66648017600005],
            [-14.265781339999933, 11.666493373000037],
            [-14.266166691999956, 11.66623280500005],
            [-14.267040730999952, 11.665635548000068],
            [-14.267910693999966, 11.665032367000038],
            [-14.268776539999976, 11.664423292000038],
            [-14.269638225999927, 11.663808350000068],
            [-14.27049571799995, 11.663187568000069],
            [-14.271348971999942, 11.662560977000055],
            [-14.272197949999963, 11.661928603000035],
            [-14.273042613999962, 11.661290478000069],
            [-14.273882923999963, 11.66064663000003],
            [-14.27471884099998, 11.659997090000047],
            [-14.275550325999973, 11.65934188500006],
            [-14.276377341999932, 11.658681048000062],
            [-14.277199848999942, 11.658014609000077],
            [-14.278017810999927, 11.65734259800007],
            [-14.278831188999959, 11.656665046000057],
            [-14.279639945999975, 11.655981986000029],
            [-14.280444045999957, 11.655293448000066],
            [-14.281243449999977, 11.654599463000068],
            [-14.282038120999971, 11.653900064000027],
            [-14.282828021999933, 11.653195284000049],
            [-14.283613117999948, 11.652485155000022],
            [-14.284393372999943, 11.651769709000064],
            [-14.285168749999968, 11.651048979000052],
            [-14.285939213999939, 11.650323],
            [-14.286704727999961, 11.649591804000067],
            [-14.287220108999975, 11.649092791000044],
            [-14.28746525799994, 11.648855426000068],
            [-14.288220769999953, 11.64811389700003],
            [-14.288971224999955, 11.647367254000073],
            [-14.28971659299998, 11.646615530000076],
            [-14.290456837999955, 11.645858762000046],
            [-14.291191922999928, 11.645096982000041],
            [-14.291921817999935, 11.644330227000069],
            [-14.292646488999935, 11.643558532000043],
            [-14.293365900999959, 11.642781932000048],
            [-14.294080019999967, 11.642000464000034],
            [-14.294788814999947, 11.64121416300003],
            [-14.295492252999964, 11.64042306400006],
            [-14.296190300999967, 11.639627207000046],
            [-14.296240127999965, 11.639569980000033],
            [-14.296969084999944, 11.638720331000059],
            [-14.297812868999927, 11.637726568000062],
            [-14.29865115399997, 11.636728161000065],
            [-14.29948391299996, 11.635725140000034],
            [-14.300311122999972, 11.634717537000029],
            [-14.301132755999959, 11.633705383000063],
            [-14.301948787999947, 11.632688708000046],
            [-14.302759193999975, 11.63166754200006],
            [-14.303563950999944, 11.630641917000048],
            [-14.30436303199997, 11.629611866000062],
            [-14.305156413999953, 11.628577418000077],
            [-14.305944072999978, 11.62753860500004],
            [-14.306725984999957, 11.626495458000022],
            [-14.30750212299995, 11.62544801100006],
            [-14.308272467999927, 11.624396295000054],
            [-14.309036994999929, 11.623340341000073],
            [-14.309795677999944, 11.622280183000044],
            [-14.317743677999943, 11.611046793000071],
            [-14.318317983999975, 11.610230609000041],
            [-14.318886385999974, 11.60941030400005],
            [-14.31944885699994, 11.608585921000042],
            [-14.32000536399994, 11.607757501000037],
            [-14.320555881999951, 11.606925089000072],
            [-14.32110038299993, 11.606088728000032],
            [-14.321638832999952, 11.605248458000062],
            [-14.322171208999976, 11.604404328000044],
            [-14.322697481999967, 11.603556379000054],
            [-14.323217625999973, 11.602704655000025],
            [-14.323731610999971, 11.60184920200004],
            [-14.323924992999935, 11.601523689000032],
            [-14.32418820199996, 11.601355364000028],
            [-14.325186995999957, 11.600722021000024],
            [-14.32618962, 11.600094758000068],
            [-14.327196037999954, 11.599473600000067],
            [-14.328206209999962, 11.598858570000061],
            [-14.329220100999976, 11.598249688000067],
            [-14.330237672999942, 11.597646980000036],
            [-14.331258888999969, 11.597050465000052],
            [-14.332283711999935, 11.596460167000032],
            [-14.33262354599998, 11.59626716300005],
            [-14.333312100999933, 11.595876107000038],
            [-14.334344022999971, 11.595298307000064],
            [-14.335379433999947, 11.59472678800006],
            [-14.336418298999945, 11.59416157100003],
            [-14.337460581999949, 11.593602677000035],
            [-14.338506238999969, 11.593050128000073],
            [-14.339555234999978, 11.592503942000064],
            [-14.340607530999932, 11.591964140000073],
            [-14.341663088999951, 11.591430744000036],
            [-14.342145874999972, 11.591189457000041],
            [-14.343361352999977, 11.590571597000064],
            [-14.345555587999968, 11.589449949000027],
            [-14.347746541999982, 11.588321905000043],
            [-14.349934193999957, 11.587187474000075],
            [-14.352118529999927, 11.586046666000072],
            [-14.354299527999956, 11.584899492000034],
            [-14.356477169999948, 11.583745962000023],
            [-14.35865143899997, 11.58258608400007],
            [-14.360822313999961, 11.581419867000022],
            [-14.362989778999975, 11.580247325000073],
            [-14.365153814999928, 11.579068464000045],
            [-14.367314401999977, 11.57788329500005],
            [-14.369471523999948, 11.576691831000062],
            [-14.371625159999951, 11.57549407700003],
            [-14.373775293999927, 11.57429004900007],
            [-14.375921904999927, 11.573079751000023],
            [-14.378064976999951, 11.571863200000053],
            [-14.380204492999951, 11.570640401000048],
            [-14.404702586999974, 11.556825587000048],
            [-14.406164260999958, 11.556001328000036],
            [-14.438376442999981, 11.537839272000042],
            [-14.445131149999952, 11.534060895000039],
            [-14.447955313999955, 11.532523066000067],
            [-14.450776033999944, 11.530978926000046],
            [-14.453593293999973, 11.52942848300006],
            [-14.456407079999963, 11.527871746000073],
            [-14.459217378999938, 11.526308718000053],
            [-14.462024175999943, 11.52473941200003],
            [-14.464827454999977, 11.523163834000059],
            [-14.467627205999975, 11.52158199300004],
            [-14.470423411999946, 11.519993893000049],
            [-14.47321606, 11.518399546000069],
            [-14.476005137999948, 11.516798959000027],
            [-14.478790627999956, 11.515192139000021],
            [-14.48157251899994, 11.513579094000022],
            [-14.484350794999955, 11.511959834000038],
            [-14.487125443999957, 11.510334365000062],
            [-14.489896451999982, 11.508702697000047],
            [-14.49266380399996, 11.50706483600004],
            [-14.495427487999962, 11.505420792000052],
            [-14.498187487999928, 11.503770571000075],
            [-14.49938937199994, 11.50304933600006],
            [-14.517338301999928, 11.493014254000059],
            [-14.569553367999958, 11.499269183000024],
            [-14.570709572999931, 11.499393953000038],
            [-14.572609304999958, 11.499593141000048],
            [-14.574509773999978, 11.499785175000056],
            [-14.576410950999957, 11.499970055000063],
            [-14.578312811999979, 11.500147774000027],
            [-14.580215327999952, 11.500318333000052],
            [-14.582118470999944, 11.500481729000057],
            [-14.584022217999973, 11.500637958000027],
            [-14.585926536999978, 11.500787021000065],
            [-14.587831405999964, 11.500928912000063],
            [-14.589736795999954, 11.501063631000022],
            [-14.591642677999971, 11.501191177000067],
            [-14.593549027999927, 11.501311548000047],
            [-14.59545581499998, 11.501424740000061],
            [-14.597363017999953, 11.501530752000065],
            [-14.599270603999969, 11.501629584000057],
            [-14.601178550999975, 11.501721235000048],
            [-14.60308682799996, 11.50180570200007],
            [-14.604995408999969, 11.501882984000076],
            [-14.606904267999937, 11.501953080000021],
            [-14.608813376999933, 11.502015990000075],
            [-14.610722710999937, 11.502071712000031],
            [-14.612632238999936, 11.50212024700005],
            [-14.614541936999956, 11.50216159200005],
            [-14.616451776999952, 11.50219574700003],
            [-14.618361732999972, 11.502222713000037],
            [-14.620271774999935, 11.502242487000046],
            [-14.622181879999971, 11.502255072000025],
            [-14.624092017999942, 11.502260465000063],
            [-14.624705530999961, 11.502260672000034],
            [-14.671427136999966, 11.491926440000043],
            [-14.701287628999978, 11.46212034000007],
            [-14.720324371999936, 11.435468900000046],
            [-14.72543709699994, 11.426494434000062],
            [-14.735771328999931, 11.415888250000023],
            [-14.750047860999928, 11.396387883000045],
            [-14.761878861999946, 11.379392093000035],
            [-14.779773399999954, 11.35339334200006],
            [-14.786572236999973, 11.334356599000046],
            [-14.78812401099998, 11.330528891000029],
            [-14.79717842399998, 11.308194674000049],
            [-14.807730216999971, 11.279911513000059],
            [-14.813527434999969, 11.267055152000069],
            [-14.828344289999961, 11.234196134000058],
            [-14.828536054999972, 11.233778823000023],
            [-14.82909738199993, 11.232545755000046],
            [-14.829652158999977, 11.231309726000063],
            [-14.830200365999929, 11.230070770000054],
            [-14.830741992999947, 11.228828922000048],
            [-14.831277019999959, 11.227584217000071],
            [-14.831805434999978, 11.226336691000029],
            [-14.832327222999936, 11.225086378000071],
            [-14.832842367999945, 11.223833314000046],
            [-14.833350855999981, 11.222577535000028],
            [-14.833852672999967, 11.221319073000075],
            [-14.834347804999936, 11.220057968000049],
            [-14.834836236999934, 11.218794252000066],
            [-14.835317955999926, 11.217527962000077],
            [-14.835792948999938, 11.216259132000062],
            [-14.836261200999957, 11.214987801000063],
            [-14.836722701999975, 11.213714002000074],
            [-14.837177434999944, 11.212437773000033],
            [-14.837625387999935, 11.211159149000025],
            [-14.83806655099994, 11.209878164000031],
            [-14.838500909999937, 11.208594857000037],
            [-14.838928451999948, 11.20730926300007],
            [-14.839349166999966, 11.206021419000024],
            [-14.839763038999934, 11.20473136000004],
            [-14.84017006199997, 11.203439122000077],
            [-14.84057022099995, 11.202144744000066],
            [-14.840963504999934, 11.200848258000065],
            [-14.84134990399997, 11.199549705000038],
            [-14.841398057999982, 11.199386090000075],
            [-14.852548149999961, 11.179533487000072],
            [-14.88458426799997, 11.12522437900003],
            [-14.919557485999974, 11.06398045800006],
            [-14.925612189999981, 11.053408749000027],
            [-14.918045998999958, 11.044884681000042],
            [-14.903278350999926, 11.034573555000065],
            [-14.898909569999944, 11.037010193000071],
            [-14.894099235999931, 11.03390693700004],
            [-14.89546108299993, 11.033503532000054],
            [-14.898449897999967, 11.030477524000048],
            [-14.899318694999977, 11.025406838000038],
            [-14.890547751999975, 11.012859344000049],
            [-14.890150069999947, 11.011621475000027],
            [-14.886430741999959, 11.00002670400005],
            [-14.886494636999942, 10.986454010000045],
            [-14.881169320999959, 10.969953537000038],
            [-14.873158454999952, 10.962229730000047],
            [-14.86989784399998, 10.962038040000039],
            [-14.867831229999979, 10.964363100000071],
            [-14.864309311999932, 10.96187496300007],
            [-14.85919761699995, 10.962626457000056],
            [-14.848103522999963, 10.96919822700005],
            [-14.837979317999952, 10.978749275000041],
            [-14.831256865999933, 10.981128693000073],
            [-14.823210716999938, 10.989504815000032],
            [-14.814840317999938, 10.990294456000072],
            [-14.81214046599996, 10.991920471000071],
            [-14.809266581999964, 10.990553073000058],
            [-14.809077118999937, 10.99046292600002],
            [-14.798484802999951, 10.98542308900005],
            [-14.801192283999967, 10.978260994000038],
            [-14.799053191999974, 10.974605562000022],
            [-14.794843673999935, 10.973044396000034],
            [-14.787398338999935, 10.97336006200004],
            [-14.776265144999968, 10.976710320000052],
            [-14.76904392299997, 10.976333619000059],
            [-14.756625174999954, 10.969575883000061],
            [-14.751042366999968, 10.969869613000071],
            [-14.727160452999954, 10.997518539000055],
            [-14.723503112999936, 11.003310204000059],
            [-14.723544121999964, 11.006760597000039],
            [-14.729946135999967, 11.013854982000055],
            [-14.727703093999935, 11.01915073500004],
            [-14.697134970999969, 11.03788471200005],
            [-14.685727118999978, 11.03801345900007],
            [-14.68339061699993, 11.042108536000057],
            [-14.671010017999947, 11.03900146500007],
            [-14.67125034299994, 11.034495353000068],
            [-14.66982364699993, 11.031981470000062],
            [-14.65908813599998, 11.029800416000057],
            [-14.655987739999944, 11.023394586000052],
            [-14.65758800499998, 11.020845413000075],
            [-14.664639472999966, 11.026517869000031],
            [-14.68209552899998, 11.026092530000028],
            [-14.69161319899996, 11.023685457000056],
            [-14.693906785999957, 11.020668030000024],
            [-14.692161559999931, 11.01079654700004],
            [-14.694165230999943, 11.00295353100006],
            [-14.698285103999979, 10.99692535500003],
            [-14.702670097999942, 10.993655205000039],
            [-14.712662696999928, 10.992161752000072],
            [-14.717517851999958, 10.989347458000054],
            [-14.72920703899996, 10.973800659000062],
            [-14.74093341899993, 10.961474420000059],
            [-14.744833945999972, 10.960021973000039],
            [-14.746031760999927, 10.959575654000048],
            [-14.752545356999974, 10.959269524000035],
            [-14.761184691999972, 10.96147346500004],
            [-14.769557952999946, 10.96091556600004],
            [-14.773491858999932, 10.959030152000025],
            [-14.785899162999954, 10.945544243000029],
            [-14.793562889999976, 10.944074632000024],
            [-14.796796797999946, 10.941966057000059],
            [-14.797712325999953, 10.939620972000057],
            [-14.805223465999973, 10.933257104000063],
            [-14.812791825999966, 10.933190345000071],
            [-14.81634902999997, 10.930010797000023],
            [-14.81729698199996, 10.924688339000056],
            [-14.81496143499993, 10.908375740000054],
            [-14.816065789999982, 10.898329735000061],
            [-14.814444541999933, 10.89519500800003],
            [-14.815788269999928, 10.889475823000055],
            [-14.81555175799997, 10.885345460000053],
            [-14.814183235999963, 10.884857177000072],
            [-14.809782027999972, 10.874564171000031],
            [-14.806808471999943, 10.865479470000025],
            [-14.807694436999952, 10.861788751000063],
            [-14.804094315999976, 10.852629662000027],
            [-14.80632114499997, 10.844322205000026],
            [-14.805788993999954, 10.838808061000066],
            [-14.809371947999978, 10.827263833000075],
            [-14.809088707999933, 10.823127748000047],
            [-14.807435036999948, 10.821076393000055],
            [-14.801415443999929, 10.823447228000077],
            [-14.795369147999963, 10.823747636000064],
            [-14.791913032999958, 10.826547624000057],
            [-14.783040047999975, 10.823890687000073],
            [-14.777003288999936, 10.82488346100007],
            [-14.768590926999934, 10.834453583000027],
            [-14.761412621999966, 10.847433090000038],
            [-14.754131317999963, 10.836192131000075],
            [-14.755395890999978, 10.827430726000046],
            [-14.758775710999942, 10.818190575000074],
            [-14.758023261999938, 10.813598634000073],
            [-14.760732650999955, 10.80666732800006],
            [-14.761760710999965, 10.795384408000075],
            [-14.767842293999934, 10.776308060000076],
            [-14.778833388999942, 10.764133454000046],
            [-14.781843185999946, 10.762948037000058],
            [-14.789066315999946, 10.764014246000045],
            [-14.789958000999945, 10.760784150000063],
            [-14.78705120199993, 10.751156808000076],
            [-14.775099753999939, 10.724383355000043],
            [-14.768489837999937, 10.716178895000041],
            [-14.762408257999937, 10.71325874300004],
            [-14.759754181999938, 10.705239296000059],
            [-14.748177527999928, 10.689961434000054],
            [-14.743817328999967, 10.694841385000075],
            [-14.74224090599995, 10.70045852800007],
            [-14.741808890999948, 10.701994897000077],
            [-14.742557525999928, 10.706357002000061],
            [-14.743321418999926, 10.711868287000073],
            [-14.744128227999965, 10.721059799000045],
            [-14.739868164999962, 10.734449386000051],
            [-14.740148544999954, 10.738587379000023],
            [-14.736235617999967, 10.747120858000073],
            [-14.730832100999976, 10.748952867000071],
            [-14.728230476999954, 10.753444672000057],
            [-14.726860047999935, 10.755530359000034],
            [-14.72223377399996, 10.757653237000056],
            [-14.713972091999949, 10.767178537000063],
            [-14.707737922999968, 10.771159172000068],
            [-14.691521645999956, 10.776863098000035],
            [-14.679739951999977, 10.783896446000028],
            [-14.672147750999954, 10.791112901000076],
            [-14.668965338999953, 10.797589302000063],
            [-14.664504051999927, 10.80981636000007],
            [-14.656233787999952, 10.800231934000067],
            [-14.66618824099993, 10.778758050000022],
            [-14.669404983999982, 10.775272370000039],
            [-14.674476622999975, 10.771304131000022],
            [-14.693945884999948, 10.765335084000071],
            [-14.703863143999968, 10.758091926000077],
            [-14.705685616999972, 10.754851343000041],
            [-14.707128524999973, 10.738783837000028],
            [-14.712270736999926, 10.72142410300006],
            [-14.715431213999977, 10.713336945000037],
            [-14.717246056999954, 10.709405899000046],
            [-14.720241546999944, 10.70707225800004],
            [-14.723169326999937, 10.698987962000047],
            [-14.703064918999928, 10.690015794000033],
            [-14.688346862999936, 10.69660854500006],
            [-14.68493175499998, 10.690450669000029],
            [-14.691717148999942, 10.682207108000057],
            [-14.691021919999969, 10.67891693200005],
            [-14.694685935999928, 10.678554535000046],
            [-14.695646286999931, 10.67393398300004],
            [-14.69764614099995, 10.673916818000066],
            [-14.700608252999928, 10.678772926000022],
            [-14.707112312999982, 10.67823886800005],
            [-14.71101665599997, 10.67405414600006],
            [-14.711432457999933, 10.66990852300006],
            [-14.707080840999936, 10.655697823000025],
            [-14.708667756999944, 10.652229310000052],
            [-14.705804824999973, 10.646052361000045],
            [-14.706662177999931, 10.638195991000032],
            [-14.706498145999944, 10.634887696000021],
            [-14.70327472699995, 10.632538795000073],
            [-14.69532585099995, 10.63973522200007],
            [-14.690122603999953, 10.640175819000035],
            [-14.688540458999967, 10.64256477500004],
            [-14.661901473999933, 10.669548035000048],
            [-14.652683258999957, 10.67678070200003],
            [-14.636949538999943, 10.683626176000075],
            [-14.629098892999934, 10.708785058000046],
            [-14.609889029999977, 10.737681390000034],
            [-14.605239867999956, 10.745876312000064],
            [-14.593705176999947, 10.76644897500006],
            [-14.59227371299994, 10.773961068000062],
            [-14.58577728399996, 10.785397530000068],
            [-14.573373795999942, 10.804623605000074],
            [-14.564440726999976, 10.811852455000064],
            [-14.560749054999974, 10.814650537000034],
            [-14.552947998999969, 10.82650280100006],
            [-14.547275035999974, 10.822068053000066],
            [-14.541002272999947, 10.81716442000004],
            [-14.546528816999967, 10.812045097000066],
            [-14.554984092999973, 10.784515381000062],
            [-14.558954238999945, 10.779015541000035],
            [-14.560934067999938, 10.768875123000043],
            [-14.574777602999973, 10.738132476000033],
            [-14.588770866999937, 10.720958710000048],
            [-14.595145224999953, 10.71640205400007],
            [-14.600374220999981, 10.714764596000066],
            [-14.603303908999976, 10.710449219000054],
            [-14.611522674999947, 10.698690415000044],
            [-14.61180496299994, 10.692425728000046],
            [-14.617348670999945, 10.680809021000073],
            [-14.61473846499996, 10.673061372000063],
            [-14.616887092999946, 10.657626152000034],
            [-14.620802880999975, 10.645295143000055],
            [-14.62209796899998, 10.645607948000077],
            [-14.625006675999941, 10.635684967000032],
            [-14.627397536999979, 10.62116909100007],
            [-14.623734474999935, 10.605798722000031],
            [-14.620185851999963, 10.600317956000026],
            [-14.617374421999955, 10.598507881000046],
            [-14.602497100999926, 10.598442078000062],
            [-14.595540046999929, 10.59989833800006],
            [-14.59384536899995, 10.593936920000033],
            [-14.597666739999966, 10.593328476000067],
            [-14.606563567999956, 10.588047028000062],
            [-14.618312835999973, 10.578717232000031],
            [-14.621053695999933, 10.574546815000076],
            [-14.62453460699993, 10.574048997000034],
            [-14.628394127999968, 10.565956116000052],
            [-14.633264542999939, 10.564981461000059],
            [-14.63676357299994, 10.566091538000023],
            [-14.640444755999965, 10.562831880000033],
            [-14.64299869599995, 10.562573434000058],
            [-14.645966529999953, 10.557939530000056],
            [-14.650863646999937, 10.55926609200003],
            [-14.654650687999947, 10.565203667000048],
            [-14.65812301699998, 10.564015389000076],
            [-14.660819052999955, 10.55593490700005],
            [-14.659815788999936, 10.549506188000066],
            [-14.65791416199994, 10.545846940000047],
            [-14.654649734999964, 10.544963838000058],
            [-14.652948379999941, 10.538542747000065],
            [-14.657941818999973, 10.528137208000032],
            [-14.662348748999932, 10.527397156000063],
            [-14.664360045999956, 10.520472526000049],
            [-14.668516158999978, 10.518128395000076],
            [-14.669162749999941, 10.513751030000037],
            [-14.667467116999944, 10.507789611000021],
            [-14.666003227999965, 10.501826286000039],
            [-14.6625776299999, 10.486914636000051],
            [-14.667083739999953, 10.474674225000058],
            [-14.665385245999971, 10.46848297200006],
            [-14.660693169999945, 10.464626314000043],
            [-14.656757355999957, 10.465820312000062],
            [-14.653533935999974, 10.468385696000041],
            [-14.651533127999926, 10.476228715000047],
            [-14.631262779999929, 10.471393586000033],
            [-14.623343467999973, 10.469641687000035],
            [-14.591046332999952, 10.469766618000051],
            [-14.582470894999972, 10.471699715000057],
            [-14.57715892799996, 10.474517823000042],
            [-14.574176787999932, 10.477999687000022],
            [-14.56897354299997, 10.490704536000067],
            [-14.562789916999975, 10.498593330000062],
            [-14.555633545999967, 10.503040314000032],
            [-14.545666694999966, 10.505857468000045],
            [-14.544851869999945, 10.503164878000064],
            [-14.543840455999941, 10.499822659000074],
            [-14.542655329999945, 10.495906406000074],
            [-14.542650556999945, 10.495890634000034],
            [-14.542523075999952, 10.495469374000038],
            [-14.542323393999936, 10.49480952600004],
            [-14.542149544999972, 10.494235038000056],
            [-14.548232077999955, 10.494186402000025],
            [-14.551980972999957, 10.48790073400005],
            [-14.554555892999929, 10.468782425000029],
            [-14.55190468, 10.439372063000064],
            [-14.55577945799996, 10.432660103000046],
            [-14.552132606999976, 10.418210031000058],
            [-14.542930602999945, 10.405429840000068],
            [-14.535104752999928, 10.39102458900004],
            [-14.505584716999977, 10.368111612000064],
            [-14.494556427999953, 10.357879638000043],
            [-14.485141755999962, 10.346479416000022],
            [-14.479284286999928, 10.341711046000057],
            [-14.475086211999951, 10.340146064000066],
            [-14.46344757199995, 10.339283943000055],
            [-14.463552474999972, 10.32600784400006],
            [-14.456084251999982, 10.301018716000044],
            [-14.471147537999968, 10.255555153000046],
            [-14.470640182999944, 10.25142097500003],
            [-14.465477943999929, 10.24641609300005],
            [-14.463812828999949, 10.242753029000028],
            [-14.465380667999966, 10.23744774000005],
            [-14.462759971999958, 10.231266021000067],
            [-14.463626862999945, 10.225507736000054],
            [-14.460032463999937, 10.220862388000057],
            [-14.459388733999958, 10.220032692000075],
            [-14.45836830099995, 10.211535455000046],
            [-14.456477164999967, 10.208334922000063],
            [-14.454380035999975, 10.207667352000044],
            [-14.44905853299997, 10.209332466000035],
            [-14.437347411999951, 10.22118282300005],
            [-14.429735182999934, 10.225630760000058],
            [-14.423941612999954, 10.226610184000037],
            [-14.417884827999956, 10.224603654000077],
            [-14.413475989999938, 10.224879265000027],
            [-14.40175056499993, 10.235578538000027],
            [-14.39177131699995, 10.235910416000024],
            [-14.379204749999928, 10.233049393000044],
            [-14.368244171999947, 10.228330613000026],
            [-14.356350898999949, 10.222229957000025],
            [-14.349338531999933, 10.218633652000051],
            [-14.317090034999978, 10.197799682000039],
            [-14.312398910999946, 10.193017006000048],
            [-14.299261092999927, 10.179348946000061],
            [-14.294774054999948, 10.171804429000076],
            [-14.28423023199997, 10.162019731000044],
            [-14.267653464999967, 10.152294159000064],
            [-14.26272869099995, 10.153208733000042],
            [-14.25431223499993, 10.142906229000062],
            [-14.25337028499996, 10.141753197000071],
            [-14.25308322899997, 10.134269715000073],
            [-14.249772071999928, 10.128092766000066],
            [-14.238041877999933, 10.115328789000046],
            [-14.218910216999973, 10.104935647000048],
            [-14.209377289999964, 10.102957726000056],
            [-14.204284667999957, 10.104155542000058],
            [-14.20083522799996, 10.10740852500004],
            [-14.193988800999932, 10.116120339000076],
            [-14.194334029999936, 10.118095398000037],
            [-14.191681861999939, 10.119430542000032],
            [-14.18433284799994, 10.128261567000038],
            [-14.180010796999966, 10.11489391400005],
            [-14.18502712299994, 10.104340553000043],
            [-14.18867778799995, 10.09809589400004],
            [-14.18907642499994, 10.091423988000031],
            [-14.186223029999951, 10.084321977000059],
            [-14.17517471399998, 10.06970977900005],
            [-14.163961410999946, 10.061767578000058],
            [-14.159514426999976, 10.057899475000056],
            [-14.146690368999941, 10.051121711000064],
            [-14.141562462999957, 10.048640251000052],
            [-14.137603759999934, 10.047067643000048],
            [-14.128335953999965, 10.048303605000058],
            [-14.113995552999938, 10.052804947000027],
            [-14.101939200999936, 10.053606033000051],
            [-14.091088294999963, 10.059224129000029],
            [-14.08552932699996, 10.060194970000055],
            [-14.078621863999956, 10.065776826000047],
            [-14.080520628999977, 10.070358278000072],
            [-14.086965559999953, 10.076137542000026],
            [-14.084671019999973, 10.080267908000053],
            [-14.079434394999964, 10.078186035000044],
            [-14.077370643999927, 10.08073425300006],
            [-14.081430434999959, 10.089693070000067],
            [-14.084229469999968, 10.09446907000006],
            [-14.087111472999936, 10.104790688000037],
            [-14.087457657999948, 10.116975785000022],
            [-14.085485457999937, 10.12918090900007],
            [-14.086759566999945, 10.141356468000026],
            [-14.083845138999948, 10.152192115000048],
            [-14.07931900099993, 10.139815332000069],
            [-14.075616836999927, 10.140998840000066],
            [-14.073385237999958, 10.150856973000032],
            [-14.069630622999966, 10.146110535000048],
            [-14.061260224999955, 10.144348144000048],
            [-14.056633948999945, 10.145999909000068],
            [-14.048113822999937, 10.153204919000075],
            [-14.046263446999944, 10.14715824800004],
            [-14.044043540999951, 10.139904023000042],
            [-14.041703224999935, 10.137625695000054],
            [-14.042755126999964, 10.133887292000054],
            [-14.046058654999968, 10.131837845000064],
            [-14.050206183999933, 10.128581048000058],
            [-14.051795959999936, 10.124887468000054],
            [-14.049212454999974, 10.121460915000057],
            [-14.03547477799998, 10.116525651000075],
            [-14.031004906999954, 10.116164209000033],
            [-14.031725036999944, 10.108172490000072],
            [-14.03210926, 10.103908539000031],
            [-14.035551071999976, 10.099738122000076],
            [-14.038002967999944, 10.088908196000034],
            [-14.053565025999944, 10.065773965000062],
            [-14.05629539499995, 10.063615799000047],
            [-14.058363914999973, 10.057911873000023],
            [-14.060643196999933, 10.053522111000063],
            [-14.064996719999954, 10.047734261000073],
            [-14.068098068999973, 10.032070160000046],
            [-14.06713771799997, 10.02862930300006],
            [-14.036687849999964, 10],
            [-14.03273868599996, 9.996287347000077],
            [-14.029268264999928, 9.997238159000062],
            [-14.027814864999982, 10],
            [-14.02744483899994, 10.00070381200004],
            [-14.026007651999976, 10],
            [-14.01711273199993, 9.995645523000064],
            [-14.009927749999974, 9.987522126000044],
            [-13.989870070999928, 9.975282669000023],
            [-13.98033046699993, 9.97191715300005],
            [-13.973143577999963, 9.972209930000076],
            [-13.968750952999926, 9.973857880000025],
            [-13.960161209999967, 9.971457481000073],
            [-13.954233169999952, 9.963017463000028],
            [-13.955196379999961, 9.962248802000033],
            [-13.952116965999949, 9.955147744000044],
            [-13.909448622999946, 9.929071426000064],
            [-13.878176689999975, 9.906112672000063],
            [-13.867883681999956, 9.895622254000045],
            [-13.85888767199998, 9.884132385000044],
            [-13.843738555999948, 9.86478233300005],
            [-13.82665825, 9.846300125000027],
            [-13.821557045999953, 9.84611129800004],
            [-13.814317703999961, 9.839962959000047],
            [-13.80805206399998, 9.839094162000038],
            [-13.801536559999931, 9.836158753000063],
            [-13.796682356999952, 9.837807656000052],
            [-13.789560317999928, 9.845452310000042],
            [-13.787951469999939, 9.847074508000048],
            [-13.775000571999954, 9.850857734000044],
            [-13.766984940999976, 9.849980354000024],
            [-13.75323581799995, 9.85402012000003],
            [-13.734065056999953, 9.862679481000043],
            [-13.725068091999958, 9.868267059000061],
            [-13.717707633999964, 9.875452042000063],
            [-13.707002639999928, 9.899217606000036],
            [-13.703346705999934, 9.90402436000005],
            [-13.703341, 9.904036],
            [-13.70173, 9.906157],
            [-13.701488, 9.907813],
            [-13.701481158999968, 9.907826948000036],
            [-13.699305533999961, 9.922730447000049],
            [-13.692904473999931, 9.933815957000036],
            [-13.692516325999975, 9.93955993700007],
            [-13.689535575999969, 9.939043446000028],
            [-13.684244900999943, 9.938126703000023],
            [-13.67751, 9.956395],
            [-13.687215, 9.960356],
            [-13.692853, 9.958137],
            [-13.692985, 9.957999],
            [-13.6933, 9.95856],
            [-13.70075, 9.96428],
            [-13.72216, 9.97133],
            [-13.72900012599996, 9.972077083000045],
            [-13.74038, 9.97332],
            [-13.75102, 9.98037],
            [-13.80382, 10.00524],
            [-13.81619, 10.01561],
            [-13.84239, 10.03197],
            [-13.86434, 10.05631],
            [-13.87032, 10.06669],
            [-13.88868, 10.08504],
            [-13.90171, 10.10286],
            [-13.9065, 10.10871],
            [-13.90637, 10.12414],
            [-13.90105, 10.13026],
            [-13.87032, 10.1526],
            [-13.86328, 10.15048],
            [-13.83535, 10.1663],
            [-13.81486, 10.19955],
            [-13.80941, 10.22309],
            [-13.8009, 10.22961],
            [-13.77097, 10.26286],
            [-13.76799, 10.278844],
            [-13.75712, 10.277774],
            [-13.74359, 10.277774],
            [-13.731188, 10.276664],
            [-13.719913, 10.270005],
            [-13.692853, 10.276664],
            [-13.672283, 10.289232],
            [-13.66524, 10.27895],
            [-13.65486, 10.2759],
            [-13.64236, 10.26299],
            [-13.62494, 10.25222],
            [-13.60898, 10.25049],
            [-13.59408, 10.25289],
            [-13.58331, 10.25794],
            [-13.55738, 10.31008],
            [-13.55272, 10.3259],
            [-13.55059, 10.33734],
            [-13.54142, 10.34705],
            [-13.53038, 10.35131],
            [-13.522, 10.35835],
            [-13.50311, 10.38229],
            [-13.50324, 10.39307],
            [-13.49925, 10.39639],
            [-13.49899, 10.40304],
            [-13.50737, 10.41182],
            [-13.519265, 10.427787],
            [-13.508549, 10.452537],
            [-13.497759, 10.452537],
            [-13.497191, 10.462584],
            [-13.492649, 10.469841],
            [-13.485266, 10.478772],
            [-13.481291, 10.479889],
            [-13.47902, 10.479889],
            [-13.475045, 10.477656],
            [-13.469934, 10.475423],
            [-13.467095, 10.475981],
            [-13.461984, 10.475981],
            [-13.459713, 10.478772],
            [-13.45233, 10.486029],
            [-13.445516, 10.493285],
            [-13.441541, 10.495518],
            [-13.432455, 10.496635],
            [-13.418827, 10.491053],
            [-13.416555, 10.491611],
            [-13.411444, 10.496076],
            [-13.40463, 10.499984],
            [-13.400655, 10.506124],
            [-13.396273807999933, 10.516890372000034],
            [-13.39618, 10.51689],
            [-13.396036, 10.517396],
            [-13.38873, 10.54309],
            [-13.38474, 10.55453],
            [-13.38461, 10.56158],
            [-13.39645, 10.57834],
            [-13.40323, 10.58406],
            [-13.40057, 10.59656],
            [-13.39578, 10.60015],
            [-13.38607, 10.61491],
            [-13.37743, 10.61983],
            [-13.37211, 10.62755],
            [-13.37277, 10.6314],
            [-13.35708, 10.64484],
            [-13.35083, 10.66053],
            [-13.350948, 10.660235],
            [-13.351041, 10.660228],
            [-13.367012, 10.681807],
            [-13.369674, 10.683115],
            [-13.376328, 10.685077],
            [-13.383648, 10.685077],
            [-13.384979, 10.689],
            [-13.394295, 10.698155],
            [-13.401615, 10.697501],
            [-13.403612, 10.696194],
            [-13.406939, 10.693578],
            [-13.411597, 10.689654],
            [-13.41559, 10.687692],
            [-13.42291, 10.687692],
            [-13.425572, 10.685077],
            [-13.432226, 10.682461],
            [-13.440211, 10.683769],
            [-13.443539, 10.683115],
            [-13.443955, 10.683503],
            [-13.4434, 10.68407],
            [-13.44473, 10.68899],
            [-13.44619, 10.71852],
            [-13.43568, 10.73754],
            [-13.43648, 10.76148],
            [-13.4398, 10.7656],
            [-13.44007, 10.77292],
            [-13.45191, 10.7785],
            [-13.46467, 10.7918],
            [-13.4688, 10.79978],
            [-13.466, 10.80164],
            [-13.466868, 10.801838],
            [-13.47944, 10.8047],
            [-13.49207, 10.80337],
            [-13.51748, 10.79021],
            [-13.56802, 10.74086],
            [-13.59914, 10.71573],
            [-13.59967, 10.71147],
            [-13.65247, 10.66825],
            [-13.66817, 10.66612],
            [-13.67162, 10.67277],
            [-13.67561, 10.67423],
            [-13.67974, 10.68221],
            [-13.68426, 10.68314],
            [-13.68373, 10.68593],
            [-13.68679, 10.68793],
            [-13.68373, 10.69471],
            [-13.69809, 10.7011],
            [-13.70806, 10.6975],
            [-13.71604, 10.7261],
            [-13.72243, 10.72996],
            [-13.74531, 10.7277],
            [-13.75196, 10.7249],
            [-13.76366, 10.72956],
            [-13.78161, 10.75257],
            [-13.79491, 10.75563],
            [-13.80396, 10.74126],
            [-13.81566, 10.74113],
            [-13.82072, 10.73302],
            [-13.82803, 10.72943],
            [-13.84785, 10.73169],
            [-13.86168, 10.73927],
            [-13.867, 10.73607],
            [-13.87485, 10.72663],
            [-13.8787, 10.71586],
            [-13.88083, 10.68487],
            [-13.88602, 10.68154],
            [-13.89945, 10.68806],
            [-13.90358, 10.68593],
            [-13.90437, 10.68128],
            [-13.89892, 10.66997],
            [-13.9065, 10.66279],
            [-13.91887, 10.66559],
            [-13.95717, 10.70987],
            [-13.97101, 10.72078],
            [-13.97925, 10.72011],
            [-13.98072, 10.73568],
            [-13.97726, 10.73993],
            [-13.97167, 10.76427],
            [-13.94321, 10.80417],
            [-13.93084, 10.82665],
            [-13.92765, 10.8462],
            [-13.9198, 10.86189],
            [-13.87498, 10.87825],
            [-13.85184, 10.8986],
            [-13.8118, 10.91124],
            [-13.77696, 10.93744],
            [-13.76898, 10.9534],
            [-13.77004, 10.96364],
            [-13.77377, 10.95792],
            [-13.76938, 10.97454],
            [-13.7727, 10.98186],
            [-13.81832, 11.03626],
            [-13.81646, 11.04942],
            [-13.80835, 11.05421],
            [-13.80063, 11.05315],
            [-13.79837, 11.08015],
            [-13.78813, 11.08547],
            [-13.76273, 11.09358],
            [-13.74398, 11.11965],
            [-13.7457, 11.13534],
            [-13.74012, 11.1517],
            [-13.74677, 11.17684],
            [-13.75741, 11.19],
            [-13.78068, 11.19333],
            [-13.78853, 11.19812],
            [-13.78121, 11.21062],
            [-13.77217, 11.21966],
            [-13.77151, 11.22857],
            [-13.7735, 11.23389],
            [-13.78161, 11.23669],
            [-13.79318, 11.25198],
            [-13.79997, 11.24999],
            [-13.80821, 11.25743],
            [-13.81021, 11.26515],
            [-13.82856, 11.2706],
            [-13.83082, 11.28084],
            [-13.84186, 11.28802],
            [-13.84293, 11.29667],
            [-13.83242, 11.29122],
            [-13.81766, 11.29295],
            [-13.80662, 11.29853],
            [-13.79611, 11.30851],
            [-13.78454, 11.31436],
            [-13.75355, 11.32487],
            [-13.73865, 11.33444],
            [-13.7324, 11.33311],
            [-13.70275, 11.33723],
            [-13.69463, 11.33484],
            [-13.68572, 11.32753],
            [-13.63558, 11.33285],
            [-13.626, 11.3375],
            [-13.59754, 11.35679],
            [-13.57679, 11.38006],
            [-13.570932, 11.380888],
            [-13.55605, 11.38299],
            [-13.52772, 11.38166],
            [-13.51295, 11.3782],
            [-13.50457, 11.37873],
            [-13.47997, 11.37394],
            [-13.46946, 11.3782],
            [-13.443, 11.37807],
            [-13.43621, 11.37314],
            [-13.42943, 11.35838],
            [-13.44991, 11.30625],
            [-13.44725, 11.29148],
            [-13.42996, 11.29135],
            [-13.41573, 11.2964],
            [-13.39379, 11.31409],
            [-13.36971, 11.32367],
            [-13.34817, 11.33737],
            [-13.32516, 11.33923],
            [-13.31399, 11.34694],
            [-13.295157, 11.347769],
            [-13.28379, 11.34827],
            [-13.27568, 11.34521],
            [-13.2673, 11.32992],
            [-13.28286, 11.28922],
            [-13.28233, 11.27925],
            [-13.27209, 11.26382],
            [-13.26424, 11.22937],
            [-13.25906, 11.18229],
            [-13.233917, 11.143819],
            [-13.232541, 11.099563],
            [-13.215526, 11.05891],
            [-13.2137, 11.05887],
            [-13.20812, 11.06179],
            [-13.20173, 11.06299],
            [-13.197514954999974, 11.064654846000053],
            [-13.19735, 11.06472],
            [-13.204541, 11.087823],
            [-13.21703, 11.11778],
            [-13.220961, 11.132948],
            [-13.21702, 11.133593],
            [-13.213079, 11.135527],
            [-13.206511, 11.136172],
            [-13.197972, 11.136816],
            [-13.192061, 11.138105],
            [-13.187464, 11.139395],
            [-13.182866, 11.141973],
            [-13.176298, 11.145841],
            [-13.169073, 11.14842],
            [-13.158564, 11.153577],
            [-13.150026, 11.158089],
            [-13.142801, 11.161957],
            [-13.135576, 11.164536],
            [-13.134262, 11.16647],
            [-13.131635, 11.170338],
            [-13.129008, 11.172916],
            [-13.123097, 11.175495],
            [-13.118499, 11.178073],
            [-13.111931, 11.17485],
            [-13.109304, 11.17485],
            [-13.106676, 11.17485],
            [-13.104049, 11.174205],
            [-13.100765, 11.174205],
            [-13.09354, 11.176784],
            [-13.088286, 11.178073],
            [-13.079747, 11.181296],
            [-13.069895, 11.183875],
            [-13.064641, 11.187098],
            [-13.062014, 11.187098],
            [-13.058073, 11.186454],
            [-13.054132, 11.185809],
            [-13.048878, 11.18452],
            [-13.039682, 11.183875],
            [-13.036398, 11.185164],
            [-13.022605, 11.189677],
            [-13.016694, 11.187098],
            [-13.01144, 11.190321],
            [-13.002244, 11.190966],
            [-12.99699, 11.189677],
            [-12.996361, 11.189523],
            [-12.99568, 11.190061],
            [-12.985002, 11.200545],
            [-12.975555, 11.215345],
            [-12.975988, 11.226476],
            [-12.984297, 11.242071],
            [-12.989955, 11.253272],
            [-12.991064, 11.26676],
            [-12.97725, 11.277951],
            [-12.956411, 11.292906],
            [-12.973397, 11.312549],
            [-12.984275, 11.332014],
            [-12.965491, 11.356972],
            [-12.945955, 11.373932],
            [-12.930489, 11.384969],
            [-12.919125, 11.39054],
            [-12.899522, 11.399242],
            [-12.90366, 11.401576],
            [-12.900346, 11.404712],
            [-12.887883, 11.422265],
            [-12.870933, 11.439337],
            [-12.852524, 11.444772],
            [-12.833815, 11.456417],
            [-12.818959, 11.462377],
            [-12.796939, 11.466372],
            [-12.787843, 11.474362],
            [-12.78147, 11.488194],
            [-12.764246, 11.493804],
            [-12.75798, 11.509537],
            [-12.758344, 11.525743],
            [-12.753059, 11.531034],
            [-12.738711, 11.533988],
            [-12.729076, 11.544528],
            [-12.710137, 11.559855],
            [-12.703757, 11.566343],
            [-12.696966, 11.559263],
            [-12.694077, 11.563742],
            [-12.699983, 11.571402],
            [-12.706513, 11.582532],
            [-12.711164, 11.593946],
            [-12.707346, 11.595348],
            [-12.701189, 11.606551],
            [-12.701394, 11.616009],
            [-12.707889, 11.62372],
            [-12.721786, 11.611748],
            [-12.72534, 11.621135],
            [-12.732955, 11.621016],
            [-12.73367, 11.625467],
            [-12.741076, 11.624801],
            [-12.743967, 11.630791],
            [-12.750252, 11.62748],
            [-12.750203, 11.638603],
            [-12.763707, 11.641816],
            [-12.769942, 11.649773],
            [-12.770464, 11.663562],
            [-12.777967, 11.67242],
            [-12.78226, 11.678295],
            [-12.783823, 11.686293],
            [-12.795907, 11.6818],
            [-12.809092, 11.686348],
            [-12.818563, 11.696146],
            [-12.819057, 11.706128],
            [-12.804579, 11.712531],
            [-12.793028, 11.719688],
            [-12.788834, 11.723267],
            [-12.787697, 11.739091],
            [-12.78153, 11.75444],
            [-12.7981, 11.77524],
            [-12.79985, 11.785481],
            [-12.801385, 11.794471],
            [-12.7971, 11.813169],
            [-12.802463, 11.833427],
            [-12.795288, 11.835953],
            [-12.783519, 11.840095],
            [-12.77111, 11.840436],
            [-12.763041, 11.854824],
            [-12.760333, 11.863245],
            [-12.769294, 11.89699],
            [-12.788311, 11.915393],
            [-12.808411, 11.93604],
            [-12.809714, 11.938045],
            [-12.805862, 11.94373],
            [-12.799511, 11.950676],
            [-12.791996, 11.961348],
            [-12.775425, 11.978204],
            [-12.78793, 11.989784],
            [-12.799561, 11.995493],
            [-12.813905, 11.998134],
            [-12.821689, 12.002922],
            [-12.82345, 12.01779],
            [-12.826105, 12.026718],
            [-12.828315, 12.044978],
            [-12.829768, 12.056933],
            [-12.817775, 12.061724],
            [-12.819303, 12.077279],
            [-12.806763, 12.098125],
            [-12.80931, 12.112468],
            [-12.815884, 12.122643],
            [-12.812151, 12.132378],
            [-12.817605, 12.135948],
            [-12.819437, 12.140131],
            [-12.82676, 12.144254],
            [-12.826306, 12.144892],
            [-12.815445, 12.160144],
            [-12.804017, 12.175585],
            [-12.787394, 12.197689],
            [-12.7688, 12.22195],
            [-12.751214, 12.253917],
            [-12.721357, 12.271948],
            [-12.694961, 12.28753],
            [-12.65843, 12.306419],
            [-12.630777, 12.317556],
            [-12.607269, 12.33512],
            [-12.5647, 12.354554],
            [-12.561209, 12.365033],
            [-12.563143, 12.367398]
          ]
        ]
      ]
    },
    properties: {
      name: 'Boke',
      center: [-14.27958672176777, 10.961569951254146],
      shapeName: 'Boke',
      shapeISO: '',
      shapeID: '26780975B47808819949297',
      shapeGroup: 'GIN',
      shapeType: 'ADM1'
    }
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-13.78686046599995, 9.465923309000061],
            [-13.790131568999982, 9.469574929000032],
            [-13.798910141999954, 9.467434884000056],
            [-13.803509711999936, 9.463719368000056],
            [-13.80484581099995, 9.457502366000028],
            [-13.80183124499996, 9.456837655000072],
            [-13.798831939999957, 9.458011628000065],
            [-13.796043396999949, 9.456884384000034],
            [-13.792621612999937, 9.462888717000055],
            [-13.787526129999947, 9.46247005500004],
            [-13.78686046599995, 9.465923309000061]
          ]
        ],
        [
          [
            [-13.753335, 9.442746164000027],
            [-13.750593185999946, 9.447134972000072],
            [-13.74560546899994, 9.46004676900003],
            [-13.747275351999974, 9.466010094000069],
            [-13.74616336899993, 9.47153568300007],
            [-13.747606276999932, 9.478190422000068],
            [-13.755760192999958, 9.484332084000073],
            [-13.760077476999982, 9.502687454000068],
            [-13.76426792199993, 9.505412102000037],
            [-13.769588469999974, 9.504910469000038],
            [-13.772558211999979, 9.50005817500005],
            [-13.771832465999978, 9.496156693000046],
            [-13.764820098999962, 9.488166810000052],
            [-13.761036872999966, 9.478543282000032],
            [-13.757294655999942, 9.47397518200006],
            [-13.755625725999948, 9.468012810000062],
            [-13.755805969999926, 9.464803697000036],
            [-13.756635664999976, 9.450075149000043],
            [-13.759828567999932, 9.444304466000062],
            [-13.756562232999954, 9.441112518000068],
            [-13.753335, 9.442746164000027]
          ]
        ],
        [
          [
            [-13.83279323599993, 9.450149537000073],
            [-13.831214903999978, 9.454987526000025],
            [-13.833600044999969, 9.463242531000049],
            [-13.831345558999942, 9.470388413000023],
            [-13.824709892999977, 9.479637146000073],
            [-13.815496444999951, 9.48522949200003],
            [-13.805436133999933, 9.500252724000063],
            [-13.799234389999981, 9.506279947000053],
            [-13.79461097799998, 9.507006646000036],
            [-13.793242453999937, 9.509315491000052],
            [-13.79534816699993, 9.512057306000031],
            [-13.80577945799996, 9.513352396000073],
            [-13.807876587999942, 9.51517391300007],
            [-13.811554908999938, 9.511925698000027],
            [-13.815022468999928, 9.511209488000077],
            [-13.81913852799994, 9.505198479000057],
            [-13.824814795999941, 9.492049217000044],
            [-13.83104229099996, 9.489239693000059],
            [-13.834473610999964, 9.484383584000057],
            [-13.838619232999974, 9.481821060000073],
            [-13.843815803999973, 9.466836931000046],
            [-13.840992926999945, 9.461573602000044],
            [-13.840953827999954, 9.456976891000068],
            [-13.839314460999958, 9.45469188800007],
            [-13.836762427999929, 9.454023363000033],
            [-13.834637642999951, 9.44921398100007],
            [-13.83279323599993, 9.450149537000073]
          ]
        ],
        [
          [
            [-13.54767, 9.74004],
            [-13.55378, 9.73259],
            [-13.56017, 9.73033],
            [-13.56669, 9.73685],
            [-13.57028, 9.75055],
            [-13.585627285999976, 9.753296108000029],
            [-13.59179592199996, 9.750875474000054],
            [-13.600790977999964, 9.74529075700002],
            [-13.608137129999932, 9.736269952000043],
            [-13.60415840099995, 9.731241226000066],
            [-13.601140021999981, 9.73034382000003],
            [-13.600467680999941, 9.730826379000064],
            [-13.598665238999956, 9.72905445200007],
            [-13.597841262999964, 9.724665642000048],
            [-13.602264404999971, 9.72596836200006],
            [-13.608738899999935, 9.724081039000055],
            [-13.617795944999955, 9.726772308000022],
            [-13.62150001599997, 9.726284981000049],
            [-13.624260903999982, 9.723734856000021],
            [-13.629012108999973, 9.708986282000069],
            [-13.632756233999942, 9.68389892600004],
            [-13.626907348999964, 9.676586152000027],
            [-13.625279426999953, 9.669680596000035],
            [-13.624506951999933, 9.665799142000026],
            [-13.626105309999957, 9.662798882000061],
            [-13.629797936999978, 9.660932542000069],
            [-13.631127521999929, 9.654327247000026],
            [-13.633366585999966, 9.643203737000022],
            [-13.632637023999962, 9.633851052000068],
            [-13.63417530099997, 9.62802505600007],
            [-13.638080596999941, 9.623856545000024],
            [-13.64592266099993, 9.619660378000049],
            [-13.653693198999974, 9.606268883000041],
            [-13.65628118099994, 9.605120599000031],
            [-13.657385825999938, 9.604630470000075],
            [-13.660349845999974, 9.598631859000022],
            [-13.661426544999927, 9.588278771000034],
            [-13.669213294999963, 9.577185631000077],
            [-13.670066283999972, 9.57090776800004],
            [-13.671839714999976, 9.557855606000032],
            [-13.677367210999932, 9.554135323000025],
            [-13.693448067999952, 9.537919998000064],
            [-13.695985793999967, 9.531527520000054],
            [-13.697200351999982, 9.528466378000076],
            [-13.699121476999949, 9.523624421000022],
            [-13.709263801999953, 9.517797470000062],
            [-13.721758843999964, 9.516781808000076],
            [-13.725193977999936, 9.512157440000067],
            [-13.727001189999953, 9.50662612900004],
            [-13.725815773999955, 9.503187180000054],
            [-13.723717688999955, 9.50136470800004],
            [-13.71630859399994, 9.501422883000032],
            [-13.70316410099997, 9.50796127500007],
            [-13.700658799999928, 9.513038636000033],
            [-13.686933266999972, 9.52201837900003],
            [-13.667949676999967, 9.534438134000027],
            [-13.661517143999959, 9.540924072000053],
            [-13.650193213999955, 9.543767930000058],
            [-13.645136832999981, 9.548633575000053],
            [-13.642131804999963, 9.549345970000047],
            [-13.639177322999956, 9.556494712000074],
            [-13.633833885999934, 9.55423545900004],
            [-13.632476899999972, 9.554431657000066],
            [-13.62712574099993, 9.555205345000047],
            [-13.626991618999966, 9.555649966000033],
            [-13.627136, 9.556442],
            [-13.627113, 9.55636],
            [-13.627047, 9.556319],
            [-13.62694, 9.556378],
            [-13.62680721099997, 9.556261286000051],
            [-13.626535654999941, 9.557161506000057],
            [-13.625318526999934, 9.561196327000062],
            [-13.619771003999972, 9.562386512000046],
            [-13.616785049999976, 9.56562709800005],
            [-13.607048988999964, 9.56432056500006],
            [-13.603510857999936, 9.562349320000067],
            [-13.600741718999927, 9.558858394000026],
            [-13.594850539999982, 9.551431657000023],
            [-13.595584870999971, 9.549004555000067],
            [-13.586458205999975, 9.53642749900007],
            [-13.569892883999955, 9.519768715000055],
            [-13.559365272999969, 9.505363464000027],
            [-13.553535460999967, 9.499888421000037],
            [-13.543315887999938, 9.495365143000072],
            [-13.543182383999977, 9.495369197000059],
            [-13.53336143499996, 9.495667457000025],
            [-13.526446343999964, 9.499623300000053],
            [-13.520698547999928, 9.505181313000037],
            [-13.511539460999927, 9.519038200000068],
            [-13.507370925999965, 9.514476061000039],
            [-13.50019, 9.52258],
            [-13.493986002999975, 9.539449001000037],
            [-13.493, 9.54213],
            [-13.4958, 9.55038],
            [-13.49952, 9.55158],
            [-13.50298, 9.57459],
            [-13.49048, 9.60026],
            [-13.47438, 9.61861],
            [-13.46627, 9.62433],
            [-13.46029, 9.63656],
            [-13.46002, 9.64734],
            [-13.46494, 9.65372],
            [-13.48848, 9.67301],
            [-13.49487, 9.67806],
            [-13.49979, 9.6887],
            [-13.499735, 9.689076],
            [-13.49886, 9.69548],
            [-13.47931, 9.71769],
            [-13.47518, 9.72195],
            [-13.47505, 9.73286],
            [-13.47744, 9.74323],
            [-13.50152, 9.73698],
            [-13.53703, 9.74257],
            [-13.54767, 9.74004]
          ]
        ]
      ]
    },
    properties: {
      name: 'Conakry',
      center: [-13.579567751104644, 9.648452917668456],
      shapeName: 'Conakry',
      shapeISO: '',
      shapeID: '26780975B16921326553537',
      shapeGroup: 'GIN',
      shapeType: 'ADM1'
    }
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-10.086779, 12.177659],
          [-10.09096, 12.17974],
          [-10.09914, 12.17932],
          [-10.10734, 12.17615],
          [-10.112950000999945, 12.17572],
          [-10.13141, 12.17811000100005],
          [-10.143130000999975, 12.173110001000055],
          [-10.14897, 12.17268],
          [-10.167620000999932, 12.18427],
          [-10.18374, 12.187110001000065],
          [-10.186770000999957, 12.18966],
          [-10.19093000099997, 12.19888],
          [-10.19369, 12.200100001000067],
          [-10.195130000999939, 12.20074],
          [-10.210650000999976, 12.201010001000043],
          [-10.2136, 12.20106000100003],
          [-10.22388, 12.202950001000033],
          [-10.237930000999938, 12.19864],
          [-10.24331000099994, 12.19936],
          [-10.2461, 12.2019],
          [-10.247480000999929, 12.20628],
          [-10.24628, 12.2134],
          [-10.24838, 12.21525],
          [-10.25375, 12.21528],
          [-10.26712, 12.20706],
          [-10.27645, 12.21285000100005],
          [-10.283000000999948, 12.212650001000043],
          [-10.287450000999968, 12.20968],
          [-10.28888, 12.2044],
          [-10.28565, 12.194730001000039],
          [-10.288940000999958, 12.191280001000052],
          [-10.29362, 12.19085],
          [-10.29922, 12.19294],
          [-10.30475, 12.2116],
          [-10.30939000099994, 12.21875],
          [-10.31289, 12.22061],
          [-10.32014000099997, 12.22133],
          [-10.327400000999944, 12.21906],
          [-10.33658, 12.224950001000025],
          [-10.339323416999946, 12.225478732000056],
          [-10.34121, 12.22042],
          [-10.34006, 12.21559],
          [-10.332150000999945, 12.20244],
          [-10.331950000999939, 12.19439],
          [-10.335010000999944, 12.187270001000059],
          [-10.351600000999952, 12.19309],
          [-10.358150000999956, 12.19219],
          [-10.360960000999967, 12.1899],
          [-10.36055, 12.175640001000033],
          [-10.36336, 12.171970001000034],
          [-10.37109000099997, 12.168770001000041],
          [-10.37109000099997, 12.16763],
          [-10.38115, 12.169500001000074],
          [-10.396140000999935, 12.176370001000066],
          [-10.396710000999974, 12.17632],
          [-10.40405, 12.175790001000053],
          [-10.41072, 12.172580001000028],
          [-10.41445, 12.166850001000057],
          [-10.41246, 12.14501000100006],
          [-10.41403, 12.14108],
          [-10.4341, 12.13334],
          [-10.43967, 12.13005],
          [-10.44898, 12.12011],
          [-10.4564, 12.11653],
          [-10.46748, 12.11781],
          [-10.476960000999952, 12.120760001000065],
          [-10.49449, 12.122660001000042],
          [-10.503140000999963, 12.121770001000073],
          [-10.510630000999981, 12.11835],
          [-10.51650000099994, 12.11285],
          [-10.518630000999963, 12.10642000100006],
          [-10.51483000099995, 12.100350001000038],
          [-10.50723, 12.09051],
          [-10.50515, 12.08383],
          [-10.50704, 12.08038],
          [-10.51872, 12.07882],
          [-10.52154, 12.076070001000062],
          [-10.52367, 12.06894],
          [-10.52045, 12.05582000100003],
          [-10.52139, 12.05306],
          [-10.520310000999928, 12.04999],
          [-10.521458546999952, 12.046168789000035],
          [-10.52294, 12.04124],
          [-10.531040000999951, 12.03354],
          [-10.53269, 12.031710001000022],
          [-10.55864, 12.02651],
          [-10.570950000999972, 12.02727],
          [-10.57828000099994, 12.021980001000031],
          [-10.579010000999972, 12.013930001000062],
          [-10.57203, 12.00562],
          [-10.56364, 12.00053],
          [-10.562950000999933, 11.99777],
          [-10.56436, 11.99593],
          [-10.57278, 11.99366],
          [-10.57701, 11.986090001000036],
          [-10.58192, 11.98449],
          [-10.588930000999937, 11.98544],
          [-10.59948000099996, 11.974200001000042],
          [-10.605550000999926, 11.97399],
          [-10.61278, 11.97678],
          [-10.62075, 11.96921],
          [-10.6257, 11.95772],
          [-10.62839, 11.946870001000036],
          [-10.63019000099996, 11.93957],
          [-10.633480000999953, 11.93475],
          [-10.64027, 11.929480001000059],
          [-10.641460000999928, 11.9235],
          [-10.64009, 11.91315],
          [-10.642440000999954, 11.91016],
          [-10.65133, 11.905820001000052],
          [-10.65951, 11.90378],
          [-10.66256, 11.90054],
          [-10.66282, 11.89906],
          [-10.672220000999971, 11.89388],
          [-10.690210000999969, 11.893000001000075],
          [-10.69722, 11.89601],
          [-10.71703, 11.91560000100003],
          [-10.731290000999934, 11.91656],
          [-10.73549000099996, 11.91933],
          [-10.74247, 11.93384],
          [-10.749210234999964, 11.94155399300007],
          [-10.751330000999928, 11.94398],
          [-10.745450000999938, 11.96283],
          [-10.74987, 11.973880001000055],
          [-10.75637, 11.99378],
          [-10.76134, 11.99921],
          [-10.777730000999952, 12.01095],
          [-10.78601, 12.024690001000067],
          [-10.79231, 12.02977],
          [-10.795090000999949, 12.0376],
          [-10.794150000999934, 12.041510001000063],
          [-10.78898000099997, 12.04839000100003],
          [-10.784970000999976, 12.06587],
          [-10.78448, 12.07391],
          [-10.78717, 12.102220001000035],
          [-10.787430000999962, 12.10498],
          [-10.79023, 12.10936],
          [-10.796290000999932, 12.11352000100004],
          [-10.81522, 12.11586],
          [-10.829, 12.12142],
          [-10.8451, 12.13158],
          [-10.8703, 12.15396],
          [-10.87683, 12.161110001000054],
          [-10.87915000099997, 12.168010001000027],
          [-10.88005, 12.1767],
          [-10.880290000999935, 12.179060001000039],
          [-10.88635, 12.18758],
          [-10.89052, 12.20232],
          [-10.89216000099998, 12.20393000100006],
          [-10.89918, 12.19912000100004],
          [-10.90292, 12.20097],
          [-10.9078, 12.212710001000062],
          [-10.91061, 12.21432],
          [-10.918320000999927, 12.21434],
          [-10.9286, 12.222420001000046],
          [-10.9328, 12.223350001000028],
          [-10.94006, 12.21761],
          [-10.943800000999943, 12.21739],
          [-10.952920000999939, 12.22155],
          [-10.96718, 12.221120001000031],
          [-10.97298000099994, 12.21874],
          [-10.97444, 12.21814],
          [-10.98568, 12.20874],
          [-10.99223, 12.207370001000072],
          [-11.007430000999932, 12.20786],
          [-11.015380000999926, 12.20557],
          [-11.02005, 12.210180001000026],
          [-11.023090000999957, 12.21111],
          [-11.03314, 12.21067],
          [-11.03945, 12.218040001000077],
          [-11.044820000999948, 12.22104],
          [-11.04833, 12.21713],
          [-11.050230000999932, 12.20173],
          [-11.055160000999933, 12.19184000100006],
          [-11.055160000999933, 12.185170001000074],
          [-11.06056, 12.175060001000077],
          [-11.06199, 12.162410001000069],
          [-11.067380000999947, 12.154600001000063],
          [-11.07278, 12.135290001000044],
          [-11.08215, 12.12909],
          [-11.092440000999943, 12.12382000100007],
          [-11.09945000099998, 12.12199],
          [-11.10341, 12.11921],
          [-11.10437, 12.11855],
          [-11.10648, 12.11394],
          [-11.103920000999949, 12.10221000100006],
          [-11.105800000999977, 12.09715],
          [-11.10931, 12.09486],
          [-11.13432, 12.09512],
          [-11.139460000999975, 12.093060001000026],
          [-11.14228000099996, 12.089850001000059],
          [-11.144160000999932, 12.07881],
          [-11.138110000999973, 12.059480001000054],
          [-11.14256, 12.05051],
          [-11.14451, 12.04864],
          [-11.15215, 12.041320001000031],
          [-11.16069, 12.03618],
          [-11.166594, 12.032625],
          [-11.165517, 12.03104],
          [-11.161114, 12.024563],
          [-11.154836, 12.010853],
          [-11.152783, 11.992103],
          [-11.15444, 11.979376],
          [-11.155548, 11.955768],
          [-11.159923, 11.941589],
          [-11.152214, 11.932158],
          [-11.142889, 11.925085],
          [-11.128498, 11.917316],
          [-11.123172, 11.920034],
          [-11.112559, 11.920901],
          [-11.100883, 11.919845],
          [-11.093214, 11.909831],
          [-11.082573, 11.896951],
          [-11.06866, 11.892409],
          [-11.052836, 11.889461],
          [-11.047884, 11.883291],
          [-11.059637, 11.877534],
          [-11.071722, 11.869091],
          [-11.076797, 11.855749],
          [-11.073543, 11.837286],
          [-11.072569, 11.810992],
          [-11.074752, 11.785317],
          [-11.070336, 11.749882],
          [-11.079966, 11.730269],
          [-11.089669, 11.706956],
          [-11.100833, 11.691768],
          [-11.121708, 11.694716],
          [-11.141488, 11.686568],
          [-11.155732, 11.668371],
          [-11.164983, 11.654556],
          [-11.165243, 11.647159],
          [-11.158496, 11.643567],
          [-11.149878, 11.638703],
          [-11.14416, 11.628473],
          [-11.149628, 11.624124],
          [-11.160088, 11.613321],
          [-11.166217, 11.603824],
          [-11.176122, 11.598895],
          [-11.192336, 11.589475],
          [-11.208988, 11.585236],
          [-11.21332, 11.573958],
          [-11.213177, 11.562174],
          [-11.202912, 11.5518],
          [-11.196319, 11.537038],
          [-11.175565, 11.537135],
          [-11.168377, 11.537858],
          [-11.152024, 11.535892],
          [-11.13659, 11.530371],
          [-11.127648, 11.52264],
          [-11.121052, 11.517595],
          [-11.119997, 11.505046],
          [-11.126966, 11.501425],
          [-11.12569, 11.492285],
          [-11.123279, 11.478755],
          [-11.131426, 11.465994],
          [-11.143086, 11.46223],
          [-11.160252, 11.44719],
          [-11.171035, 11.438312],
          [-11.180678, 11.431041],
          [-11.189919, 11.42407],
          [-11.212168, 11.423065],
          [-11.223752, 11.418863],
          [-11.239992, 11.406576],
          [-11.267742, 11.401403],
          [-11.289832, 11.367469],
          [-11.317879, 11.337118],
          [-11.336954, 11.30838],
          [-11.33679, 11.267492],
          [-11.320935, 11.227322],
          [-11.306745, 11.178142],
          [-11.279045, 11.141022],
          [-11.254607, 11.147399],
          [-11.231347, 11.118629],
          [-11.208031, 11.100461],
          [-11.174227, 11.088193],
          [-11.148877, 11.078149],
          [-11.137166, 11.074795],
          [-11.15547, 11.055242],
          [-11.17945, 11.041137],
          [-11.202909, 11.031352],
          [-11.214905, 11.023095],
          [-11.230882, 11.015459],
          [-11.239377, 11.009971],
          [-11.278331, 10.989641],
          [-11.314717, 10.984164],
          [-11.350145, 10.971423],
          [-11.380541, 10.968629],
          [-11.388999, 10.97037],
          [-11.417321, 10.983411],
          [-11.454077, 10.993466],
          [-11.470287, 10.980536],
          [-11.508673, 10.94322],
          [-11.5258, 10.905738],
          [-11.524458, 10.876288],
          [-11.498424, 10.877968],
          [-11.457027, 10.850161],
          [-11.437417, 10.826632],
          [-11.448311, 10.794547],
          [-11.446132, 10.764601],
          [-11.461384, 10.732516],
          [-11.448311, 10.72396],
          [-11.458232, 10.702755],
          [-11.462111, 10.682072],
          [-11.479061, 10.665],
          [-11.478932, 10.637699],
          [-11.492074, 10.612541],
          [-11.507414, 10.607686],
          [-11.517479, 10.57397],
          [-11.519142, 10.570681],
          [-11.515156, 10.559849],
          [-11.520645, 10.539633],
          [-11.526832, 10.515712],
          [-11.527931, 10.492234],
          [-11.507014, 10.491986],
          [-11.488621, 10.485077],
          [-11.465278, 10.47221],
          [-11.442444, 10.457434],
          [-11.416567, 10.453156],
          [-11.398645, 10.451565],
          [-11.393752, 10.434523],
          [-11.390386, 10.411269],
          [-11.387306, 10.393562],
          [-11.384599, 10.374432],
          [-11.384643, 10.365757],
          [-11.382728, 10.350748],
          [-11.378827, 10.334705],
          [-11.377982, 10.305271],
          [-11.376092, 10.285442],
          [-11.381208, 10.25911],
          [-11.391767, 10.239716],
          [-11.387933, 10.21066],
          [-11.395053, 10.181987],
          [-11.402114, 10.164881],
          [-11.410196, 10.14299],
          [-11.430279, 10.111878],
          [-11.437963, 10.08338],
          [-11.470417, 10.054955],
          [-11.469302, 10.026374],
          [-11.468743, 10.020226],
          [-11.471357, 10.014791],
          [-11.474115, 10.009279],
          [-11.475633, 10.00298],
          [-11.477589, 9.999689],
          [-11.44652, 9.999224],
          [-11.31897, 9.998436],
          [-11.31394, 9.998405],
          [-11.278986567999937, 9.999066046000053],
          [-11.271321943999965, 9.999212097000054],
          [-11.25149, 9.99959],
          [-11.20675, 10.00043000100004],
          [-11.20595, 10],
          [-11.19469, 9.991091],
          [-11.17497, 9.975811],
          [-11.17126, 9.966152],
          [-11.169594287999928, 9.95758919800005],
          [-11.167100000999937, 9.94477],
          [-11.16456, 9.912580001000038],
          [-11.16016, 9.89051],
          [-11.1563, 9.88299],
          [-11.15437, 9.879233],
          [-11.153788686999974, 9.878727445000038],
          [-11.152820000999952, 9.87789],
          [-11.14277, 9.86911],
          [-11.09734, 9.84792],
          [-11.09618, 9.84562],
          [-11.06976, 9.828120001000059],
          [-11.04876, 9.80757],
          [-11.03871000099997, 9.797740001000022],
          [-11.025632044999952, 9.778528004000066],
          [-11.01255, 9.759313],
          [-11.00398, 9.750796],
          [-11.000267, 9.746044],
          [-10.98384, 9.725022],
          [-10.979517544999965, 9.717655817000036],
          [-10.977360000999965, 9.71398],
          [-10.97690250599993, 9.71283982600005],
          [-10.97415, 9.705986],
          [-10.971704088999957, 9.699902735000023],
          [-10.97089000099993, 9.69788],
          [-10.96791, 9.673740001000056],
          [-10.965728155999955, 9.669594926000059],
          [-10.96282, 9.664074],
          [-10.954714026999966, 9.657859088000066],
          [-10.947127467999962, 9.656699041000024],
          [-10.94406, 9.656231],
          [-10.93293, 9.656444],
          [-10.92622, 9.654593],
          [-10.923304603999952, 9.651830572000051],
          [-10.92136, 9.649990001000049],
          [-10.921129942999926, 9.645848966000074],
          [-10.9209, 9.641712],
          [-10.91489, 9.632967],
          [-10.91264, 9.615035],
          [-10.911264681999967, 9.604110810000066],
          [-10.91099, 9.60193],
          [-10.90867751099995, 9.59939759100007],
          [-10.90405, 9.594332],
          [-10.89976601199993, 9.594095056000072],
          [-10.89548, 9.593860001000053],
          [-10.87813, 9.57383],
          [-10.8594, 9.558166],
          [-10.859005065999952, 9.55342937000006],
          [-10.858940078999979, 9.55264993700007],
          [-10.87007, 9.54324],
          [-10.87379, 9.537500001000069],
          [-10.860440000999972, 9.527170001000059],
          [-10.85713, 9.5246],
          [-10.83096, 9.520650001000035],
          [-10.82749, 9.51788],
          [-10.82634, 9.5142],
          [-10.83052, 9.507310001000064],
          [-10.844430000999978, 9.5009],
          [-10.84837, 9.495400001000064],
          [-10.84861000099994, 9.49103000100007],
          [-10.840760000999978, 9.476530001000071],
          [-10.840770000999953, 9.47102],
          [-10.84889, 9.461610001000054],
          [-10.8489, 9.454710001000024],
          [-10.843840000999933, 9.44379],
          [-10.84336, 9.44275],
          [-10.83549, 9.438140001000022],
          [-10.82716, 9.43537],
          [-10.81607, 9.42569000100002],
          [-10.806350000999942, 9.420160001000056],
          [-10.801970000999972, 9.414400001000047],
          [-10.80452, 9.40728],
          [-10.8124, 9.40132],
          [-10.81659, 9.39191],
          [-10.81474, 9.38661],
          [-10.80966000099994, 9.38086],
          [-10.80457, 9.38085],
          [-10.79923, 9.390720001000034],
          [-10.789730000999953, 9.39001],
          [-10.78331, 9.385590001000025],
          [-10.780020000999968, 9.38333],
          [-10.770070000999965, 9.3817],
          [-10.76011000099993, 9.38604],
          [-10.748310000999936, 9.38487],
          [-10.74092, 9.37543000100004],
          [-10.738380000999939, 9.37404],
          [-10.73713, 9.375080001000072],
          [-10.73098, 9.380190001000074],
          [-10.72499000099998, 9.37167],
          [-10.71111, 9.348470001000067],
          [-10.71021, 9.33789],
          [-10.711600000999965, 9.33468],
          [-10.707440000999952, 9.33008],
          [-10.689410000999942, 9.32452],
          [-10.668840000999978, 9.309300001000054],
          [-10.663310000999957, 9.300550001000033],
          [-10.66148, 9.28997],
          [-10.66752, 9.274720001000048],
          [-10.67102, 9.26587],
          [-10.67522, 9.25025000100004],
          [-10.674659712999926, 9.241218488000072],
          [-10.672888485999977, 9.24031865400002],
          [-10.674659, 9.241208],
          [-10.67462250899996, 9.240618788000063],
          [-10.674550000999943, 9.23945],
          [-10.66347, 9.22655],
          [-10.664399879999962, 9.22655],
          [-10.664115784999979, 9.219427375000066],
          [-10.663740149999967, 9.210009761000038],
          [-10.666526666999971, 9.20557],
          [-10.66792, 9.20335],
          [-10.67903, 9.19832000100007],
          [-10.682730000999982, 9.19856],
          [-10.686617664999972, 9.19714291400004],
          [-10.69523, 9.193997],
          [-10.70604248099994, 9.19232799200006],
          [-10.70842, 9.19196],
          [-10.71149, 9.19625],
          [-10.71304, 9.1984],
          [-10.7176, 9.197628571000052],
          [-10.71836, 9.197499],
          [-10.719384743999967, 9.19469596500005],
          [-10.723479185999963, 9.18349223100006],
          [-10.722045916999946, 9.180377632000045],
          [-10.714260000999957, 9.16348],
          [-10.714539612999943, 9.143976964000046],
          [-10.71454, 9.143949],
          [-10.72613, 9.131107],
          [-10.726160000999926, 9.11663],
          [-10.730130000999964, 9.09137],
          [-10.731835001999968, 9.087658070000032],
          [-10.73205, 9.087188],
          [-10.733389726999974, 9.084249600000021],
          [-10.729930000999957, 9.07919],
          [-10.708200000999966, 9.075460001000067],
          [-10.697167887999967, 9.077424303000043],
          [-10.67512, 9.081352],
          [-10.670891948999952, 9.081407136000053],
          [-10.66032, 9.08155000100004],
          [-10.633060000999933, 9.069530001000032],
          [-10.62266, 9.06766],
          [-10.621970000999966, 9.069500001000051],
          [-10.619630107999967, 9.068775033000065],
          [-10.61729, 9.068054],
          [-10.61296, 9.066714],
          [-10.61295, 9.06671],
          [-10.606035695999935, 9.062410932000034],
          [-10.599707, 9.058478],
          [-10.591662, 9.061749],
          [-10.582442, 9.055789],
          [-10.577339, 9.05249],
          [-10.574655, 9.050755],
          [-10.572018, 9.055494],
          [-10.562766, 9.064424],
          [-10.552787, 9.066399],
          [-10.543676, 9.067142],
          [-10.527392, 9.069589],
          [-10.499615, 9.070484],
          [-10.488657, 9.066228],
          [-10.4769, 9.070158],
          [-10.466852, 9.08093],
          [-10.459302, 9.095507],
          [-10.454264, 9.109453],
          [-10.453966, 9.100431],
          [-10.453927, 9.094166],
          [-10.45334, 9.08925],
          [-10.446502, 9.078697],
          [-10.438788, 9.072081],
          [-10.434766, 9.061741],
          [-10.43469, 9.054637],
          [-10.435639, 9.049498],
          [-10.436552, 9.044214],
          [-10.444041, 9.036914],
          [-10.456391, 9.0276],
          [-10.464573, 9.016109],
          [-10.468407, 9.008342],
          [-10.467016, 9.000294],
          [-10.467601, 8.980597],
          [-10.454477, 8.980754],
          [-10.447316, 8.982442],
          [-10.443224, 8.983106],
          [-10.437525, 8.984572],
          [-10.430947, 8.98586],
          [-10.425357, 8.988494],
          [-10.418889, 8.988759],
          [-10.411801, 8.990229],
          [-10.400218, 8.992581],
          [-10.389292, 8.996243],
          [-10.382423, 8.995781],
          [-10.375041, 9.000055],
          [-10.3685, 9.003163],
          [-10.362033, 9.003976],
          [-10.350704, 9.008839],
          [-10.343285, 9.013259],
          [-10.341166, 9.020622],
          [-10.339558, 9.030497],
          [-10.339411, 9.041391],
          [-10.339339, 9.046346],
          [-10.337767, 9.053053],
          [-10.334002, 9.061401],
          [-10.327131, 9.066914],
          [-10.311049, 9.066865],
          [-10.298806, 9.059925],
          [-10.293069, 9.049913],
          [-10.285286, 9.038229],
          [-10.282875, 9.03193],
          [-10.282294, 9.030219],
          [-10.279524, 9.022056],
          [-10.282776, 9.01684],
          [-10.281831, 9.011282],
          [-10.280403, 9.003825],
          [-10.279751, 8.992801],
          [-10.281923, 8.98418],
          [-10.283026, 8.975664],
          [-10.279289, 8.970743],
          [-10.278412, 8.959302],
          [-10.274585, 8.956093],
          [-10.273819, 8.956113],
          [-10.263507, 8.956377],
          [-10.256542, 8.953524],
          [-10.251437, 8.948282],
          [-10.245398, 8.949264],
          [-10.23228, 8.950898],
          [-10.225222, 8.950305],
          [-10.219757, 8.947964],
          [-10.215394, 8.93963],
          [-10.213497, 8.930124],
          [-10.210338, 8.920854],
          [-10.205468, 8.915922],
          [-10.194915, 8.915072],
          [-10.187377, 8.915028],
          [-10.185537, 8.90377],
          [-10.186426, 8.893739],
          [-10.18581, 8.887217],
          [-10.183644183999945, 8.88632678700003],
          [-10.179735, 8.88472],
          [-10.169534, 8.88704],
          [-10.164442, 8.892043],
          [-10.155513, 8.898446],
          [-10.14969, 8.907857],
          [-10.145929, 8.915629],
          [-10.135835, 8.918469],
          [-10.129035, 8.923785],
          [-10.126657, 8.926314],
          [-10.123987, 8.929155],
          [-10.117587, 8.928574],
          [-10.110207, 8.927101],
          [-10.110012, 8.927062],
          [-10.106133, 8.919128],
          [-10.10874, 8.909661],
          [-10.10935, 8.900926],
          [-10.109049, 8.892158],
          [-10.108009, 8.882496],
          [-10.102449, 8.879963],
          [-10.093541, 8.877181],
          [-10.083577, 8.873334],
          [-10.074833, 8.87082],
          [-10.070233, 8.87509],
          [-10.059334, 8.882845],
          [-10.044687, 8.887321],
          [-10.03242, 8.893289],
          [-10.028601, 8.896885],
          [-10.026193, 8.903659],
          [-10.021348, 8.914166],
          [-10.019594, 8.922489],
          [-10.015895, 8.92891],
          [-10.016176, 8.938408],
          [-10.016792, 8.945882],
          [-10.00952, 8.952074],
          [-10.000112, 8.951559],
          [-9.99486, 8.941949],
          [-9.996264, 8.922111],
          [-10.001201, 8.909891],
          [-10.001065, 8.899928],
          [-9.998976, 8.888649],
          [-9.99545, 8.877007],
          [-9.994439, 8.865517],
          [-9.99594, 8.857651],
          [-9.995929, 8.850919],
          [-9.990076, 8.84203],
          [-9.98906, 8.828636],
          [-9.9896, 8.817955],
          [-9.984677, 8.811808],
          [-9.972022, 8.808269],
          [-9.966541, 8.814521],
          [-9.958191, 8.819499],
          [-9.952128, 8.809498],
          [-9.94135, 8.816599],
          [-9.937451, 8.814406],
          [-9.925501, 8.819553],
          [-9.913395, 8.826775],
          [-9.904257, 8.830521],
          [-9.899878, 8.835722],
          [-9.898879, 8.844685],
          [-9.883188, 8.846955],
          [-9.878879, 8.849269],
          [-9.873503, 8.84283],
          [-9.865659, 8.838585],
          [-9.860186, 8.836509],
          [-9.857437, 8.835372],
          [-9.854503, 8.836199],
          [-9.841326, 8.839915],
          [-9.831106, 8.843722],
          [-9.823159, 8.843727],
          [-9.809161, 8.845081],
          [-9.803546, 8.846477],
          [-9.796716, 8.867739],
          [-9.782164, 8.878532],
          [-9.762154, 8.892923],
          [-9.740325, 8.880331],
          [-9.722134, 8.878532],
          [-9.710234, 8.878532],
          [-9.709401, 8.878532],
          [-9.693029, 8.889325],
          [-9.667562, 8.891124],
          [-9.654829, 8.889325],
          [-9.613443, 8.874721],
          [-9.613681, 8.875572],
          [-9.62014, 8.891243],
          [-9.648911, 8.927413],
          [-9.654939, 8.936408],
          [-9.645213, 8.941803],
          [-9.634011, 8.951747],
          [-9.62728, 8.97361],
          [-9.628814, 8.992269],
          [-9.640548, 9.010784],
          [-9.658547, 9.021904],
          [-9.679152, 9.027959],
          [-9.697972, 9.030368],
          [-9.703587, 9.038787],
          [-9.70782, 9.043015],
          [-9.715658, 9.05515],
          [-9.719405, 9.065536],
          [-9.719063, 9.073735],
          [-9.706618, 9.081968],
          [-9.688551, 9.086594],
          [-9.675747, 9.090891],
          [-9.665462, 9.097339],
          [-9.662203, 9.101769],
          [-9.660585, 9.10397],
          [-9.66071, 9.115885],
          [-9.670935, 9.123067],
          [-9.685699, 9.126897],
          [-9.699553, 9.129414],
          [-9.71874, 9.134736],
          [-9.722595, 9.146616],
          [-9.721239, 9.157484],
          [-9.720408, 9.164143],
          [-9.725069, 9.175986],
          [-9.735144, 9.186846],
          [-9.738394, 9.189433],
          [-9.746353, 9.197779],
          [-9.741689, 9.209876],
          [-9.739653, 9.225399],
          [-9.730322, 9.22784],
          [-9.722632, 9.233634],
          [-9.709568, 9.237896],
          [-9.681066, 9.244161],
          [-9.660368, 9.241604],
          [-9.649629, 9.253117],
          [-9.648987, 9.283909],
          [-9.652036, 9.296627],
          [-9.649722, 9.301109],
          [-9.646601, 9.307849],
          [-9.640326, 9.317468],
          [-9.631456, 9.326393],
          [-9.616403, 9.33528],
          [-9.604903, 9.341324],
          [-9.597887, 9.355607],
          [-9.606746, 9.378369],
          [-9.606977, 9.379303],
          [-9.616882, 9.389977],
          [-9.622718, 9.399673],
          [-9.624967, 9.409985],
          [-9.626435, 9.426747],
          [-9.640428, 9.43936],
          [-9.651335, 9.458421],
          [-9.673453, 9.471763],
          [-9.707067, 9.48441],
          [-9.735636, 9.495306],
          [-9.761246, 9.502482],
          [-9.780892, 9.508603],
          [-9.801637, 9.515631],
          [-9.812027, 9.518689],
          [-9.829697, 9.526555],
          [-9.842532, 9.536973],
          [-9.850937, 9.548338],
          [-9.865174, 9.555807],
          [-9.871204, 9.558969],
          [-9.859305, 9.560577],
          [-9.869864, 9.578172],
          [-9.885334, 9.592559],
          [-9.883315, 9.597333],
          [-9.871655, 9.612496],
          [-9.879618, 9.629509],
          [-9.894116, 9.629501],
          [-9.901763, 9.635365],
          [-9.911933, 9.644249],
          [-9.927814, 9.65482],
          [-9.951461, 9.663136],
          [-9.975295, 9.668038],
          [-10.00478, 9.658432],
          [-10.025551, 9.644676],
          [-10.044165, 9.622799],
          [-10.066249, 9.610024],
          [-10.079791, 9.616094],
          [-10.09392, 9.624313],
          [-10.099004, 9.634766],
          [-10.099509, 9.650505],
          [-10.104482, 9.665729],
          [-10.107299, 9.671263],
          [-10.118243, 9.681127],
          [-10.125931, 9.685196],
          [-10.133033, 9.691092],
          [-10.144857, 9.700256],
          [-10.160642, 9.699254],
          [-10.172107, 9.682624],
          [-10.173428, 9.671],
          [-10.179327, 9.653795],
          [-10.190789, 9.640335],
          [-10.201153, 9.626147],
          [-10.216348, 9.615742],
          [-10.22023, 9.609908],
          [-10.221073, 9.600068],
          [-10.225797, 9.590115],
          [-10.236853, 9.579824],
          [-10.239021, 9.576277],
          [-10.241868, 9.57162],
          [-10.2523, 9.566285],
          [-10.265478, 9.559889],
          [-10.270968, 9.555836],
          [-10.281655, 9.546202],
          [-10.294868, 9.54363],
          [-10.307861, 9.545611],
          [-10.325503, 9.547147],
          [-10.333079, 9.545239],
          [-10.342741, 9.542492],
          [-10.369094, 9.547032],
          [-10.373633, 9.552198],
          [-10.383373, 9.563364],
          [-10.396954, 9.567346],
          [-10.404165, 9.569407],
          [-10.415897, 9.563313],
          [-10.418876, 9.561766],
          [-10.421201, 9.583091],
          [-10.428198, 9.594909],
          [-10.425363, 9.612595],
          [-10.411667, 9.626779],
          [-10.405852, 9.640081],
          [-10.403403, 9.654628],
          [-10.404442, 9.67283],
          [-10.40369, 9.686302],
          [-10.403166, 9.695696],
          [-10.409755, 9.698576],
          [-10.411838, 9.72124],
          [-10.414239, 9.734135],
          [-10.404895, 9.757664],
          [-10.393401, 9.760512],
          [-10.387735, 9.774826],
          [-10.366805, 9.782994],
          [-10.368922, 9.805481],
          [-10.375118, 9.817268],
          [-10.372589, 9.838125],
          [-10.372696, 9.852618],
          [-10.372063010999966, 9.854566],
          [-10.368863, 9.864414],
          [-10.361077, 9.863731],
          [-10.350607, 9.864149],
          [-10.340385, 9.874089],
          [-10.341554, 9.881692],
          [-10.335363, 9.886055],
          [-10.327657, 9.8949],
          [-10.334315, 9.91159],
          [-10.337538, 9.928228],
          [-10.343442, 9.947228],
          [-10.34604, 9.962825],
          [-10.352499, 9.975728],
          [-10.359644, 9.986645],
          [-10.360433, 9.992888],
          [-10.361583, 10.001992],
          [-10.353203, 10.01489],
          [-10.349704, 10.020272],
          [-10.327377, 10.042767],
          [-10.320761, 10.058456],
          [-10.329524, 10.078449],
          [-10.325774, 10.089504],
          [-10.325471, 10.090872],
          [-10.323269, 10.100806],
          [-10.305139, 10.095111],
          [-10.292202, 10.104601],
          [-10.285574, 10.110518],
          [-10.28037, 10.123638],
          [-10.265748, 10.125313],
          [-10.253231, 10.139305],
          [-10.259376, 10.147274],
          [-10.254984, 10.154582],
          [-10.241165, 10.156142],
          [-10.228554, 10.158238],
          [-10.237341, 10.175491],
          [-10.220493, 10.179196],
          [-10.211448, 10.193205],
          [-10.201884, 10.20617],
          [-10.216171, 10.226204],
          [-10.227954, 10.242793],
          [-10.236794, 10.255649],
          [-10.226834, 10.28578],
          [-10.225697, 10.293213],
          [-10.235956, 10.288106],
          [-10.236491, 10.29286],
          [-10.22571, 10.303886],
          [-10.226878, 10.319384],
          [-10.232856, 10.321799],
          [-10.241262, 10.327518],
          [-10.239447, 10.338562],
          [-10.221473, 10.337733],
          [-10.195795, 10.324578],
          [-10.179776, 10.327016],
          [-10.166919, 10.340936],
          [-10.169275, 10.352353],
          [-10.157058, 10.354117],
          [-10.153595, 10.36564],
          [-10.161434, 10.375256],
          [-10.151802, 10.390782],
          [-10.152938, 10.407434],
          [-10.145808, 10.401163],
          [-10.135085, 10.401839],
          [-10.125219, 10.414659],
          [-10.128519, 10.423246],
          [-10.129367, 10.425451],
          [-10.145861, 10.421644],
          [-10.149765, 10.435071],
          [-10.152688, 10.442158],
          [-10.154123, 10.45203],
          [-10.150614, 10.461893],
          [-10.145859, 10.462713],
          [-10.140076, 10.463432],
          [-10.132591, 10.459652],
          [-10.123337, 10.453216],
          [-10.116941, 10.456064],
          [-10.116618, 10.466018],
          [-10.118623, 10.48079],
          [-10.115997, 10.484381],
          [-10.109886, 10.492738],
          [-10.124951, 10.496184],
          [-10.139499, 10.498518],
          [-10.133125, 10.506488],
          [-10.132022, 10.513779],
          [-10.135527, 10.521401],
          [-10.137974, 10.526723],
          [-10.138199, 10.536062],
          [-10.145419, 10.537064],
          [-10.145898, 10.529528],
          [-10.148722, 10.514114],
          [-10.153508, 10.495158],
          [-10.167257, 10.482565],
          [-10.175376, 10.488358],
          [-10.180024, 10.487934],
          [-10.180617, 10.473329],
          [-10.187295, 10.468243],
          [-10.195246, 10.461526],
          [-10.18713, 10.448089],
          [-10.193559, 10.445022],
          [-10.202319, 10.463106],
          [-10.214173, 10.48503],
          [-10.22591, 10.489142],
          [-10.237094, 10.484747],
          [-10.232228, 10.501363],
          [-10.2337, 10.510513],
          [-10.219405, 10.515465],
          [-10.212892, 10.524626],
          [-10.221037, 10.527606],
          [-10.237845, 10.519789],
          [-10.253515, 10.51908],
          [-10.27751, 10.521096],
          [-10.292883, 10.527059],
          [-10.291856, 10.534097],
          [-10.292522, 10.550606],
          [-10.295072, 10.555314],
          [-10.302572, 10.546215],
          [-10.310446, 10.538883],
          [-10.322202, 10.531814],
          [-10.332073, 10.528579],
          [-10.334998, 10.520304],
          [-10.337253, 10.510051],
          [-10.345239, 10.51072],
          [-10.354327, 10.512033],
          [-10.352932, 10.518602],
          [-10.352082, 10.531481],
          [-10.363574, 10.537318],
          [-10.37721, 10.539827],
          [-10.385791, 10.527908],
          [-10.392253, 10.538285],
          [-10.401124, 10.547027],
          [-10.404105, 10.542896],
          [-10.407799, 10.528161],
          [-10.414233, 10.512075],
          [-10.419003, 10.511367],
          [-10.420583, 10.512657],
          [-10.422769, 10.513245],
          [-10.4314, 10.515568],
          [-10.438326, 10.52113],
          [-10.443691, 10.524135],
          [-10.451545, 10.526708],
          [-10.457825, 10.529751],
          [-10.467331, 10.531563],
          [-10.467392, 10.535978],
          [-10.467524, 10.545494],
          [-10.447493, 10.556912],
          [-10.448437, 10.567067],
          [-10.450327, 10.586896],
          [-10.455165, 10.614541],
          [-10.461499, 10.641756],
          [-10.47434, 10.662931],
          [-10.487183, 10.683625],
          [-10.495061, 10.701253],
          [-10.500937, 10.72074],
          [-10.509094, 10.751011],
          [-10.511689, 10.760641],
          [-10.531613, 10.760374],
          [-10.554046, 10.756337],
          [-10.568017, 10.751523],
          [-10.582502, 10.743353],
          [-10.586504, 10.740118],
          [-10.602421, 10.744049],
          [-10.618356, 10.744605],
          [-10.642289, 10.739657],
          [-10.668939, 10.735324],
          [-10.673228, 10.736987],
          [-10.674405, 10.740744],
          [-10.678104, 10.743349],
          [-10.678812, 10.746447],
          [-10.6828, 10.74942],
          [-10.685657, 10.752564],
          [-10.686805, 10.755519],
          [-10.689511, 10.759431],
          [-10.692514, 10.76265],
          [-10.694863, 10.765576],
          [-10.698093, 10.767631],
          [-10.701246, 10.770234],
          [-10.703708, 10.772614],
          [-10.706353, 10.774923],
          [-10.709066, 10.777996],
          [-10.71342, 10.78086],
          [-10.716141, 10.782622],
          [-10.718974, 10.784094],
          [-10.722245, 10.785386],
          [-10.726342, 10.788539],
          [-10.729979, 10.789651],
          [-10.732803, 10.792615],
          [-10.73637, 10.793182],
          [-10.738795, 10.795743],
          [-10.740684, 10.796591],
          [-10.745262, 10.7988],
          [-10.747514, 10.799905],
          [-10.751991, 10.80073],
          [-10.754826, 10.801872],
          [-10.758831, 10.802514],
          [-10.763201, 10.802756],
          [-10.766689, 10.804561],
          [-10.769231, 10.805956],
          [-10.773455, 10.80649],
          [-10.776468, 10.808363],
          [-10.779343, 10.80885],
          [-10.78952, 10.811212],
          [-10.79026, 10.811384],
          [-10.793241, 10.812529],
          [-10.79946, 10.814528],
          [-10.802105, 10.81709],
          [-10.806734, 10.816934],
          [-10.80887, 10.819055],
          [-10.812902, 10.82126],
          [-10.816134, 10.823172],
          [-10.81969, 10.8257],
          [-10.822348, 10.825932],
          [-10.825836, 10.827661],
          [-10.827253, 10.828299],
          [-10.827799, 10.828545],
          [-10.830193, 10.830305],
          [-10.832851, 10.830685],
          [-10.83528, 10.832881],
          [-10.839051, 10.836029],
          [-10.843661, 10.838893],
          [-10.845612, 10.841779],
          [-10.848521, 10.842884],
          [-10.852299, 10.84476],
          [-10.855528, 10.847362],
          [-10.85891, 10.84847],
          [-10.861229, 10.850813],
          [-10.864787, 10.853049],
          [-10.8676, 10.853171],
          [-10.870496, 10.855372],
          [-10.87548, 10.856709],
          [-10.879514, 10.858513],
          [-10.883112, 10.860351],
          [-10.887992, 10.861103],
          [-10.893483, 10.863132],
          [-10.897558, 10.864208],
          [-10.900093, 10.867059],
          [-10.904161, 10.8693],
          [-10.906366, 10.872622],
          [-10.908789, 10.875836],
          [-10.90851, 10.879839],
          [-10.906486, 10.883103],
          [-10.902899, 10.885962],
          [-10.899495, 10.888308],
          [-10.895941, 10.891348],
          [-10.892172, 10.894022],
          [-10.890019, 10.894302],
          [-10.885346, 10.895481],
          [-10.882322, 10.895355],
          [-10.877654, 10.895732],
          [-10.876076, 10.897362],
          [-10.875116, 10.899578],
          [-10.872944, 10.903241],
          [-10.868727, 10.907297],
          [-10.864417, 10.90884],
          [-10.86117, 10.909404],
          [-10.859056, 10.909139],
          [-10.856031, 10.909123],
          [-10.851276, 10.911937],
          [-10.848968, 10.913745],
          [-10.842765, 10.914768],
          [-10.841907, 10.917967],
          [-10.83678, 10.921944],
          [-10.832103, 10.923594],
          [-10.826654, 10.926441],
          [-10.823513, 10.927515],
          [-10.820082, 10.928116],
          [-10.815442, 10.929912],
          [-10.816813, 10.932211],
          [-10.817568, 10.933999],
          [-10.81474, 10.937367],
          [-10.811838, 10.940991],
          [-10.80984, 10.945749],
          [-10.805776, 10.948457],
          [-10.80244, 10.951387],
          [-10.798039, 10.955658],
          [-10.797178, 10.959292],
          [-10.798866, 10.963414],
          [-10.799423, 10.967785],
          [-10.798816, 10.97164],
          [-10.799471, 10.977903],
          [-10.798968, 10.982488],
          [-10.794014, 10.987701],
          [-10.795371, 10.992257],
          [-10.792762, 10.99552],
          [-10.791086, 10.999675],
          [-10.789956, 11.000785],
          [-10.773311, 11.014308],
          [-10.758024, 11.024396],
          [-10.746317, 11.030367],
          [-10.737254, 11.05058],
          [-10.727412, 11.055127],
          [-10.71814, 11.058776],
          [-10.715547, 11.077291],
          [-10.714482, 11.093105],
          [-10.716854, 11.102797],
          [-10.722473, 11.11564],
          [-10.725499, 11.129346],
          [-10.720635, 11.13326],
          [-10.720925, 11.144409],
          [-10.715937, 11.140283],
          [-10.703451, 11.154657],
          [-10.699379, 11.157476],
          [-10.690035, 11.158725],
          [-10.68984, 11.163405],
          [-10.687754, 11.16274],
          [-10.683985, 11.161539],
          [-10.677109, 11.157498],
          [-10.665143, 11.154693],
          [-10.66588, 11.165378],
          [-10.666079, 11.173542],
          [-10.6593, 11.174793],
          [-10.663143, 11.183478],
          [-10.652792, 11.184317],
          [-10.645252, 11.181796],
          [-10.633866, 11.179121],
          [-10.641307, 11.191474],
          [-10.644987, 11.199209],
          [-10.638359, 11.198249],
          [-10.626032, 11.191327],
          [-10.609808, 11.189034],
          [-10.589711, 11.183721],
          [-10.568904, 11.183158],
          [-10.545046, 11.186211],
          [-10.537279, 11.186371],
          [-10.512555, 11.197074],
          [-10.443231, 11.255601],
          [-10.464619, 11.271268],
          [-10.468238, 11.274326],
          [-10.472064, 11.279638],
          [-10.47628, 11.285492],
          [-10.475761, 11.29452],
          [-10.468367, 11.303205],
          [-10.445804, 11.310573],
          [-10.410721, 11.312785],
          [-10.390649, 11.319431],
          [-10.380228, 11.322627],
          [-10.371622, 11.330907],
          [-10.365835, 11.340282],
          [-10.361006, 11.348068],
          [-10.352111, 11.35504],
          [-10.345026, 11.368221],
          [-10.349405, 11.373643],
          [-10.3544, 11.381751],
          [-10.352897, 11.392012],
          [-10.351618, 11.401659],
          [-10.350002, 11.403432],
          [-10.345914, 11.407915],
          [-10.335841, 11.394531],
          [-10.329144, 11.401038],
          [-10.327858, 11.412245],
          [-10.325213, 11.423773],
          [-10.321229, 11.430617],
          [-10.318795, 11.424149],
          [-10.317323, 11.405024],
          [-10.322949, 11.390459],
          [-10.331524, 11.381564],
          [-10.333165, 11.373551],
          [-10.320381, 11.37035],
          [-10.300256, 11.379784],
          [-10.301185, 11.386393],
          [-10.296724, 11.403029],
          [-10.294129, 11.410747],
          [-10.288983, 11.414102],
          [-10.274372, 11.399321],
          [-10.266724, 11.395156],
          [-10.255586, 11.402408],
          [-10.247214, 11.405715],
          [-10.243708, 11.411544],
          [-10.254809, 11.423738],
          [-10.248531, 11.42553],
          [-10.243915, 11.424388],
          [-10.239263, 11.422956],
          [-10.23314, 11.423041],
          [-10.22213, 11.416142],
          [-10.217568, 11.401067],
          [-10.203033, 11.404893],
          [-10.195039, 11.414224],
          [-10.193313, 11.396148],
          [-10.189843, 11.384087],
          [-10.182188, 11.38199],
          [-10.170653, 11.387422],
          [-10.155239, 11.391025],
          [-10.152486, 11.382922],
          [-10.163494, 11.371938],
          [-10.16911, 11.361437],
          [-10.164782, 11.35304],
          [-10.152217, 11.350737],
          [-10.137884, 11.350352],
          [-10.127067, 11.350563],
          [-10.124081, 11.35436],
          [-10.135643, 11.368777],
          [-10.131612, 11.376997],
          [-10.12103, 11.390739],
          [-10.115481, 11.384911],
          [-10.103262, 11.378547],
          [-10.085243, 11.382208],
          [-10.072639, 11.388177],
          [-10.067989, 11.395123],
          [-10.063362, 11.3967],
          [-10.064116, 11.391805],
          [-10.067822, 11.383221],
          [-10.068961, 11.374409],
          [-10.076959, 11.365045],
          [-10.081973, 11.358789],
          [-10.077703, 11.354308],
          [-10.070984, 11.356528],
          [-10.057274, 11.364271],
          [-10.050072, 11.375668],
          [-10.048735, 11.387528],
          [-10.048582, 11.397034],
          [-10.041887, 11.401757],
          [-10.030967, 11.407949],
          [-10.030511, 11.411648],
          [-10.029092, 11.417158],
          [-10.023209, 11.420398],
          [-10.019793, 11.413595],
          [-10.015497, 11.410562],
          [-10.013945, 11.40947],
          [-10.010213, 11.407893],
          [-10.004753, 11.406959],
          [-10.006326, 11.41144],
          [-10.007999, 11.422114],
          [-10.010794, 11.436605],
          [-10.010726, 11.44321],
          [-10.011427, 11.446505],
          [-10.011359, 11.45311],
          [-10.009626, 11.462862],
          [-10.020935, 11.468138],
          [-10.027762, 11.475063],
          [-10.031232, 11.478379],
          [-10.04856, 11.483029],
          [-10.051756994999948, 11.486723010000048],
          [-10.055494, 11.491041],
          [-10.061983, 11.491904],
          [-10.079288, 11.493574],
          [-10.085701, 11.494982],
          [-10.099509, 11.499793],
          [-10.109988, 11.502704],
          [-10.121258, 11.500499],
          [-10.13039, 11.500936],
          [-10.139891, 11.509393],
          [-10.146734, 11.522302],
          [-10.17218, 11.53463],
          [-10.193201, 11.534242],
          [-10.212815, 11.53758],
          [-10.2219, 11.540954],
          [-10.224299, 11.546406],
          [-10.233039, 11.563599],
          [-10.239319, 11.57146],
          [-10.247077, 11.57682],
          [-10.261721, 11.584965],
          [-10.265526, 11.588353],
          [-10.265067, 11.593213],
          [-10.265643, 11.605987],
          [-10.260308, 11.619936],
          [-10.258657, 11.639522],
          [-10.260555, 11.652227],
          [-10.257479, 11.660198],
          [-10.256078, 11.67184],
          [-10.257658, 11.681533],
          [-10.253265, 11.688229],
          [-10.243769, 11.695088],
          [-10.238819, 11.693982],
          [-10.228137, 11.703812],
          [-10.226624, 11.715451],
          [-10.231825, 11.728496],
          [-10.22301, 11.739203],
          [-10.219256, 11.751379],
          [-10.224516, 11.758511],
          [-10.244966, 11.758185],
          [-10.246227, 11.774698],
          [-10.236478, 11.789719],
          [-10.223241, 11.804258],
          [-10.211035, 11.817564],
          [-10.210697, 11.829535],
          [-10.211823, 11.842237],
          [-10.198198, 11.851731],
          [-10.182375, 11.869051],
          [-10.18401, 11.882915],
          [-10.193026, 11.907222],
          [-10.168027, 11.920922],
          [-10.159502, 11.932193],
          [-10.166926, 11.941128],
          [-10.175815, 11.9559],
          [-10.178749, 11.966607],
          [-10.185106, 11.989484],
          [-10.193634, 12.017086],
          [-10.190793, 12.02981],
          [-10.209776, 12.054043],
          [-10.221271, 12.07618],
          [-10.210949, 12.096915],
          [-10.191436, 12.114068],
          [-10.165961, 12.128603],
          [-10.141996, 12.139816],
          [-10.112351, 12.152322],
          [-10.086779, 12.177659]
        ]
      ]
    },
    properties: {
      name: 'Faranah',
      center: [-10.761524745394068, 10.054803766790739],
      shapeName: 'Faranah',
      shapeISO: '',
      shapeID: '26780975B17934337985358',
      shapeGroup: 'GIN',
      shapeType: 'ADM1'
    }
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-9.613443, 8.874721],
          [-9.565061, 8.867669],
          [-9.536865, 8.868568],
          [-9.482293, 8.877559],
          [-9.433179, 8.894641],
          [-9.402254, 8.896439],
          [-9.374059, 8.877626],
          [-9.346773, 8.867669],
          [-9.321306, 8.850644],
          [-9.304025, 8.829909],
          [-9.27492, 8.804736],
          [-9.25491, 8.784957],
          [-9.223076, 8.780491],
          [-9.213071, 8.769673],
          [-9.197609, 8.766077],
          [-9.17578, 8.764279],
          [-9.163047, 8.7661],
          [-9.13758, 8.780491],
          [-9.114842, 8.784058],
          [-9.095741, 8.787654],
          [-9.064817, 8.798479],
          [-9.041169, 8.793082],
          [-9.010245, 8.793082],
          [-8.988416, 8.785887],
          [-8.973863, 8.780491],
          [-8.957743, 8.774724],
          [-8.949018, 8.772633],
          [-8.942117, 8.775102],
          [-8.937698, 8.776788],
          [-8.931355, 8.781874],
          [-8.884337, 8.812312],
          [-8.870218, 8.823968],
          [-8.86051, 8.831982],
          [-8.841395, 8.846946],
          [-8.837225, 8.851498],
          [-8.830797, 8.859161],
          [-8.815758, 8.888093],
          [-8.797379, 8.911242],
          [-8.795011, 8.914223],
          [-8.766308, 8.924608],
          [-8.718796, 8.942068],
          [-8.705963, 8.954859],
          [-8.699784, 8.961019],
          [-8.702632, 8.986367],
          [-8.723121, 9.014424],
          [-8.7289, 9.041519],
          [-8.727099, 9.067683],
          [-8.72221, 9.0872],
          [-8.717468, 9.10029],
          [-8.715391, 9.113894],
          [-8.709053, 9.13793],
          [-8.693579, 9.162068],
          [-8.688983, 9.1659],
          [-8.663333, 9.187278],
          [-8.662573, 9.219529],
          [-8.644527, 9.245836],
          [-8.638777, 9.288745],
          [-8.623387, 9.304491],
          [-8.622857, 9.316806],
          [-8.620444, 9.337312],
          [-8.621024, 9.35851],
          [-8.621932, 9.379779],
          [-8.626037, 9.40003],
          [-8.631351, 9.421031],
          [-8.634562, 9.443001],
          [-8.634532, 9.455667],
          [-8.634515, 9.463335],
          [-8.621874, 9.477815],
          [-8.619746, 9.497952],
          [-8.616615, 9.502604],
          [-8.611771, 9.487618],
          [-8.604907, 9.46108],
          [-8.590835, 9.456621],
          [-8.58395, 9.435055],
          [-8.577566, 9.441478],
          [-8.557861, 9.445757],
          [-8.554029, 9.477968],
          [-8.541859, 9.485106],
          [-8.534164, 9.47357],
          [-8.521361, 9.490517],
          [-8.513122, 9.49743],
          [-8.49681, 9.491213],
          [-8.484848, 9.512581],
          [-8.49469, 9.53517],
          [-8.483555, 9.544328],
          [-8.461775, 9.541941],
          [-8.438908, 9.532558],
          [-8.426564, 9.541073],
          [-8.392008, 9.567061],
          [-8.352451, 9.581116],
          [-8.237157, 9.587204],
          [-8.14727, 9.583955],
          [-8.149895521999952, 9.58634930900007],
          [-8.152279014999976, 9.588517750000051],
          [-8.152930000999959, 9.58911],
          [-8.150596358999962, 9.597421957000051],
          [-8.147596, 9.608129],
          [-8.14490505799995, 9.611346548000029],
          [-8.143600643999946, 9.61290923000007],
          [-8.144266, 9.615214],
          [-8.147429397999929, 9.618339879000075],
          [-8.15228, 9.62313],
          [-8.151790000999938, 9.62542],
          [-8.146223, 9.625584],
          [-8.142213, 9.631512],
          [-8.133579766999958, 9.636692940000046],
          [-8.13276, 9.64703],
          [-8.1278, 9.654780001000063],
          [-8.12774000099995, 9.659380001000045],
          [-8.129978796999978, 9.663738720000026],
          [-8.129999, 9.663778],
          [-8.136683, 9.666619],
          [-8.13527, 9.668441],
          [-8.130631, 9.668843],
          [-8.13023288599993, 9.669610672000033],
          [-8.129450000999952, 9.67113],
          [-8.133280000999946, 9.67968],
          [-8.13320000799996, 9.68611939300007],
          [-8.13343545899994, 9.686444772000073],
          [-8.13639, 9.690520001000039],
          [-8.131613, 9.701962],
          [-8.12512, 9.702342],
          [-8.124983533999966, 9.702681738000024],
          [-8.123930000999962, 9.70532],
          [-8.123190411999929, 9.708528209000065],
          [-8.127101, 9.729496],
          [-8.12425000099995, 9.735440001000029],
          [-8.125233393999963, 9.736571372000071],
          [-8.129502, 9.741481],
          [-8.125456, 9.74942],
          [-8.123564244999955, 9.753133519000073],
          [-8.125049001999969, 9.754364430000066],
          [-8.12976, 9.75827],
          [-8.129207724999958, 9.759197241000038],
          [-8.127865, 9.761463],
          [-8.119471768999972, 9.762437400000067],
          [-8.115330000999961, 9.762920001000055],
          [-8.115316094999969, 9.762933389000068],
          [-8.113455, 9.764734],
          [-8.120694, 9.779078],
          [-8.12062773699995, 9.78439458500003],
          [-8.12055, 9.791030001000024],
          [-8.109599441999933, 9.795265442000073],
          [-8.10934, 9.79779],
          [-8.11703147399993, 9.803155530000026],
          [-8.11807, 9.80388],
          [-8.11873000099996, 9.806880001000025],
          [-8.11612, 9.811670001000039],
          [-8.106199683999932, 9.807871028000022],
          [-8.10451, 9.813360001000035],
          [-8.10509, 9.822340001000043],
          [-8.10217, 9.83068],
          [-8.102125952999927, 9.830805116000022],
          [-8.101484299999981, 9.832640281000067],
          [-8.104512203999946, 9.832837630000029],
          [-8.106849, 9.832987],
          [-8.111407, 9.836215],
          [-8.113000000999932, 9.838770001000057],
          [-8.109055147999925, 9.845103988000062],
          [-8.108741, 9.845609],
          [-8.109390000999952, 9.849300001000074],
          [-8.10769000099998, 9.85571],
          [-8.109040000999926, 9.861550001000069],
          [-8.10945, 9.86332],
          [-8.113339, 9.867279],
          [-8.113997544999961, 9.867542386000025],
          [-8.117498802999933, 9.868939522000062],
          [-8.123061, 9.868549],
          [-8.126648, 9.867108837000046],
          [-8.130284, 9.865649],
          [-8.132447171999956, 9.865740653000046],
          [-8.13793, 9.86597],
          [-8.14345, 9.869720001000076],
          [-8.1441, 9.87387],
          [-8.141770347999966, 9.881873040000073],
          [-8.140703, 9.885551],
          [-8.139982287999942, 9.88601480400007],
          [-8.13860000099993, 9.886910001000047],
          [-8.13402, 9.88225],
          [-8.130789, 9.881061],
          [-8.128689227999928, 9.882412922000071],
          [-8.1248, 9.89712],
          [-8.12452000099995, 9.90099],
          [-8.127929496999968, 9.906089246000022],
          [-8.135073, 9.909625],
          [-8.13224, 9.91396],
          [-8.132861772999945, 9.91589814100007],
          [-8.13335230599995, 9.917419848000065],
          [-8.14217, 9.91684],
          [-8.14648000099993, 9.91376],
          [-8.152940000999934, 9.90915],
          [-8.154974266999943, 9.909044779000055],
          [-8.156420000999958, 9.90897],
          [-8.15903511099998, 9.910931332000075],
          [-8.16078, 9.912239],
          [-8.165472, 9.926783],
          [-8.163783058999968, 9.930032878000077],
          [-8.163100000999975, 9.93135],
          [-8.155405, 9.934478],
          [-8.153943493999975, 9.935783190000052],
          [-8.152360000999977, 9.937200001000065],
          [-8.152899050999963, 9.938688425000066],
          [-8.15303, 9.939048],
          [-8.156560844999944, 9.940599527000074],
          [-8.165950000999942, 9.94472],
          [-8.16651105799997, 9.946321220000073],
          [-8.166844, 9.947264],
          [-8.164014, 9.951138],
          [-8.14826, 9.94876],
          [-8.133142372999941, 9.955174799000076],
          [-8.130088, 9.956472],
          [-8.12363, 9.953633],
          [-8.122477928999956, 9.95388177700005],
          [-8.12061, 9.95429],
          [-8.118120612999974, 9.957289580000065],
          [-8.117781, 9.9577],
          [-8.117384, 9.971032],
          [-8.121493334999968, 9.976140173000033],
          [-8.12729, 9.97644],
          [-8.13852, 9.968530001000033],
          [-8.14013805199994, 9.968991846000051],
          [-8.143605, 9.969975],
          [-8.15027, 9.974885],
          [-8.151834, 9.979733],
          [-8.150828160999936, 9.982793959000048],
          [-8.150630000999968, 9.983400001000064],
          [-8.149219923999965, 9.984875080000052],
          [-8.14781, 9.986353],
          [-8.1373, 9.992203],
          [-8.13714962299997, 9.993211159000055],
          [-8.136140000999944, 10],
          [-8.135867832999963, 10.001816010000027],
          [-8.135443997999971, 10.004644015000054],
          [-8.134982, 10.00774],
          [-8.132332815999973, 10.009046922000039],
          [-8.131010000999936, 10.0097],
          [-8.13143, 10.013850001000037],
          [-8.124489178999966, 10.023115678000067],
          [-8.112925, 10.03856],
          [-8.107720489999963, 10.045509345000028],
          [-8.106680000999972, 10.04690000100004],
          [-8.106067414999927, 10.047384205000071],
          [-8.098949, 10.05302],
          [-8.080786388999968, 10.05828745100007],
          [-8.079160000999934, 10.05876],
          [-8.053627891999952, 10.069659496000043],
          [-8.045101, 10.0733],
          [-8.042154836999941, 10.07550029400005],
          [-8.0245, 10.088690001000032],
          [-8.018499575999954, 10.094997947000024],
          [-8.016502, 10.0971],
          [-8.013885229999971, 10.102474527000027],
          [-8.01127, 10.107850001000031],
          [-8.00599000099993, 10.12227],
          [-8.00242585199993, 10.128988280000044],
          [-7.988174, 10.15586],
          [-7.985986, 10.16411],
          [-7.981299857999943, 10.173118845000033],
          [-7.98052, 10.17462],
          [-7.97855, 10.175110001000064],
          [-7.978140000999929, 10.179650001000027],
          [-7.96335, 10.194760001000077],
          [-7.957450000999927, 10.20079],
          [-7.954955312999971, 10.205354427000032],
          [-7.952461, 10.20992],
          [-7.950551, 10.23336],
          [-7.950072886999976, 10.234469930000046],
          [-7.94721, 10.24113],
          [-7.94810399499994, 10.247351965000064],
          [-7.948704, 10.2515],
          [-7.95944, 10.28544],
          [-7.960951, 10.29489],
          [-7.968623, 10.31361],
          [-7.980092, 10.32571],
          [-7.995272, 10.33831],
          [-8.006831, 10.34305],
          [-8.021619991999955, 10.345762167000032],
          [-8.051110000999927, 10.35117],
          [-8.070832271999961, 10.352623780000044],
          [-8.070878726999979, 10.352627204000044],
          [-8.080821, 10.35336],
          [-8.084183, 10.35482],
          [-8.091685, 10.35809],
          [-8.108612, 10.38105],
          [-8.114284, 10.39285],
          [-8.11524577299997, 10.40153624100003],
          [-8.11597, 10.40805],
          [-8.11885, 10.42027],
          [-8.119810031999975, 10.421386037000048],
          [-8.120452, 10.42213],
          [-8.122248705999937, 10.422610322000025],
          [-8.127637309999955, 10.424049282000055],
          [-8.14068, 10.42122],
          [-8.148176, 10.41601],
          [-8.160475, 10.40429],
          [-8.162796884999977, 10.402084142000035],
          [-8.16392, 10.40101],
          [-8.173234, 10.39905],
          [-8.182661950999943, 10.399160146000042],
          [-8.1895, 10.399240001000067],
          [-8.195756718999974, 10.400229481000054],
          [-8.202023, 10.40122],
          [-8.225393, 10.41],
          [-8.22911527399998, 10.413767266000036],
          [-8.2316, 10.41628],
          [-8.240630000999943, 10.44029000100005],
          [-8.242302074999941, 10.456874738000067],
          [-8.242721, 10.46102],
          [-8.24426, 10.46433],
          [-8.244763, 10.46541],
          [-8.246292509999932, 10.466260699000031],
          [-8.25701, 10.472210001000064],
          [-8.261840000999939, 10.47687],
          [-8.26407, 10.485170001000029],
          [-8.26227, 10.50079],
          [-8.26616, 10.50635],
          [-8.27376, 10.51288],
          [-8.2767, 10.52073000100006],
          [-8.277350044999935, 10.527205441000035],
          [-8.277743, 10.53109],
          [-8.272542, 10.56025],
          [-8.273184, 10.57549],
          [-8.273246668999946, 10.576983400000074],
          [-8.27328, 10.57773],
          [-8.27805, 10.5879],
          [-8.281548311999927, 10.588176050000072],
          [-8.28502, 10.58844],
          [-8.289154, 10.59309],
          [-8.289637, 10.602561],
          [-8.290303, 10.61564],
          [-8.295124, 10.62144],
          [-8.301474, 10.63623],
          [-8.301114, 10.64796],
          [-8.296882, 10.67344],
          [-8.29668, 10.681259983000075],
          [-8.296480000999964, 10.689080001000036],
          [-8.296589998999934, 10.689314998000043],
          [-8.296702, 10.68955],
          [-8.305574, 10.70827],
          [-8.306882, 10.71634],
          [-8.29557, 10.75094],
          [-8.29784674299998, 10.75556947800004],
          [-8.31149602599993, 10.75451146100005],
          [-8.313450000999978, 10.75436],
          [-8.319480971999951, 10.755574189000072],
          [-8.319493497999929, 10.75557671100006],
          [-8.325521, 10.75679],
          [-8.328957, 10.76189],
          [-8.329325, 10.77086],
          [-8.327928094999947, 10.77402631800004],
          [-8.3267, 10.77682],
          [-8.32351, 10.782890001000055],
          [-8.320114468999975, 10.789346010000031],
          [-8.316722, 10.7958],
          [-8.30752, 10.80743],
          [-8.30620000099998, 10.821900001000074],
          [-8.304663637999965, 10.823841723000044],
          [-8.300063, 10.82966],
          [-8.286531, 10.83273],
          [-8.285415908999937, 10.833694214000047],
          [-8.28207, 10.836590001000047],
          [-8.28064279399996, 10.839135827000064],
          [-8.278744, 10.84253],
          [-8.275601, 10.85308],
          [-8.276783, 10.861552],
          [-8.280493, 10.88814],
          [-8.281644, 10.89639],
          [-8.281693, 10.91318],
          [-8.278995, 10.92557],
          [-8.287782456999935, 10.958361363000051],
          [-8.291300000999968, 10.971480001000032],
          [-8.287, 10.98155],
          [-8.28708, 10.99513],
          [-8.293230000999927, 11.00784],
          [-8.295568396999954, 11.008289339000044],
          [-8.295785, 11.00833],
          [-8.29888, 11.01382],
          [-8.305840000999979, 11.02616],
          [-8.31298, 11.033600001000025],
          [-8.31732, 11.04123000100003],
          [-8.319388516999936, 11.043551670000056],
          [-8.323532, 11.0482],
          [-8.347776, 11.06802],
          [-8.371, 11.07448],
          [-8.381930393999937, 11.07597964200005],
          [-8.38993, 11.068700001000025],
          [-8.391575823999972, 11.06776453200007],
          [-8.396507, 11.06496],
          [-8.399069, 11.06351],
          [-8.40561, 11.062200001000065],
          [-8.411259936999954, 11.065966625000044],
          [-8.415784, 11.06898],
          [-8.425534, 11.07207],
          [-8.437238569999977, 11.072984286000064],
          [-8.446017882999968, 11.073669835000032],
          [-8.472857, 11.06865],
          [-8.482444, 11.06507],
          [-8.485307397999975, 11.05837273800006],
          [-8.48626, 11.056140001000074],
          [-8.49666338399993, 11.04085815600007],
          [-8.501869, 11.03321],
          [-8.50305, 11.03147],
          [-8.513667, 10.99501],
          [-8.514457, 10.98604],
          [-8.514039529999934, 10.982039106000059],
          [-8.51318, 10.97384],
          [-8.515064, 10.97202],
          [-8.527014374999965, 10.974505733000058],
          [-8.535977854999942, 10.97636955400003],
          [-8.564066, 10.9617],
          [-8.564722073999974, 10.961519620000047],
          [-8.5713, 10.9597],
          [-8.57357, 10.958320001000061],
          [-8.577239199999951, 10.956070491000048],
          [-8.580909, 10.95382],
          [-8.590490000999978, 10.950240001000054],
          [-8.592456916999936, 10.951352171000053],
          [-8.598363, 10.95469],
          [-8.600743299999976, 10.95513670500003],
          [-8.60283889699997, 10.955529485000056],
          [-8.60743, 10.956390001000045],
          [-8.61138, 10.95787000100006],
          [-8.612246205999952, 10.958598116000076],
          [-8.618282, 10.96366],
          [-8.621809853999935, 10.96506336400006],
          [-8.622458758999926, 10.965319510000029],
          [-8.627114287999973, 10.96471322900004],
          [-8.62898, 10.96447],
          [-8.635396653999976, 10.961405777000039],
          [-8.661040000999947, 10.94916],
          [-8.665628863999927, 10.94959354100007],
          [-8.667924, 10.94981],
          [-8.671962, 10.95019],
          [-8.677261, 10.95531],
          [-8.677753098999972, 10.956464449000066],
          [-8.68022, 10.96224],
          [-8.680561656999942, 10.966264887000023],
          [-8.680631, 10.96707],
          [-8.677723, 10.97808],
          [-8.682355, 11.00275],
          [-8.681372, 11.0078],
          [-8.68097733299993, 11.008474724000052],
          [-8.67924, 11.01146],
          [-8.66048000099994, 11.024600001000067],
          [-8.65037, 11.0337],
          [-8.647670000999938, 11.046780001000059],
          [-8.64808, 11.052310001000023],
          [-8.647742496999967, 11.053630012000042],
          [-8.647631, 11.05407],
          [-8.645162, 11.06378],
          [-8.630499, 11.076972],
          [-8.624944, 11.08197],
          [-8.620676, 11.08906],
          [-8.620635713999945, 11.092805755000029],
          [-8.62063, 11.09343],
          [-8.621752681999965, 11.097295626000061],
          [-8.625123, 11.10889],
          [-8.625302, 11.11395],
          [-8.62448595099994, 11.115649508000047],
          [-8.622450000999947, 11.119900001000076],
          [-8.6175, 11.12491],
          [-8.611413, 11.12784],
          [-8.60711884999995, 11.12779514500005],
          [-8.602797, 11.12775],
          [-8.58559809899998, 11.124570573000028],
          [-8.581160000999944, 11.12591],
          [-8.57741, 11.12933],
          [-8.576921, 11.12977],
          [-8.57099, 11.13983],
          [-8.564303, 11.15494],
          [-8.56759034299995, 11.163375881000036],
          [-8.57088, 11.17181],
          [-8.573290000999975, 11.185870001000069],
          [-8.571621721999975, 11.193210425000075],
          [-8.570842, 11.19665],
          [-8.568584817999977, 11.200650322000058],
          [-8.56633, 11.20465],
          [-8.558810000999927, 11.21055],
          [-8.558633140999973, 11.210593163000055],
          [-8.558096, 11.210725],
          [-8.552502, 11.2121],
          [-8.53073986399994, 11.210085523000032],
          [-8.529923568999948, 11.210010329000056],
          [-8.524422, 11.2142],
          [-8.521736530999931, 11.216243596000027],
          [-8.517710000999955, 11.219310001000053],
          [-8.512350000999959, 11.219480001000022],
          [-8.505838908999976, 11.21798222700005],
          [-8.497702836999963, 11.216110652000054],
          [-8.492562, 11.21766],
          [-8.490119319999962, 11.21938048000004],
          [-8.48646, 11.22196000100007],
          [-8.485740072999931, 11.223591085000066],
          [-8.484545, 11.22631],
          [-8.484736, 11.22999],
          [-8.491642, 11.23766],
          [-8.492515062999928, 11.242960383000025],
          [-8.49339, 11.24826],
          [-8.498420000999943, 11.25706],
          [-8.498706689999949, 11.258980154000028],
          [-8.499286, 11.26282],
          [-8.497986568999977, 11.267867048000028],
          [-8.49734, 11.27039],
          [-8.4933, 11.27748],
          [-8.48458, 11.28612],
          [-8.47919, 11.288830001000065],
          [-8.467070000999968, 11.28915],
          [-8.45015, 11.28045],
          [-8.446815, 11.27926],
          [-8.422298, 11.27048],
          [-8.412519677999967, 11.269910098000025],
          [-8.412193506999927, 11.270008786000062],
          [-8.406671, 11.27169],
          [-8.394009902999926, 11.27222314100004],
          [-8.39385, 11.272230001000025],
          [-8.38239, 11.27555],
          [-8.376987, 11.27917],
          [-8.373804241999949, 11.285139661000073],
          [-8.37199, 11.28855],
          [-8.366950286999952, 11.307508920000032],
          [-8.364432, 11.31699],
          [-8.364827082999966, 11.319348430000048],
          [-8.365088536999963, 11.320901311000057],
          [-8.369471810999926, 11.324639839000042],
          [-8.37396, 11.32424],
          [-8.40167, 11.32178],
          [-8.418155, 11.32749],
          [-8.420456850999926, 11.329975399000034],
          [-8.421380000999932, 11.33097],
          [-8.421585033999975, 11.333275374000038],
          [-8.421792, 11.33558],
          [-8.421111, 11.33821],
          [-8.415994, 11.35346],
          [-8.41079, 11.36007],
          [-8.39745, 11.36498],
          [-8.383929869999974, 11.36483004300004],
          [-8.37589, 11.36748],
          [-8.381552, 11.37525],
          [-8.396867358999941, 11.382309244000055],
          [-8.406800224999927, 11.381287344000043],
          [-8.409008, 11.38106],
          [-8.410138135999944, 11.380059880000033],
          [-8.411830000999942, 11.37856],
          [-8.423494, 11.37845],
          [-8.430681, 11.38244],
          [-8.434053601999949, 11.383164700000066],
          [-8.437429427999973, 11.383889877000058],
          [-8.448190000999944, 11.380780001000062],
          [-8.44981427199997, 11.380935452000074],
          [-8.456344, 11.38156],
          [-8.466326308999953, 11.385573516000022],
          [-8.47632, 11.38959],
          [-8.477285349999931, 11.389810080000075],
          [-8.478254, 11.39003],
          [-8.485657222999976, 11.391704627000024],
          [-8.49121, 11.392960001000063],
          [-8.495875332999958, 11.395667522000053],
          [-8.502104, 11.39928],
          [-8.508901, 11.41891],
          [-8.517227, 11.42589],
          [-8.524699362999968, 11.43012557000003],
          [-8.52674, 11.43128],
          [-8.52856000099996, 11.43567],
          [-8.52689000099997, 11.43934],
          [-8.518382144999975, 11.44288649200007],
          [-8.517752, 11.44315],
          [-8.520856991999949, 11.449711869000055],
          [-8.525520000999961, 11.45956],
          [-8.52565, 11.46969],
          [-8.529183324999963, 11.473863323000046],
          [-8.530951, 11.47595],
          [-8.531789382999932, 11.47630973500003],
          [-8.534310000999938, 11.47739],
          [-8.53536, 11.47783000100003],
          [-8.54455, 11.46896],
          [-8.565670000999944, 11.45898],
          [-8.570105961999957, 11.449565188000065],
          [-8.571878, 11.4458],
          [-8.576340000999949, 11.44332],
          [-8.581700000999945, 11.44338],
          [-8.586882206999974, 11.448181962000035],
          [-8.591897, 11.452827],
          [-8.596014, 11.45664],
          [-8.602770605999979, 11.456939850000026],
          [-8.61072, 11.45496],
          [-8.611865979999948, 11.453640024000038],
          [-8.61263, 11.452760001000058],
          [-8.61768226199996, 11.458232174000045],
          [-8.620211, 11.46097],
          [-8.632178, 11.47354],
          [-8.634055048999926, 11.475516123000034],
          [-8.63568, 11.477220001000035],
          [-8.64166, 11.48695],
          [-8.646347094999953, 11.490099729000065],
          [-8.647912, 11.49115],
          [-8.654133, 11.49926],
          [-8.65774, 11.51241],
          [-8.66534, 11.52283],
          [-8.67039000099993, 11.55601],
          [-8.671290800999941, 11.559001589000047],
          [-8.673101, 11.565],
          [-8.685652, 11.57042],
          [-8.688864, 11.5762],
          [-8.689315, 11.57909],
          [-8.690624, 11.58749],
          [-8.682874, 11.61709],
          [-8.682335, 11.62467],
          [-8.685074, 11.63114],
          [-8.68878370899995, 11.633671035000077],
          [-8.691570000999945, 11.63557],
          [-8.702070000999981, 11.63613],
          [-8.719634, 11.62963],
          [-8.730900559999952, 11.63015067300006],
          [-8.733699048999938, 11.630279957000027],
          [-8.76013, 11.606800001000067],
          [-8.762202947999981, 11.608536289000028],
          [-8.77343, 11.61794],
          [-8.775878501999955, 11.618221653000035],
          [-8.779955, 11.61869],
          [-8.785295, 11.62173],
          [-8.791590372999963, 11.622249701000044],
          [-8.79370991199994, 11.620545071000038],
          [-8.795826, 11.61884],
          [-8.79709655299996, 11.615940261000048],
          [-8.797732, 11.61449],
          [-8.804713, 11.61685],
          [-8.808769328999972, 11.619879499000035],
          [-8.81283, 11.62291],
          [-8.82214, 11.62621],
          [-8.823409703999971, 11.62629441100006],
          [-8.854325, 11.62834],
          [-8.85433, 11.628350001000058],
          [-8.852080000999933, 11.63822],
          [-8.846120302999964, 11.664401087000044],
          [-8.84471127699993, 11.670592007000039],
          [-8.844483, 11.671595],
          [-8.844260962999954, 11.672570545000042],
          [-8.823993, 11.76162],
          [-8.822245, 11.7693],
          [-8.810767958999975, 11.834215646000075],
          [-8.80159, 11.886150001000033],
          [-8.79713663299998, 11.91129686000005],
          [-8.794912, 11.92387],
          [-8.795683843999939, 11.926841664000051],
          [-8.797850000999972, 11.93516],
          [-8.80135, 11.93589],
          [-8.80173, 11.945550001000072],
          [-8.807369819999963, 11.95306531600005],
          [-8.814425, 11.96246],
          [-8.816203, 11.9726],
          [-8.80783, 11.99392],
          [-8.806820247999951, 11.998573597000075],
          [-8.80589, 12.002870001000076],
          [-8.80539, 12.00517],
          [-8.812392, 12.01405],
          [-8.83101, 12.03094],
          [-8.832333, 12.039],
          [-8.835360357999946, 12.039949799000055],
          [-8.836723314999972, 12.03918238700004],
          [-8.842180000999974, 12.036110001000054],
          [-8.84591, 12.03683],
          [-8.847665823999932, 12.038689813000076],
          [-8.850303, 12.04148],
          [-8.855900607999956, 12.041989510000064],
          [-8.859118068999976, 12.039388274000032],
          [-8.861795, 12.03722],
          [-8.868448, 12.02601],
          [-8.869980146999978, 12.024644870000031],
          [-8.871512, 12.02328],
          [-8.872445046999928, 12.023460009000075],
          [-8.87525, 12.024],
          [-8.88309, 12.034430001000032],
          [-8.889597662999961, 12.037251819000062],
          [-8.896120702999951, 12.040079872000035],
          [-8.901513905999934, 12.039093371000035],
          [-8.906890000999965, 12.03811],
          [-8.906665664999935, 12.04068122600006],
          [-8.90663, 12.041090001000043],
          [-8.908195280999962, 12.041104908000023],
          [-8.912936, 12.04115],
          [-8.915381, 12.05429],
          [-8.919301, 12.05985],
          [-8.918266909999943, 12.062139462000061],
          [-8.9162, 12.066720001000022],
          [-8.915653211999938, 12.07949949500005],
          [-8.914992, 12.09501],
          [-8.91004998699998, 12.094519454000022],
          [-8.909853325999961, 12.094500324000023],
          [-8.908243915999947, 12.09527160600004],
          [-8.90704, 12.09585],
          [-8.905847096999935, 12.097430047000046],
          [-8.903249, 12.10088],
          [-8.901293, 12.10983],
          [-8.901331, 12.13008],
          [-8.895484, 12.14659],
          [-8.89644008199997, 12.150830360000043],
          [-8.89668, 12.151890001000027],
          [-8.89807090599993, 12.152937761000032],
          [-8.905035, 12.15818],
          [-8.905369985999926, 12.159789930000045],
          [-8.90571, 12.1614],
          [-8.90283, 12.16874],
          [-8.910035, 12.17317],
          [-8.912307815999952, 12.179970113000024],
          [-8.914129826999954, 12.185409481000022],
          [-8.92209, 12.18433],
          [-8.922628304999932, 12.184921288000055],
          [-8.924632, 12.18711],
          [-8.92470235899998, 12.18822949500003],
          [-8.92484, 12.190340001000038],
          [-8.923506514999929, 12.193395906000035],
          [-8.922444, 12.19584],
          [-8.924521503999927, 12.198619336000036],
          [-8.92496402699993, 12.198423786000035],
          [-8.930627, 12.19591],
          [-8.936441270999978, 12.19895856900007],
          [-8.93928, 12.19576],
          [-8.939114730999961, 12.193550195000057],
          [-8.938660598999945, 12.187477992000026],
          [-8.94101, 12.18566],
          [-8.945694, 12.18524],
          [-8.95267086299998, 12.189219922000063],
          [-8.95501, 12.189010001000042],
          [-8.956779317999974, 12.187415616000067],
          [-8.958550000999935, 12.18582],
          [-8.95962549099994, 12.186280638000028],
          [-8.963924, 12.18812],
          [-8.964601160999962, 12.188409215000036],
          [-8.97331, 12.18251],
          [-8.978591, 12.19291],
          [-8.977143, 12.19772],
          [-8.978654990999928, 12.20092465600004],
          [-8.97944, 12.202580001000058],
          [-8.978263990999949, 12.209964058000025],
          [-8.977971, 12.21181],
          [-8.992901, 12.22144],
          [-8.993359898999927, 12.23639670800003],
          [-8.99382, 12.25136],
          [-8.990418589999933, 12.266056097000046],
          [-8.987021, 12.28074],
          [-8.985644087999958, 12.28284551400003],
          [-8.98289, 12.28706],
          [-8.982266179999954, 12.288007726000046],
          [-8.981333, 12.28943],
          [-8.967434, 12.30036],
          [-8.963372718999949, 12.306240200000047],
          [-8.96033, 12.31065],
          [-8.959440092999955, 12.31401631500006],
          [-8.957666, 12.32075],
          [-8.960145, 12.33204],
          [-8.96639761199998, 12.343762308000066],
          [-8.9689, 12.34845],
          [-8.972017600999948, 12.351418916000057],
          [-8.9884, 12.36701],
          [-8.990434171999937, 12.368093591000047],
          [-8.992360000999952, 12.36911],
          [-8.998028199999965, 12.375175769000066],
          [-9.002337, 12.37978],
          [-9.008170475999975, 12.38375988200005],
          [-9.00867, 12.384100001000036],
          [-9.009104013999945, 12.384642768000049],
          [-9.013021, 12.38953],
          [-9.025, 12.41287],
          [-9.03428, 12.423070001000042],
          [-9.04571, 12.42845],
          [-9.06579387499994, 12.433789701000023],
          [-9.085885, 12.43913],
          [-9.088585623999961, 12.441176833000043],
          [-9.09356, 12.44494],
          [-9.10383, 12.449400001000072],
          [-9.10667, 12.4545],
          [-9.112330000999975, 12.458420001000036],
          [-9.114191916999971, 12.458589266000047],
          [-9.117942, 12.45893],
          [-9.124934, 12.46221],
          [-9.130404939999949, 12.46558996300007],
          [-9.13588, 12.46897],
          [-9.14679, 12.47919],
          [-9.15446, 12.48408],
          [-9.157881957999962, 12.485089611000035],
          [-9.158293, 12.48521],
          [-9.16034144699995, 12.485815837000075],
          [-9.16171, 12.48622],
          [-9.1715151399999, 12.486644141000056],
          [-9.181360106999932, 12.487069918000032],
          [-9.182541, 12.48616],
          [-9.183940345999929, 12.486516951000056],
          [-9.188149287999977, 12.48758981900005],
          [-9.194697882999947, 12.487070565000067],
          [-9.201263, 12.48655],
          [-9.20631898399995, 12.487780240000063],
          [-9.20969, 12.488600001000066],
          [-9.21108000099997, 12.48893],
          [-9.220103188999929, 12.49374778400005],
          [-9.225522496999929, 12.496639732000062],
          [-9.235364, 12.49558],
          [-9.245172, 12.49842],
          [-9.249122, 12.5019],
          [-9.251924155999973, 12.50238623100006],
          [-9.25332934599993, 12.502629886000022],
          [-9.274455, 12.49567],
          [-9.27831615599996, 12.488651421000043],
          [-9.280630000999963, 12.48444],
          [-9.284165, 12.48194],
          [-9.28861, 12.48221],
          [-9.294211, 12.48432],
          [-9.31258, 12.49942],
          [-9.319590079999955, 12.500629497000034],
          [-9.320209907999981, 12.496725584000046],
          [-9.320827, 12.49282],
          [-9.325356, 12.48273],
          [-9.330076, 12.47794],
          [-9.333289555999954, 12.477306084000077],
          [-9.335703, 12.47683],
          [-9.338946, 12.481],
          [-9.339552521999963, 12.49296057600003],
          [-9.343759341999942, 12.493919851000044],
          [-9.34893, 12.49166000100007],
          [-9.35546067699994, 12.49366367400006],
          [-9.36200039399995, 12.495669201000055],
          [-9.364140000999953, 12.49132],
          [-9.3666, 12.476390001000027],
          [-9.36967, 12.47342],
          [-9.385128, 12.47216],
          [-9.389529078999942, 12.467900892000046],
          [-9.39173, 12.46577],
          [-9.39386, 12.46534],
          [-9.39792, 12.46451],
          [-9.401578, 12.46377],
          [-9.404638, 12.4615],
          [-9.405369936999932, 12.457710328000076],
          [-9.4061, 12.45392],
          [-9.4036, 12.44447000100007],
          [-9.402274901999931, 12.444229680000035],
          [-9.399629621999964, 12.44375029400004],
          [-9.39233, 12.449440001000028],
          [-9.388622375999944, 12.449583182000026],
          [-9.387411, 12.44963],
          [-9.386654498999974, 12.449049616000025],
          [-9.384387, 12.44731],
          [-9.381789847999926, 12.440236252000034],
          [-9.376590000999954, 12.426090001000034],
          [-9.371557502999963, 12.41966250300004],
          [-9.369878, 12.41752],
          [-9.36777959799997, 12.416590265000025],
          [-9.365581540999926, 12.418031176000056],
          [-9.361192, 12.42091],
          [-9.359438902999955, 12.42054977500004],
          [-9.357688, 12.42019],
          [-9.35584, 12.41718],
          [-9.355876, 12.41258],
          [-9.358276, 12.40501],
          [-9.347988, 12.40401],
          [-9.345101923999948, 12.402053949000049],
          [-9.34077, 12.39912],
          [-9.33708, 12.39265000100005],
          [-9.33622, 12.382520001000046],
          [-9.33416000099993, 12.376990001000024],
          [-9.329517882999937, 12.37273125400003],
          [-9.328589, 12.37188],
          [-9.32789084899997, 12.37153042500006],
          [-9.324390000999927, 12.369780001000038],
          [-9.314581577999945, 12.368090272000074],
          [-9.313876290999929, 12.368543575000047],
          [-9.31247, 12.36945],
          [-9.30036, 12.36199],
          [-9.29758, 12.35852],
          [-9.29691, 12.3546],
          [-9.299269, 12.35232],
          [-9.301966006999976, 12.351654752000059],
          [-9.30466, 12.35099000100007],
          [-9.307020000999955, 12.34825],
          [-9.30752, 12.34457],
          [-9.30408, 12.335570001000065],
          [-9.30425999199997, 12.333116781000058],
          [-9.304348, 12.33189],
          [-9.30863, 12.32318],
          [-9.31288, 12.31862],
          [-9.3166, 12.32118],
          [-9.32012, 12.31983],
          [-9.322485, 12.31662],
          [-9.323246203999929, 12.312888980000025],
          [-9.3237, 12.31065],
          [-9.32004731099994, 12.285433692000026],
          [-9.318829, 12.27703],
          [-9.319798, 12.27289],
          [-9.323853122999935, 12.268964914000037],
          [-9.32993000099998, 12.26308],
          [-9.34186, 12.26294000100006],
          [-9.344518495999978, 12.25215610400005],
          [-9.340349, 12.24752],
          [-9.340389844999947, 12.242937880000056],
          [-9.34039, 12.24292],
          [-9.340880006999953, 12.243048930000043],
          [-9.349626390999958, 12.245350270000074],
          [-9.353922, 12.24648],
          [-9.359530280999934, 12.246749918000035],
          [-9.361970934999931, 12.246033726000064],
          [-9.36563, 12.244960001000038],
          [-9.381896069999925, 12.249397564000049],
          [-9.387331, 12.25088],
          [-9.388120533999938, 12.251668534000032],
          [-9.39127863699997, 12.254818640000053],
          [-9.40138, 12.24961],
          [-9.40558, 12.250330001000066],
          [-9.41135000099996, 12.259340001000055],
          [-9.414413865999961, 12.26023011500007],
          [-9.414621145999945, 12.260289754000041],
          [-9.42493, 12.25807],
          [-9.441588, 12.25106],
          [-9.446878753999954, 12.250682709000046],
          [-9.457430000999977, 12.249930001000052],
          [-9.46195, 12.2496],
          [-9.473903, 12.24578],
          [-9.47928, 12.245819993000055],
          [-9.487960000999976, 12.2422],
          [-9.489768688999959, 12.240293921000045],
          [-9.490555, 12.23946],
          [-9.499567, 12.22251],
          [-9.500789019999957, 12.22127587700004],
          [-9.50522, 12.21679],
          [-9.526942732999942, 12.205062446000056],
          [-9.529168, 12.20386],
          [-9.54141, 12.19268],
          [-9.543202486999974, 12.192920334000064],
          [-9.546780361999936, 12.19339978100004],
          [-9.548190467999973, 12.192542615000036],
          [-9.553130000999943, 12.189540001000069],
          [-9.55963720699998, 12.19013863300006],
          [-9.561502, 12.19031],
          [-9.566824019999956, 12.19079703500006],
          [-9.569479200999979, 12.191039927000077],
          [-9.571648495999966, 12.190249638000068],
          [-9.578150000999926, 12.187880001000053],
          [-9.580426923999937, 12.187722972000074],
          [-9.584235, 12.18746],
          [-9.587171051999974, 12.18561012500004],
          [-9.58893, 12.1845],
          [-9.59505, 12.18358],
          [-9.596915019999926, 12.183299747000035],
          [-9.600636, 12.18274],
          [-9.602515338999979, 12.181424597000046],
          [-9.60486, 12.17978],
          [-9.61188, 12.1796],
          [-9.619154, 12.17597],
          [-9.622429605999969, 12.175645039000074],
          [-9.6257, 12.17532],
          [-9.6304, 12.17282],
          [-9.636990000999958, 12.1662],
          [-9.6396, 12.16138],
          [-9.66405, 12.14085],
          [-9.669228, 12.13435],
          [-9.671352653999975, 12.131684808000045],
          [-9.677720000999955, 12.12369],
          [-9.679410976999975, 12.119686203000072],
          [-9.680815, 12.11635],
          [-9.68046, 12.09955],
          [-9.677827, 12.0881],
          [-9.676546133999977, 12.082532581000066],
          [-9.67569, 12.07882],
          [-9.67974, 12.06665],
          [-9.68421000099994, 12.063],
          [-9.69217, 12.06121],
          [-9.695935960999975, 12.057553922000068],
          [-9.697183468999981, 12.053685950000045],
          [-9.69808, 12.0509],
          [-9.69772, 12.03525],
          [-9.69976, 12.02382],
          [-9.711058710999964, 12.02467323600007],
          [-9.733664, 12.02638],
          [-9.747891396999933, 12.029021704000058],
          [-9.765658129999963, 12.032319654000048],
          [-9.76963, 12.03188],
          [-9.790622, 12.04051],
          [-9.798347673999956, 12.046993727000029],
          [-9.809940000999973, 12.05672],
          [-9.816010000999938, 12.05767],
          [-9.819101836999948, 12.056100299000036],
          [-9.819259, 12.05602],
          [-9.81962886599996, 12.055831657000056],
          [-9.82539000099996, 12.05289],
          [-9.86207640899994, 12.058608483000057],
          [-9.863691070999948, 12.058859700000028],
          [-9.87190743399998, 12.05655580900003],
          [-9.876602, 12.05524],
          [-9.88009444599993, 12.057329669000069],
          [-9.882901, 12.05712],
          [-9.884916468999961, 12.058743174000028],
          [-9.88895, 12.06199],
          [-9.90823, 12.08165],
          [-9.908800950999932, 12.083199473000036],
          [-9.911481, 12.09046],
          [-9.914441, 12.09848],
          [-9.919801943999971, 12.100819540000032],
          [-9.93689, 12.09677],
          [-9.941794, 12.09749],
          [-9.946262561999959, 12.100922432000061],
          [-9.952969202999952, 12.106069389000027],
          [-9.958578, 12.10541],
          [-9.963455859999954, 12.102586082000073],
          [-9.970773, 12.09835],
          [-9.972736100999953, 12.100392976000023],
          [-9.981, 12.10898],
          [-9.98245979099994, 12.112395625000033],
          [-9.983063, 12.1138],
          [-9.986161889999948, 12.11560372300005],
          [-9.99030000099998, 12.11801],
          [-9.99578, 12.12483],
          [-10, 12.12723],
          [-10.0017, 12.12819],
          [-10.02437, 12.12878],
          [-10.03043, 12.1318],
          [-10.03926, 12.14174],
          [-10.0451, 12.14269],
          [-10.05282, 12.14043],
          [-10.05652, 12.14782],
          [-10.05932, 12.14967],
          [-10.0619, 12.14807],
          [-10.06335, 12.13865],
          [-10.06709, 12.13913],
          [-10.07243, 12.14652],
          [-10.07663, 12.14723],
          [-10.07812, 12.15119],
          [-10.07871, 12.15276],
          [-10.0813, 12.16942],
          [-10.08165, 12.17164],
          [-10.08443, 12.17649],
          [-10.086779, 12.177659],
          [-10.112351, 12.152322],
          [-10.141996, 12.139816],
          [-10.165961, 12.128603],
          [-10.191436, 12.114068],
          [-10.210949, 12.096915],
          [-10.221271, 12.07618],
          [-10.209776, 12.054043],
          [-10.190793, 12.02981],
          [-10.193634, 12.017086],
          [-10.185106, 11.989484],
          [-10.178749, 11.966607],
          [-10.175815, 11.9559],
          [-10.166926, 11.941128],
          [-10.159502, 11.932193],
          [-10.168027, 11.920922],
          [-10.193026, 11.907222],
          [-10.18401, 11.882915],
          [-10.182375, 11.869051],
          [-10.198198, 11.851731],
          [-10.211823, 11.842237],
          [-10.210697, 11.829535],
          [-10.211035, 11.817564],
          [-10.223241, 11.804258],
          [-10.236478, 11.789719],
          [-10.246227, 11.774698],
          [-10.244966, 11.758185],
          [-10.224516, 11.758511],
          [-10.219256, 11.751379],
          [-10.22301, 11.739203],
          [-10.231825, 11.728496],
          [-10.226624, 11.715451],
          [-10.228137, 11.703812],
          [-10.238819, 11.693982],
          [-10.243769, 11.695088],
          [-10.253265, 11.688229],
          [-10.257658, 11.681533],
          [-10.256078, 11.67184],
          [-10.257479, 11.660198],
          [-10.260555, 11.652227],
          [-10.258657, 11.639522],
          [-10.260308, 11.619936],
          [-10.265643, 11.605987],
          [-10.265067, 11.593213],
          [-10.265526, 11.588353],
          [-10.261721, 11.584965],
          [-10.247077, 11.57682],
          [-10.239319, 11.57146],
          [-10.233039, 11.563599],
          [-10.224299, 11.546406],
          [-10.2219, 11.540954],
          [-10.212815, 11.53758],
          [-10.193201, 11.534242],
          [-10.17218, 11.53463],
          [-10.146734, 11.522302],
          [-10.139891, 11.509393],
          [-10.13039, 11.500936],
          [-10.121258, 11.500499],
          [-10.109988, 11.502704],
          [-10.099509, 11.499793],
          [-10.085701, 11.494982],
          [-10.079288, 11.493574],
          [-10.061983, 11.491904],
          [-10.055494, 11.491041],
          [-10.051756994999948, 11.486723010000048],
          [-10.04856, 11.483029],
          [-10.031232, 11.478379],
          [-10.027762, 11.475063],
          [-10.020935, 11.468138],
          [-10.009626, 11.462862],
          [-10.011359, 11.45311],
          [-10.011427, 11.446505],
          [-10.010726, 11.44321],
          [-10.010794, 11.436605],
          [-10.007999, 11.422114],
          [-10.006326, 11.41144],
          [-10.004753, 11.406959],
          [-10.010213, 11.407893],
          [-10.013945, 11.40947],
          [-10.015497, 11.410562],
          [-10.019793, 11.413595],
          [-10.023209, 11.420398],
          [-10.029092, 11.417158],
          [-10.030511, 11.411648],
          [-10.030967, 11.407949],
          [-10.041887, 11.401757],
          [-10.048582, 11.397034],
          [-10.048735, 11.387528],
          [-10.050072, 11.375668],
          [-10.057274, 11.364271],
          [-10.070984, 11.356528],
          [-10.077703, 11.354308],
          [-10.081973, 11.358789],
          [-10.076959, 11.365045],
          [-10.068961, 11.374409],
          [-10.067822, 11.383221],
          [-10.064116, 11.391805],
          [-10.063362, 11.3967],
          [-10.067989, 11.395123],
          [-10.072639, 11.388177],
          [-10.085243, 11.382208],
          [-10.103262, 11.378547],
          [-10.115481, 11.384911],
          [-10.12103, 11.390739],
          [-10.131612, 11.376997],
          [-10.135643, 11.368777],
          [-10.124081, 11.35436],
          [-10.127067, 11.350563],
          [-10.137884, 11.350352],
          [-10.152217, 11.350737],
          [-10.164782, 11.35304],
          [-10.16911, 11.361437],
          [-10.163494, 11.371938],
          [-10.152486, 11.382922],
          [-10.155239, 11.391025],
          [-10.170653, 11.387422],
          [-10.182188, 11.38199],
          [-10.189843, 11.384087],
          [-10.193313, 11.396148],
          [-10.195039, 11.414224],
          [-10.203033, 11.404893],
          [-10.217568, 11.401067],
          [-10.22213, 11.416142],
          [-10.23314, 11.423041],
          [-10.239263, 11.422956],
          [-10.243915, 11.424388],
          [-10.248531, 11.42553],
          [-10.254809, 11.423738],
          [-10.243708, 11.411544],
          [-10.247214, 11.405715],
          [-10.255586, 11.402408],
          [-10.266724, 11.395156],
          [-10.274372, 11.399321],
          [-10.288983, 11.414102],
          [-10.294129, 11.410747],
          [-10.296724, 11.403029],
          [-10.301185, 11.386393],
          [-10.300256, 11.379784],
          [-10.320381, 11.37035],
          [-10.333165, 11.373551],
          [-10.331524, 11.381564],
          [-10.322949, 11.390459],
          [-10.317323, 11.405024],
          [-10.318795, 11.424149],
          [-10.321229, 11.430617],
          [-10.325213, 11.423773],
          [-10.327858, 11.412245],
          [-10.329144, 11.401038],
          [-10.335841, 11.394531],
          [-10.345914, 11.407915],
          [-10.350002, 11.403432],
          [-10.351618, 11.401659],
          [-10.352897, 11.392012],
          [-10.3544, 11.381751],
          [-10.349405, 11.373643],
          [-10.345026, 11.368221],
          [-10.352111, 11.35504],
          [-10.361006, 11.348068],
          [-10.365835, 11.340282],
          [-10.371622, 11.330907],
          [-10.380228, 11.322627],
          [-10.390649, 11.319431],
          [-10.410721, 11.312785],
          [-10.445804, 11.310573],
          [-10.468367, 11.303205],
          [-10.475761, 11.29452],
          [-10.47628, 11.285492],
          [-10.472064, 11.279638],
          [-10.468238, 11.274326],
          [-10.464619, 11.271268],
          [-10.443231, 11.255601],
          [-10.512555, 11.197074],
          [-10.537279, 11.186371],
          [-10.545046, 11.186211],
          [-10.568904, 11.183158],
          [-10.589711, 11.183721],
          [-10.609808, 11.189034],
          [-10.626032, 11.191327],
          [-10.638359, 11.198249],
          [-10.644987, 11.199209],
          [-10.641307, 11.191474],
          [-10.633866, 11.179121],
          [-10.645252, 11.181796],
          [-10.652792, 11.184317],
          [-10.663143, 11.183478],
          [-10.6593, 11.174793],
          [-10.666079, 11.173542],
          [-10.66588, 11.165378],
          [-10.665143, 11.154693],
          [-10.677109, 11.157498],
          [-10.683985, 11.161539],
          [-10.687754, 11.16274],
          [-10.68984, 11.163405],
          [-10.690035, 11.158725],
          [-10.699379, 11.157476],
          [-10.703451, 11.154657],
          [-10.715937, 11.140283],
          [-10.720925, 11.144409],
          [-10.720635, 11.13326],
          [-10.725499, 11.129346],
          [-10.722473, 11.11564],
          [-10.716854, 11.102797],
          [-10.714482, 11.093105],
          [-10.715547, 11.077291],
          [-10.71814, 11.058776],
          [-10.727412, 11.055127],
          [-10.737254, 11.05058],
          [-10.746317, 11.030367],
          [-10.758024, 11.024396],
          [-10.773311, 11.014308],
          [-10.789956, 11.000785],
          [-10.791086, 10.999675],
          [-10.792762, 10.99552],
          [-10.795371, 10.992257],
          [-10.794014, 10.987701],
          [-10.798968, 10.982488],
          [-10.799471, 10.977903],
          [-10.798816, 10.97164],
          [-10.799423, 10.967785],
          [-10.798866, 10.963414],
          [-10.797178, 10.959292],
          [-10.798039, 10.955658],
          [-10.80244, 10.951387],
          [-10.805776, 10.948457],
          [-10.80984, 10.945749],
          [-10.811838, 10.940991],
          [-10.81474, 10.937367],
          [-10.817568, 10.933999],
          [-10.816813, 10.932211],
          [-10.815442, 10.929912],
          [-10.820082, 10.928116],
          [-10.823513, 10.927515],
          [-10.826654, 10.926441],
          [-10.832103, 10.923594],
          [-10.83678, 10.921944],
          [-10.841907, 10.917967],
          [-10.842765, 10.914768],
          [-10.848968, 10.913745],
          [-10.851276, 10.911937],
          [-10.856031, 10.909123],
          [-10.859056, 10.909139],
          [-10.86117, 10.909404],
          [-10.864417, 10.90884],
          [-10.868727, 10.907297],
          [-10.872944, 10.903241],
          [-10.875116, 10.899578],
          [-10.876076, 10.897362],
          [-10.877654, 10.895732],
          [-10.882322, 10.895355],
          [-10.885346, 10.895481],
          [-10.890019, 10.894302],
          [-10.892172, 10.894022],
          [-10.895941, 10.891348],
          [-10.899495, 10.888308],
          [-10.902899, 10.885962],
          [-10.906486, 10.883103],
          [-10.90851, 10.879839],
          [-10.908789, 10.875836],
          [-10.906366, 10.872622],
          [-10.904161, 10.8693],
          [-10.900093, 10.867059],
          [-10.897558, 10.864208],
          [-10.893483, 10.863132],
          [-10.887992, 10.861103],
          [-10.883112, 10.860351],
          [-10.879514, 10.858513],
          [-10.87548, 10.856709],
          [-10.870496, 10.855372],
          [-10.8676, 10.853171],
          [-10.864787, 10.853049],
          [-10.861229, 10.850813],
          [-10.85891, 10.84847],
          [-10.855528, 10.847362],
          [-10.852299, 10.84476],
          [-10.848521, 10.842884],
          [-10.845612, 10.841779],
          [-10.843661, 10.838893],
          [-10.839051, 10.836029],
          [-10.83528, 10.832881],
          [-10.832851, 10.830685],
          [-10.830193, 10.830305],
          [-10.827799, 10.828545],
          [-10.827253, 10.828299],
          [-10.825836, 10.827661],
          [-10.822348, 10.825932],
          [-10.81969, 10.8257],
          [-10.816134, 10.823172],
          [-10.812902, 10.82126],
          [-10.80887, 10.819055],
          [-10.806734, 10.816934],
          [-10.802105, 10.81709],
          [-10.79946, 10.814528],
          [-10.793241, 10.812529],
          [-10.79026, 10.811384],
          [-10.78952, 10.811212],
          [-10.779343, 10.80885],
          [-10.776468, 10.808363],
          [-10.773455, 10.80649],
          [-10.769231, 10.805956],
          [-10.766689, 10.804561],
          [-10.763201, 10.802756],
          [-10.758831, 10.802514],
          [-10.754826, 10.801872],
          [-10.751991, 10.80073],
          [-10.747514, 10.799905],
          [-10.745262, 10.7988],
          [-10.740684, 10.796591],
          [-10.738795, 10.795743],
          [-10.73637, 10.793182],
          [-10.732803, 10.792615],
          [-10.729979, 10.789651],
          [-10.726342, 10.788539],
          [-10.722245, 10.785386],
          [-10.718974, 10.784094],
          [-10.716141, 10.782622],
          [-10.71342, 10.78086],
          [-10.709066, 10.777996],
          [-10.706353, 10.774923],
          [-10.703708, 10.772614],
          [-10.701246, 10.770234],
          [-10.698093, 10.767631],
          [-10.694863, 10.765576],
          [-10.692514, 10.76265],
          [-10.689511, 10.759431],
          [-10.686805, 10.755519],
          [-10.685657, 10.752564],
          [-10.6828, 10.74942],
          [-10.678812, 10.746447],
          [-10.678104, 10.743349],
          [-10.674405, 10.740744],
          [-10.673228, 10.736987],
          [-10.668939, 10.735324],
          [-10.642289, 10.739657],
          [-10.618356, 10.744605],
          [-10.602421, 10.744049],
          [-10.586504, 10.740118],
          [-10.582502, 10.743353],
          [-10.568017, 10.751523],
          [-10.554046, 10.756337],
          [-10.531613, 10.760374],
          [-10.511689, 10.760641],
          [-10.509094, 10.751011],
          [-10.500937, 10.72074],
          [-10.495061, 10.701253],
          [-10.487183, 10.683625],
          [-10.47434, 10.662931],
          [-10.461499, 10.641756],
          [-10.455165, 10.614541],
          [-10.450327, 10.586896],
          [-10.448437, 10.567067],
          [-10.447493, 10.556912],
          [-10.467524, 10.545494],
          [-10.467392, 10.535978],
          [-10.467331, 10.531563],
          [-10.457825, 10.529751],
          [-10.451545, 10.526708],
          [-10.443691, 10.524135],
          [-10.438326, 10.52113],
          [-10.4314, 10.515568],
          [-10.422769, 10.513245],
          [-10.420583, 10.512657],
          [-10.419003, 10.511367],
          [-10.414233, 10.512075],
          [-10.407799, 10.528161],
          [-10.404105, 10.542896],
          [-10.401124, 10.547027],
          [-10.392253, 10.538285],
          [-10.385791, 10.527908],
          [-10.37721, 10.539827],
          [-10.363574, 10.537318],
          [-10.352082, 10.531481],
          [-10.352932, 10.518602],
          [-10.354327, 10.512033],
          [-10.345239, 10.51072],
          [-10.337253, 10.510051],
          [-10.334998, 10.520304],
          [-10.332073, 10.528579],
          [-10.322202, 10.531814],
          [-10.310446, 10.538883],
          [-10.302572, 10.546215],
          [-10.295072, 10.555314],
          [-10.292522, 10.550606],
          [-10.291856, 10.534097],
          [-10.292883, 10.527059],
          [-10.27751, 10.521096],
          [-10.253515, 10.51908],
          [-10.237845, 10.519789],
          [-10.221037, 10.527606],
          [-10.212892, 10.524626],
          [-10.219405, 10.515465],
          [-10.2337, 10.510513],
          [-10.232228, 10.501363],
          [-10.237094, 10.484747],
          [-10.22591, 10.489142],
          [-10.214173, 10.48503],
          [-10.202319, 10.463106],
          [-10.193559, 10.445022],
          [-10.18713, 10.448089],
          [-10.195246, 10.461526],
          [-10.187295, 10.468243],
          [-10.180617, 10.473329],
          [-10.180024, 10.487934],
          [-10.175376, 10.488358],
          [-10.167257, 10.482565],
          [-10.153508, 10.495158],
          [-10.148722, 10.514114],
          [-10.145898, 10.529528],
          [-10.145419, 10.537064],
          [-10.138199, 10.536062],
          [-10.137974, 10.526723],
          [-10.135527, 10.521401],
          [-10.132022, 10.513779],
          [-10.133125, 10.506488],
          [-10.139499, 10.498518],
          [-10.124951, 10.496184],
          [-10.109886, 10.492738],
          [-10.115997, 10.484381],
          [-10.118623, 10.48079],
          [-10.116618, 10.466018],
          [-10.116941, 10.456064],
          [-10.123337, 10.453216],
          [-10.132591, 10.459652],
          [-10.140076, 10.463432],
          [-10.145859, 10.462713],
          [-10.150614, 10.461893],
          [-10.154123, 10.45203],
          [-10.152688, 10.442158],
          [-10.149765, 10.435071],
          [-10.145861, 10.421644],
          [-10.129367, 10.425451],
          [-10.128519, 10.423246],
          [-10.125219, 10.414659],
          [-10.135085, 10.401839],
          [-10.145808, 10.401163],
          [-10.152938, 10.407434],
          [-10.151802, 10.390782],
          [-10.161434, 10.375256],
          [-10.153595, 10.36564],
          [-10.157058, 10.354117],
          [-10.169275, 10.352353],
          [-10.166919, 10.340936],
          [-10.179776, 10.327016],
          [-10.195795, 10.324578],
          [-10.221473, 10.337733],
          [-10.239447, 10.338562],
          [-10.241262, 10.327518],
          [-10.232856, 10.321799],
          [-10.226878, 10.319384],
          [-10.22571, 10.303886],
          [-10.236491, 10.29286],
          [-10.235956, 10.288106],
          [-10.225697, 10.293213],
          [-10.226834, 10.28578],
          [-10.236794, 10.255649],
          [-10.227954, 10.242793],
          [-10.216171, 10.226204],
          [-10.201884, 10.20617],
          [-10.211448, 10.193205],
          [-10.220493, 10.179196],
          [-10.237341, 10.175491],
          [-10.228554, 10.158238],
          [-10.241165, 10.156142],
          [-10.254984, 10.154582],
          [-10.259376, 10.147274],
          [-10.253231, 10.139305],
          [-10.265748, 10.125313],
          [-10.28037, 10.123638],
          [-10.285574, 10.110518],
          [-10.292202, 10.104601],
          [-10.305139, 10.095111],
          [-10.323269, 10.100806],
          [-10.325471, 10.090872],
          [-10.325774, 10.089504],
          [-10.329524, 10.078449],
          [-10.320761, 10.058456],
          [-10.327377, 10.042767],
          [-10.349704, 10.020272],
          [-10.353203, 10.01489],
          [-10.361583, 10.001992],
          [-10.360433, 9.992888],
          [-10.359644, 9.986645],
          [-10.352499, 9.975728],
          [-10.34604, 9.962825],
          [-10.343442, 9.947228],
          [-10.337538, 9.928228],
          [-10.334315, 9.91159],
          [-10.327657, 9.8949],
          [-10.335363, 9.886055],
          [-10.341554, 9.881692],
          [-10.340385, 9.874089],
          [-10.350607, 9.864149],
          [-10.361077, 9.863731],
          [-10.368863, 9.864414],
          [-10.372063010999966, 9.854566],
          [-10.372696, 9.852618],
          [-10.372589, 9.838125],
          [-10.375118, 9.817268],
          [-10.368922, 9.805481],
          [-10.366805, 9.782994],
          [-10.387735, 9.774826],
          [-10.393401, 9.760512],
          [-10.404895, 9.757664],
          [-10.414239, 9.734135],
          [-10.411838, 9.72124],
          [-10.409755, 9.698576],
          [-10.403166, 9.695696],
          [-10.40369, 9.686302],
          [-10.404442, 9.67283],
          [-10.403403, 9.654628],
          [-10.405852, 9.640081],
          [-10.411667, 9.626779],
          [-10.425363, 9.612595],
          [-10.428198, 9.594909],
          [-10.421201, 9.583091],
          [-10.418876, 9.561766],
          [-10.415897, 9.563313],
          [-10.404165, 9.569407],
          [-10.396954, 9.567346],
          [-10.383373, 9.563364],
          [-10.373633, 9.552198],
          [-10.369094, 9.547032],
          [-10.342741, 9.542492],
          [-10.333079, 9.545239],
          [-10.325503, 9.547147],
          [-10.307861, 9.545611],
          [-10.294868, 9.54363],
          [-10.281655, 9.546202],
          [-10.270968, 9.555836],
          [-10.265478, 9.559889],
          [-10.2523, 9.566285],
          [-10.241868, 9.57162],
          [-10.239021, 9.576277],
          [-10.236853, 9.579824],
          [-10.225797, 9.590115],
          [-10.221073, 9.600068],
          [-10.22023, 9.609908],
          [-10.216348, 9.615742],
          [-10.201153, 9.626147],
          [-10.190789, 9.640335],
          [-10.179327, 9.653795],
          [-10.173428, 9.671],
          [-10.172107, 9.682624],
          [-10.160642, 9.699254],
          [-10.144857, 9.700256],
          [-10.133033, 9.691092],
          [-10.125931, 9.685196],
          [-10.118243, 9.681127],
          [-10.107299, 9.671263],
          [-10.104482, 9.665729],
          [-10.099509, 9.650505],
          [-10.099004, 9.634766],
          [-10.09392, 9.624313],
          [-10.079791, 9.616094],
          [-10.066249, 9.610024],
          [-10.044165, 9.622799],
          [-10.025551, 9.644676],
          [-10.00478, 9.658432],
          [-9.975295, 9.668038],
          [-9.951461, 9.663136],
          [-9.927814, 9.65482],
          [-9.911933, 9.644249],
          [-9.901763, 9.635365],
          [-9.894116, 9.629501],
          [-9.879618, 9.629509],
          [-9.871655, 9.612496],
          [-9.883315, 9.597333],
          [-9.885334, 9.592559],
          [-9.869864, 9.578172],
          [-9.859305, 9.560577],
          [-9.871204, 9.558969],
          [-9.865174, 9.555807],
          [-9.850937, 9.548338],
          [-9.842532, 9.536973],
          [-9.829697, 9.526555],
          [-9.812027, 9.518689],
          [-9.801637, 9.515631],
          [-9.780892, 9.508603],
          [-9.761246, 9.502482],
          [-9.735636, 9.495306],
          [-9.707067, 9.48441],
          [-9.673453, 9.471763],
          [-9.651335, 9.458421],
          [-9.640428, 9.43936],
          [-9.626435, 9.426747],
          [-9.624967, 9.409985],
          [-9.622718, 9.399673],
          [-9.616882, 9.389977],
          [-9.606977, 9.379303],
          [-9.606746, 9.378369],
          [-9.597887, 9.355607],
          [-9.604903, 9.341324],
          [-9.616403, 9.33528],
          [-9.631456, 9.326393],
          [-9.640326, 9.317468],
          [-9.646601, 9.307849],
          [-9.649722, 9.301109],
          [-9.652036, 9.296627],
          [-9.648987, 9.283909],
          [-9.649629, 9.253117],
          [-9.660368, 9.241604],
          [-9.681066, 9.244161],
          [-9.709568, 9.237896],
          [-9.722632, 9.233634],
          [-9.730322, 9.22784],
          [-9.739653, 9.225399],
          [-9.741689, 9.209876],
          [-9.746353, 9.197779],
          [-9.738394, 9.189433],
          [-9.735144, 9.186846],
          [-9.725069, 9.175986],
          [-9.720408, 9.164143],
          [-9.721239, 9.157484],
          [-9.722595, 9.146616],
          [-9.71874, 9.134736],
          [-9.699553, 9.129414],
          [-9.685699, 9.126897],
          [-9.670935, 9.123067],
          [-9.66071, 9.115885],
          [-9.660585, 9.10397],
          [-9.662203, 9.101769],
          [-9.665462, 9.097339],
          [-9.675747, 9.090891],
          [-9.688551, 9.086594],
          [-9.706618, 9.081968],
          [-9.719063, 9.073735],
          [-9.719405, 9.065536],
          [-9.715658, 9.05515],
          [-9.70782, 9.043015],
          [-9.703587, 9.038787],
          [-9.697972, 9.030368],
          [-9.679152, 9.027959],
          [-9.658547, 9.021904],
          [-9.640548, 9.010784],
          [-9.628814, 8.992269],
          [-9.62728, 8.97361],
          [-9.634011, 8.951747],
          [-9.645213, 8.941803],
          [-9.654939, 8.936408],
          [-9.648911, 8.927413],
          [-9.62014, 8.891243],
          [-9.613681, 8.875572],
          [-9.613443, 8.874721]
        ]
      ]
    },
    properties: {
      name: 'Kankan',
      center: [-9.310755448769752, 10.377681960879158],
      shapeName: 'Kankan',
      shapeISO: '',
      shapeID: '26780975B92486014513914',
      shapeGroup: 'GIN',
      shapeType: 'ADM1'
    }
  },
  {
    type: 'Feature',
    geometry: {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [-13.320146560999945, 9.147760391000077],
            [-13.320624350999935, 9.150055885000029],
            [-13.324099541999942, 9.151412010000058],
            [-13.318594933999975, 9.157881736000036],
            [-13.319768904999933, 9.160632134000025],
            [-13.32322692799994, 9.15900135000004],
            [-13.326887131999968, 9.153002739000044],
            [-13.325686454999982, 9.146346094000023],
            [-13.320146560999945, 9.147760391000077]
          ]
        ],
        [
          [
            [-13.42019939499994, 9.270743371000037],
            [-13.421375275999935, 9.273493766000058],
            [-13.43042087699996, 9.276880264000056],
            [-13.43411922499996, 9.276394845000027],
            [-13.435683249999954, 9.26856994600007],
            [-13.434743882999953, 9.266508103000035],
            [-13.42988586599995, 9.266540528000064],
            [-13.428024291999975, 9.264945031000025],
            [-13.42178630899997, 9.266136169000049],
            [-13.42019939499994, 9.270743371000037]
          ]
        ],
        [
          [
            [-13.765042304999952, 9.82403659800002],
            [-13.761831283999982, 9.82820034100007],
            [-13.75371456199997, 9.836003305000077],
            [-13.751941679999959, 9.837705612000036],
            [-13.733024597999929, 9.848891259000027],
            [-13.731007576999957, 9.85091972400005],
            [-13.733670234999977, 9.851894379000044],
            [-13.747845649999931, 9.846935272000053],
            [-13.76146125899993, 9.839469910000048],
            [-13.772343636999949, 9.838003158000049],
            [-13.778575897999929, 9.834733963000076],
            [-13.784501076999959, 9.822501183000043],
            [-13.784903526999926, 9.81514072400006],
            [-13.778790473999948, 9.804614067000045],
            [-13.770673751999936, 9.80421924600006],
            [-13.765042304999952, 9.82403659800002]
          ]
        ],
        [
          [
            [-13.695358275999979, 9.892869950000033],
            [-13.688465117999954, 9.900740624000036],
            [-13.686642647999975, 9.907049179000069],
            [-13.688855171999933, 9.920511246000046],
            [-13.696384430999956, 9.905278206000048],
            [-13.695162773999982, 9.902168274000076],
            [-13.698401450999938, 9.896525384000029],
            [-13.69743919399997, 9.892164230000049],
            [-13.695358275999979, 9.892869950000033]
          ]
        ],
        [
          [
            [-13.684244900999943, 9.938126703000023],
            [-13.683737754999981, 9.93803882700007],
            [-13.681314468999972, 9.934595109000043],
            [-13.685007095999936, 9.932268143000044],
            [-13.684067725999967, 9.901694298000052],
            [-13.69299984, 9.88782978100005],
            [-13.70174312699993, 9.87948513200007],
            [-13.703029452999942, 9.876756670000077],
            [-13.711317062999967, 9.859177589000069],
            [-13.72049427099995, 9.847380638000061],
            [-13.739615440999955, 9.832745553000052],
            [-13.744169235999948, 9.822592735000057],
            [-13.74875545499998, 9.816578865000054],
            [-13.750568390999945, 9.811506273000077],
            [-13.747578619999956, 9.806096077000063],
            [-13.755908013999942, 9.795027732000051],
            [-13.759395598999959, 9.78568649400006],
            [-13.760041236999939, 9.779703141000027],
            [-13.75789165499998, 9.771903991000045],
            [-13.755065917999957, 9.76640796700002],
            [-13.738860130999967, 9.752003670000022],
            [-13.730796813999973, 9.745908738000026],
            [-13.72069358799996, 9.742707254000038],
            [-13.698994636999942, 9.739029886000026],
            [-13.68927001999998, 9.740024566000045],
            [-13.67132186899994, 9.756025315000045],
            [-13.66577339099996, 9.757677078000029],
            [-13.656348228999946, 9.767406464000032],
            [-13.650351524999962, 9.771129608000024],
            [-13.646216392999975, 9.77575874400003],
            [-13.639847755999938, 9.79121017400007],
            [-13.64410591199993, 9.802213669000025],
            [-13.653897284999971, 9.80949687900005],
            [-13.663812638999957, 9.822834015000069],
            [-13.66343212199996, 9.829573631000073],
            [-13.65678119699993, 9.82684421600004],
            [-13.65491676299996, 9.82121563100003],
            [-13.65211009899997, 9.818017960000077],
            [-13.644425392999949, 9.813477516000034],
            [-13.638852119999967, 9.812140464000038],
            [-13.625102996999942, 9.821993828000075],
            [-13.629364967999948, 9.81428051000006],
            [-13.632072448999963, 9.80483341200005],
            [-13.630185126999947, 9.80047988900003],
            [-13.61441993699998, 9.79990768500005],
            [-13.611611365999977, 9.797761918000049],
            [-13.612589835999927, 9.794563293000067],
            [-13.616931915999942, 9.795061111000052],
            [-13.62245368999993, 9.789731026000027],
            [-13.623130797999977, 9.787426948000075],
            [-13.619342804999974, 9.777110100000073],
            [-13.61560630799994, 9.773459435000063],
            [-13.596080780999955, 9.765787124000042],
            [-13.575005531999977, 9.767549515000042],
            [-13.56820487899995, 9.770332336000024],
            [-13.569869278999931, 9.765095710000026],
            [-13.56602, 9.76757],
            [-13.56549, 9.77103],
            [-13.55924, 9.77754],
            [-13.55977, 9.78286],
            [-13.55538, 9.78739],
            [-13.55285, 9.78326],
            [-13.55711, 9.76956],
            [-13.56469, 9.7564],
            [-13.5627, 9.75028],
            [-13.54767, 9.74004],
            [-13.53703, 9.74257],
            [-13.50152, 9.73698],
            [-13.47744, 9.74323],
            [-13.47505, 9.73286],
            [-13.47518, 9.72195],
            [-13.47931, 9.71769],
            [-13.49886, 9.69548],
            [-13.499735, 9.689076],
            [-13.49979, 9.6887],
            [-13.49487, 9.67806],
            [-13.48848, 9.67301],
            [-13.46494, 9.65372],
            [-13.46002, 9.64734],
            [-13.46029, 9.63656],
            [-13.46627, 9.62433],
            [-13.47438, 9.61861],
            [-13.49048, 9.60026],
            [-13.50298, 9.57459],
            [-13.49952, 9.55158],
            [-13.4958, 9.55038],
            [-13.493, 9.54213],
            [-13.493986002999975, 9.539449001000037],
            [-13.50019, 9.52258],
            [-13.507370925999965, 9.514476061000039],
            [-13.504186630999982, 9.510991097000044],
            [-13.508174895999957, 9.50228118900003],
            [-13.512530326999979, 9.496273994000035],
            [-13.517981530999975, 9.481984139000076],
            [-13.521415709999928, 9.47654056700003],
            [-13.517338751999944, 9.457393646000071],
            [-13.511464118999982, 9.44548225400007],
            [-13.497702598999979, 9.431098939000037],
            [-13.49024867999998, 9.420963288000053],
            [-13.480657577999978, 9.410531044000038],
            [-13.474379539999973, 9.40666675600005],
            [-13.466962813999942, 9.405109406000065],
            [-13.44940853199995, 9.41028690500002],
            [-13.446651458999952, 9.412834168000074],
            [-13.442024230999948, 9.413095474000045],
            [-13.435523987999943, 9.41038227100006],
            [-13.424079895999967, 9.410198212000068],
            [-13.424380302999964, 9.406772613000044],
            [-13.421231126999942, 9.401052178000043],
            [-13.421099433999927, 9.401134995000064],
            [-13.42013454399995, 9.399060250000048],
            [-13.424539565999964, 9.395056725000074],
            [-13.424044608999964, 9.390462876000072],
            [-13.428049087999966, 9.367451669000047],
            [-13.431505202999972, 9.365359307000062],
            [-13.432566642999973, 9.351791382000044],
            [-13.427157401999978, 9.339416505000031],
            [-13.427566528999932, 9.331829072000062],
            [-13.417257308999979, 9.313282013000048],
            [-13.411601066999935, 9.298380852000037],
            [-13.409696578999956, 9.290579795000042],
            [-13.412022590999982, 9.285242080000046],
            [-13.413111686999969, 9.28274345400007],
            [-13.412625313999968, 9.279298783000058],
            [-13.397079467999959, 9.27250766700007],
            [-13.38991546699998, 9.273474693000026],
            [-13.387849807999942, 9.276016236000032],
            [-13.384615897999936, 9.276727676000064],
            [-13.370705603999966, 9.272223474000043],
            [-13.357077598999979, 9.275299072000053],
            [-13.355267524999931, 9.281287193000026],
            [-13.35581207399997, 9.293463707000058],
            [-13.352375030999951, 9.29831314200004],
            [-13.354527472999962, 9.308871271000044],
            [-13.344973564999975, 9.333065988000044],
            [-13.339670181999963, 9.335858346000066],
            [-13.324626923999972, 9.338552476000075],
            [-13.322614488999932, 9.333278230000076],
            [-13.319654465999974, 9.32552051500005],
            [-13.31989406699995, 9.325429443000075],
            [-13.325472831999946, 9.323308946000054],
            [-13.32845115699996, 9.318922996000026],
            [-13.32978153199997, 9.310409546000074],
            [-13.324157714999956, 9.299414636000051],
            [-13.32711124399998, 9.291351319000057],
            [-13.326571465999962, 9.27963447600007],
            [-13.324172973999964, 9.266778946000045],
            [-13.322078703999978, 9.264953614000035],
            [-13.323898314999951, 9.260345459000064],
            [-13.309889794999947, 9.240670204000025],
            [-13.310566902999938, 9.238137245000075],
            [-13.313481330999934, 9.224100113000077],
            [-13.31368064999998, 9.219272614000033],
            [-13.318454741999972, 9.20660209700003],
            [-13.32001495299994, 9.197859764000043],
            [-13.318820953999932, 9.192122459000075],
            [-13.316193580999936, 9.191487313000039],
            [-13.312047005999943, 9.19092369200007],
            [-13.325907707999932, 9.179667474000041],
            [-13.3207016, 9.170609475000049],
            [-13.315156937999973, 9.162499428000046],
            [-13.310031890999937, 9.156786920000059],
            [-13.292218207999952, 9.155291558000044],
            [-13.285029411999972, 9.152348520000032],
            [-13.280406951999964, 9.152607918000058],
            [-13.276968002999979, 9.156995773000062],
            [-13.27401733399995, 9.165746690000049],
            [-13.274957656999959, 9.168038369000044],
            [-13.272535324999978, 9.186898232000033],
            [-13.270494460999942, 9.19311523500005],
            [-13.262488264999945, 9.190949285000045],
            [-13.260532379999972, 9.190420152000058],
            [-13.25804233499997, 9.162628175000066],
            [-13.251594543999943, 9.166804314000046],
            [-13.249702454999976, 9.160150528000031],
            [-13.24966422999995, 9.15933601300003],
            [-13.248967170999947, 9.144482612000047],
            [-13.251893044999974, 9.141064645000029],
            [-13.25651836399993, 9.141265869000051],
            [-13.265497207999942, 9.135235788000045],
            [-13.268681526999956, 9.126942634000045],
            [-13.275790214999972, 9.117936135000036],
            [-13.284741401999952, 9.107998849000069],
            [-13.293026924999936, 9.101972581000041],
            [-13.29365444299998, 9.091857911000034],
            [-13.304427146999956, 9.077772142000072],
            [-13.310674666999944, 9.078651428000057],
            [-13.323564528999952, 9.070297242000038],
            [-13.32469654199997, 9.067072869000071],
            [-13.324358221999944, 9.063612611000053],
            [-13.3213, 9.05921],
            [-13.321417980999968, 9.057907758000056],
            [-13.307630539999934, 9.037538529000074],
            [-13.30071, 9.04154],
            [-13.28857, 9.05257],
            [-13.28143, 9.056520001000024],
            [-13.276390000999982, 9.062070001000052],
            [-13.26787, 9.067410001000042],
            [-13.24758, 9.07513],
            [-13.236250000999974, 9.07544000100006],
            [-13.21564000099994, 9.07167000100003],
            [-13.20687000099997, 9.07265],
            [-13.18836, 9.08457],
            [-13.17947000099997, 9.09029],
            [-13.174160000999962, 9.0924],
            [-13.163060000999963, 9.09155],
            [-13.151460000999975, 9.08588],
            [-13.12306, 9.0578],
            [-13.10751000099998, 9.049630001000025],
            [-13.087620000999948, 9.04837],
            [-13.078650000999971, 9.050230001000045],
            [-13.06938, 9.052160001000061],
            [-13.05832, 9.058430001000033],
            [-13.05085, 9.08261],
            [-13.0437, 9.086560001000066],
            [-13.03608, 9.08821],
            [-13.032589848999976, 9.088094787000045],
            [-13.01851, 9.08763],
            [-13.01182, 9.09088],
            [-13.00215, 9.09737],
            [-12.99161, 9.11283],
            [-12.98777, 9.11982000100005],
            [-12.98086, 9.132430001000046],
            [-12.97339, 9.1589],
            [-12.9732, 9.165100001000042],
            [-12.97023, 9.171780001000059],
            [-12.95619, 9.1822],
            [-12.95415, 9.1898],
            [-12.957543882999971, 9.222774319000052],
            [-12.962087, 9.229221],
            [-12.961531, 9.238001],
            [-12.952527, 9.258721],
            [-12.948436517999937, 9.261502088000043],
            [-12.94381, 9.27558],
            [-12.94193, 9.277960001000054],
            [-12.93508, 9.28665],
            [-12.926520000999972, 9.28648],
            [-12.91654, 9.28263],
            [-12.900740000999974, 9.27099],
            [-12.893100000999937, 9.270340001000022],
            [-12.88111, 9.277990001000035],
            [-12.87636, 9.29664],
            [-12.8729, 9.298260001000074],
            [-12.868250000999979, 9.2953],
            [-12.85715000099998, 9.29582],
            [-12.844600000999947, 9.28693],
            [-12.8408, 9.28531],
            [-12.834329728999933, 9.289270134000049],
            [-12.831340000999944, 9.2911],
            [-12.825090000999978, 9.2909],
            [-12.817220000999953, 9.28629],
            [-12.816510000999926, 9.28588],
            [-12.81212, 9.28682],
            [-12.8066, 9.29351],
            [-12.794130000999928, 9.297470001000022],
            [-12.78951000099994, 9.300710001000027],
            [-12.788370000999976, 9.30393],
            [-12.789570000999959, 9.31266],
            [-12.78796, 9.31589],
            [-12.78519, 9.31682],
            [-12.781520000999933, 9.32396],
            [-12.77856, 9.33340000100003],
            [-12.778820000999929, 9.34006000100004],
            [-12.77698, 9.34260000100005],
            [-12.756850000999975, 9.342230001000075],
            [-12.753390000999957, 9.345],
            [-12.75087, 9.34961000100003],
            [-12.75159, 9.355580001000021],
            [-12.756510000999981, 9.366240001000051],
            [-12.75817000099994, 9.37693],
            [-12.75776, 9.388420001000043],
            [-12.752240000999961, 9.396490001000075],
            [-12.724000000999979, 9.39523],
            [-12.7168, 9.39044],
            [-12.712170000999947, 9.38953],
            [-12.7113, 9.40149],
            [-12.70879, 9.41093],
            [-12.70349, 9.415540001000068],
            [-12.698400000999982, 9.41626],
            [-12.69119, 9.40801],
            [-12.688870000999941, 9.407330001000048],
            [-12.6868, 9.40918],
            [-12.686580000999982, 9.41217],
            [-12.686140000999956, 9.41769000100004],
            [-12.68338, 9.421140001000026],
            [-12.6855, 9.43079],
            [-12.67997, 9.43518],
            [-12.67998, 9.438860001000023],
            [-12.68393, 9.44298],
            [-12.680260000999965, 9.45058],
            [-12.67938, 9.46001],
            [-12.67847, 9.463690001000032],
            [-12.67269, 9.466930001000037],
            [-12.67131, 9.469230001000028],
            [-12.67203000099994, 9.47544],
            [-12.654749461999927, 9.50243124800005],
            [-12.65484, 9.50529],
            [-12.653068, 9.508355],
            [-12.65065, 9.508852],
            [-12.649733, 9.514125],
            [-12.649727801999973, 9.51413399200004],
            [-12.64929000099994, 9.516670001000023],
            [-12.651180000999943, 9.524480001000029],
            [-12.658610000999943, 9.5318],
            [-12.66047, 9.53203],
            [-12.657490000999928, 9.539160001000027],
            [-12.64803, 9.54794],
            [-12.64417, 9.54917],
            [-12.642940000999943, 9.54957],
            [-12.63437000099998, 9.54822],
            [-12.63277, 9.55260000100003],
            [-12.636510000999976, 9.56109],
            [-12.636290000999963, 9.56546],
            [-12.630760000999942, 9.57214],
            [-12.62377, 9.593260001000033],
            [-12.62115, 9.60115],
            [-12.614920000999973, 9.606230001000029],
            [-12.60008, 9.60077],
            [-12.591740000999948, 9.59988],
            [-12.591735894999943, 9.599926785000036],
            [-12.590630000999965, 9.612530001000039],
            [-12.58536, 9.62887],
            [-12.585483993999958, 9.631936465000024],
            [-12.58556258699997, 9.63388012400003],
            [-12.585620000999938, 9.635300001000076],
            [-12.587960000999942, 9.642190001000074],
            [-12.59149, 9.65413],
            [-12.58966000099997, 9.66057],
            [-12.58712, 9.66311],
            [-12.583190000999934, 9.663590001000046],
            [-12.57853, 9.65809],
            [-12.575530000999947, 9.659940001000052],
            [-12.574180000999945, 9.67005000100005],
            [-12.569570000999931, 9.67743],
            [-12.56797, 9.68387],
            [-12.56893, 9.69238],
            [-12.56666, 9.702730001000077],
            [-12.5567, 9.70346],
            [-12.547450000999959, 9.70901],
            [-12.53994, 9.716660001000037],
            [-12.536140000999978, 9.720540001000074],
            [-12.53359, 9.72009],
            [-12.528910000999929, 9.709070001000043],
            [-12.525660000999949, 9.70587],
            [-12.522410000999969, 9.70542000100005],
            [-12.51941, 9.70727],
            [-12.51928, 9.71143],
            [-12.519200000999945, 9.71416],
            [-12.52809, 9.73643],
            [-12.52556, 9.741730001000064],
            [-12.523020000999963, 9.74335],
            [-12.50911, 9.74294],
            [-12.50727, 9.745930001000033],
            [-12.50844000099994, 9.749380001000077],
            [-12.518420000999981, 9.753940001000046],
            [-12.51866, 9.756470001000025],
            [-12.51243, 9.76339],
            [-12.511320000999945, 9.77512],
            [-12.501400000999979, 9.78917],
            [-12.50031, 9.808020001000045],
            [-12.49731, 9.810340001000043],
            [-12.49128000099995, 9.811280001000057],
            [-12.49017, 9.826000001000068],
            [-12.49228, 9.82898],
            [-12.49668, 9.82988],
            [-12.499470000999963, 9.83217],
            [-12.500640000999965, 9.8347],
            [-12.498870000999943, 9.83932],
            [-12.49901, 9.84365],
            [-12.4801, 9.85547],
            [-12.456900000999951, 9.854640001000064],
            [-12.45251000099995, 9.85719],
            [-12.443500000999961, 9.86619],
            [-12.43752, 9.87748],
            [-12.43174, 9.881630001000076],
            [-12.39373, 9.88776],
            [-12.38804, 9.889],
            [-12.37126000099994, 9.892670001000056],
            [-12.365930000999981, 9.89568],
            [-12.35318, 9.89618],
            [-12.28921, 9.90882],
            [-12.287360000999968, 9.91089],
            [-12.26455, 9.91611],
            [-12.22711, 9.92465],
            [-12.2227, 9.924430001000076],
            [-12.21526, 9.91871],
            [-12.21293, 9.91412],
            [-12.20502000099998, 9.907020001000035],
            [-12.17738, 9.897440001000064],
            [-12.156920000999946, 9.8821],
            [-12.15296, 9.88005000100003],
            [-12.14826785699995, 9.877636538000047],
            [-12.15875, 9.93462],
            [-12.16766, 9.95324],
            [-12.17271, 9.97518],
            [-12.1642, 9.99952],
            [-12.13827, 10.00777],
            [-12.12842, 10.02492],
            [-12.1251, 10.03862],
            [-12.13348, 10.07693],
            [-12.1384, 10.0889],
            [-12.14651, 10.09967],
            [-12.15675, 10.12574],
            [-12.16513, 10.13585],
            [-12.18601, 10.15101],
            [-12.19453, 10.17388],
            [-12.19506, 10.18253],
            [-12.20317, 10.1929],
            [-12.21009, 10.19689],
            [-12.21767, 10.20487],
            [-12.22711, 10.22589],
            [-12.22618, 10.23639],
            [-12.22924, 10.25036],
            [-12.22738, 10.2586],
            [-12.23004, 10.27071],
            [-12.235774, 10.273129],
            [-12.24201, 10.27576],
            [-12.25863, 10.27816],
            [-12.27033, 10.27536],
            [-12.28443, 10.27536],
            [-12.33245, 10.28667],
            [-12.34295, 10.28427],
            [-12.3621, 10.26525],
            [-12.37341, 10.26472],
            [-12.38618, 10.26885],
            [-12.40147, 10.26885],
            [-12.41557, 10.27164],
            [-12.4185, 10.2739],
            [-12.41863, 10.28507],
            [-12.42289, 10.30183],
            [-12.4314, 10.31899],
            [-12.43938, 10.32218],
            [-12.44323, 10.35969],
            [-12.44204, 10.36673],
            [-12.42727, 10.38389],
            [-12.40959, 10.45358],
            [-12.39562, 10.47061],
            [-12.399719, 10.481095],
            [-12.40134, 10.48524],
            [-12.40985, 10.49056],
            [-12.41956, 10.49189],
            [-12.42767, 10.50027],
            [-12.44417, 10.50013],
            [-12.45161, 10.50612],
            [-12.44457, 10.52726],
            [-12.45773, 10.54402],
            [-12.46664, 10.54934],
            [-12.48367, 10.54655],
            [-12.49072, 10.54775],
            [-12.49776, 10.55373],
            [-12.50096, 10.55985],
            [-12.50654, 10.5649],
            [-12.50854, 10.57421],
            [-12.50747, 10.60507],
            [-12.49245, 10.61864],
            [-12.496181, 10.625764],
            [-12.4983, 10.62981],
            [-12.50375, 10.63553],
            [-12.51732, 10.63021],
            [-12.52397, 10.62209],
            [-12.53394, 10.61983],
            [-12.54365, 10.61265],
            [-12.55921, 10.60268],
            [-12.57251, 10.58339],
            [-12.58315, 10.58007],
            [-12.60669, 10.56198],
            [-12.61773, 10.55666],
            [-12.62505, 10.55852],
            [-12.63742, 10.55892],
            [-12.642029, 10.556716],
            [-12.64965, 10.55307],
            [-12.68636, 10.51982],
            [-12.69873, 10.5121],
            [-12.70059, 10.50559],
            [-12.71589, 10.49042],
            [-12.72586, 10.4847],
            [-12.74514, 10.48125],
            [-12.75352, 10.48776],
            [-12.75432, 10.49362],
            [-12.75845, 10.49827],
            [-12.7651, 10.49574],
            [-12.77188, 10.50426],
            [-12.78012, 10.51662],
            [-12.79741, 10.51676],
            [-12.8038, 10.52647],
            [-12.81577, 10.53338],
            [-12.8284, 10.53418],
            [-12.84237, 10.52966],
            [-12.85434, 10.53086],
            [-12.86458, 10.5387],
            [-12.87269, 10.54456],
            [-12.8816, 10.54682],
            [-12.89903, 10.52899],
            [-12.91671, 10.51569],
            [-12.9227, 10.51343],
            [-12.94704, 10.51796],
            [-12.9509, 10.53139],
            [-12.95223, 10.54921],
            [-12.95462, 10.55892],
            [-12.96167, 10.56956],
            [-12.97391, 10.57701],
            [-12.97909, 10.58791],
            [-12.98175, 10.60281],
            [-12.97417, 10.61159],
            [-12.97124, 10.61212],
            [-12.96872, 10.60613],
            [-12.96393, 10.60587],
            [-12.95808, 10.62901],
            [-12.94052, 10.65761],
            [-12.94278, 10.66319],
            [-12.95302, 10.66638],
            [-12.95302, 10.68806],
            [-12.9481, 10.69923],
            [-12.93573, 10.71506],
            [-12.93081, 10.72903],
            [-12.93866, 10.73993],
            [-12.94531, 10.74485],
            [-12.95515, 10.75775],
            [-12.9622, 10.79606],
            [-12.95688, 10.80377],
            [-12.96353, 10.80949],
            [-12.96659, 10.81973],
            [-12.95888, 10.84527],
            [-12.96034, 10.86509],
            [-12.9477, 10.89714],
            [-12.94518, 10.93584],
            [-12.93294, 10.95473],
            [-12.90754, 10.96364],
            [-12.90089, 10.97508],
            [-12.89943, 10.98665],
            [-12.88972, 10.99702],
            [-12.86006, 11.00912],
            [-12.85394, 11.03054],
            [-12.84543, 11.02947],
            [-12.82707, 11.0175],
            [-12.82295, 11.02668],
            [-12.81776, 11.05514],
            [-12.81165, 11.06738],
            [-12.81005, 11.07642],
            [-12.796076, 11.083343],
            [-12.79582, 11.08347],
            [-12.7893, 11.09291],
            [-12.78771, 11.10302],
            [-12.78877, 11.11114],
            [-12.79688, 11.11699],
            [-12.83718, 11.12736],
            [-12.84755, 11.13907],
            [-12.85899, 11.14412],
            [-12.87083, 11.14359],
            [-12.87974, 11.13946],
            [-12.89291, 11.12138],
            [-12.911, 11.10555],
            [-12.92563, 11.09863],
            [-12.95249, 11.10542],
            [-12.95888, 11.11114],
            [-12.9618, 11.12284],
            [-12.96127, 11.15542],
            [-12.96885, 11.17285],
            [-12.9751, 11.18322],
            [-12.976531, 11.181909],
            [-12.976629, 11.18323],
            [-12.986481, 11.187098],
            [-12.996361, 11.189523],
            [-12.99699, 11.189677],
            [-13.002244, 11.190966],
            [-13.01144, 11.190321],
            [-13.016694, 11.187098],
            [-13.022605, 11.189677],
            [-13.036398, 11.185164],
            [-13.039682, 11.183875],
            [-13.048878, 11.18452],
            [-13.054132, 11.185809],
            [-13.058073, 11.186454],
            [-13.062014, 11.187098],
            [-13.064641, 11.187098],
            [-13.069895, 11.183875],
            [-13.079747, 11.181296],
            [-13.088286, 11.178073],
            [-13.09354, 11.176784],
            [-13.100765, 11.174205],
            [-13.104049, 11.174205],
            [-13.106676, 11.17485],
            [-13.109304, 11.17485],
            [-13.111931, 11.17485],
            [-13.118499, 11.178073],
            [-13.123097, 11.175495],
            [-13.129008, 11.172916],
            [-13.131635, 11.170338],
            [-13.134262, 11.16647],
            [-13.135576, 11.164536],
            [-13.142801, 11.161957],
            [-13.150026, 11.158089],
            [-13.158564, 11.153577],
            [-13.169073, 11.14842],
            [-13.176298, 11.145841],
            [-13.182866, 11.141973],
            [-13.187464, 11.139395],
            [-13.192061, 11.138105],
            [-13.197972, 11.136816],
            [-13.206511, 11.136172],
            [-13.213079, 11.135527],
            [-13.21702, 11.133593],
            [-13.220961, 11.132948],
            [-13.21703, 11.11778],
            [-13.204541, 11.087823],
            [-13.19735, 11.06472],
            [-13.197514954999974, 11.064654846000053],
            [-13.20173, 11.06299],
            [-13.20812, 11.06179],
            [-13.2137, 11.05887],
            [-13.215526, 11.05891],
            [-13.232541, 11.099563],
            [-13.233917, 11.143819],
            [-13.25906, 11.18229],
            [-13.26424, 11.22937],
            [-13.27209, 11.26382],
            [-13.28233, 11.27925],
            [-13.28286, 11.28922],
            [-13.2673, 11.32992],
            [-13.27568, 11.34521],
            [-13.28379, 11.34827],
            [-13.295157, 11.347769],
            [-13.31399, 11.34694],
            [-13.32516, 11.33923],
            [-13.34817, 11.33737],
            [-13.36971, 11.32367],
            [-13.39379, 11.31409],
            [-13.41573, 11.2964],
            [-13.42996, 11.29135],
            [-13.44725, 11.29148],
            [-13.44991, 11.30625],
            [-13.42943, 11.35838],
            [-13.43621, 11.37314],
            [-13.443, 11.37807],
            [-13.46946, 11.3782],
            [-13.47997, 11.37394],
            [-13.50457, 11.37873],
            [-13.51295, 11.3782],
            [-13.52772, 11.38166],
            [-13.55605, 11.38299],
            [-13.570932, 11.380888],
            [-13.57679, 11.38006],
            [-13.59754, 11.35679],
            [-13.626, 11.3375],
            [-13.63558, 11.33285],
            [-13.68572, 11.32753],
            [-13.69463, 11.33484],
            [-13.70275, 11.33723],
            [-13.7324, 11.33311],
            [-13.73865, 11.33444],
            [-13.75355, 11.32487],
            [-13.78454, 11.31436],
            [-13.79611, 11.30851],
            [-13.80662, 11.29853],
            [-13.81766, 11.29295],
            [-13.83242, 11.29122],
            [-13.84293, 11.29667],
            [-13.84186, 11.28802],
            [-13.83082, 11.28084],
            [-13.82856, 11.2706],
            [-13.81021, 11.26515],
            [-13.80821, 11.25743],
            [-13.79997, 11.24999],
            [-13.79318, 11.25198],
            [-13.78161, 11.23669],
            [-13.7735, 11.23389],
            [-13.77151, 11.22857],
            [-13.77217, 11.21966],
            [-13.78121, 11.21062],
            [-13.78853, 11.19812],
            [-13.78068, 11.19333],
            [-13.75741, 11.19],
            [-13.74677, 11.17684],
            [-13.74012, 11.1517],
            [-13.7457, 11.13534],
            [-13.74398, 11.11965],
            [-13.76273, 11.09358],
            [-13.78813, 11.08547],
            [-13.79837, 11.08015],
            [-13.80063, 11.05315],
            [-13.80835, 11.05421],
            [-13.81646, 11.04942],
            [-13.81832, 11.03626],
            [-13.7727, 10.98186],
            [-13.76938, 10.97454],
            [-13.77377, 10.95792],
            [-13.77004, 10.96364],
            [-13.76898, 10.9534],
            [-13.77696, 10.93744],
            [-13.8118, 10.91124],
            [-13.85184, 10.8986],
            [-13.87498, 10.87825],
            [-13.9198, 10.86189],
            [-13.92765, 10.8462],
            [-13.93084, 10.82665],
            [-13.94321, 10.80417],
            [-13.97167, 10.76427],
            [-13.97726, 10.73993],
            [-13.98072, 10.73568],
            [-13.97925, 10.72011],
            [-13.97101, 10.72078],
            [-13.95717, 10.70987],
            [-13.91887, 10.66559],
            [-13.9065, 10.66279],
            [-13.89892, 10.66997],
            [-13.90437, 10.68128],
            [-13.90358, 10.68593],
            [-13.89945, 10.68806],
            [-13.88602, 10.68154],
            [-13.88083, 10.68487],
            [-13.8787, 10.71586],
            [-13.87485, 10.72663],
            [-13.867, 10.73607],
            [-13.86168, 10.73927],
            [-13.84785, 10.73169],
            [-13.82803, 10.72943],
            [-13.82072, 10.73302],
            [-13.81566, 10.74113],
            [-13.80396, 10.74126],
            [-13.79491, 10.75563],
            [-13.78161, 10.75257],
            [-13.76366, 10.72956],
            [-13.75196, 10.7249],
            [-13.74531, 10.7277],
            [-13.72243, 10.72996],
            [-13.71604, 10.7261],
            [-13.70806, 10.6975],
            [-13.69809, 10.7011],
            [-13.68373, 10.69471],
            [-13.68679, 10.68793],
            [-13.68373, 10.68593],
            [-13.68426, 10.68314],
            [-13.67974, 10.68221],
            [-13.67561, 10.67423],
            [-13.67162, 10.67277],
            [-13.66817, 10.66612],
            [-13.65247, 10.66825],
            [-13.59967, 10.71147],
            [-13.59914, 10.71573],
            [-13.56802, 10.74086],
            [-13.51748, 10.79021],
            [-13.49207, 10.80337],
            [-13.47944, 10.8047],
            [-13.466868, 10.801838],
            [-13.466, 10.80164],
            [-13.4688, 10.79978],
            [-13.46467, 10.7918],
            [-13.45191, 10.7785],
            [-13.44007, 10.77292],
            [-13.4398, 10.7656],
            [-13.43648, 10.76148],
            [-13.43568, 10.73754],
            [-13.44619, 10.71852],
            [-13.44473, 10.68899],
            [-13.4434, 10.68407],
            [-13.443955, 10.683503],
            [-13.443539, 10.683115],
            [-13.440211, 10.683769],
            [-13.432226, 10.682461],
            [-13.425572, 10.685077],
            [-13.42291, 10.687692],
            [-13.41559, 10.687692],
            [-13.411597, 10.689654],
            [-13.406939, 10.693578],
            [-13.403612, 10.696194],
            [-13.401615, 10.697501],
            [-13.394295, 10.698155],
            [-13.384979, 10.689],
            [-13.383648, 10.685077],
            [-13.376328, 10.685077],
            [-13.369674, 10.683115],
            [-13.367012, 10.681807],
            [-13.351041, 10.660228],
            [-13.350948, 10.660235],
            [-13.35083, 10.66053],
            [-13.35708, 10.64484],
            [-13.37277, 10.6314],
            [-13.37211, 10.62755],
            [-13.37743, 10.61983],
            [-13.38607, 10.61491],
            [-13.39578, 10.60015],
            [-13.40057, 10.59656],
            [-13.40323, 10.58406],
            [-13.39645, 10.57834],
            [-13.38461, 10.56158],
            [-13.38474, 10.55453],
            [-13.38873, 10.54309],
            [-13.396036, 10.517396],
            [-13.39618, 10.51689],
            [-13.396273807999933, 10.516890372000034],
            [-13.400655, 10.506124],
            [-13.40463, 10.499984],
            [-13.411444, 10.496076],
            [-13.416555, 10.491611],
            [-13.418827, 10.491053],
            [-13.432455, 10.496635],
            [-13.441541, 10.495518],
            [-13.445516, 10.493285],
            [-13.45233, 10.486029],
            [-13.459713, 10.478772],
            [-13.461984, 10.475981],
            [-13.467095, 10.475981],
            [-13.469934, 10.475423],
            [-13.475045, 10.477656],
            [-13.47902, 10.479889],
            [-13.481291, 10.479889],
            [-13.485266, 10.478772],
            [-13.492649, 10.469841],
            [-13.497191, 10.462584],
            [-13.497759, 10.452537],
            [-13.508549, 10.452537],
            [-13.519265, 10.427787],
            [-13.50737, 10.41182],
            [-13.49899, 10.40304],
            [-13.49925, 10.39639],
            [-13.50324, 10.39307],
            [-13.50311, 10.38229],
            [-13.522, 10.35835],
            [-13.53038, 10.35131],
            [-13.54142, 10.34705],
            [-13.55059, 10.33734],
            [-13.55272, 10.3259],
            [-13.55738, 10.31008],
            [-13.58331, 10.25794],
            [-13.59408, 10.25289],
            [-13.60898, 10.25049],
            [-13.62494, 10.25222],
            [-13.64236, 10.26299],
            [-13.65486, 10.2759],
            [-13.66524, 10.27895],
            [-13.672283, 10.289232],
            [-13.692853, 10.276664],
            [-13.719913, 10.270005],
            [-13.731188, 10.276664],
            [-13.74359, 10.277774],
            [-13.75712, 10.277774],
            [-13.76799, 10.278844],
            [-13.77097, 10.26286],
            [-13.8009, 10.22961],
            [-13.80941, 10.22309],
            [-13.81486, 10.19955],
            [-13.83535, 10.1663],
            [-13.86328, 10.15048],
            [-13.87032, 10.1526],
            [-13.90105, 10.13026],
            [-13.90637, 10.12414],
            [-13.9065, 10.10871],
            [-13.90171, 10.10286],
            [-13.88868, 10.08504],
            [-13.87032, 10.06669],
            [-13.86434, 10.05631],
            [-13.84239, 10.03197],
            [-13.81619, 10.01561],
            [-13.80382, 10.00524],
            [-13.75102, 9.98037],
            [-13.74038, 9.97332],
            [-13.72900012599996, 9.972077083000045],
            [-13.72216, 9.97133],
            [-13.70075, 9.96428],
            [-13.6933, 9.95856],
            [-13.692985, 9.957999],
            [-13.692853, 9.958137],
            [-13.687215, 9.960356],
            [-13.67751, 9.956395],
            [-13.684244900999943, 9.938126703000023]
          ]
        ]
      ]
    },
    properties: {
      name: 'Kindia',
      center: [-12.859014575543448, 10.08502849904629],
      shapeName: 'Kindia',
      shapeISO: '',
      shapeID: '26780975B80189905722511',
      shapeGroup: 'GIN',
      shapeType: 'ADM1'
    }
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-12.996361, 11.189523],
          [-12.986481, 11.187098],
          [-12.976629, 11.18323],
          [-12.976531, 11.181909],
          [-12.9751, 11.18322],
          [-12.96885, 11.17285],
          [-12.96127, 11.15542],
          [-12.9618, 11.12284],
          [-12.95888, 11.11114],
          [-12.95249, 11.10542],
          [-12.92563, 11.09863],
          [-12.911, 11.10555],
          [-12.89291, 11.12138],
          [-12.87974, 11.13946],
          [-12.87083, 11.14359],
          [-12.85899, 11.14412],
          [-12.84755, 11.13907],
          [-12.83718, 11.12736],
          [-12.79688, 11.11699],
          [-12.78877, 11.11114],
          [-12.77761, 11.12397],
          [-12.776063, 11.132951],
          [-12.763867, 11.149891],
          [-12.758984, 11.164609],
          [-12.757346, 11.17858],
          [-12.757697, 11.187119],
          [-12.755131, 11.196395],
          [-12.749665, 11.204148],
          [-12.741048, 11.213193],
          [-12.7328, 11.213266],
          [-12.731199, 11.220066],
          [-12.731237, 11.231785],
          [-12.728187, 11.235546],
          [-12.720758, 11.23679],
          [-12.714121, 11.238437],
          [-12.708071, 11.240936],
          [-12.701305, 11.258387],
          [-12.690553, 11.260344],
          [-12.679348, 11.262724],
          [-12.66528, 11.271289],
          [-12.655863, 11.281602],
          [-12.651615, 11.290398],
          [-12.647064, 11.299899],
          [-12.651471, 11.304017],
          [-12.655746, 11.305813],
          [-12.660139, 11.311428],
          [-12.656265, 11.315841],
          [-12.64641, 11.316378],
          [-12.638865, 11.323849],
          [-12.63725, 11.328538],
          [-12.624381, 11.322968],
          [-12.607821, 11.32734],
          [-12.603508, 11.328295],
          [-12.5961, 11.329916],
          [-12.580868, 11.337872],
          [-12.568182, 11.337719],
          [-12.563394, 11.323236],
          [-12.553287, 11.316682],
          [-12.540603, 11.315651],
          [-12.528931, 11.305918],
          [-12.522952, 11.292936],
          [-12.507665, 11.295867],
          [-12.497011, 11.285766],
          [-12.49645, 11.273662],
          [-12.50342, 11.25726],
          [-12.50306, 11.245587],
          [-12.502151, 11.232116],
          [-12.497381, 11.224402],
          [-12.496686, 11.20892],
          [-12.493266, 11.198059],
          [-12.491296, 11.192129],
          [-12.488576, 11.180328],
          [-12.486772, 11.172099],
          [-12.484877, 11.166968],
          [-12.479699, 11.155647],
          [-12.474987, 11.151043],
          [-12.46439, 11.147669],
          [-12.455272, 11.147187],
          [-12.445072, 11.145472],
          [-12.438585, 11.139011],
          [-12.435328, 11.135766],
          [-12.424725, 11.131298],
          [-12.416341, 11.141857],
          [-12.412519, 11.157843],
          [-12.404497, 11.163651],
          [-12.398771, 11.173451],
          [-12.395286, 11.181231],
          [-12.386646, 11.179855],
          [-12.380401, 11.175694],
          [-12.367765, 11.173827],
          [-12.324925, 11.18155],
          [-12.292541, 11.193668],
          [-12.27695, 11.192901],
          [-12.264897, 11.18538],
          [-12.256417, 11.166723],
          [-12.244725, 11.148947],
          [-12.237322, 11.143505],
          [-12.218216, 11.152644],
          [-12.208989, 11.162247],
          [-12.208362, 11.163423],
          [-12.190748, 11.17387],
          [-12.172507, 11.185494],
          [-12.162137, 11.191936],
          [-12.149711, 11.21729],
          [-12.14208, 11.24426],
          [-12.13413, 11.273539],
          [-12.120216, 11.286799],
          [-12.099031, 11.292821],
          [-12.073625, 11.293615],
          [-12.063537, 11.295497],
          [-12.050551, 11.29792],
          [-12.031711, 11.28821],
          [-12.011669, 11.290911],
          [-12.002724, 11.278854],
          [-11.996509, 11.260015],
          [-11.981293, 11.256465],
          [-11.977337, 11.246091],
          [-11.971741, 11.23212],
          [-11.961561, 11.218371],
          [-11.957142, 11.21671],
          [-11.913915, 11.204716],
          [-11.895959, 11.195443],
          [-11.878873, 11.17787],
          [-11.873299, 11.157015],
          [-11.871811, 11.144921],
          [-11.878417, 11.145535],
          [-11.878298, 11.143705],
          [-11.88957, 11.118633],
          [-11.895148, 11.099789],
          [-11.894885, 11.084886],
          [-11.887459, 11.068016],
          [-11.882211, 11.052194],
          [-11.866141, 11.028608],
          [-11.863128, 11.025533],
          [-11.832179, 11.007485],
          [-11.804864, 10.998608],
          [-11.783175, 10.99685],
          [-11.766511, 10.993026],
          [-11.75292, 10.993191],
          [-11.742933, 10.987454],
          [-11.708509, 11.019721],
          [-11.704262, 11.033676],
          [-11.6884, 11.041269],
          [-11.678974, 11.049331],
          [-11.673587, 11.053463],
          [-11.66789, 11.059673],
          [-11.661254, 11.068644],
          [-11.658068, 11.071997],
          [-11.649534, 11.081186],
          [-11.643289, 11.084188],
          [-11.623857, 11.075076],
          [-11.612066, 11.077683],
          [-11.595149, 11.084427],
          [-11.577405, 11.096486],
          [-11.561153, 11.109854],
          [-11.53899, 11.139832],
          [-11.505549, 11.185381],
          [-11.464152, 11.205182],
          [-11.446657, 11.21746],
          [-11.405575, 11.227606],
          [-11.390905, 11.232871],
          [-11.385701, 11.232192],
          [-11.38752, 11.238534],
          [-11.389387, 11.249234],
          [-11.392833, 11.269235],
          [-11.379829, 11.267385],
          [-11.366314, 11.259843],
          [-11.346205, 11.256327],
          [-11.351735, 11.269197],
          [-11.33679, 11.267492],
          [-11.336954, 11.30838],
          [-11.317879, 11.337118],
          [-11.289832, 11.367469],
          [-11.267742, 11.401403],
          [-11.239992, 11.406576],
          [-11.223752, 11.418863],
          [-11.212168, 11.423065],
          [-11.189919, 11.42407],
          [-11.180678, 11.431041],
          [-11.171035, 11.438312],
          [-11.160252, 11.44719],
          [-11.143086, 11.46223],
          [-11.131426, 11.465994],
          [-11.123279, 11.478755],
          [-11.12569, 11.492285],
          [-11.126966, 11.501425],
          [-11.119997, 11.505046],
          [-11.121052, 11.517595],
          [-11.127648, 11.52264],
          [-11.13659, 11.530371],
          [-11.152024, 11.535892],
          [-11.168377, 11.537858],
          [-11.175565, 11.537135],
          [-11.196319, 11.537038],
          [-11.202912, 11.5518],
          [-11.213177, 11.562174],
          [-11.21332, 11.573958],
          [-11.208988, 11.585236],
          [-11.192336, 11.589475],
          [-11.176122, 11.598895],
          [-11.166217, 11.603824],
          [-11.160088, 11.613321],
          [-11.149628, 11.624124],
          [-11.14416, 11.628473],
          [-11.149878, 11.638703],
          [-11.158496, 11.643567],
          [-11.165243, 11.647159],
          [-11.164983, 11.654556],
          [-11.155732, 11.668371],
          [-11.141488, 11.686568],
          [-11.121708, 11.694716],
          [-11.100833, 11.691768],
          [-11.089669, 11.706956],
          [-11.079966, 11.730269],
          [-11.070336, 11.749882],
          [-11.074752, 11.785317],
          [-11.072569, 11.810992],
          [-11.073543, 11.837286],
          [-11.076797, 11.855749],
          [-11.071722, 11.869091],
          [-11.059637, 11.877534],
          [-11.047884, 11.883291],
          [-11.052836, 11.889461],
          [-11.06866, 11.892409],
          [-11.082573, 11.896951],
          [-11.093214, 11.909831],
          [-11.100883, 11.919845],
          [-11.112559, 11.920901],
          [-11.123172, 11.920034],
          [-11.128498, 11.917316],
          [-11.142889, 11.925085],
          [-11.152214, 11.932158],
          [-11.159923, 11.941589],
          [-11.155548, 11.955768],
          [-11.15444, 11.979376],
          [-11.152783, 11.992103],
          [-11.154836, 12.010853],
          [-11.161114, 12.024563],
          [-11.165517, 12.03104],
          [-11.166594, 12.032625],
          [-11.17274, 12.02893],
          [-11.178820000999963, 12.02042],
          [-11.1842, 12.017440001000068],
          [-11.19202, 12.014530001000026],
          [-11.212590000999967, 12.017760001000056],
          [-11.224040000999935, 12.012020001000053],
          [-11.2383, 11.99592],
          [-11.24695, 11.99247],
          [-11.257470000999945, 11.99248],
          [-11.2971, 12.01324],
          [-11.304530116999956, 12.017129885000031],
          [-11.304572607999944, 12.017152129000067],
          [-11.3124, 12.021250001000055],
          [-11.355180000999951, 12.053920001000051],
          [-11.36176, 12.05994],
          [-11.36035, 12.07904],
          [-11.37095, 12.09925],
          [-11.376000000999966, 12.10389],
          [-11.38511000099993, 12.10827],
          [-11.397960000999944, 12.10827],
          [-11.41105, 12.11173],
          [-11.43305, 12.11955],
          [-11.439560000999961, 12.12187],
          [-11.46411, 12.133610001000022],
          [-11.469020000999933, 12.13867],
          [-11.473220000999959, 12.149020001000054],
          [-11.47845, 12.15625],
          [-11.48164000099996, 12.16068000100006],
          [-11.4945, 12.17847000100005],
          [-11.4959, 12.18606],
          [-11.4945, 12.19595],
          [-11.49777, 12.20952],
          [-11.497754287999953, 12.209534807000068],
          [-11.49777, 12.20953],
          [-11.49239, 12.21459],
          [-11.491537451999932, 12.216143963000036],
          [-11.49019, 12.2186],
          [-11.482570000999942, 12.232530001000043],
          [-11.476490000999945, 12.238500001000034],
          [-11.46316, 12.241720001000033],
          [-11.458710000999929, 12.24908],
          [-11.4531, 12.252990001000057],
          [-11.45193, 12.26587],
          [-11.44982, 12.271160001000055],
          [-11.443270000999973, 12.273230001000059],
          [-11.43859, 12.27783000100004],
          [-11.434850000999973, 12.286800001000074],
          [-11.433670000999939, 12.29738],
          [-11.43788000099994, 12.30474],
          [-11.437480000999926, 12.3091],
          [-11.436710000999938, 12.317390001000035],
          [-11.43974, 12.33465],
          [-11.45004, 12.35052],
          [-11.44863, 12.3549],
          [-11.44185, 12.35765],
          [-11.43997000099995, 12.3634],
          [-11.43974, 12.37076],
          [-11.43966, 12.37861],
          [-11.43719, 12.38197000100007],
          [-11.432130000999962, 12.383490001000041],
          [-11.42593, 12.38548],
          [-11.41633, 12.39537000100006],
          [-11.413990000999945, 12.395130001000041],
          [-11.412820000999943, 12.39122],
          [-11.4147, 12.38295000100004],
          [-11.412830000999975, 12.37788],
          [-11.408620000999974, 12.37765],
          [-11.402530000999946, 12.3834],
          [-11.399490000999947, 12.38202],
          [-11.392940000999943, 12.383850001000042],
          [-11.388610000999961, 12.383690001000048],
          [-11.3803, 12.38338],
          [-11.37375, 12.38936],
          [-11.37515, 12.39465],
          [-11.37468, 12.402240001000052],
          [-11.38052000099998, 12.41122000100006],
          [-11.37946, 12.41356],
          [-11.40071, 12.41528],
          [-11.408670000999962, 12.41758],
          [-11.43302, 12.43161],
          [-11.45971, 12.44149],
          [-11.47792, 12.44183],
          [-11.531350000999964, 12.44284],
          [-11.550070000999938, 12.44029],
          [-11.58144, 12.43314],
          [-11.60039, 12.42807],
          [-11.60039, 12.42692],
          [-11.603720000999942, 12.42664],
          [-11.63901, 12.42366000100003],
          [-11.64815, 12.42549],
          [-11.66383, 12.423400001000061],
          [-11.67201, 12.41995],
          [-11.68791, 12.40958],
          [-11.70335, 12.396910001000037],
          [-11.712470000999929, 12.393450001000076],
          [-11.72264, 12.392290001000049],
          [-11.76161, 12.387860001000035],
          [-11.78573, 12.39358],
          [-11.81382, 12.392840001000025],
          [-11.8494, 12.39738],
          [-11.85001000099993, 12.397620001000064],
          [-11.86065, 12.40174],
          [-11.88246, 12.41918],
          [-11.88714, 12.41986],
          [-11.89395000099995, 12.42721],
          [-11.90332000099994, 12.430870001000073],
          [-11.912680000999956, 12.43039],
          [-11.93186, 12.42529],
          [-11.93419, 12.422070001000066],
          [-11.958740000999967, 12.40706],
          [-11.960670000999926, 12.406430001000047],
          [-11.977680000999953, 12.40082],
          [-11.99874, 12.39686000100005],
          [-12.01676, 12.39659],
          [-12.02496, 12.399560001000054],
          [-12.03622, 12.40689],
          [-12.03716, 12.410800001000041],
          [-12.04961, 12.42319],
          [-12.073000000999969, 12.41899],
          [-12.075700000999973, 12.417500001000064],
          [-12.08209, 12.421340001000033],
          [-12.088650000999962, 12.42202],
          [-12.09684, 12.421310001000052],
          [-12.1132, 12.41231],
          [-12.13001, 12.39502],
          [-12.13982, 12.38856],
          [-12.159190000999956, 12.368050001000029],
          [-12.16598, 12.36849],
          [-12.17251, 12.36273],
          [-12.175639928999942, 12.361611786000026],
          [-12.17836, 12.36064],
          [-12.18568, 12.360930001000042],
          [-12.189140000999942, 12.358640001000026],
          [-12.21067, 12.358110001000057],
          [-12.23476, 12.355060001000027],
          [-12.292010000999937, 12.331180001000064],
          [-12.29785, 12.32702],
          [-12.30565, 12.324070001000052],
          [-12.309530000999928, 12.32261],
          [-12.330780000999937, 12.311490001000038],
          [-12.34363, 12.30731],
          [-12.35322, 12.307280001000038],
          [-12.360030000999927, 12.31139],
          [-12.368020000999934, 12.32148],
          [-12.36874, 12.325390001000073],
          [-12.3765, 12.333410001000061],
          [-12.37745, 12.336860001000048],
          [-12.38993, 12.35614],
          [-12.40037, 12.36547],
          [-12.40402, 12.36874],
          [-12.41435, 12.37445],
          [-12.434510000999978, 12.38242],
          [-12.44953, 12.39157],
          [-12.47414, 12.39906],
          [-12.489360000999966, 12.40061],
          [-12.496600000999933, 12.39759],
          [-12.505676937999965, 12.397334711000042],
          [-12.506200000999968, 12.39732],
          [-12.51761000099998, 12.389450001000057],
          [-12.54376, 12.37141],
          [-12.55545, 12.3679],
          [-12.562076008999952, 12.368378993000022],
          [-12.563143, 12.367398],
          [-12.561209, 12.365033],
          [-12.5647, 12.354554],
          [-12.607269, 12.33512],
          [-12.630777, 12.317556],
          [-12.65843, 12.306419],
          [-12.694961, 12.28753],
          [-12.721357, 12.271948],
          [-12.751214, 12.253917],
          [-12.7688, 12.22195],
          [-12.787394, 12.197689],
          [-12.804017, 12.175585],
          [-12.815445, 12.160144],
          [-12.826306, 12.144892],
          [-12.82676, 12.144254],
          [-12.819437, 12.140131],
          [-12.817605, 12.135948],
          [-12.812151, 12.132378],
          [-12.815884, 12.122643],
          [-12.80931, 12.112468],
          [-12.806763, 12.098125],
          [-12.819303, 12.077279],
          [-12.817775, 12.061724],
          [-12.829768, 12.056933],
          [-12.828315, 12.044978],
          [-12.826105, 12.026718],
          [-12.82345, 12.01779],
          [-12.821689, 12.002922],
          [-12.813905, 11.998134],
          [-12.799561, 11.995493],
          [-12.78793, 11.989784],
          [-12.775425, 11.978204],
          [-12.791996, 11.961348],
          [-12.799511, 11.950676],
          [-12.805862, 11.94373],
          [-12.809714, 11.938045],
          [-12.808411, 11.93604],
          [-12.788311, 11.915393],
          [-12.769294, 11.89699],
          [-12.760333, 11.863245],
          [-12.763041, 11.854824],
          [-12.77111, 11.840436],
          [-12.783519, 11.840095],
          [-12.795288, 11.835953],
          [-12.802463, 11.833427],
          [-12.7971, 11.813169],
          [-12.801385, 11.794471],
          [-12.79985, 11.785481],
          [-12.7981, 11.77524],
          [-12.78153, 11.75444],
          [-12.787697, 11.739091],
          [-12.788834, 11.723267],
          [-12.793028, 11.719688],
          [-12.804579, 11.712531],
          [-12.819057, 11.706128],
          [-12.818563, 11.696146],
          [-12.809092, 11.686348],
          [-12.795907, 11.6818],
          [-12.783823, 11.686293],
          [-12.78226, 11.678295],
          [-12.777967, 11.67242],
          [-12.770464, 11.663562],
          [-12.769942, 11.649773],
          [-12.763707, 11.641816],
          [-12.750203, 11.638603],
          [-12.750252, 11.62748],
          [-12.743967, 11.630791],
          [-12.741076, 11.624801],
          [-12.73367, 11.625467],
          [-12.732955, 11.621016],
          [-12.72534, 11.621135],
          [-12.721786, 11.611748],
          [-12.707889, 11.62372],
          [-12.701394, 11.616009],
          [-12.701189, 11.606551],
          [-12.707346, 11.595348],
          [-12.711164, 11.593946],
          [-12.706513, 11.582532],
          [-12.699983, 11.571402],
          [-12.694077, 11.563742],
          [-12.696966, 11.559263],
          [-12.703757, 11.566343],
          [-12.710137, 11.559855],
          [-12.729076, 11.544528],
          [-12.738711, 11.533988],
          [-12.753059, 11.531034],
          [-12.758344, 11.525743],
          [-12.75798, 11.509537],
          [-12.764246, 11.493804],
          [-12.78147, 11.488194],
          [-12.787843, 11.474362],
          [-12.796939, 11.466372],
          [-12.818959, 11.462377],
          [-12.833815, 11.456417],
          [-12.852524, 11.444772],
          [-12.870933, 11.439337],
          [-12.887883, 11.422265],
          [-12.900346, 11.404712],
          [-12.90366, 11.401576],
          [-12.899522, 11.399242],
          [-12.919125, 11.39054],
          [-12.930489, 11.384969],
          [-12.945955, 11.373932],
          [-12.965491, 11.356972],
          [-12.984275, 11.332014],
          [-12.973397, 11.312549],
          [-12.956411, 11.292906],
          [-12.97725, 11.277951],
          [-12.991064, 11.26676],
          [-12.989955, 11.253272],
          [-12.984297, 11.242071],
          [-12.975988, 11.226476],
          [-12.975555, 11.215345],
          [-12.985002, 11.200545],
          [-12.99568, 11.190061],
          [-12.996361, 11.189523]
        ]
      ]
    },
    properties: {
      name: 'Labe',
      center: [-12.286940332260953, 11.354046168633047],
      shapeName: 'Labe',
      shapeISO: '',
      shapeID: '26780975B40188570853212',
      shapeGroup: 'GIN',
      shapeType: 'ADM1'
    }
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-11.33679, 11.267492],
          [-11.351735, 11.269197],
          [-11.346205, 11.256327],
          [-11.366314, 11.259843],
          [-11.379829, 11.267385],
          [-11.392833, 11.269235],
          [-11.389387, 11.249234],
          [-11.38752, 11.238534],
          [-11.385701, 11.232192],
          [-11.390905, 11.232871],
          [-11.405575, 11.227606],
          [-11.446657, 11.21746],
          [-11.464152, 11.205182],
          [-11.505549, 11.185381],
          [-11.53899, 11.139832],
          [-11.561153, 11.109854],
          [-11.577405, 11.096486],
          [-11.595149, 11.084427],
          [-11.612066, 11.077683],
          [-11.623857, 11.075076],
          [-11.643289, 11.084188],
          [-11.649534, 11.081186],
          [-11.658068, 11.071997],
          [-11.661254, 11.068644],
          [-11.66789, 11.059673],
          [-11.673587, 11.053463],
          [-11.678974, 11.049331],
          [-11.6884, 11.041269],
          [-11.704262, 11.033676],
          [-11.708509, 11.019721],
          [-11.742933, 10.987454],
          [-11.75292, 10.993191],
          [-11.766511, 10.993026],
          [-11.783175, 10.99685],
          [-11.804864, 10.998608],
          [-11.832179, 11.007485],
          [-11.863128, 11.025533],
          [-11.866141, 11.028608],
          [-11.882211, 11.052194],
          [-11.887459, 11.068016],
          [-11.894885, 11.084886],
          [-11.895148, 11.099789],
          [-11.88957, 11.118633],
          [-11.878298, 11.143705],
          [-11.878417, 11.145535],
          [-11.871811, 11.144921],
          [-11.873299, 11.157015],
          [-11.878873, 11.17787],
          [-11.895959, 11.195443],
          [-11.913915, 11.204716],
          [-11.957142, 11.21671],
          [-11.961561, 11.218371],
          [-11.971741, 11.23212],
          [-11.977337, 11.246091],
          [-11.981293, 11.256465],
          [-11.996509, 11.260015],
          [-12.002724, 11.278854],
          [-12.011669, 11.290911],
          [-12.031711, 11.28821],
          [-12.050551, 11.29792],
          [-12.063537, 11.295497],
          [-12.073625, 11.293615],
          [-12.099031, 11.292821],
          [-12.120216, 11.286799],
          [-12.13413, 11.273539],
          [-12.14208, 11.24426],
          [-12.149711, 11.21729],
          [-12.162137, 11.191936],
          [-12.172507, 11.185494],
          [-12.190748, 11.17387],
          [-12.208362, 11.163423],
          [-12.208989, 11.162247],
          [-12.218216, 11.152644],
          [-12.237322, 11.143505],
          [-12.244725, 11.148947],
          [-12.256417, 11.166723],
          [-12.264897, 11.18538],
          [-12.27695, 11.192901],
          [-12.292541, 11.193668],
          [-12.324925, 11.18155],
          [-12.367765, 11.173827],
          [-12.380401, 11.175694],
          [-12.386646, 11.179855],
          [-12.395286, 11.181231],
          [-12.398771, 11.173451],
          [-12.404497, 11.163651],
          [-12.412519, 11.157843],
          [-12.416341, 11.141857],
          [-12.424725, 11.131298],
          [-12.435328, 11.135766],
          [-12.438585, 11.139011],
          [-12.445072, 11.145472],
          [-12.455272, 11.147187],
          [-12.46439, 11.147669],
          [-12.474987, 11.151043],
          [-12.479699, 11.155647],
          [-12.484877, 11.166968],
          [-12.486772, 11.172099],
          [-12.488576, 11.180328],
          [-12.491296, 11.192129],
          [-12.493266, 11.198059],
          [-12.496686, 11.20892],
          [-12.497381, 11.224402],
          [-12.502151, 11.232116],
          [-12.50306, 11.245587],
          [-12.50342, 11.25726],
          [-12.49645, 11.273662],
          [-12.497011, 11.285766],
          [-12.507665, 11.295867],
          [-12.522952, 11.292936],
          [-12.528931, 11.305918],
          [-12.540603, 11.315651],
          [-12.553287, 11.316682],
          [-12.563394, 11.323236],
          [-12.568182, 11.337719],
          [-12.580868, 11.337872],
          [-12.5961, 11.329916],
          [-12.603508, 11.328295],
          [-12.607821, 11.32734],
          [-12.624381, 11.322968],
          [-12.63725, 11.328538],
          [-12.638865, 11.323849],
          [-12.64641, 11.316378],
          [-12.656265, 11.315841],
          [-12.660139, 11.311428],
          [-12.655746, 11.305813],
          [-12.651471, 11.304017],
          [-12.647064, 11.299899],
          [-12.651615, 11.290398],
          [-12.655863, 11.281602],
          [-12.66528, 11.271289],
          [-12.679348, 11.262724],
          [-12.690553, 11.260344],
          [-12.701305, 11.258387],
          [-12.708071, 11.240936],
          [-12.714121, 11.238437],
          [-12.720758, 11.23679],
          [-12.728187, 11.235546],
          [-12.731237, 11.231785],
          [-12.731199, 11.220066],
          [-12.7328, 11.213266],
          [-12.741048, 11.213193],
          [-12.749665, 11.204148],
          [-12.755131, 11.196395],
          [-12.757697, 11.187119],
          [-12.757346, 11.17858],
          [-12.758984, 11.164609],
          [-12.763867, 11.149891],
          [-12.776063, 11.132951],
          [-12.77761, 11.12397],
          [-12.78877, 11.11114],
          [-12.78771, 11.10302],
          [-12.7893, 11.09291],
          [-12.79582, 11.08347],
          [-12.796076, 11.083343],
          [-12.81005, 11.07642],
          [-12.81165, 11.06738],
          [-12.81776, 11.05514],
          [-12.82295, 11.02668],
          [-12.82707, 11.0175],
          [-12.84543, 11.02947],
          [-12.85394, 11.03054],
          [-12.86006, 11.00912],
          [-12.88972, 10.99702],
          [-12.89943, 10.98665],
          [-12.90089, 10.97508],
          [-12.90754, 10.96364],
          [-12.93294, 10.95473],
          [-12.94518, 10.93584],
          [-12.9477, 10.89714],
          [-12.96034, 10.86509],
          [-12.95888, 10.84527],
          [-12.96659, 10.81973],
          [-12.96353, 10.80949],
          [-12.95688, 10.80377],
          [-12.9622, 10.79606],
          [-12.95515, 10.75775],
          [-12.94531, 10.74485],
          [-12.93866, 10.73993],
          [-12.93081, 10.72903],
          [-12.93573, 10.71506],
          [-12.9481, 10.69923],
          [-12.95302, 10.68806],
          [-12.95302, 10.66638],
          [-12.94278, 10.66319],
          [-12.94052, 10.65761],
          [-12.95808, 10.62901],
          [-12.96393, 10.60587],
          [-12.96872, 10.60613],
          [-12.97124, 10.61212],
          [-12.97417, 10.61159],
          [-12.98175, 10.60281],
          [-12.97909, 10.58791],
          [-12.97391, 10.57701],
          [-12.96167, 10.56956],
          [-12.95462, 10.55892],
          [-12.95223, 10.54921],
          [-12.9509, 10.53139],
          [-12.94704, 10.51796],
          [-12.9227, 10.51343],
          [-12.91671, 10.51569],
          [-12.89903, 10.52899],
          [-12.8816, 10.54682],
          [-12.87269, 10.54456],
          [-12.86458, 10.5387],
          [-12.85434, 10.53086],
          [-12.84237, 10.52966],
          [-12.8284, 10.53418],
          [-12.81577, 10.53338],
          [-12.8038, 10.52647],
          [-12.79741, 10.51676],
          [-12.78012, 10.51662],
          [-12.77188, 10.50426],
          [-12.7651, 10.49574],
          [-12.75845, 10.49827],
          [-12.75432, 10.49362],
          [-12.75352, 10.48776],
          [-12.74514, 10.48125],
          [-12.72586, 10.4847],
          [-12.71589, 10.49042],
          [-12.70059, 10.50559],
          [-12.69873, 10.5121],
          [-12.68636, 10.51982],
          [-12.64965, 10.55307],
          [-12.642029, 10.556716],
          [-12.63742, 10.55892],
          [-12.62505, 10.55852],
          [-12.61773, 10.55666],
          [-12.60669, 10.56198],
          [-12.58315, 10.58007],
          [-12.57251, 10.58339],
          [-12.55921, 10.60268],
          [-12.54365, 10.61265],
          [-12.53394, 10.61983],
          [-12.52397, 10.62209],
          [-12.51732, 10.63021],
          [-12.50375, 10.63553],
          [-12.4983, 10.62981],
          [-12.496181, 10.625764],
          [-12.49245, 10.61864],
          [-12.50747, 10.60507],
          [-12.50854, 10.57421],
          [-12.50654, 10.5649],
          [-12.50096, 10.55985],
          [-12.49776, 10.55373],
          [-12.49072, 10.54775],
          [-12.48367, 10.54655],
          [-12.46664, 10.54934],
          [-12.45773, 10.54402],
          [-12.44457, 10.52726],
          [-12.45161, 10.50612],
          [-12.44417, 10.50013],
          [-12.42767, 10.50027],
          [-12.41956, 10.49189],
          [-12.40985, 10.49056],
          [-12.40134, 10.48524],
          [-12.399719, 10.481095],
          [-12.39562, 10.47061],
          [-12.40959, 10.45358],
          [-12.42727, 10.38389],
          [-12.44204, 10.36673],
          [-12.44323, 10.35969],
          [-12.43938, 10.32218],
          [-12.4314, 10.31899],
          [-12.42289, 10.30183],
          [-12.41863, 10.28507],
          [-12.4185, 10.2739],
          [-12.41557, 10.27164],
          [-12.40147, 10.26885],
          [-12.38618, 10.26885],
          [-12.37341, 10.26472],
          [-12.3621, 10.26525],
          [-12.34295, 10.28427],
          [-12.33245, 10.28667],
          [-12.28443, 10.27536],
          [-12.27033, 10.27536],
          [-12.25863, 10.27816],
          [-12.24201, 10.27576],
          [-12.235774, 10.273129],
          [-12.23004, 10.27071],
          [-12.22738, 10.2586],
          [-12.22924, 10.25036],
          [-12.22618, 10.23639],
          [-12.22711, 10.22589],
          [-12.21767, 10.20487],
          [-12.21009, 10.19689],
          [-12.20317, 10.1929],
          [-12.19506, 10.18253],
          [-12.19453, 10.17388],
          [-12.18601, 10.15101],
          [-12.16513, 10.13585],
          [-12.15675, 10.12574],
          [-12.14651, 10.09967],
          [-12.1384, 10.0889],
          [-12.13348, 10.07693],
          [-12.1251, 10.03862],
          [-12.12842, 10.02492],
          [-12.13827, 10.00777],
          [-12.1642, 9.99952],
          [-12.17271, 9.97518],
          [-12.16766, 9.95324],
          [-12.15875, 9.93462],
          [-12.14826785699995, 9.877636538000047],
          [-12.142664730999968, 9.874754499000062],
          [-12.141820000999928, 9.874320001000058],
          [-12.126510000999929, 9.87138],
          [-12.120023341999968, 9.871849759000042],
          [-12.097497715999964, 9.878809470000022],
          [-12.08248, 9.88345],
          [-12.08204765399995, 9.883747328000027],
          [-12.07948, 9.885525],
          [-12.063642773999959, 9.88878704800004],
          [-12.0614, 9.88925000100005],
          [-12.054450375999977, 9.892254837000053],
          [-12.0475, 9.895261],
          [-12.03156, 9.898302],
          [-12.030967455999928, 9.898415008000029],
          [-12.02919, 9.898760001000028],
          [-11.93788000099994, 9.92655],
          [-11.92559, 9.92887],
          [-11.91864, 9.932563],
          [-11.913317638999956, 9.933994097000038],
          [-11.90977, 9.93495],
          [-11.906580000999952, 9.93581],
          [-11.89825, 9.94571],
          [-11.89139, 9.98619],
          [-11.889199451999957, 9.990646222000066],
          [-11.887982487999977, 9.99312188700003],
          [-11.88585, 9.997462],
          [-11.82868, 9.997452],
          [-11.798679264999976, 9.99744],
          [-11.76868, 9.99743],
          [-11.73481, 10],
          [-11.72553, 9.99795000100005],
          [-11.70146, 9.99836],
          [-11.65337, 9.99918],
          [-11.614390000999947, 9.99807],
          [-11.599531104999926, 9.998790189000033],
          [-11.58468, 9.999512],
          [-11.57588, 9.999563],
          [-11.57399, 9.999575],
          [-11.565314860999933, 9.998736653000037],
          [-11.563580000999934, 9.99857],
          [-11.5014, 10],
          [-11.49839, 10],
          [-11.477589, 9.999689],
          [-11.475633, 10.00298],
          [-11.474115, 10.009279],
          [-11.471357, 10.014791],
          [-11.468743, 10.020226],
          [-11.469302, 10.026374],
          [-11.470417, 10.054955],
          [-11.437963, 10.08338],
          [-11.430279, 10.111878],
          [-11.410196, 10.14299],
          [-11.402114, 10.164881],
          [-11.395053, 10.181987],
          [-11.387933, 10.21066],
          [-11.391767, 10.239716],
          [-11.381208, 10.25911],
          [-11.376092, 10.285442],
          [-11.377982, 10.305271],
          [-11.378827, 10.334705],
          [-11.382728, 10.350748],
          [-11.384643, 10.365757],
          [-11.384599, 10.374432],
          [-11.387306, 10.393562],
          [-11.390386, 10.411269],
          [-11.393752, 10.434523],
          [-11.398645, 10.451565],
          [-11.416567, 10.453156],
          [-11.442444, 10.457434],
          [-11.465278, 10.47221],
          [-11.488621, 10.485077],
          [-11.507014, 10.491986],
          [-11.527931, 10.492234],
          [-11.526832, 10.515712],
          [-11.520645, 10.539633],
          [-11.515156, 10.559849],
          [-11.519142, 10.570681],
          [-11.517479, 10.57397],
          [-11.507414, 10.607686],
          [-11.492074, 10.612541],
          [-11.478932, 10.637699],
          [-11.479061, 10.665],
          [-11.462111, 10.682072],
          [-11.458232, 10.702755],
          [-11.448311, 10.72396],
          [-11.461384, 10.732516],
          [-11.446132, 10.764601],
          [-11.448311, 10.794547],
          [-11.437417, 10.826632],
          [-11.457027, 10.850161],
          [-11.498424, 10.877968],
          [-11.524458, 10.876288],
          [-11.5258, 10.905738],
          [-11.508673, 10.94322],
          [-11.470287, 10.980536],
          [-11.454077, 10.993466],
          [-11.417321, 10.983411],
          [-11.388999, 10.97037],
          [-11.380541, 10.968629],
          [-11.350145, 10.971423],
          [-11.314717, 10.984164],
          [-11.278331, 10.989641],
          [-11.239377, 11.009971],
          [-11.230882, 11.015459],
          [-11.214905, 11.023095],
          [-11.202909, 11.031352],
          [-11.17945, 11.041137],
          [-11.15547, 11.055242],
          [-11.137166, 11.074795],
          [-11.148877, 11.078149],
          [-11.174227, 11.088193],
          [-11.208031, 11.100461],
          [-11.231347, 11.118629],
          [-11.254607, 11.147399],
          [-11.279045, 11.141022],
          [-11.306745, 11.178142],
          [-11.320935, 11.227322],
          [-11.33679, 11.267492]
        ]
      ]
    },
    properties: {
      name: 'Mamou',
      center: [-12.071620331628719, 10.403528569523152],
      shapeName: 'Mamou',
      shapeISO: '',
      shapeID: '26780975B8692014098499',
      shapeGroup: 'GIN',
      shapeType: 'ADM1'
    }
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-10.599707, 9.058478],
          [-10.59402, 9.054944],
          [-10.5848, 9.0432],
          [-10.583590242999946, 9.036371369000051],
          [-10.583718404999956, 9.036038632000043],
          [-10.58544, 9.031569],
          [-10.58619, 9.016176],
          [-10.586812536999958, 9.01444134600007],
          [-10.587590000999967, 9.01227],
          [-10.59176, 9.0093],
          [-10.592783665999946, 9.006916778000061],
          [-10.59432, 9.003337],
          [-10.59733992699995, 8.981136413000058],
          [-10.59734524199996, 8.981097341000066],
          [-10.598349938999945, 8.973710441000037],
          [-10.598071569999945, 8.971139496000035],
          [-10.597230215999957, 8.963368996000042],
          [-10.598169557999938, 8.95900705200006],
          [-10.595, 8.937860001000047],
          [-10.592616144999965, 8.93362025700003],
          [-10.592020178999974, 8.93256031900006],
          [-10.593649906999929, 8.926360353000064],
          [-10.59325, 8.914953],
          [-10.59302, 8.90867],
          [-10.596089912999958, 8.889610537000067],
          [-10.595530744999962, 8.886633964000055],
          [-10.59329, 8.87471],
          [-10.589570383999956, 8.854849192000074],
          [-10.586780274999967, 8.839951470000074],
          [-10.58772, 8.834670001000063],
          [-10.58381219699993, 8.822865552000053],
          [-10.58338060099993, 8.821561815000052],
          [-10.58524, 8.817890001000023],
          [-10.585322872999939, 8.817217590000041],
          [-10.5869, 8.804345],
          [-10.584097930999974, 8.80209684700003],
          [-10.58345, 8.80158],
          [-10.581962867999948, 8.801669999000069],
          [-10.57998, 8.801794],
          [-10.57859726199996, 8.800407262000022],
          [-10.57756, 8.799370001000057],
          [-10.576300000999936, 8.79811],
          [-10.57148381899998, 8.800959712000065],
          [-10.57052, 8.801531],
          [-10.56705, 8.80106],
          [-10.56269, 8.793693],
          [-10.53575, 8.77246],
          [-10.52749, 8.75704],
          [-10.527167328999951, 8.75446981600004],
          [-10.527025, 8.753337],
          [-10.52547, 8.740951],
          [-10.52318, 8.737496],
          [-10.5114, 8.738831],
          [-10.51026, 8.73538],
          [-10.517657602999975, 8.729896776000032],
          [-10.51218, 8.722530001000052],
          [-10.50686, 8.71538],
          [-10.510348611999973, 8.709422371000073],
          [-10.49399, 8.70201],
          [-10.48457, 8.68934],
          [-10.48276, 8.680145],
          [-10.47514, 8.680804],
          [-10.46939, 8.67458],
          [-10.47868, 8.659685],
          [-10.481709299999977, 8.652348694000068],
          [-10.47978459799998, 8.648757114000034],
          [-10.478500194999981, 8.646360362000053],
          [-10.47921, 8.640390001000071],
          [-10.480059747999974, 8.63955358100003],
          [-10.48176, 8.637876],
          [-10.4984, 8.631278],
          [-10.500881427999957, 8.62684411500004],
          [-10.50212, 8.62463],
          [-10.515760000999933, 8.618020001000048],
          [-10.518152696999948, 8.615427357000044],
          [-10.52294, 8.610238],
          [-10.52617, 8.609791],
          [-10.52618, 8.609790001000022],
          [-10.528009311999938, 8.611013329000059],
          [-10.53101000099997, 8.61302],
          [-10.54417, 8.611690001000056],
          [-10.550091013999975, 8.606569123000043],
          [-10.55157, 8.605289],
          [-10.55199988299995, 8.605103384000074],
          [-10.552860000999942, 8.60473],
          [-10.55943, 8.60187],
          [-10.56638000099997, 8.595690001000037],
          [-10.572286133999967, 8.596577483000033],
          [-10.57583, 8.597107],
          [-10.57805024399994, 8.596287910000058],
          [-10.57953, 8.59574],
          [-10.580920532999926, 8.593564166000021],
          [-10.582309877999933, 8.591388191000021],
          [-10.581210239999962, 8.575079556000048],
          [-10.58307, 8.571637],
          [-10.59023, 8.569366],
          [-10.59486, 8.564558],
          [-10.6023, 8.549655],
          [-10.604916341999967, 8.547931514000027],
          [-10.607840000999943, 8.546000001000039],
          [-10.609849160999943, 8.545478166000066],
          [-10.61811, 8.543326],
          [-10.62239, 8.541684],
          [-10.623490815999958, 8.535251899000059],
          [-10.62404, 8.532040001000041],
          [-10.629349954999952, 8.51722012700003],
          [-10.63112, 8.512279],
          [-10.631156196999939, 8.512116397000057],
          [-10.632890000999964, 8.50428],
          [-10.62947080899994, 8.494627277000063],
          [-10.630050362999953, 8.492673780000075],
          [-10.630630000999929, 8.49072],
          [-10.634430226999939, 8.492230090000021],
          [-10.63823, 8.493735],
          [-10.639222788999973, 8.49291862800004],
          [-10.63962, 8.49259],
          [-10.639622577999944, 8.492537090000042],
          [-10.63988, 8.487054],
          [-10.639445150999961, 8.483387088000029],
          [-10.639420521999966, 8.483179404000055],
          [-10.64243, 8.47974],
          [-10.64774, 8.478379],
          [-10.64959, 8.476318],
          [-10.64995788799996, 8.46889759100003],
          [-10.649960026999963, 8.468854449000048],
          [-10.65033, 8.46139],
          [-10.65153, 8.45733],
          [-10.651959947999956, 8.455887175000043],
          [-10.651840217999961, 8.449421764000022],
          [-10.651760033999949, 8.445091866000041],
          [-10.65278981199998, 8.441085817000044],
          [-10.65712967199994, 8.42421127800003],
          [-10.65347, 8.416162],
          [-10.65214003799997, 8.400310448000027],
          [-10.65286, 8.38975],
          [-10.6531, 8.38791],
          [-10.65656, 8.38517],
          [-10.66441, 8.38221],
          [-10.66928, 8.375107],
          [-10.67528, 8.370992],
          [-10.69191, 8.363926],
          [-10.69607, 8.359575],
          [-10.697761094999976, 8.354047574000049],
          [-10.69979000099994, 8.34741],
          [-10.701062184999955, 8.33873873500005],
          [-10.70191, 8.332956],
          [-10.70607, 8.3248],
          [-10.71628, 8.30476],
          [-10.71226877, 8.28284570300002],
          [-10.712200157999973, 8.282470859000057],
          [-10.712897345999977, 8.281845833000034],
          [-10.71705, 8.278123],
          [-10.71892, 8.27101],
          [-10.714080000999957, 8.27054],
          [-10.70605259499996, 8.272165879000056],
          [-10.7007, 8.273252],
          [-10.70052799299998, 8.279935115000058],
          [-10.700180000999978, 8.29346],
          [-10.698472828999968, 8.300035276000074],
          [-10.69762, 8.303322],
          [-10.695837617999928, 8.304628223000066],
          [-10.69138000099997, 8.307900001000064],
          [-10.68788000099994, 8.32258],
          [-10.673990000999936, 8.34091],
          [-10.668405080999946, 8.340905],
          [-10.66282, 8.340904],
          [-10.65668, 8.340903],
          [-10.63328, 8.334555],
          [-10.5971, 8.33226],
          [-10.588103616999945, 8.326958560000037],
          [-10.586460000999978, 8.32599],
          [-10.58234, 8.31144],
          [-10.581880032999948, 8.310863375000054],
          [-10.57958, 8.307985],
          [-10.5676, 8.307254],
          [-10.56092, 8.305163],
          [-10.55354, 8.307663],
          [-10.539670495999928, 8.31765067200007],
          [-10.52118, 8.33097],
          [-10.512170000999959, 8.335990001000027],
          [-10.49043, 8.35542],
          [-10.48416, 8.36757],
          [-10.47780003899993, 8.372134972000026],
          [-10.47144, 8.376703],
          [-10.466394246999926, 8.38546446600003],
          [-10.464710000999958, 8.388390001000062],
          [-10.46343000099995, 8.389120001000038],
          [-10.45269, 8.39523],
          [-10.44575, 8.40347],
          [-10.43135, 8.429820001000053],
          [-10.428607924999937, 8.432741727000064],
          [-10.42556, 8.435999],
          [-10.41724, 8.440558],
          [-10.410042647999944, 8.448408915000073],
          [-10.40843, 8.45017],
          [-10.40145, 8.465530001000047],
          [-10.399317656999926, 8.468465108000032],
          [-10.3938, 8.476060001000064],
          [-10.392119537999974, 8.480737287000068],
          [-10.3896, 8.487753],
          [-10.388705479999942, 8.489017649000061],
          [-10.38598, 8.49288000100006],
          [-10.38597, 8.492885],
          [-10.3727, 8.487056],
          [-10.36399, 8.48814],
          [-10.3534, 8.49164],
          [-10.346890000999963, 8.49607],
          [-10.343115626999975, 8.497660438000025],
          [-10.33557, 8.500842],
          [-10.33141, 8.502891],
          [-10.331400000999963, 8.50289],
          [-10.327195608999943, 8.501172591000056],
          [-10.32173, 8.498944],
          [-10.30196, 8.488677],
          [-10.288747501999978, 8.485283751000054],
          [-10.28686000099998, 8.4848],
          [-10.281023816999948, 8.488619071000073],
          [-10.27818, 8.49048],
          [-10.2694, 8.49159],
          [-10.26227, 8.48765],
          [-10.25187000099993, 8.49128],
          [-10.25068676099994, 8.491313655000056],
          [-10.24851339199995, 8.491375473000062],
          [-10.248497562999944, 8.491375922000032],
          [-10.24519, 8.491474],
          [-10.241513538999982, 8.487326992000021],
          [-10.234643541999958, 8.494760459000076],
          [-10.23201, 8.497613],
          [-10.22948, 8.496682],
          [-10.229330138999956, 8.495304607000037],
          [-10.229244883999968, 8.494522154000038],
          [-10.229030312999953, 8.492548876000058],
          [-10.231139465999945, 8.484978917000035],
          [-10.230861711999978, 8.484416424000074],
          [-10.229542712999944, 8.481759465000039],
          [-10.21811, 8.48092],
          [-10.21258, 8.480470001000072],
          [-10.207899417999954, 8.486433],
          [-10.2032, 8.492421],
          [-10.19974, 8.493093],
          [-10.19325, 8.499493],
          [-10.185256786999958, 8.504247779000025],
          [-10.174251570999957, 8.510798386000033],
          [-10.171040000999938, 8.512710001000073],
          [-10.165839567999967, 8.51413149900003],
          [-10.164930067999933, 8.514380103000065],
          [-10.163649643999975, 8.514731354000048],
          [-10.162444274999928, 8.519302561000075],
          [-10.16129, 8.52368],
          [-10.15805, 8.52642],
          [-10.14609, 8.519240001000071],
          [-10.142390000999967, 8.52244],
          [-10.138240000999929, 8.52196],
          [-10.13106050999994, 8.523863610000035],
          [-10.130549626999937, 8.523999068000023],
          [-10.12553, 8.525335],
          [-10.121677511999962, 8.523300280000058],
          [-10.120696702999965, 8.522783531000073],
          [-10.11695, 8.522251],
          [-10.10894, 8.521115],
          [-10.10066, 8.51533],
          [-10.09373, 8.51805],
          [-10.08681, 8.518014],
          [-10.083392290999939, 8.516026814000043],
          [-10.083202506999953, 8.515916688000061],
          [-10.08083, 8.51454],
          [-10.079579974999945, 8.510969929000055],
          [-10.07833, 8.507403],
          [-10.071874820999938, 8.506679980000058],
          [-10.071560725999973, 8.506644946000051],
          [-10.06542000099995, 8.50596],
          [-10.060895520999964, 8.50160261700006],
          [-10.05486, 8.495794],
          [-10.05257, 8.493256],
          [-10.052506155999936, 8.492905428000029],
          [-10.05237789499995, 8.492213196000023],
          [-10.05189, 8.48958],
          [-10.054517823999959, 8.476380028000051],
          [-10.056447138999943, 8.466688772000055],
          [-10.05664, 8.465718],
          [-10.05815432399993, 8.463139394000052],
          [-10.062690000999964, 8.455410001000075],
          [-10.062850783999977, 8.454526221000037],
          [-10.06419, 8.447115],
          [-10.067376556999932, 8.42960952900006],
          [-10.067439929999978, 8.429260388000046],
          [-10.06730796499994, 8.428111695000041],
          [-10.066780000999927, 8.423520001000043],
          [-10.06263, 8.421200001000045],
          [-10.055260000999965, 8.42093],
          [-10.037680000999956, 8.43048],
          [-10.02845, 8.431350001000055],
          [-10.026561899999933, 8.432152087000077],
          [-10.02314, 8.433613],
          [-10.0164, 8.441844],
          [-10.01294, 8.442054],
          [-10.00673, 8.439493],
          [-10.003845259999935, 8.439477140000065],
          [-10.003753528999937, 8.43947673200006],
          [-10, 8.43946],
          [-9.99866, 8.43945],
          [-9.99496, 8.44127000100002],
          [-9.988461129999962, 8.44702187400003],
          [-9.988004, 8.447427],
          [-9.97972, 8.457685],
          [-9.977602952999973, 8.46030638600007],
          [-9.97361, 8.46526],
          [-9.96504955599994, 8.467825283000025],
          [-9.964599612999962, 8.467960316000074],
          [-9.95429742899995, 8.476365728000076],
          [-9.945593, 8.48347],
          [-9.944258199999979, 8.483693221000067],
          [-9.94028162899997, 8.484359727000026],
          [-9.938652278999939, 8.48641149100007],
          [-9.93448000099994, 8.49167],
          [-9.931949430999964, 8.492738425000027],
          [-9.919440000999941, 8.498020001000043],
          [-9.89613000099996, 8.50018],
          [-9.895319939999979, 8.499668615000076],
          [-9.894485, 8.500412],
          [-9.891758660999926, 8.49742040600006],
          [-9.88878, 8.495540001000052],
          [-9.88509, 8.495520001000045],
          [-9.875630000999934, 8.49661],
          [-9.87254390399994, 8.497898363000047],
          [-9.869455, 8.499194],
          [-9.867537, 8.500002],
          [-9.861758333999944, 8.50043277900005],
          [-9.85899, 8.50064],
          [-9.849281683999948, 8.49011474200006],
          [-9.848768, 8.489558],
          [-9.848655359999952, 8.489435807000064],
          [-9.84685000099995, 8.48748],
          [-9.845035690999964, 8.488137073000075],
          [-9.844999885999925, 8.488150208000036],
          [-9.83987, 8.49754],
          [-9.837609115999953, 8.499334633000046],
          [-9.837299, 8.499582],
          [-9.83370647199996, 8.50099563300006],
          [-9.832680000999972, 8.5014],
          [-9.80431, 8.502600001000076],
          [-9.8001419199999, 8.50532492900004],
          [-9.795275, 8.508511],
          [-9.794485301999941, 8.511125659000072],
          [-9.794100000999947, 8.51241],
          [-9.79569, 8.51586],
          [-9.79727894299998, 8.516489436000029],
          [-9.799833, 8.517497],
          [-9.800022, 8.524158],
          [-9.801880504999929, 8.524170085000037],
          [-9.801937685999974, 8.524170395000056],
          [-9.803713, 8.524182],
          [-9.804392, 8.526254],
          [-9.80106763799995, 8.532429743000023],
          [-9.800777517999961, 8.532969379000065],
          [-9.799960000999931, 8.534490001000052],
          [-9.79741, 8.536085],
          [-9.796044, 8.534806],
          [-9.794243499999936, 8.533120654000072],
          [-9.7942, 8.53308],
          [-9.792764337999927, 8.533188841000026],
          [-9.79143, 8.533291],
          [-9.78952895599997, 8.53520502300006],
          [-9.78191, 8.54288],
          [-9.77905, 8.54798],
          [-9.77439310699998, 8.550893425000027],
          [-9.77296, 8.551791],
          [-9.768840523999927, 8.551072121000061],
          [-9.75936, 8.549420001000044],
          [-9.753600000999938, 8.546170001000064],
          [-9.74584, 8.53097],
          [-9.735709773999929, 8.52032126000006],
          [-9.73529, 8.51988],
          [-9.734845, 8.516431],
          [-9.737834335999935, 8.512073047000058],
          [-9.74159, 8.50659],
          [-9.74512, 8.49398],
          [-9.742850000999965, 8.48685],
          [-9.72314, 8.464000001000045],
          [-9.72, 8.44768],
          [-9.717480000999956, 8.44445],
          [-9.712640000999954, 8.44442],
          [-9.70915, 8.44945],
          [-9.707170000999952, 8.47401],
          [-9.704876372999934, 8.480117449000034],
          [-9.704589, 8.480884],
          [-9.698409761999926, 8.486076246000039],
          [-9.691840000999946, 8.4916],
          [-9.69184, 8.49086554300004],
          [-9.691840000999946, 8.490682137000022],
          [-9.671058, 8.493541],
          [-9.660473, 8.488653],
          [-9.65245, 8.478960001000075],
          [-9.633610000999965, 8.46598],
          [-9.62812, 8.4586],
          [-9.627844911999944, 8.457964451000066],
          [-9.627459159999944, 8.45707322900006],
          [-9.624932047999948, 8.451228111000034],
          [-9.625039951999952, 8.451128496000024],
          [-9.63235, 8.44438],
          [-9.634488638999926, 8.440841398000032],
          [-9.635385, 8.439352],
          [-9.643725, 8.433433],
          [-9.655796, 8.420648],
          [-9.659217615999978, 8.411507146000076],
          [-9.65931, 8.411260001000073],
          [-9.65774, 8.40413],
          [-9.654750198999977, 8.40227012400004],
          [-9.645521, 8.402903],
          [-9.63419, 8.407654],
          [-9.633495, 8.407692],
          [-9.622884, 8.408272],
          [-9.61400439099998, 8.407489079000072],
          [-9.606050000999971, 8.40679],
          [-9.59796, 8.41064],
          [-9.59325587099994, 8.415397719000055],
          [-9.590065, 8.418627],
          [-9.58469, 8.429844],
          [-9.582599981999977, 8.43105541400007],
          [-9.582569926999952, 8.431072893000021],
          [-9.5796, 8.4328],
          [-9.577187667999965, 8.432280890000072],
          [-9.576947835999931, 8.432229280000058],
          [-9.574067, 8.431613],
          [-9.570597822999957, 8.425220480000064],
          [-9.57019, 8.424470001000032],
          [-9.568180392999977, 8.413662111000065],
          [-9.565835311999933, 8.410227297000063],
          [-9.563394388999939, 8.406653704000064],
          [-9.561090173999958, 8.403280255000027],
          [-9.542252, 8.389766],
          [-9.53331, 8.383351],
          [-9.486964, 8.380974],
          [-9.480072, 8.376794],
          [-9.479784096999936, 8.37630897300005],
          [-9.47733, 8.37218],
          [-9.477592751999964, 8.367742965000048],
          [-9.477601093999965, 8.367602092000027],
          [-9.477855, 8.363231],
          [-9.480205, 8.356643],
          [-9.481589308999958, 8.35478243700004],
          [-9.481796936999956, 8.354502818000071],
          [-9.48618, 8.348600001000023],
          [-9.48944, 8.3445],
          [-9.5156, 8.32999],
          [-9.517676093999967, 8.328254622000031],
          [-9.520167456999957, 8.326167130000044],
          [-9.514570000999981, 8.31985],
          [-9.512169096999969, 8.318287198000064],
          [-9.501024, 8.311034],
          [-9.50049486599994, 8.305699278000077],
          [-9.500158, 8.302303],
          [-9.504149888999962, 8.291079312000022],
          [-9.503260000999944, 8.285560001000022],
          [-9.502708000999974, 8.28481594300007],
          [-9.499148, 8.280024],
          [-9.494396712999958, 8.277140832000043],
          [-9.494330115999958, 8.277100492000045],
          [-9.49111, 8.27515],
          [-9.49031569899995, 8.273034566000035],
          [-9.488165, 8.267322],
          [-9.489175959999955, 8.264519627000027],
          [-9.48928000099994, 8.26423],
          [-9.516600000999972, 8.2427],
          [-9.51608686399993, 8.23843565900006],
          [-9.516019, 8.237877],
          [-9.516025, 8.236972],
          [-9.509840116999953, 8.230730119000043],
          [-9.511120000999938, 8.210080001000051],
          [-9.51267822899996, 8.206570154000076],
          [-9.512763186999962, 8.206378789000041],
          [-9.514166, 8.20321],
          [-9.516500019999967, 8.201106277000065],
          [-9.516840121999962, 8.200799208000035],
          [-9.51973000099997, 8.19819],
          [-9.520243768999933, 8.195478593000075],
          [-9.521638, 8.188107],
          [-9.51985, 8.179371],
          [-9.514802, 8.175894],
          [-9.504177, 8.179266],
          [-9.50196600399994, 8.178620554000076],
          [-9.500960000999953, 8.178330001000063],
          [-9.495010000999969, 8.170940001000076],
          [-9.489720000999966, 8.169760001000043],
          [-9.48164000099996, 8.17154],
          [-9.469440000999953, 8.169390001000068],
          [-9.466699121999966, 8.167787899000075],
          [-9.464529, 8.166521],
          [-9.463471, 8.165902],
          [-9.46172015299993, 8.151658244000032],
          [-9.463217, 8.134449],
          [-9.462329, 8.129393],
          [-9.459291494999945, 8.126882028000068],
          [-9.457290231999934, 8.125227672000051],
          [-9.45925970299993, 8.105498968000063],
          [-9.455881188999967, 8.093764126000053],
          [-9.449018, 8.086371],
          [-9.44845086, 8.083945885000048],
          [-9.448361567999939, 8.083565565000072],
          [-9.447670000999949, 8.08062],
          [-9.447253780999972, 8.080164285000023],
          [-9.444929, 8.077621],
          [-9.444740028999945, 8.071420936000038],
          [-9.445192900999928, 8.070410457000037],
          [-9.450586, 8.058377],
          [-9.453831684999955, 8.053694803000042],
          [-9.4558, 8.05085],
          [-9.457555071999934, 8.048323640000035],
          [-9.458029, 8.04764],
          [-9.458751460999963, 8.04573460100005],
          [-9.46037, 8.04146],
          [-9.460119024999926, 8.038304868000068],
          [-9.460095696999929, 8.038011611000059],
          [-9.459948149999946, 8.036177862000045],
          [-9.459070235999945, 8.035162773000025],
          [-9.456747, 8.032481],
          [-9.45475487899995, 8.032183512000074],
          [-9.453525128999956, 8.032000763000042],
          [-9.440782, 8.043159],
          [-9.43038, 8.04814],
          [-9.429676725999968, 8.048076298000069],
          [-9.427620089999948, 8.04788718900005],
          [-9.427820466999947, 8.041544171000055],
          [-9.42791, 8.038710001000027],
          [-9.428101209999966, 8.037995420000072],
          [-9.428315405999967, 8.037194932000034],
          [-9.431218, 8.026335],
          [-9.429869361999977, 8.021739641000067],
          [-9.4216, 8.017090001000042],
          [-9.421346974999949, 8.014318650000064],
          [-9.421176, 8.012492],
          [-9.42133, 8],
          [-9.411776, 7.986394],
          [-9.41055384699996, 7.982274915000062],
          [-9.41049650499997, 7.982082467000055],
          [-9.40973, 7.97951],
          [-9.41183, 7.97263],
          [-9.412593810999965, 7.972122592000062],
          [-9.415533370999981, 7.970169811000062],
          [-9.415725814999973, 7.970041968000032],
          [-9.423147, 7.965106],
          [-9.432427, 7.947937],
          [-9.428607, 7.924752],
          [-9.424505, 7.914182],
          [-9.430327, 7.898146],
          [-9.423958, 7.872638],
          [-9.423477, 7.862288],
          [-9.417027, 7.852179],
          [-9.41531925199996, 7.850622996000027],
          [-9.41091, 7.84661],
          [-9.407689773999948, 7.838344502000041],
          [-9.406637, 7.835647],
          [-9.40624801399997, 7.83524783200005],
          [-9.39586000099996, 7.82459],
          [-9.3904, 7.809200001000022],
          [-9.387832097999933, 7.80680009200006],
          [-9.38719, 7.806201],
          [-9.386419802999967, 7.806076627000039],
          [-9.38282, 7.8055],
          [-9.378680000999964, 7.80296],
          [-9.369490000999974, 7.803680001000032],
          [-9.359363, 7.796106],
          [-9.35475, 7.78031],
          [-9.355980000999978, 7.77825000100006],
          [-9.356324732999951, 7.777678355000035],
          [-9.35635075099998, 7.777635211000074],
          [-9.356769, 7.776936],
          [-9.357308916999955, 7.775340632000052],
          [-9.357388746999959, 7.775103718000025],
          [-9.355328, 7.772801],
          [-9.349106, 7.774152],
          [-9.3482, 7.770020001000034],
          [-9.349708981999981, 7.763393321000024],
          [-9.350548, 7.759702],
          [-9.349849426999981, 7.756968207000057],
          [-9.349808843999938, 7.75680995700003],
          [-9.349720674999958, 7.756466155000055],
          [-9.34896, 7.7535],
          [-9.347573488999956, 7.752168510000047],
          [-9.347326929999952, 7.751931735000028],
          [-9.343918, 7.748662],
          [-9.347284227999978, 7.742338622000034],
          [-9.35111, 7.73515],
          [-9.35187, 7.7184],
          [-9.360905, 7.707952],
          [-9.36095045899998, 7.707899415000043],
          [-9.362741016999962, 7.705828138000072],
          [-9.363779543999954, 7.703925236000032],
          [-9.369237, 7.693922],
          [-9.370667, 7.681994],
          [-9.36945293499997, 7.673845027000027],
          [-9.369392732999927, 7.673442132000048],
          [-9.368200000999934, 7.66546],
          [-9.372190000999979, 7.64574],
          [-9.369510182999932, 7.638854233000075],
          [-9.369238219999943, 7.638157564000039],
          [-9.368965098999979, 7.63778375000004],
          [-9.365088, 7.632481],
          [-9.364550709999946, 7.631746156000077],
          [-9.36446929899995, 7.631635376000077],
          [-9.36351, 7.63033],
          [-9.35662, 7.62801],
          [-9.356041145999939, 7.627360897000074],
          [-9.354559, 7.625704],
          [-9.35715, 7.61217],
          [-9.357693988999927, 7.61132841500006],
          [-9.376374, 7.582417],
          [-9.377372, 7.574505],
          [-9.378515, 7.565443],
          [-9.389703969999971, 7.545795672000054],
          [-9.39938, 7.5288],
          [-9.39938774199993, 7.528513537000038],
          [-9.399508, 7.523986],
          [-9.399583193999945, 7.521109300000035],
          [-9.39965, 7.51848],
          [-9.400240347999954, 7.517454016000045],
          [-9.405447, 7.508401],
          [-9.410257659999957, 7.495104609000066],
          [-9.410679167999945, 7.493938947000061],
          [-9.41871, 7.47173],
          [-9.4298, 7.461670001000073],
          [-9.433975, 7.454116],
          [-9.435802182999964, 7.453192826000077],
          [-9.44389, 7.4491],
          [-9.45128000099993, 7.44202],
          [-9.45335225599996, 7.438671885000076],
          [-9.453644026999939, 7.438200473000052],
          [-9.456379, 7.433776],
          [-9.460887, 7.418865],
          [-9.466978042999926, 7.398712045000025],
          [-9.47107, 7.38517],
          [-9.471945095999956, 7.384397698000043],
          [-9.477537, 7.379457],
          [-9.478456835999964, 7.376559318000034],
          [-9.4787, 7.37579],
          [-9.478257307999968, 7.368696637000028],
          [-9.477838024999926, 7.36201139700006],
          [-9.46815, 7.36886],
          [-9.462370000999954, 7.37595],
          [-9.45655, 7.39177],
          [-9.451955759999976, 7.39567949800005],
          [-9.446619, 7.400223],
          [-9.415713, 7.417775],
          [-9.410858, 7.423495],
          [-9.409350075999953, 7.423215468000024],
          [-9.408330000999968, 7.423030001000029],
          [-9.406554864999976, 7.421093489000043],
          [-9.40558, 7.420031],
          [-9.40513627799993, 7.418922513000041],
          [-9.40314000099994, 7.41394],
          [-9.40079, 7.40808],
          [-9.39696, 7.38855],
          [-9.39607545499996, 7.388612125000066],
          [-9.393971, 7.388764],
          [-9.391016917999934, 7.390712450000024],
          [-9.390953766999928, 7.390754203000029],
          [-9.38913, 7.39196],
          [-9.386043466999979, 7.39165326400007],
          [-9.384299251999948, 7.391480322000064],
          [-9.382365635999975, 7.39231350700004],
          [-9.380609, 7.393072],
          [-9.372733, 7.405436],
          [-9.36494361399997, 7.410174175000066],
          [-9.36073986599996, 7.412731257000075],
          [-9.359780000999933, 7.421680001000027],
          [-9.353506153999945, 7.426556783000024],
          [-9.35008, 7.42922],
          [-9.347275769999953, 7.429074854000021],
          [-9.345250000999954, 7.428970001000039],
          [-9.342105897999943, 7.427474536000034],
          [-9.337444, 7.425263],
          [-9.334126802999947, 7.424732447000054],
          [-9.33008, 7.42409],
          [-9.325406384999951, 7.425425320000045],
          [-9.325343288999932, 7.425443347000055],
          [-9.324555, 7.425669],
          [-9.32337083899995, 7.426978508000047],
          [-9.320630000999927, 7.43002],
          [-9.31072893199996, 7.429150069000059],
          [-9.309584, 7.42905],
          [-9.304801230999942, 7.425528397000051],
          [-9.304572276999977, 7.425360027000067],
          [-9.300180000999944, 7.422130001000028],
          [-9.29527, 7.422640001000048],
          [-9.293109827999956, 7.418290465000041],
          [-9.29009, 7.41221],
          [-9.28889920499995, 7.405643994000059],
          [-9.288871705999952, 7.405492369000058],
          [-9.288297, 7.402335],
          [-9.289948, 7.392701],
          [-9.28858850599994, 7.388109088000022],
          [-9.28322151499998, 7.384827555000072],
          [-9.281297816999938, 7.383651351000026],
          [-9.274587, 7.379552],
          [-9.270731370999954, 7.379761904000077],
          [-9.26676, 7.37998],
          [-9.26533995099993, 7.380621432000055],
          [-9.259104, 7.383443],
          [-9.23528466099998, 7.387891325000055],
          [-9.227320000999953, 7.38938],
          [-9.217268356999966, 7.386663340000041],
          [-9.21696044, 7.38658011900003],
          [-9.211778, 7.385181],
          [-9.207747, 7.383283],
          [-9.20210024499994, 7.380620116000046],
          [-9.202229706999958, 7.379097084000023],
          [-9.203797, 7.360659],
          [-9.205447, 7.356916],
          [-9.205908227999942, 7.355871053000044],
          [-9.206120000999931, 7.355390001000046],
          [-9.206139630999928, 7.351336395000033],
          [-9.206139981999968, 7.351259963000075],
          [-9.204762309999978, 7.34854488600007],
          [-9.201347, 7.341824],
          [-9.201343301999941, 7.341217179000068],
          [-9.201330000999974, 7.34002],
          [-9.201227425999946, 7.323505510000075],
          [-9.201209, 7.320703],
          [-9.195473812999978, 7.311554522000051],
          [-9.195437046999928, 7.311495903000036],
          [-9.19024, 7.30321],
          [-9.184789724999973, 7.300306706000072],
          [-9.181978, 7.29881],
          [-9.160678, 7.27668],
          [-9.15379, 7.273436],
          [-9.13667974699996, 7.273957401000075],
          [-9.134, 7.27404000100006],
          [-9.12065, 7.276510001000076],
          [-9.119197634999978, 7.275630354000043],
          [-9.116056, 7.273731],
          [-9.113697, 7.25734],
          [-9.113606, 7.256712],
          [-9.110641, 7.252284],
          [-9.104943, 7.250193],
          [-9.104547598999943, 7.247345093000035],
          [-9.103936, 7.24294],
          [-9.102276, 7.240062],
          [-9.095346908999943, 7.227913061000038],
          [-9.094150000999946, 7.22582],
          [-9.09564, 7.222990001000028],
          [-9.096443595999972, 7.218716326000049],
          [-9.09652, 7.21831],
          [-9.09994093399996, 7.213769872000057],
          [-9.104618, 7.207559],
          [-9.105797, 7.201135],
          [-9.104642094999974, 7.194628575000024],
          [-9.104450420999967, 7.193552363000038],
          [-9.092455, 7.201077],
          [-9.086480800999936, 7.202241594000043],
          [-9.086355243999947, 7.20226623800005],
          [-9.084400000999949, 7.20265],
          [-9.06697961599997, 7.201049976000036],
          [-9.06599989199998, 7.200960082000051],
          [-9.06493723899996, 7.201761673000021],
          [-9.053303, 7.210546],
          [-9.04647678899994, 7.214519766000024],
          [-9.04635641599998, 7.21458997600007],
          [-9.04039, 7.218070001000058],
          [-9.036, 7.222410001000071],
          [-9.03181, 7.232720001000075],
          [-9.029389138999932, 7.234486052000022],
          [-9.016803, 7.243674],
          [-9.006499395999981, 7.243086326000025],
          [-9.00427, 7.24296],
          [-9.00369, 7.24293],
          [-8.99285, 7.249770001000059],
          [-8.97652, 7.24832],
          [-8.96438879599998, 7.251556265000033],
          [-8.963851, 7.2517],
          [-8.958549962999939, 7.254200074000039],
          [-8.956691132999936, 7.257867764000025],
          [-8.957124, 7.263608],
          [-8.953183, 7.269789],
          [-8.948350000999937, 7.26931],
          [-8.945081200999937, 7.264457350000043],
          [-8.944469, 7.26355],
          [-8.944285666999974, 7.26350031000004],
          [-8.94102, 7.26262],
          [-8.93779, 7.26513],
          [-8.937789816999953, 7.265164878000064],
          [-8.937763, 7.270866],
          [-8.933381, 7.273372],
          [-8.933467812999936, 7.273556578000068],
          [-8.93771000099997, 7.28257],
          [-8.93701, 7.28555],
          [-8.928959, 7.284141],
          [-8.920876, 7.287661],
          [-8.919045, 7.288458],
          [-8.91722710199997, 7.288207037000063],
          [-8.915597, 7.287982],
          [-8.911908929999981, 7.281845717000067],
          [-8.911724651999975, 7.281539460000033],
          [-8.90646, 7.27279],
          [-8.906873140999949, 7.269150959000058],
          [-8.907186, 7.266363],
          [-8.905776723999963, 7.26310946500007],
          [-8.905591065999943, 7.262682450000057],
          [-8.90284, 7.261062],
          [-8.863511, 7.258125],
          [-8.848072, 7.263333],
          [-8.836682572999962, 7.279049176000058],
          [-8.833480991999977, 7.283468630000073],
          [-8.833461, 7.2876],
          [-8.834217011999954, 7.289017313000045],
          [-8.83573, 7.29185],
          [-8.837338002999957, 7.294868196000039],
          [-8.83825, 7.296580001000052],
          [-8.837770000999967, 7.30185],
          [-8.833370000999935, 7.30734],
          [-8.833247154999981, 7.309508682000057],
          [-8.833245371999965, 7.309540145000028],
          [-8.833114, 7.311933],
          [-8.837683, 7.318842],
          [-8.837221406999959, 7.325288392000061],
          [-8.837190194999948, 7.325727271000062],
          [-8.84199, 7.332407],
          [-8.846082, 7.342757],
          [-8.845927071999938, 7.345567757000026],
          [-8.84583, 7.34735],
          [-8.83539, 7.36452],
          [-8.832384871999977, 7.368040077000046],
          [-8.815492, 7.387836],
          [-8.811285, 7.40136],
          [-8.810303627999929, 7.401599858000054],
          [-8.80668, 7.40249],
          [-8.805410592999976, 7.403597217000026],
          [-8.802818345999981, 7.405858257000034],
          [-8.80081, 7.407610001000023],
          [-8.799253102999955, 7.408967982000036],
          [-8.796741, 7.411161],
          [-8.792145, 7.409762],
          [-8.788452, 7.41204],
          [-8.787473335999948, 7.411847280000075],
          [-8.782475, 7.410863],
          [-8.779938138999967, 7.412228387000027],
          [-8.777623608999932, 7.422658109000054],
          [-8.774291, 7.437682],
          [-8.776334, 7.44343],
          [-8.775391, 7.448017],
          [-8.772390000999962, 7.4503],
          [-8.75718, 7.454360001000055],
          [-8.7463, 7.46877],
          [-8.726977895999937, 7.489433918000032],
          [-8.726861, 7.489559],
          [-8.726428410999972, 7.489960601000064],
          [-8.715302, 7.50029],
          [-8.713861280999936, 7.50213386300004],
          [-8.70905, 7.508300001000066],
          [-8.706720000999951, 7.51425],
          [-8.708481037999945, 7.519036170000049],
          [-8.712394, 7.529655],
          [-8.711733951999975, 7.533489904000021],
          [-8.711280000999977, 7.536140001000035],
          [-8.71074, 7.53929],
          [-8.71161951099998, 7.546635913000046],
          [-8.719451, 7.545297],
          [-8.721744, 7.547143],
          [-8.722532918999946, 7.551597970000046],
          [-8.72264, 7.552200001000074],
          [-8.720852755999942, 7.558505561000061],
          [-8.713740000999962, 7.5836],
          [-8.71259648199998, 7.592705788000046],
          [-8.711582, 7.6008],
          [-8.704133, 7.617746],
          [-8.704331, 7.624173],
          [-8.705828, 7.629555],
          [-8.707272, 7.634744],
          [-8.707134087999975, 7.636235032000059],
          [-8.70679, 7.64002],
          [-8.702034796999953, 7.645145419000073],
          [-8.701015, 7.646246],
          [-8.700380000999928, 7.64668],
          [-8.687580000999958, 7.655400001000032],
          [-8.67662, 7.668810001000054],
          [-8.6703, 7.68793],
          [-8.66612, 7.69309],
          [-8.663374404999956, 7.694336891000034],
          [-8.663136291999933, 7.694445027000029],
          [-8.659362, 7.696163],
          [-8.639963101999967, 7.695475520000059],
          [-8.638829297999962, 7.695435511000028],
          [-8.6007, 7.69409],
          [-8.571949933999974, 7.693065468000043],
          [-8.56557688099997, 7.692838359000064],
          [-8.565501128999927, 7.692835660000071],
          [-8.559730000999934, 7.692630001000055],
          [-8.55811320999993, 7.685326570000029],
          [-8.557989, 7.684766],
          [-8.556175, 7.676572],
          [-8.554747, 7.649656],
          [-8.565239469999938, 7.625899465000032],
          [-8.562502595999945, 7.623140269000032],
          [-8.562460930999976, 7.623098264000021],
          [-8.559104, 7.61972],
          [-8.553959, 7.614541],
          [-8.544582, 7.605104],
          [-8.487146, 7.57121],
          [-8.481278528999951, 7.564691372000027],
          [-8.47218, 7.55459],
          [-8.440890000999957, 7.58653],
          [-8.417430000999957, 7.60816],
          [-8.405477, 7.611213],
          [-8.401551, 7.611521],
          [-8.393017, 7.612191],
          [-8.388126721999981, 7.609111017000032],
          [-8.384740000999955, 7.60698],
          [-8.381670000999975, 7.59765],
          [-8.381100000999936, 7.592310001000044],
          [-8.37969, 7.57901],
          [-8.374545129999944, 7.57589521400007],
          [-8.369346567999969, 7.572750553000049],
          [-8.364408244999936, 7.573546546000046],
          [-8.35688, 7.574763],
          [-8.352422764999972, 7.576517179000064],
          [-8.346480000999975, 7.57886],
          [-8.324140955999951, 7.598041023000064],
          [-8.322505, 7.599446],
          [-8.312778694999963, 7.600421145000041],
          [-8.302776, 7.601424],
          [-8.298977459999946, 7.597120276000055],
          [-8.29865012199997, 7.596749405000025],
          [-8.299205064999967, 7.594042749000039],
          [-8.299710000999937, 7.591580001000068],
          [-8.29767, 7.58484],
          [-8.292734494999934, 7.580178690000025],
          [-8.292452, 7.579912],
          [-8.292294727999945, 7.579763411000044],
          [-8.289410000999965, 7.577040001000057],
          [-8.25728000099997, 7.56757],
          [-8.23557, 7.55194],
          [-8.233717077999927, 7.551257344000021],
          [-8.229874, 7.549843],
          [-8.21491, 7.53373],
          [-8.20504, 7.53575],
          [-8.19358, 7.54501],
          [-8.186913096999945, 7.555625120000059],
          [-8.179971, 7.566684],
          [-8.177045262999968, 7.572649013000046],
          [-8.172630000999959, 7.581660001000046],
          [-8.172054124999931, 7.612113085000033],
          [-8.17204, 7.612864],
          [-8.172037573999944, 7.612992349000024],
          [-8.17191, 7.61995],
          [-8.15725, 7.64421],
          [-8.148920000999965, 7.64934],
          [-8.1437, 7.65553],
          [-8.142587246999938, 7.666469172000063],
          [-8.140549302999943, 7.686503648000041],
          [-8.139861, 7.693283],
          [-8.12914541899994, 7.704881945000068],
          [-8.123680000999968, 7.710800001000052],
          [-8.11271, 7.713290001000075],
          [-8.101330000999951, 7.71587],
          [-8.09404, 7.72204],
          [-8.09274611, 7.735670554000023],
          [-8.09129, 7.75101],
          [-8.086, 7.76857],
          [-8.07135, 7.79023],
          [-8.070262, 7.799538],
          [-8.071770793999974, 7.808340799000064],
          [-8.07409288699995, 7.810680370000057],
          [-8.081068, 7.817705],
          [-8.082301622999978, 7.818947601000048],
          [-8.08313, 7.81978],
          [-8.11466, 7.844770001000029],
          [-8.116387429999975, 7.854353124000056],
          [-8.117181, 7.858753],
          [-8.11661111899997, 7.86240355800004],
          [-8.11557, 7.86909],
          [-8.10753387799997, 7.883917075000056],
          [-8.107179, 7.884572],
          [-8.106183, 7.885633],
          [-8.105529414999978, 7.886329359000058],
          [-8.09413000099994, 7.898480001000053],
          [-8.084776761999933, 7.912712837000072],
          [-8.082611, 7.91601],
          [-8.075221, 7.938737],
          [-8.06577, 7.95835],
          [-8.065489338999953, 7.959291827000072],
          [-8.062202, 7.970335],
          [-8.053371, 8],
          [-8.06354, 8.01108],
          [-8.062135, 8.013854],
          [-8.057645, 8.022717],
          [-8.047423, 8.02994],
          [-8.045602239999937, 8.03071826300004],
          [-8.04074, 8.032800001000055],
          [-8.03708491599997, 8.03176419500005],
          [-8.031526, 8.030193],
          [-8.029654, 8.029682],
          [-8.027311461999943, 8.031162976000076],
          [-8.024320000999978, 8.03306],
          [-8.020392008999977, 8.03275930500007],
          [-8.009818, 8.031954],
          [-8.008200923999937, 8.033581473000027],
          [-8.00329, 8.038530001000026],
          [-8.000280000999965, 8.03918],
          [-7.995216874999926, 8.037248612000042],
          [-7.989054, 8.034902],
          [-7.98726043299996, 8.031200891000026],
          [-7.9918, 8.01818],
          [-7.99067, 8.01679],
          [-7.988450259999979, 8.017419406000045],
          [-7.986046, 8.018105],
          [-7.984294, 8.010965],
          [-7.982229488999963, 8.010480350000023],
          [-7.980471066999939, 8.011685229000022],
          [-7.972703, 8.017014],
          [-7.95963, 8.012941],
          [-7.956747733999975, 8.01010650400002],
          [-7.95372, 8.007130001000064],
          [-7.950518688999978, 8.007216835000065],
          [-7.948189, 8.007283],
          [-7.945582, 8.012988],
          [-7.946386870999959, 8.014645579000046],
          [-7.94693, 8.015760001000046],
          [-7.946744809999927, 8.018710481000028],
          [-7.946344043999943, 8.025165294000033],
          [-7.947357610999973, 8.026491874000044],
          [-7.950421, 8.030499],
          [-7.946624, 8.039173],
          [-7.946581, 8.042386],
          [-7.954108, 8.044547],
          [-7.963332, 8.047195],
          [-7.96959, 8.06197],
          [-7.981113, 8.079337],
          [-7.987072, 8.081709],
          [-7.993225, 8.08684],
          [-8.010641, 8.09395],
          [-8.012717434999956, 8.098481098000036],
          [-8.014451, 8.102264],
          [-8.010151, 8.114821],
          [-8.010612890999937, 8.115340338000067],
          [-8.01434, 8.119530001000044],
          [-8.014449922999972, 8.120040287000052],
          [-8.015433, 8.124595],
          [-8.009334, 8.133704],
          [-8.007620267999926, 8.142171735000034],
          [-8.008443252999939, 8.147491369000022],
          [-8.009974, 8.157359],
          [-8.00952856799995, 8.16022262100006],
          [-8.00898, 8.16378],
          [-7.999994332999961, 8.172352850000038],
          [-7.99592, 8.176244],
          [-7.995136203999948, 8.17842984400005],
          [-7.9933, 8.183560001000046],
          [-7.993649927999968, 8.192748128000062],
          [-7.99842, 8.198545],
          [-8.004183, 8.198616],
          [-8.004994247999946, 8.197695641000053],
          [-8.006990000999963, 8.19543],
          [-8.01324127, 8.191917465000074],
          [-8.014179, 8.19139],
          [-8.01793, 8.186380001000032],
          [-8.023675, 8.187602],
          [-8.027199, 8.192967],
          [-8.03477, 8.204496],
          [-8.037530129999936, 8.205054312000073],
          [-8.0396, 8.205470001000037],
          [-8.043329228999937, 8.20207070300006],
          [-8.05142, 8.180359],
          [-8.052777892999927, 8.178708538000024],
          [-8.064510000999974, 8.16444000100006],
          [-8.068549764999943, 8.161762056000043],
          [-8.06893, 8.16151],
          [-8.084825, 8.165919581000026],
          [-8.089083, 8.1671],
          [-8.093732938999949, 8.173061639000025],
          [-8.09468967099997, 8.173595546000058],
          [-8.101743, 8.177526],
          [-8.10291078299997, 8.181305775000055],
          [-8.10374, 8.18398],
          [-8.11094000099996, 8.18875],
          [-8.126380000999973, 8.196170001000041],
          [-8.16672000099993, 8.19641],
          [-8.170164407999948, 8.197312617000023],
          [-8.179585, 8.199775],
          [-8.189421, 8.206546],
          [-8.207398, 8.227416],
          [-8.219083, 8.233978],
          [-8.224302, 8.233438],
          [-8.225082, 8.233357],
          [-8.230310000999964, 8.22603],
          [-8.238089015999947, 8.227508468000053],
          [-8.243995, 8.228629],
          [-8.245723133999945, 8.231778202000044],
          [-8.24666, 8.23348],
          [-8.247453737999933, 8.234930427000052],
          [-8.248055, 8.236025],
          [-8.248492215999931, 8.246885018000057],
          [-8.248734560999935, 8.252839279000057],
          [-8.24877, 8.25371],
          [-8.247574991999954, 8.257030023000027],
          [-8.246383, 8.260345],
          [-8.239401161999979, 8.267019128000072],
          [-8.23286, 8.273272],
          [-8.23111347899993, 8.277996273000042],
          [-8.230240117999926, 8.28035968100005],
          [-8.23175, 8.289104],
          [-8.231594598999948, 8.290756375000058],
          [-8.230693, 8.300343],
          [-8.237602, 8.320174],
          [-8.237973, 8.327757],
          [-8.229066873999955, 8.344763036000074],
          [-8.228770922999956, 8.345328238000036],
          [-8.2285696499999, 8.345949887000074],
          [-8.2276, 8.34895],
          [-8.22566, 8.354940001000045],
          [-8.225736431999962, 8.357681996000053],
          [-8.225821, 8.36068],
          [-8.23004988799994, 8.373920552000072],
          [-8.23680000099995, 8.395050001000072],
          [-8.237284485999965, 8.398981433000074],
          [-8.238372, 8.407795],
          [-8.238042987999961, 8.411757326000043],
          [-8.23788568699996, 8.413665714000047],
          [-8.23975, 8.429168],
          [-8.240520120999975, 8.430934423000053],
          [-8.245590000999925, 8.44255],
          [-8.243500000999973, 8.444720001000064],
          [-8.24284, 8.44675],
          [-8.242719805999968, 8.447101041000053],
          [-8.241584, 8.450436],
          [-8.242240919999972, 8.45543744500003],
          [-8.24243, 8.45687],
          [-8.241341273999979, 8.459421292000059],
          [-8.24077, 8.46076000100004],
          [-8.23541, 8.46575000100006],
          [-8.233789, 8.465731],
          [-8.233557342999973, 8.462653110000076],
          [-8.233390196999949, 8.460442601000068],
          [-8.230417, 8.458963],
          [-8.22675266899995, 8.45862788200003],
          [-8.22258, 8.45825],
          [-8.221053894999955, 8.459231068000065],
          [-8.220485, 8.459599],
          [-8.223381, 8.468132],
          [-8.221212, 8.470409],
          [-8.21513, 8.47268],
          [-8.21357, 8.47419],
          [-8.205989218999946, 8.481632047000062],
          [-8.205919927999958, 8.481700107000052],
          [-8.19676, 8.495370001000026],
          [-8.1983, 8.50159],
          [-8.198087199999975, 8.501761760000022],
          [-8.196898, 8.502724],
          [-8.187, 8.500996],
          [-8.184115350999946, 8.499722912000038],
          [-8.18379, 8.499580001000027],
          [-8.17824, 8.50043],
          [-8.17526, 8.49925],
          [-8.172425311999973, 8.495777632000056],
          [-8.169574, 8.492285],
          [-8.164914093999926, 8.495106129000021],
          [-8.162140000999955, 8.49679],
          [-8.158306640999967, 8.496343651000075],
          [-8.157760000999929, 8.49628000100006],
          [-8.157260690999976, 8.495336860000066],
          [-8.156406, 8.493731],
          [-8.156916, 8.492364],
          [-8.15753, 8.490715],
          [-8.157599, 8.490531],
          [-8.157032259999937, 8.486653399000033],
          [-8.156960000999959, 8.48616000100003],
          [-8.15689710199996, 8.486123740000039],
          [-8.153300000999934, 8.484050001000071],
          [-8.151669022999954, 8.487805359000049],
          [-8.150922, 8.489531],
          [-8.14699, 8.4904],
          [-8.143240000999981, 8.495180001000051],
          [-8.142134281999972, 8.494918260000077],
          [-8.139099, 8.494208],
          [-8.134102, 8.487945],
          [-8.130080712999927, 8.487293419000025],
          [-8.1295, 8.4872],
          [-8.127766368999971, 8.48812902700007],
          [-8.125318, 8.489444],
          [-8.120944, 8.488701],
          [-8.11977021499996, 8.481343151000033],
          [-8.11965967499998, 8.480650229000048],
          [-8.10903378599994, 8.48811349400006],
          [-8.103602, 8.491931],
          [-8.10175051999994, 8.496220653000023],
          [-8.09838, 8.50404],
          [-8.09536, 8.505840001000024],
          [-8.092526521999957, 8.504167578000022],
          [-8.081842458999972, 8.497861451000063],
          [-8.076901, 8.499309],
          [-8.071492315999933, 8.502643739000064],
          [-8.06959, 8.50382],
          [-8.066238365999936, 8.50591195900006],
          [-8.065125, 8.506608],
          [-8.063127946999941, 8.505801083000051],
          [-8.059846, 8.504475],
          [-8.057124242999976, 8.502133636000053],
          [-8.05551000099996, 8.50075],
          [-8.051901750999946, 8.498891710000066],
          [-8.05118, 8.498521],
          [-8.044966172999978, 8.495329089000052],
          [-8.03783348199994, 8.49363082900004],
          [-8.031118, 8.495842],
          [-8.028621516999976, 8.49560860400004],
          [-8.02259, 8.49505],
          [-8.01142181299997, 8.488498927000023],
          [-8.00977, 8.487531],
          [-8.00683129999993, 8.48846446400006],
          [-8.00282, 8.48974],
          [-7.997980000999974, 8.489450001000023],
          [-7.99065, 8.485220001000073],
          [-7.985275221999927, 8.483805584000038],
          [-7.98513993499995, 8.483770034000031],
          [-7.98049, 8.48624],
          [-7.980464045999952, 8.486316671000054],
          [-7.978093, 8.493325],
          [-7.975443472999928, 8.495313063000026],
          [-7.970871, 8.498744],
          [-7.967639, 8.498933],
          [-7.963098, 8.493361],
          [-7.96253644199993, 8.493487506000065],
          [-7.957310000999939, 8.49467],
          [-7.95134287299993, 8.493817554000032],
          [-7.950175, 8.493652],
          [-7.94449588699996, 8.498989895000022],
          [-7.9427, 8.500680001000035],
          [-7.93808, 8.501080001000048],
          [-7.929210741999952, 8.491310817000056],
          [-7.925072003999958, 8.489807460000065],
          [-7.915440000999979, 8.48631],
          [-7.913171303999945, 8.483291735000023],
          [-7.913668017999953, 8.482810203000042],
          [-7.914806, 8.481707],
          [-7.921217247999948, 8.480242920000023],
          [-7.923365631999957, 8.479751],
          [-7.918569, 8.476244],
          [-7.912547951999954, 8.465948256000047],
          [-7.911800000999961, 8.46467],
          [-7.91171735699993, 8.463180192000038],
          [-7.911430192999944, 8.458003460000043],
          [-7.913283, 8.45711],
          [-7.915274825999973, 8.460476269000026],
          [-7.91712, 8.46359],
          [-7.919439186999966, 8.462700313000028],
          [-7.919980683999938, 8.46101787300006],
          [-7.92304, 8.4515],
          [-7.921250201999953, 8.44734046900004],
          [-7.911083, 8.449043],
          [-7.910409284999957, 8.448354489000053],
          [-7.909717, 8.447647],
          [-7.909099953999942, 8.438019147000034],
          [-7.90895031499997, 8.435694875000024],
          [-7.896785, 8.433481],
          [-7.892615, 8.432723],
          [-7.884745, 8.434916],
          [-7.884272617999954, 8.433271224000066],
          [-7.884080000999973, 8.43261],
          [-7.887459880999927, 8.421400396000024],
          [-7.886596, 8.417026],
          [-7.87639, 8.421484],
          [-7.864760405999959, 8.420771647000038],
          [-7.852420000999928, 8.42002],
          [-7.84756, 8.421790001000033],
          [-7.832880000999978, 8.432850001000077],
          [-7.830991354999981, 8.436037715000055],
          [-7.833532961999936, 8.448327195000047],
          [-7.837710000999948, 8.46851],
          [-7.836188493999941, 8.475455030000035],
          [-7.835003, 8.480877],
          [-7.834141139999929, 8.482420743000034],
          [-7.8317, 8.4868],
          [-7.82727, 8.49019000100003],
          [-7.822758, 8.48232],
          [-7.820995, 8.481792],
          [-7.815832, 8.480424],
          [-7.811394501999928, 8.480209065000054],
          [-7.808260000999951, 8.480060001000027],
          [-7.807802075999973, 8.479755050000051],
          [-7.803680000999975, 8.477010001000053],
          [-7.79916, 8.470290001000023],
          [-7.79365, 8.468380001000071],
          [-7.789098455999977, 8.464323566000076],
          [-7.788409, 8.46371],
          [-7.780378, 8.443619],
          [-7.777522571999953, 8.437899842000036],
          [-7.76421, 8.411250001000042],
          [-7.75366, 8.3839],
          [-7.751740173999963, 8.378920452000045],
          [-7.750804623999954, 8.378127549000055],
          [-7.74513, 8.37332],
          [-7.71698, 8.374770001000059],
          [-7.705587329999958, 8.372793935000061],
          [-7.695356, 8.371022],
          [-7.694583020999971, 8.371287044000042],
          [-7.689570000999936, 8.37301],
          [-7.687842564999926, 8.372729500000048],
          [-7.683352, 8.372003],
          [-7.680118475999961, 8.373102109000058],
          [-7.67687, 8.374210001000051],
          [-7.66278, 8.375390001000028],
          [-7.651730000999976, 8.37455],
          [-7.643967245999931, 8.376647255000023],
          [-7.641071532999945, 8.377431856000044],
          [-7.649700000999928, 8.38788],
          [-7.650740961999929, 8.393006590000027],
          [-7.651432, 8.396402],
          [-7.654605, 8.400578],
          [-7.66603, 8.409000001000038],
          [-7.669987349999928, 8.419754155000021],
          [-7.673108, 8.428234],
          [-7.67559677099996, 8.432623640000031],
          [-7.68015, 8.44065],
          [-7.681132194999975, 8.446658274000072],
          [-7.68143, 8.44848],
          [-7.680826328999956, 8.451112333000026],
          [-7.679962, 8.454887],
          [-7.67575, 8.459430001000044],
          [-7.674515910999958, 8.462683506000076],
          [-7.671686, 8.470164],
          [-7.667126823999979, 8.477821433000031],
          [-7.666260000999955, 8.479280001000063],
          [-7.660180000999958, 8.485400001000073],
          [-7.659420000999944, 8.49067],
          [-7.660133978999966, 8.49727865400007],
          [-7.661064, 8.505851],
          [-7.663392580999925, 8.510642395000048],
          [-7.66601, 8.516020001000072],
          [-7.675064455999973, 8.525104463000048],
          [-7.67808, 8.528128],
          [-7.678926075999925, 8.529995571000029],
          [-7.682910000999925, 8.53878],
          [-7.684328644999937, 8.541920588000039],
          [-7.684360155999968, 8.541990138000074],
          [-7.68756, 8.54479],
          [-7.697020000999942, 8.58029],
          [-7.697987177999948, 8.580885511000076],
          [-7.699310000999958, 8.581700001000058],
          [-7.700345934999973, 8.587889156000074],
          [-7.701663, 8.595739],
          [-7.704161, 8.598989],
          [-7.706618956999932, 8.60514405400005],
          [-7.711540000999946, 8.61746000100004],
          [-7.712061565999932, 8.620959012000071],
          [-7.712813, 8.625978],
          [-7.722933, 8.656665],
          [-7.723921, 8.659661],
          [-7.726825, 8.66728],
          [-7.736094, 8.682563],
          [-7.73808, 8.68971],
          [-7.75067, 8.71675],
          [-7.752169503999937, 8.723451865000072],
          [-7.752631, 8.725507],
          [-7.754158849999953, 8.727712177000058],
          [-7.756490000999975, 8.73107],
          [-7.76209, 8.74447],
          [-7.763579910999965, 8.75436940000003],
          [-7.763940900999955, 8.75501188100003],
          [-7.76787, 8.762],
          [-7.77266, 8.76712],
          [-7.773971461999963, 8.767811430000052],
          [-7.776152, 8.76896],
          [-7.781603, 8.77183],
          [-7.786075508999943, 8.772591246000047],
          [-7.79036, 8.77332],
          [-7.8124, 8.76602],
          [-7.823950000999957, 8.765940001000047],
          [-7.832957487999977, 8.770468788000073],
          [-7.845527, 8.776785],
          [-7.855422961999977, 8.777344863000053],
          [-7.861220000999936, 8.77767],
          [-7.87442, 8.775310001000037],
          [-7.89673000099998, 8.76695],
          [-7.901106, 8.76531],
          [-7.915887, 8.765494],
          [-7.920611918999953, 8.767309512000054],
          [-7.925780000999964, 8.76929],
          [-7.940434, 8.779353],
          [-7.947045, 8.786557],
          [-7.961311, 8.810396],
          [-7.962561, 8.821669],
          [-7.960837015999971, 8.832292662000043],
          [-7.95778, 8.851140001000033],
          [-7.956573901999946, 8.858564022000053],
          [-7.954822, 8.869361],
          [-7.95206924799993, 8.879164751000076],
          [-7.94589, 8.901190001000032],
          [-7.93626242299996, 8.926706489000026],
          [-7.930363, 8.942351],
          [-7.919865196999979, 8.961889041000063],
          [-7.91712, 8.967],
          [-7.917441264999979, 8.968914208000058],
          [-7.917602, 8.969865],
          [-7.920782, 8.988647],
          [-7.918283, 9.004011],
          [-7.916189, 9.006518],
          [-7.913421339999957, 9.009831456000029],
          [-7.90632, 9.01834000100007],
          [-7.900094876999958, 9.02423271300006],
          [-7.896024, 9.02809],
          [-7.877067799999963, 9.035603412000057],
          [-7.86512, 9.04034],
          [-7.85379, 9.05927],
          [-7.851411247999977, 9.06140182400003],
          [-7.850898, 9.061863],
          [-7.849866271999929, 9.062790045000042],
          [-7.840930000999947, 9.07083],
          [-7.813030000999959, 9.08219],
          [-7.80381, 9.07954],
          [-7.802651846999936, 9.078356449000069],
          [-7.801531, 9.077214],
          [-7.794565334999959, 9.074481013000025],
          [-7.79002, 9.0727],
          [-7.784899485999972, 9.071782832000054],
          [-7.777567, 9.070471],
          [-7.764905915999975, 9.06617065100005],
          [-7.759093124999936, 9.06709677400005],
          [-7.754245, 9.06787],
          [-7.737269530999981, 9.075231770000073],
          [-7.737197485999957, 9.075503620000063],
          [-7.736526, 9.078054],
          [-7.740957482999931, 9.083108947000028],
          [-7.741600000999938, 9.083840001000056],
          [-7.746443689999978, 9.085631844000034],
          [-7.755172, 9.088856],
          [-7.761541, 9.096526],
          [-7.770549, 9.097109],
          [-7.770924901999933, 9.097381936000033],
          [-7.777650000999927, 9.10226],
          [-7.786410541999942, 9.105493359000036],
          [-7.788000000999943, 9.10608],
          [-7.801419346999978, 9.12257919800004],
          [-7.801967679999962, 9.122775424000054],
          [-7.808090000999925, 9.124960001000034],
          [-7.81197, 9.12916000100006],
          [-7.812596465999945, 9.133414001000062],
          [-7.813024, 9.136293],
          [-7.812062779999962, 9.140541553000048],
          [-7.82722, 9.149010001000022],
          [-7.830184212999939, 9.149434257000053],
          [-7.837843, 9.150526],
          [-7.838887069999942, 9.152485556000045],
          [-7.839200000999938, 9.15307],
          [-7.832061101999955, 9.168837566000036],
          [-7.833143, 9.174826],
          [-7.84003377099998, 9.178589782000074],
          [-7.843301500999928, 9.17840082300006],
          [-7.853, 9.177836],
          [-7.867379693999965, 9.169203848000052],
          [-7.87025, 9.16748],
          [-7.870871786999942, 9.166955517000076],
          [-7.881877, 9.157667],
          [-7.907810000999973, 9.17295],
          [-7.914900000999978, 9.179010001000051],
          [-7.916290403999938, 9.18134840700003],
          [-7.916881, 9.182337],
          [-7.919881, 9.186895],
          [-7.922934, 9.200954],
          [-7.926015, 9.212946],
          [-7.925840026999936, 9.215596713000025],
          [-7.925730000999977, 9.21731],
          [-7.920297747999939, 9.227039009000066],
          [-7.915542, 9.235562],
          [-7.906466, 9.257277],
          [-7.902009565999947, 9.262307489000023],
          [-7.90083, 9.263640001000056],
          [-7.89533, 9.277590001000021],
          [-7.893223770999953, 9.279140566000024],
          [-7.891354, 9.280523],
          [-7.887446, 9.290613],
          [-7.886144906999959, 9.297237728000027],
          [-7.8848, 9.30411],
          [-7.885561246999941, 9.307762228000058],
          [-7.886536, 9.312407],
          [-7.886476672999947, 9.328494678000027],
          [-7.88645, 9.33654],
          [-7.887800390999928, 9.34043587900004],
          [-7.888453, 9.342314],
          [-7.887539491999974, 9.345754912000075],
          [-7.88748, 9.345980001000044],
          [-7.886693958999956, 9.346739226000068],
          [-7.882791, 9.350514],
          [-7.87922, 9.358052],
          [-7.878509503999965, 9.359728992000043],
          [-7.87564, 9.36651],
          [-7.87199882699997, 9.370027811000057],
          [-7.866493, 9.375353],
          [-7.862561, 9.39254],
          [-7.859716, 9.397559],
          [-7.858212, 9.406274],
          [-7.856081151999945, 9.40992202800004],
          [-7.85708832499995, 9.411694142000044],
          [-7.857663, 9.412703],
          [-7.855549278999945, 9.417207473000076],
          [-7.85552, 9.417270001000077],
          [-7.856390000999966, 9.421650001000046],
          [-7.8554, 9.426460001000066],
          [-7.851320721999969, 9.425673053000025],
          [-7.850320000999943, 9.42548000100004],
          [-7.848992615999975, 9.428641756000047],
          [-7.848882, 9.428906],
          [-7.849984421999977, 9.437737369000047],
          [-7.857287320999944, 9.433390623000037],
          [-7.857916, 9.433016],
          [-7.865322, 9.433339],
          [-7.872233, 9.436185],
          [-7.880433950999929, 9.435768513000028],
          [-7.883123019999971, 9.435630359000072],
          [-7.885249, 9.432211],
          [-7.886969, 9.424188],
          [-7.890654130999962, 9.418492087000061],
          [-7.89169, 9.41689000100007],
          [-7.89401000099997, 9.41623],
          [-7.915738460999933, 9.419489769000052],
          [-7.918125460999931, 9.418290581000065],
          [-7.93714, 9.40873],
          [-7.94253, 9.406030001000033],
          [-7.96812, 9.396],
          [-7.973046651999937, 9.39546058600007],
          [-7.983186, 9.394347],
          [-7.983542451999938, 9.394557753000072],
          [-7.98755, 9.39692],
          [-7.999350000999925, 9.39799000100004],
          [-8.011340000999951, 9.40158000100007],
          [-8.012758995999945, 9.401524323000046],
          [-8.02884338499996, 9.40089320800007],
          [-8.047015, 9.400175],
          [-8.053896595999959, 9.400996305000035],
          [-8.06033, 9.40176],
          [-8.07353, 9.41986],
          [-8.081110000999956, 9.42432000100007],
          [-8.083590000999948, 9.42964],
          [-8.085472767999931, 9.43178628100003],
          [-8.09888, 9.447069],
          [-8.101551, 9.455608],
          [-8.106612, 9.4582],
          [-8.107265679999955, 9.460494881000045],
          [-8.108389921999958, 9.464429726000049],
          [-8.11161, 9.465847],
          [-8.114586177999968, 9.470785700000022],
          [-8.11524, 9.47187],
          [-8.11537999899997, 9.472943325000074],
          [-8.116085, 9.478315],
          [-8.118498, 9.48085],
          [-8.123171, 9.48576],
          [-8.124024505999955, 9.491513672000053],
          [-8.12861, 9.495250001000045],
          [-8.134060000999966, 9.503820001000065],
          [-8.133332021999934, 9.506573918000072],
          [-8.137630000999934, 9.514900001000058],
          [-8.135930000999963, 9.52132],
          [-8.134130095999978, 9.52346106300007],
          [-8.132874, 9.524959],
          [-8.138912630999926, 9.528773432000037],
          [-8.14252, 9.53105],
          [-8.14594728399993, 9.531565880000073],
          [-8.14923, 9.53206],
          [-8.14952707599997, 9.534229129000039],
          [-8.149864, 9.536661],
          [-8.14872189099998, 9.539505273000032],
          [-8.14748, 9.542610001000071],
          [-8.150926290999962, 9.55313432500003],
          [-8.15194, 9.556227],
          [-8.151269082999931, 9.55851444600006],
          [-8.150731, 9.560349],
          [-8.15111368, 9.562377522000077],
          [-8.151820000999976, 9.566110001000027],
          [-8.14994, 9.568620001000056],
          [-8.147717796999927, 9.569173959000068],
          [-8.14645, 9.569493],
          [-8.144315, 9.573605],
          [-8.146283, 9.583055],
          [-8.14727, 9.583955],
          [-8.237157, 9.587204],
          [-8.352451, 9.581116],
          [-8.392008, 9.567061],
          [-8.426564, 9.541073],
          [-8.438908, 9.532558],
          [-8.461775, 9.541941],
          [-8.483555, 9.544328],
          [-8.49469, 9.53517],
          [-8.484848, 9.512581],
          [-8.49681, 9.491213],
          [-8.513122, 9.49743],
          [-8.521361, 9.490517],
          [-8.534164, 9.47357],
          [-8.541859, 9.485106],
          [-8.554029, 9.477968],
          [-8.557861, 9.445757],
          [-8.577566, 9.441478],
          [-8.58395, 9.435055],
          [-8.590835, 9.456621],
          [-8.604907, 9.46108],
          [-8.611771, 9.487618],
          [-8.616615, 9.502604],
          [-8.619746, 9.497952],
          [-8.621874, 9.477815],
          [-8.634515, 9.463335],
          [-8.634532, 9.455667],
          [-8.634562, 9.443001],
          [-8.631351, 9.421031],
          [-8.626037, 9.40003],
          [-8.621932, 9.379779],
          [-8.621024, 9.35851],
          [-8.620444, 9.337312],
          [-8.622857, 9.316806],
          [-8.623387, 9.304491],
          [-8.638777, 9.288745],
          [-8.644527, 9.245836],
          [-8.662573, 9.219529],
          [-8.663333, 9.187278],
          [-8.688983, 9.1659],
          [-8.693579, 9.162068],
          [-8.709053, 9.13793],
          [-8.715391, 9.113894],
          [-8.717468, 9.10029],
          [-8.72221, 9.0872],
          [-8.727099, 9.067683],
          [-8.7289, 9.041519],
          [-8.723121, 9.014424],
          [-8.702632, 8.986367],
          [-8.699784, 8.961019],
          [-8.705963, 8.954859],
          [-8.718796, 8.942068],
          [-8.766308, 8.924608],
          [-8.795011, 8.914223],
          [-8.797379, 8.911242],
          [-8.815758, 8.888093],
          [-8.830797, 8.859161],
          [-8.837225, 8.851498],
          [-8.841395, 8.846946],
          [-8.86051, 8.831982],
          [-8.870218, 8.823968],
          [-8.884337, 8.812312],
          [-8.931355, 8.781874],
          [-8.937698, 8.776788],
          [-8.942117, 8.775102],
          [-8.949018, 8.772633],
          [-8.957743, 8.774724],
          [-8.973863, 8.780491],
          [-8.988416, 8.785887],
          [-9.010245, 8.793082],
          [-9.041169, 8.793082],
          [-9.064817, 8.798479],
          [-9.095741, 8.787654],
          [-9.114842, 8.784058],
          [-9.13758, 8.780491],
          [-9.163047, 8.7661],
          [-9.17578, 8.764279],
          [-9.197609, 8.766077],
          [-9.213071, 8.769673],
          [-9.223076, 8.780491],
          [-9.25491, 8.784957],
          [-9.27492, 8.804736],
          [-9.304025, 8.829909],
          [-9.321306, 8.850644],
          [-9.346773, 8.867669],
          [-9.374059, 8.877626],
          [-9.402254, 8.896439],
          [-9.433179, 8.894641],
          [-9.482293, 8.877559],
          [-9.536865, 8.868568],
          [-9.565061, 8.867669],
          [-9.613443, 8.874721],
          [-9.654829, 8.889325],
          [-9.667562, 8.891124],
          [-9.693029, 8.889325],
          [-9.709401, 8.878532],
          [-9.710234, 8.878532],
          [-9.722134, 8.878532],
          [-9.740325, 8.880331],
          [-9.762154, 8.892923],
          [-9.782164, 8.878532],
          [-9.796716, 8.867739],
          [-9.803546, 8.846477],
          [-9.809161, 8.845081],
          [-9.823159, 8.843727],
          [-9.831106, 8.843722],
          [-9.841326, 8.839915],
          [-9.854503, 8.836199],
          [-9.857437, 8.835372],
          [-9.860186, 8.836509],
          [-9.865659, 8.838585],
          [-9.873503, 8.84283],
          [-9.878879, 8.849269],
          [-9.883188, 8.846955],
          [-9.898879, 8.844685],
          [-9.899878, 8.835722],
          [-9.904257, 8.830521],
          [-9.913395, 8.826775],
          [-9.925501, 8.819553],
          [-9.937451, 8.814406],
          [-9.94135, 8.816599],
          [-9.952128, 8.809498],
          [-9.958191, 8.819499],
          [-9.966541, 8.814521],
          [-9.972022, 8.808269],
          [-9.984677, 8.811808],
          [-9.9896, 8.817955],
          [-9.98906, 8.828636],
          [-9.990076, 8.84203],
          [-9.995929, 8.850919],
          [-9.99594, 8.857651],
          [-9.994439, 8.865517],
          [-9.99545, 8.877007],
          [-9.998976, 8.888649],
          [-10.001065, 8.899928],
          [-10.001201, 8.909891],
          [-9.996264, 8.922111],
          [-9.99486, 8.941949],
          [-10.000112, 8.951559],
          [-10.00952, 8.952074],
          [-10.016792, 8.945882],
          [-10.016176, 8.938408],
          [-10.015895, 8.92891],
          [-10.019594, 8.922489],
          [-10.021348, 8.914166],
          [-10.026193, 8.903659],
          [-10.028601, 8.896885],
          [-10.03242, 8.893289],
          [-10.044687, 8.887321],
          [-10.059334, 8.882845],
          [-10.070233, 8.87509],
          [-10.074833, 8.87082],
          [-10.083577, 8.873334],
          [-10.093541, 8.877181],
          [-10.102449, 8.879963],
          [-10.108009, 8.882496],
          [-10.109049, 8.892158],
          [-10.10935, 8.900926],
          [-10.10874, 8.909661],
          [-10.106133, 8.919128],
          [-10.110012, 8.927062],
          [-10.110207, 8.927101],
          [-10.117587, 8.928574],
          [-10.123987, 8.929155],
          [-10.126657, 8.926314],
          [-10.129035, 8.923785],
          [-10.135835, 8.918469],
          [-10.145929, 8.915629],
          [-10.14969, 8.907857],
          [-10.155513, 8.898446],
          [-10.164442, 8.892043],
          [-10.169534, 8.88704],
          [-10.179735, 8.88472],
          [-10.183644183999945, 8.88632678700003],
          [-10.18581, 8.887217],
          [-10.186426, 8.893739],
          [-10.185537, 8.90377],
          [-10.187377, 8.915028],
          [-10.194915, 8.915072],
          [-10.205468, 8.915922],
          [-10.210338, 8.920854],
          [-10.213497, 8.930124],
          [-10.215394, 8.93963],
          [-10.219757, 8.947964],
          [-10.225222, 8.950305],
          [-10.23228, 8.950898],
          [-10.245398, 8.949264],
          [-10.251437, 8.948282],
          [-10.256542, 8.953524],
          [-10.263507, 8.956377],
          [-10.273819, 8.956113],
          [-10.274585, 8.956093],
          [-10.278412, 8.959302],
          [-10.279289, 8.970743],
          [-10.283026, 8.975664],
          [-10.281923, 8.98418],
          [-10.279751, 8.992801],
          [-10.280403, 9.003825],
          [-10.281831, 9.011282],
          [-10.282776, 9.01684],
          [-10.279524, 9.022056],
          [-10.282294, 9.030219],
          [-10.282875, 9.03193],
          [-10.285286, 9.038229],
          [-10.293069, 9.049913],
          [-10.298806, 9.059925],
          [-10.311049, 9.066865],
          [-10.327131, 9.066914],
          [-10.334002, 9.061401],
          [-10.337767, 9.053053],
          [-10.339339, 9.046346],
          [-10.339411, 9.041391],
          [-10.339558, 9.030497],
          [-10.341166, 9.020622],
          [-10.343285, 9.013259],
          [-10.350704, 9.008839],
          [-10.362033, 9.003976],
          [-10.3685, 9.003163],
          [-10.375041, 9.000055],
          [-10.382423, 8.995781],
          [-10.389292, 8.996243],
          [-10.400218, 8.992581],
          [-10.411801, 8.990229],
          [-10.418889, 8.988759],
          [-10.425357, 8.988494],
          [-10.430947, 8.98586],
          [-10.437525, 8.984572],
          [-10.443224, 8.983106],
          [-10.447316, 8.982442],
          [-10.454477, 8.980754],
          [-10.467601, 8.980597],
          [-10.467016, 9.000294],
          [-10.468407, 9.008342],
          [-10.464573, 9.016109],
          [-10.456391, 9.0276],
          [-10.444041, 9.036914],
          [-10.436552, 9.044214],
          [-10.435639, 9.049498],
          [-10.43469, 9.054637],
          [-10.434766, 9.061741],
          [-10.438788, 9.072081],
          [-10.446502, 9.078697],
          [-10.45334, 9.08925],
          [-10.453927, 9.094166],
          [-10.453966, 9.100431],
          [-10.454264, 9.109453],
          [-10.459302, 9.095507],
          [-10.466852, 9.08093],
          [-10.4769, 9.070158],
          [-10.488657, 9.066228],
          [-10.499615, 9.070484],
          [-10.527392, 9.069589],
          [-10.543676, 9.067142],
          [-10.552787, 9.066399],
          [-10.562766, 9.064424],
          [-10.572018, 9.055494],
          [-10.574655, 9.050755],
          [-10.577339, 9.05249],
          [-10.582442, 9.055789],
          [-10.591662, 9.061749],
          [-10.599707, 9.058478]
        ]
      ]
    },
    properties: {
      name: 'Nzerekore',
      center: [-8.844376743613514, 7.879012841661088],
      shapeName: 'Nzerekore',
      shapeISO: '',
      shapeID: '26780975B19606696181390',
      shapeGroup: 'GIN',
      shapeType: 'ADM1'
    }
  }
  ]
}
