<template>
  <box>
    <div class="star-product">
      <div class="title">Alerts</div>
      <div class="list">
        <scroll :header = "header"
                :rows = "list"
                :duration = "duration"
                :capacity = "10"></scroll>
      </div>
    </div>
  </box>
</template>
<script>
import Scroll from '@/components/Common/Scroll'
export default {
  components: {
    Scroll
  },
  data () {
    return {
      header: {
        headerItems: ['Name', 'Type', 'Msg', 'Time'],
        headerHeight: 120,
        headerStyle: [
          {
            width: '260px',
            fontSize: '40px'
          },
          {
            fontSize: '40px'
          },
          {
            fontSize: '40px'
          },
          {
            width: '190px',
            fontSize: '40px'
          }
        ]
      },
      rowBg: ['red', 'green'],
      duration: 3000
    }
  },
  computed: {
    list () {
      const array = this.genAlertData(this.$store.state.alert)
      return array
    }
  },
  methods: {
    genAlertData (oriList) {
      const result = []
      oriList.forEach(item => {
        const obj = {
          name: item.name,
          type: item.type,
          msg: item.value,
          time: item.time
        }
        result.push(obj)
      })
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/variable.scss";
.star-product{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .title{
    margin-bottom: 20px;
    padding-left: 20px;
    height: 80px;
    line-height: 80px;
    font-size: 40px;
    font-weight: bold;
    background: linear-gradient(to right,rgba(0,160,255,1),rgba(255,0,0,0));
  }
  .list{
    flex: 1;
    width: 100%;
    font-size: 30px;
  }
}
</style>
