<template>
  <box>
    <div class="daily">
      <div class="title">
        Overall
      </div>
      <vue-echarts :options="chartOption" />
    </div>
  </box>
</template>
<script>
const dailyOpt = {
  title: {
    show: false,
    text: 'Overall',
    textStyle: {
      color: '#fff',
      fontSize: 40
    },
    left: 20,
    top: 20
  },
  grid: {
    top: 30,
    bottom: 50,
    left: 100,
    right: 50
  },
  xAxis: {
    offset: 10,
    type: 'category',
    axisLabel: {
      fontSize: 40,
      color: '#fff'
    },
    axisLine: {
      lineStyle: {
        color: '#fff'
      }
    },
    axisTick: {
      lineStyle: {
        color: '#fff'
      }
    }
  },
  yAxis: {
    axisLabel: {
      fontSize: 30,
      color: '#fff'
    },
    splitLine: {
      lineStyle: {
        type: 'dotted'
      }
    }
  },
  series: [
    {
      type: 'bar',
      label: {
        show: true,
        color: '#fff',
        fontSize: 40,
        position: 'top'
      },
      itemStyle: {
        color: '#3398db'
      }
    }
  ]
}
export default {
  computed: {
    chartOption () {
      const dailySales = this.$store.state.dailySales
      const names = []
      const values = []
      dailySales.forEach((item) => {
        names.push(item.name)
        values.push(item.value)
      })
      dailyOpt.xAxis.data = names
      dailyOpt.series[0].data = values
      return Object.assign({}, dailyOpt)
    }
  }
}
</script>
<style lang="scss" scoped>
.daily{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .title{
    margin-bottom: 20px;
    padding-left: 20px;
    height: 80px;
    line-height: 80px;
    font-size: 40px;
    font-weight: bold;
    background: linear-gradient(to right,rgba(0,160,255,1),rgba(255,0,0,0));
  }
}
</style>
