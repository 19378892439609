<template>
  <box class="bg">
    <div v-if="!ready">loading map...</div>
    <vue-echarts v-else :options="chartOption" />
    <div class="vanta" ref="vantaRef" style="width: 100%; height: 100%"></div>
  </box>
</template>
<script>
import map from '@/assets/data/map'
import * as echarts from 'echarts/core'

import * as THREE from 'three'
import NET from 'vanta/src/vanta.net'

import { ScatterChart, LinesChart } from 'echarts/charts'
echarts.use([ScatterChart, LinesChart])

const flowOpt = {
  title: {
    text: '',
    textStyle: {
      color: '#fff',
      fontSize: 40
    },
    left: 50,
    top: 50
  },
  tooltip: {
    show: false,
    trigger: 'item',
    formatter: function (params) {
      const data = params.data
      return data.name + ':<br/>' +
             'Plate: ' + data.value[0] + '<br>' +
             'Store: ' + data.value[1]
    },
    textStyle: {
      fontSize: 40
    }
  },
  
  geo: {
    map: 'guinea',
    zoom: 1,
    // center: [10.054803766790739, -10.761524745394068],
    itemStyle: {
      areaColor: '#3034A0',
      borderColor: '#285473',
      borderWidth: 10,
      shadowColor: '#285473',
      shadowBlur: 30,
    },
    label: {
      fontSize: 40
    }
  },
  series: [
    {
      name: '',
      type: 'map',
      map: 'guinea',
      zoom: 1,
      // center: [10.054803766790739, -10.761524745394068],
      itemStyle: {
        areaColor: '#051d33',
        borderColor: '#5d666d',
        borderWidth: 2,
        
        emphasis: {
          areaColor: '#0b4f7f'
        }
      },
      label: {
        show: false,
        fontSize: 40
      },
      select: {
        itemStyle: {
          areaColor: 'green'
        }
      }
    },
    {
      type: 'effectScatter',
      z:4,
      coordinateSystem: 'geo',
      symbolSize: 30,
      rippleEffect: {
        brushType: 'stroke'
      },
      showEffectOn: 'render',
      label: {
        show: false,
        position: ['-20', '-40'],
        formatter: '{b}',
        fontSize: 40
      },
      symbol: 'circle',
      itemStyle: {
        color: '#256391'
      }
    },
    {
      type: 'lines',
      z:3,
      symbolSize: [10, 0],
      color: 'red',
      opacity: 1,
      label: {
        show: true,
        position: 'end',
        color: '#b6bcca',
        fontSize: 30,
        align: 'center',
        lineHeight: 23,
        borderWidth: 2,
        borderRadius: 10,
        backgroundColor: '#35456d',
        borderColor: '#2BD8FB',
        opacity: 1,
        padding: 14,
        formatter (item) {
          return `${item.name}\n\nPlates: {pt|${item.data.plate}}\n\nStore: {pt|${item.data.store}}`
        },
        rich: {
          name: {
            fontWeight: 'bold',
            fontSize: 16,
            color: '#fff'
          },
          pt: {
            fontSize: 30,
            color: '#2BD8FB'
          },
          date: {
            color: 'rgb(255,255,255,0.8)'
          }
        }
      },
      lineStyle: {
        color: '#398fcd',
        width: 6,
        opacity: 0.6,
        curveness: 0.2
      }
    }
  ]
}
export default {
  data () {
    return {
      ready: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.drowBg()
    }, 1000)
    
  },
  created () {
    setTimeout(() => {
      echarts.registerMap('guinea', map)// 自定义扩展图表类型，map数据一般也从接口请求
      this.ready = true
      this.mapData = this.genMapData(map.features)
    }, 200)
  },
  methods: {
    genSeries0Data (flowData) {
      return flowData.map((item) => {
        return {
          name: item.name,
          value: [item.plate, item.store]
        }
      })
    },
    genScatterData (flowData) {
      return flowData.map((item) => {
        return {
          name: item.name,
          value: this.mapData[item.name].concat(item.plate).concat(item.store),
          offset: item.offset
        }
      })
    },
    genLineData (flowData) {
      return flowData.map( item => {
        return {
          name: item.name,
          plate: item.plate,
          store: item.store,
          coords: item.coords
        }
      })
    },
    genMapData (originData) {
      const mapData = Object.create(null)
      originData.forEach((item) => {
        mapData[item.properties.name] = item.properties.center
      })
      return mapData
    },
    drowBg() {
      this.vantaEffect = NET({
        el: this.$refs.vantaRef,
        THREE: THREE,
        points: 3.00,
        maxDistance: 60.00,
        spacing: 40.00,
        pointSize: 20,
      })
      this.vantaEffect.setOptions({

        /*以下为样式配置*/
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.0,
        minWidth: 200.0,
        scale: 1.0,
        scaleMobile: 1.0,
        color: 0x0b739b,
        backgroundColor: '#031f37',
        
       
      });
    }
  },
  computed: {
    chartOption () {
      const flowData = this.$store.state.flowDirection
      const scatterData = this.genScatterData(flowData)
      const lineData = this.genLineData(flowData)
      flowOpt.geo.data = flowData
      flowOpt.series[0].data = this.genSeries0Data(flowData)
      flowOpt.series[1].data = scatterData
      flowOpt.series[2].data = lineData
      return Object.assign({}, flowOpt)
    }
  },
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
  },
}
</script>
<style lang="scss" scoped>
.bg{
  background: none !important;
}
.vanta{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>
