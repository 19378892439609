import originAxios from 'axios'

export default function axios (option) {
  return new Promise((resolve, reject) => {
    const instance = originAxios.create({
    })
    // 配置请求和响应拦截
    instance.interceptors.request.use(config => {
      // console.log('来到了request拦截success中');
      // 1.当发送网络请求时, 在页面中添加一个loading组件, 作为动画
      // 2.某些请求要求用户必须登录, 判断用户是否有token, 如果没有token跳转到login页面
      // const token = getToken()
      // if (token) {
      //   config.headers['authorization'] = `Bearer ${token}`
      // }
      // 3.对请求的参数进行序列化(看服务器是否需要序列化)
      // config.data = qs.stringify(config.data)
      // console.log(config);
      // 4.等等
      return config
    }, err => {
      // console.log('来到了request拦截failure中');
      return err
    })
    instance.interceptors.response.use(response => {
      // console.log('来到了response拦截success中');
      return response
    }, err => {
      console.log('来到了response拦截failure中')
      if (err && err.response) {
        const data = err.response.data
        switch (err.response.status) {
          case 400:
            console.log('=============400: ', data)
            break
          case 401:
            console.log('=============401: ', data)
            break
          case 500:
            break
          default:
            console.log('=============default: ', data)
        }
      }
      return Promise.reject(err)
    })

    // 2.传入对象进行网络请求
    instance(option).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
